import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 0,
      padding: '8px 24px',
      width: '100%',
    },
    section: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 2,
      padding: '8px 24px',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        columnGap: 2,
        px: 2,
        pb: 2,
        pt: 1,
      },
    },
    leftBar: {
      width: 'auto',
      left: 0,
      right: 'unset',
    },
    rightBar: {
      width: 'auto',
      left: 'unset',
      right: 0,
    },
    centerBar: {
      width: 'auto',
      bottom: 10,
      right: '50%',
      transform: 'translateX(50%)',
    },
  };
};
