import { useCallback, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { useMytaverse } from '../../../../providers/MytaverseProvider';
import ConfirmModal from '../ConfirmModal';
import { LogoutReasonEnum } from '../../../../interfaces/user';

const LogoutNotificationModal = ({ t: translate }: WithTranslation) => {
  const { isLogoutForced, logoutUser } = useMytaverse();

  const [open, setOpen] = useState(false);

  const handleConfirm = useCallback(() => {
    setOpen(false);
    logoutUser();
  }, []);

  useEffect(() => {
    setOpen(!!isLogoutForced);
  }, [isLogoutForced]);

  if (!isLogoutForced) {
    return null;
  }

  const renderModal = () => {
    switch (isLogoutForced) {
      case LogoutReasonEnum.UNAUTHORISED:
        return (
          <ConfirmModal
            title={translate('login.accessDenied')}
            message={translate('login.accessDeniedError')}
            onConfirm={handleConfirm}
            open={open}
          />
        );
      case LogoutReasonEnum.SESSION_EXPIRED:
        return (
          <ConfirmModal
            title={translate('login.sessionExpired')}
            message={translate('login.loginAgain')}
            onConfirm={handleConfirm}
            open={open}
          />
        );
      default:
        return null;
    }
  };

  return renderModal();
};

export default withTranslation('common')(LogoutNotificationModal);
