import { useMytaverse } from '../../../../../../providers/MytaverseProvider';
import { colorsEK } from '../../../../../../constants/colors';

export const useStyles = () => {
  const { appTheme } = useMytaverse();

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2.5,
      alignItems: 'center',

      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        gap: 2,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        gap: 2,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        gap: 1,
      },

      [`@media only screen and (max-height: 340px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          gap: 2,
        },
      [`@media only screen and (max-height: 310px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          gap: 1,
        },
    },
    middleBox: {
      display: 'flex',
      gap: 2.5,
      alignItems: 'center',

      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        gap: 2,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        gap: 2,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        gap: 1,
      },

      [`@media only screen and (max-height: 340px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          gap: 2,
        },

      [`@media only screen and (max-height: 310px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          gap: 1,
        },
    },
    arrow: (isActive: boolean) => ({
      display: 'flex',
      '& path': {
        stroke: isActive ? appTheme.mainColor : colorsEK.grey_50,
      },
    }),
    boundary: {
      position: 'relative',
      width: '106px',
      height: '106px',
      borderRadius: '50%',
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.46) 0%, #737373 100%)',
      boxShadow: '0px 2.66px 1.73px 0px rgba(0, 0, 0, 0.25)',

      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        width: '86px',
        height: '86px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        width: '86px',
        height: '86px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        width: '76px',
        height: '76px',
      },

      [`@media only screen and (max-height: 340px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          width: '86px',
          height: '86px',
        },

      [`@media only screen and (max-height: 310px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          width: '76px',
          height: '76px',
        },
    },
    joystick: {
      position: 'absolute',
      width: '68px',
      height: '68px',
      borderRadius: '50%',
      background:
        'linear-gradient(180deg, rgba(238, 238, 238, 0.46) 0%, rgba(115, 115, 115, 0.63) 100%)',
      boxShadow: '0px 2.66px 1.73px 0px rgba(0, 0, 0, 0.25)',
      transition: 'all 0.2s ease-in',

      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        width: '55px',
        height: '55px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        width: '55px',
        height: '55px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        width: '49px',
        height: '49px',
      },

      [`@media only screen and (max-height: 340px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          width: '55px',
          height: '55px',
        },

      [`@media only screen and (max-height: 310px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          width: '49px',
          height: '49px',
        },
    },
  };
};
