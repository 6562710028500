import React from 'react';
import { Box, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { format } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';

import { ReactComponent as ClockIcon } from '../../../../../../../public/images/ek/clock.svg';
import JoinButton from './JoinButton/JoinButton';
import {
  convertToGST,
  getMinutesBeforeEventStart,
  getTimeBeforeEvent,
  getTimeFormat,
} from './helpers';

import { useStyles } from './styles';
import { ISlotViewProps } from './interfaces';
import { MINUTES_ALLOW_TO_JOIN } from './constants';
import { EventStatus } from '../../../../../../../interfaces/event';

const SlotView = ({
  slot,
  isLoading,
  onBook,
  onCancel,
  onJoin,
  t: translate,
}: ISlotViewProps) => {
  const classes = useStyles();

  const { startTime, endTime } = slot;

  const startDate = convertToGST(startTime);
  const endDate = convertToGST(endTime);
  const timeBeforeEvent = getTimeBeforeEvent(startTime);
  const slotTime = format(startDate, 'dd MMM yyyy (EEEE)');
  const minutesBeforeEventStar = getMinutesBeforeEventStart(startTime);

  const renderAvailableSlotActions = () => {
    if (slot.isBooked) {
      return null;
    }

    return (
      <LoadingButton
        sx={classes.button}
        onClick={onBook}
        disabled={isLoading}
        loading={isLoading}
      >
        {translate('ek.officeHours.option.book')}
      </LoadingButton>
    );
  };

  const renderBookedSlotActions = () => {
    if (!slot.isBooked) {
      return null;
    }

    return (
      <Box sx={classes.actions}>
        <LoadingButton
          sx={classes.cancelButton}
          onClick={onCancel}
          loading={isLoading}
          disabled={isLoading}
        >
          {translate('ek.officeHours.option.cancel')}
        </LoadingButton>

        <JoinButton
          onClick={onJoin}
          disabled={
            minutesBeforeEventStar > MINUTES_ALLOW_TO_JOIN ||
            slot.status !== EventStatus.ACTIVE
          }
          loading={isLoading}
        />
      </Box>
    );
  };

  return (
    <Box sx={classes.root}>
      <Typography sx={classes.slotTime}>{slotTime}</Typography>
      <Box sx={classes.slotBox(slot.isBooked)}>
        <Box sx={classes.content}>
          <Box>
            <Typography sx={classes.title}>
              {slot.name ?? translate('ek.officeHours.option.title')}
            </Typography>
            <Typography sx={classes.time}>
              {getTimeFormat(startDate)}–{getTimeFormat(endDate)} GST
            </Typography>
            <Typography sx={classes.info}>
              {slot.availableNumber}{' '}
              {translate(
                slot.availableNumber === 1
                  ? 'ek.officeHours.option.seat'
                  : 'ek.officeHours.option.seats',
              )}
            </Typography>
          </Box>
          <Box sx={classes.right}>
            {timeBeforeEvent ? (
              <Box sx={classes.clock}>
                <ClockIcon />
                <Typography sx={classes.info}>{timeBeforeEvent}</Typography>
              </Box>
            ) : null}

            {renderAvailableSlotActions()}
          </Box>
        </Box>

        {renderBookedSlotActions()}
      </Box>
    </Box>
  );
};

export default withTranslation('common')(SlotView);
