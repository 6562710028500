import React from 'react';

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { IconButton } from '@mui/material';

import { IFollowMeIcon } from './interfaces';
import { WebsocketAction } from '../../../../../../../../interfaces/webSocketConnectionInfo';

import { useStyles } from './styles';
import {
  getIsAdminParticipantBusy,
  getIsParticipantBusy,
} from '../../../../../../../../helpers/followMe';

const FollowMeIcon = ({
  participant,
  pendingFollowersData,
  acceptedFollowersData,
  currentParticipantId,
  sendFollowMeMessageToEvent,
  sx = {},
}: IFollowMeIcon) => {
  const classes = useStyles();

  const followersData = pendingFollowersData.concat(acceptedFollowersData);
  const participantId = participant?.participantId || '';

  const isAdminBusy = getIsAdminParticipantBusy(
    followersData,
    currentParticipantId,
  );
  const isTargetParticipantBusy = getIsParticipantBusy(
    followersData,
    participantId,
  );
  const isTargetParticipantFree = !(isAdminBusy || isTargetParticipantBusy);

  const isPendingByCurrentParticipant = pendingFollowersData.some(
    (followerData) =>
      followerData.adminId === currentParticipantId &&
      followerData.userId === participant.participantId,
  );

  const isFollowedByCurrentParticipant = acceptedFollowersData.some(
    (followerData) =>
      followerData.adminId === currentParticipantId &&
      followerData.userId === participant.participantId,
  );

  const preventFollowing = () => {
    sendFollowMeMessageToEvent(WebsocketAction.StopFollowingByAdmin);
  };

  const cancelPendingFollowing = () => {
    sendFollowMeMessageToEvent(WebsocketAction.CancelInviteFollowingByAdmin);
  };

  const inviteFollowing = () => {
    sendFollowMeMessageToEvent(WebsocketAction.InviteFollowingByAdmin);
  };

  if (isFollowedByCurrentParticipant) {
    return (
      <IconButton sx={sx} onClick={preventFollowing}>
        <GroupRemoveIcon sx={classes.groupRemoveIcon} />
      </IconButton>
    );
  }

  if (isPendingByCurrentParticipant) {
    return (
      <IconButton sx={sx} onClick={cancelPendingFollowing}>
        <RestartAltIcon sx={classes.restartIcon} />
      </IconButton>
    );
  }

  if (isTargetParticipantFree) {
    return (
      <IconButton sx={sx} onClick={inviteFollowing}>
        <GroupAddIcon sx={classes.groupAddIcon} />
      </IconButton>
    );
  }

  return null;
};

export default FollowMeIcon;
