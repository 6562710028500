import { useTheme } from '@mui/material';
import { COLORS, colors } from '../../../../../constants/colors';
import { ScreenSizes } from '../../../../../interfaces';

export const useStyle = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& div': {
        color: COLORS.WHITE,
      },
    },
    icon: {
      color: COLORS.WHITE,
    },
    menuProps: {
      backgroundColor: colors.gray,

      '& .MuiTypography-root': {
        color: `${COLORS.WHITE} !important`,
      },

      '& .MuiIconButton-root': {
        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          display: 'none',
        },
      },
    },
  };
};
