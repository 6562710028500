import { useTheme } from '@mui/material';

import { colors, COLORS } from '../../../../../../../constants/colors';
import { ScreenSizes } from '../../../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      position: 'relative',
      '& svg': {
        width: 24,
        height: 24,
      },

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        '& svg': {
          width: 18,
          height: 18,
        },
      },
    },
    mainButton: {
      backgroundColor: COLORS.DARK_GREY,
      backdropFilter: 'blur(6px)',
      borderRadius: 72,
      padding: '15px',
      boxSizing: 'border-box',
      color: colors.white,
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden',
      '&:hover': {
        backgroundColor: COLORS.BACKGROUND_COLOR,
      },
      '&.Mui-disabled': {
        backgroundColor: COLORS.DARK_GREY,
        color: colors.white,
        opacity: 0.5,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: '11px',
      },
    },
    button: {
      p: 0,

      '&:first-of-type': {
        marginTop: 2,
        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          marginTop: 1,
        },
      },

      '&.Mui-disabled': {
        backgroundColor: COLORS.DARK_GREY,
        color: colors.white,
        opacity: 0.5,
      },
    },
    container: (active: boolean) => ({
      position: 'absolute',
      bottom: 0,
      height: active ? 154 : 56,
      overflow: 'hidden',
      transition: 'height 0.2s ease-in',
      borderRadius: 72,
      backgroundColor: COLORS.BACKGROUND_COLOR,
      border: `1px solid ${COLORS.NEW_LOGIN_GREY}`,
      boxSizing: 'border-box',
      width: 'calc(100% + 2px)',
      transform: 'translateX(50%) translateY(1px)',
      right: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: 1,
      px: 2,
      paddingBottom: '54px',

      '& svg, & hr': {
        opacity: active ? 1 : 0,
        transition: 'opacity 0s 0.05s',
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        height: active ? 110 : 42,
        paddingBottom: '40px',
        gap: 0.5,
      },
    }),
    deliver: {
      borderBottomWidth: '2px',
      borderColor: '#404040',
    },
    close: {
      color: COLORS.ERROR,
    },
  };
};
