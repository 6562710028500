import { useState } from 'react';
import { UseDrawerType } from './interfaces';

export const useDrawer: UseDrawerType = () => {
  const [open, setOpen] = useState(false);

  const openDrawer = () => setOpen(true);

  const closeDrawer = () => setOpen(false);

  const toggleDrawer = () => setOpen((prevState) => !prevState);

  return { open, openDrawer, closeDrawer, toggleDrawer };
};
