import axios from 'axios';

import { IGenerateZoomTokenProps } from '../modules/dashboard/components/DashboardContent/interfaces';
import { IZoomMediaStream } from '../modules/dashboard/components/DashboardContent/Zoom/interfaces';

const { REACT_APP_SPATIAL_MANAGER_API_URL } = process.env;

export default class ZoomService {
  static generateZoomToken(
    data: IGenerateZoomTokenProps,
  ): Promise<{ signature: string }> {
    return axios
      .post(`${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/zoom/token`, data)
      .then((res: any) => res.data);
  }

  static createZoomMediaStream(
    data: IZoomMediaStream,
  ): Promise<IZoomMediaStream> {
    return axios
      .post(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/zoom/media-streams/${data.mediaStreamId}`,
        data,
      )
      .then((res: any) => res.data)
      .catch(() => {
        throw new Error("Can't create zoom media stream");
      });
  }

  static deleteZoomMediaStream(data: { mediaStreamId: string }): Promise<any> {
    return axios
      .delete(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/zoom/media-streams/${data.mediaStreamId}`,
      )
      .then((res: any) => res.data)
      .catch(() => {
        throw new Error("Can't create zoom media stream");
      });
  }

  static updateParticipantConference({
    conferenceName,
    participantId,
    zoomUserId,
  }: {
    participantId: string;
    zoomUserId: string;
    conferenceName: string;
  }) {
    return axios
      .patch(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/zoom/participants/${participantId}/conference`,
        { conferenceName, zoomUserId },
      )
      .catch(() => {
        throw new Error("Can't update participant");
      });
  }
}
