import React from 'react';
import { isDesktop } from 'react-device-detect';

import { Swiper } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import { Box } from '@mui/material';

import NavigationWrapper from './NavigationWrapper';

import { ISwiperWrapper } from './interfaces';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import { useStyles } from './styles';

const SwiperWrapper = ({
  children,
  loop,
  breakpoints,
  sliderWidth,
}: ISwiperWrapper) => {
  const classes = useStyles({
    swiperBreakpoints: breakpoints,
    loop,
    sliderWidth,
  });

  return (
    <Box sx={classes.root}>
      <Box sx={classes.content}>
        <NavigationWrapper sx={classes.navigationBtn}>
          <Swiper
            loop={loop}
            modules={[Navigation, Pagination]}
            pagination={{
              el: '.swiper-pagination',
              clickable: true,
              dynamicBullets: !isDesktop,
              dynamicMainBullets: 5,
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            breakpoints={breakpoints}
          >
            {children}
          </Swiper>
        </NavigationWrapper>
      </Box>
      <Box sx={classes.pagination}>
        <NavigationWrapper sx={classes.paginationNavigationBtn}>
          <Box className="swiper-pagination" />
        </NavigationWrapper>
      </Box>
    </Box>
  );
};

export default SwiperWrapper;
