import { useTheme } from '@mui/material';
import { colors } from '../../../../../../../constants/colors';
import { ScreenSizes } from '../../../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {},
    header: {
      backgroundColor: colors.gray,
      padding: '20px 0px',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: '0.5px',
      color: colors.white,
      opacity: 0.4,
      textTransform: 'uppercase',
      textAlign: 'center',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        px: 2,
        py: 1,
        mx: -1,
      },
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '40px 87px',
    },
    icon: {
      width: 226,
      height: 251,
      color: colors.gray,
      opacity: 0.5,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        height: 'calc(100% - 70px)',
      },
    },
  };
};
