import React, { useState, useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import AppLoader from '../AppLoader';
import LoadingProgressTutorial from '../LoadingProgressTutorial';
import NavigationScreen from '../NavigationScreen';

import { useGameCastProgressBar } from '../../../../../modules/dashboard/components/DashboardContent/GameCast/GameCastLoader/hooks';
import { useEventContext } from '../../../../providers/EventProvider';
import { useLandscapeDetection } from '../../../../../hooks/orientation';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';

const GameCastLoader = () => {
  const { isEventRun } = useEventContext();
  const isLandscape = useLandscapeDetection();

  const [showNavigation, setShowNavigation] = useState(false);
  const { isDebugMode } = useMytaverse();

  const {
    secondStepLoading,
    secondStepCompleted,
    thirdStepLoading,
    thirdStepCompleted,
    forthStepLoading,
    forthStepCompleted,
  } = useGameCastProgressBar();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (forthStepCompleted && !isDebugMode) {
      timeoutId = setTimeout(() => {
        setShowNavigation(true);
      }, 5000);
    }

    if (isDebugMode) {
      setShowNavigation(true);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [forthStepCompleted, isDebugMode]);

  if (!isEventRun && showNavigation) {
    return <NavigationScreen isLandscape={isLandscape && !isDesktop} />;
  }

  return (
    <AppLoader isLandscape={isLandscape && !isDesktop}>
      <LoadingProgressTutorial
        secondStepLoading={secondStepLoading}
        secondStepCompleted={secondStepCompleted}
        thirdStepLoading={thirdStepLoading}
        thirdStepCompleted={thirdStepCompleted}
        forthStepLoading={forthStepLoading}
        forthStepCompleted={forthStepCompleted}
      />
    </AppLoader>
  );
};

export default GameCastLoader;
