import React from 'react';

import UserProfilePreviewView from './UserProfilePreviewView';

import { openInNewTab } from '../../../helpers';

import { IUserProfilePreview } from './interfaces';

const UserProfilePreview = ({
  user,
  enableEditing,
  showFollowMe,
  onEditClick,
  onFollow,
}: IUserProfilePreview) => {
  const fullUserName = user?.name || '';

  const handleOpenLinkedIn = () => {
    //TODO ADD LINKEDIN
    openInNewTab('');
  };

  if (!user) {
    return null;
  }

  return (
    <UserProfilePreviewView
      user={user}
      fullUserName={fullUserName}
      enableEditing={enableEditing}
      showFollowMe={showFollowMe}
      onOpenLinkedIn={handleOpenLinkedIn}
      onEditClick={onEditClick}
      onFollow={onFollow}
    />
  );
};

export default UserProfilePreview;
