import { colorsEK } from '../../../../../../../../constants/colors';

export const useStyles = () => {
  return {
    root: {
      paddingY: 1.5,
      display: 'grid',
      gap: 1.5,
      gridTemplateRows: '1fr auto',
      height: '100%',
      overflow: 'hidden',
    },
    slots: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      height: '100%',
      overflow: 'auto',
      paddingRight: 1,

      '&::-webkit-scrollbar': {
        height: '5px',
        width: '5px',
      },

      '&::-webkit-scrollbar-track': {
        backgroundColor: '#D9D9D9',
        borderRadius: '2px',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#4272DE',
        borderRadius: '2px',
        boxShadow: 'unset',
      },
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 2,
      borderTop: `1px solid ${colorsEK.white}`,
    },
    button: {
      color: colorsEK.white,
      border: `1.5px solid ${colorsEK.white}`,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1,
      textAlign: 'center',
      paddingY: 0.8,
      paddingX: 4,
      marginX: 'auto',
      borderRadius: 99,
      textTransform: 'none',
    },
    noSlotsText: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.03em',
      textAlign: 'center',
      color: colorsEK.grey_300,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  };
};
