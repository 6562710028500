import React from 'react';

import { OfficeHoursProvider } from './provider';
import OfficeHoursModalView from './OfficeHoursModalView';
import Tabs from './Tabs';

import { OfficeHoursModalProps } from './interfaces';

const OfficeHoursModal = ({ open, handleClose }: OfficeHoursModalProps) => {
  return (
    <OfficeHoursProvider>
      <OfficeHoursModalView open={open} handleClose={handleClose}>
        <Tabs />
      </OfficeHoursModalView>
    </OfficeHoursProvider>
  );
};

export default OfficeHoursModal;
