import axios from 'axios';
import Cookies from 'js-cookie';

import { BadRequest } from '../helpers/error';

import { IEventChannel } from '../interfaces/channel';

export default class ChannelService {
  static getEventChannels(eventId: string): Promise<IEventChannel[]> {
    return axios
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/chats/${eventId}/channels`,
      )
      .then((res) => res.data)
      .catch(() => {
        throw new BadRequest(new Error('Application error'));
      });
  }

  static getEventChannel(
    eventId: string,
    privateChannelId: string,
  ): Promise<IEventChannel> {
    return axios
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/chats/${eventId}/channels/${privateChannelId}`,
      )
      .then((res) => res.data)
      .catch(() => {
        throw new BadRequest(new Error('Application error'));
      });
  }

  static createEventChannel(
    eventId: string,
    userIds: string[],
  ): Promise<IEventChannel> {
    return axios
      .post(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/chats/${eventId}/channels`,
        { userIds },
      )
      .then((res) => res.data)
      .catch(() => {
        throw new BadRequest(new Error('Application error'));
      });
  }
}
