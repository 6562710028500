import $api from '../http/axios';

import { IS3File } from './interfaces';
import axios from 'axios';

export interface IUploadFileName {
  name: string;
  contentType?: string;
}

export interface IUploadFileUrl {
  name: string;
  location: string;
  url: string;
  uploadUrl: string;
  contentType?: string;
}

export default class FilesService {
  static getS3Files(files: string[]): Promise<IS3File[]> {
    return $api
      .post(
        'cms/files/retrieveFiles',
        { files },
        {
          validateStatus: (status) => {
            return status !== 403;
          },
        },
      )
      .then((res) => (res.data ? res.data.files : []));
  }

  static getUploadUrls(files: IUploadFileName[]): Promise<IUploadFileUrl[]> {
    return axios
      .post(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/files/upload-urls`,
        {
          files,
        },
      )
      .then((res) => res.data as IUploadFileUrl[]);
  }

  static async uploadFiles(files: File[]): Promise<IUploadFileUrl[]> {
    const urlsToPut = await this.getUploadUrls(
      files.map((file) => ({ name: file.name, contentType: file.type })),
    );

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const axiosInstance = axios.create(config);

    axiosInstance.interceptors.request.use((config) => {
      // @ts-ignore
      delete config.headers['Authorization'];
      return config;
    });

    await Promise.all(
      urlsToPut.map(({ uploadUrl, contentType }, index) => {
        return axiosInstance.put(uploadUrl, files[index], {
          headers: contentType
            ? { 'Content-Type': contentType, Authorization: `` }
            : {},
          sendAuth0Headers: false,
        });
      }),
    );

    return urlsToPut;
  }
}
