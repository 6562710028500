import { ILastWebsocketJsonMessage } from '../../../../interfaces/webSocketConnectionInfo';

export interface IDashboardContentView {
  openBrowserUrlDialog: boolean;
  onSubmitBrowserUrl: (url: string) => void;
  onCloseBrowserUrlDialog: () => void;
}

export type ParticipantPosition = {
  timestamp: number;
  x: number;
  y: number;
  z: number;
  r: number;
};

export enum PoiMediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  OBJECT_3D = 'OBJECT_3D',
  WEB_BROWSER = 'WEB_BROWSER',
}

export type SendToBriefcasePropsType = Pick<
  ILastWebsocketJsonMessage,
  'fileName' | 'downloadUrl'
>;

export interface SendToBriefcasePropsTypeFull extends SendToBriefcasePropsType {
  mediaType?: PoiMediaType;
}

export type StartStreamVideoRequestType = Pick<
  ILastWebsocketJsonMessage,
  'roomId' | 'region' | 'screenName'
>;

export interface IGenerateZoomTokenProps {
  role: number;
  sessionName: string;
  expirationSeconds: number;
  userIdentity?: string;
  sessionKey?: string;
  geoRegions?: string[];
  cloudRecordingOption?: number;
  cloudRecordingElection?: number;
  audioCompatibleMode?: number;
}