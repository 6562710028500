import { useMytaverse } from '../../providers/MytaverseProvider';
import { COLORS } from '../../constants/colors';

import { ILoadingProgressStyles } from './interfaces';
import { IStyles } from '../../interfaces/styles';

export const useStyles = ({
  fullHeight,
  height,
}: ILoadingProgressStyles): IStyles => {
  const { appTheme } = useMytaverse();

  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: fullHeight ? '100vh' : height,
    },
    spinner: {
      color: appTheme?.mainColor ?? COLORS.MYTAVERSE_BLUE,
    },
  };
};
