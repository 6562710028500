import { useCallback, useEffect, useState } from 'react';

import ParticipantsService from '../../../../../../services/ParticipantsService';
import AvaturnService from '../../../../../../services/AvaturnService';
import { useMytaverse } from '../../../../../../providers/MytaverseProvider';

const useAvaturnValidate = () => {
  const [isAvaturnValid, setIsAvaturnValid] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const { user, customAvatarUrl, setCurrentAvatarId, setCustomAvatarUrl } =
    useMytaverse();

  const validateAvatarUrl = useCallback(async () => {
    const resetAvaturn = async () => {
      setCurrentAvatarId('');
      setCustomAvatarUrl('');
      await ParticipantsService.updateCurrentParticipantAvatar({
        avaturnId: '',
      });
    };

    try {
      setIsProcessing(true);
      const isValid = await AvaturnService.validateUrl(customAvatarUrl || '');

      if (isValid) {
        setIsAvaturnValid(true);
      } else {
        setIsAvaturnValid(false);
        resetAvaturn();
      }

      setIsProcessing(false);
    } catch (error) {
      setIsProcessing(false);
    }
  }, []);

  useEffect(() => {
    setIsAvaturnValid(false);
  }, [user]);

  useEffect(() => {
    if (!user || !customAvatarUrl) {
      return;
    }

    validateAvatarUrl();
  }, [customAvatarUrl, validateAvatarUrl]);

  return { isProcessing, isAvaturnValid, setIsAvaturnValid };
};

export default useAvaturnValidate;
