import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Box } from '@mui/material';

import ScreenWrapper from './ScreenWrapper';
import { useStyles } from './styles';
import { useMytaverse } from '../../../../../../providers/MytaverseProvider';
import UpdateConsentButton from '../UpdateConsentButton';

interface IBeginScreen extends WithTranslation {
  onClick: () => void;
}

const BeginScreen = ({ onClick, t: translate }: IBeginScreen) => {
  const classes = useStyles();

  const { user } = useMytaverse();

  const name = user?.hrData?.name || user?.firstName;
  const subtitle = translate('ek.selectAvatar.beginScreen.description1', {
    name: name ? ` ${name}` : '',
  });

  return (
    <ScreenWrapper
      title={translate('ek.selectAvatar.title')}
      subtitle={subtitle}
      actions={
        <Box sx={classes.boxConsent}>
          <Button onClick={onClick} sx={classes.button}>
            {translate('ek.selectAvatar.beginButton')}
          </Button>
          <UpdateConsentButton />
        </Box>
      }
    />
  );
};

export default withTranslation('common')(BeginScreen);
