import { useTheme } from '@mui/material';
import { colors } from '../../../../../../constants/colors';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    title: {
      color: colors.white,
      fontSize: 24,
      lineHeight: 1,
      fontWeight: 500,
    },
    tabTitle: {
      color: colors.white,
      borderBottom: `1px solid ${colors.white}`,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1,
      letterSpacing: '0.01em',
      paddingBottom: 1,
      marginTop: 1,
    },
    divider: {
      background: colors.white_30,
      marginY: 1,
    },
  };
};
