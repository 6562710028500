import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Button, Backdrop, Modal, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import { IConfirmLogoutView } from './interfaces';

import { useStyles } from './styles';

const ConfirmModalView = ({
  icon,
  title,
  message,
  open,
  onCancel,
  onConfirm,
  confirmButtonName,
  cancelButtonName,
  t: translate,
}: IConfirmLogoutView) => {
  const classes = useStyles();
  const cancelButtonSx = { ...classes.button, ...classes.cancelButtonSx };
  const confirmButtonSx = { ...classes.button, ...classes.confirmButtonSx };

  return (
    <Modal sx={classes.root} open={open} components={{ Backdrop }}>
      <Box component="div" sx={classes.container}>
        <Box component="div" sx={classes.iconContainer}>
          {icon || <LogoutIcon />}
        </Box>
        <Typography sx={classes.title}>{title}</Typography>
        <Typography sx={classes.description}>{message}</Typography>
        <Box component="div" sx={classes.buttonContainer}>
          {onConfirm ? (
            <Button onClick={onConfirm} variant="outlined" sx={confirmButtonSx}>
              {confirmButtonName || 'OK'}
            </Button>
          ) : null}
          {onCancel ? (
            <Button onClick={onCancel} variant="outlined" sx={cancelButtonSx}>
              {cancelButtonName || translate('userProfile.cancel')}
            </Button>
          ) : null}
        </Box>
      </Box>
    </Modal>
  );
};

export default withTranslation('common')(ConfirmModalView);
