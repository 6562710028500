import React from 'react';

import LogoutButtonView from './LogoutButtonView';

import { useMytaverse } from '../../../../providers/MytaverseProvider';
import { useOpenConfirmLogoutModal } from '../../../../modules/dashboard/components/DashboardTopBar/ConfirmLogoutModal';
import { sendCustomSentryBreadcrumb } from '../../../../helpers/sentry';

import { ILogoutButtonProps } from './interfaces';
import ConfirmLogoutModal from '../ConfirmLogoutModal';

const LogoutButton = ({ sx = {} }: ILogoutButtonProps) => {
  const { logoutUser, user, appTheme } = useMytaverse();

  const {
    open: isOpenedConfirmLogoutModal,
    openModal: onOpenConfirmLogoutModal,
    closeModal: onCloseConfirmLogoutModal,
  } = useOpenConfirmLogoutModal();

  if (!user) {
    return null;
  }

  const handleLogoutUser = () => {
    sendCustomSentryBreadcrumb({
      message: 'log out in event configuration page',
    });
    logoutUser(false);
  };

  return (
    <>
      <LogoutButtonView
        logoutUser={onOpenConfirmLogoutModal}
        iconColor={appTheme.languageButton.assets.iconColor}
        sx={sx}
      />

      <ConfirmLogoutModal
        open={isOpenedConfirmLogoutModal}
        onClose={onCloseConfirmLogoutModal}
      />
    </>
  );
};

export default LogoutButton;
