import React from 'react';
import { Swiper, SwiperProps } from 'swiper/react';
import { Navigation, EffectCoverflow } from 'swiper';
import { Box } from '@mui/material';

import NavigationWrapper from './NavigationWrapper';

import { ISwiperWrapper } from './interfaces';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import { useStyles } from './styles';

const SwiperWrapper = ({
  children,
  loop,
  breakpoints,
  sliderWidth,
}: ISwiperWrapper) => {
  const classes = useStyles({
    swiperBreakpoints: breakpoints,
    loop,
    sliderWidth,
  });

  const coverflowEffect = {
    effect: 'coverflow',
    spaceBetween: 100,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 260,
      modifier: 1,
      slideShadows: true,
    },
  } as SwiperProps;

  const basicEffect = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
  } as SwiperProps;

  return (
    <Box sx={classes.root}>
      <Box sx={classes.content}>
        <NavigationWrapper sx={classes.navigationBtn}>
          <Swiper
            loop={loop}
            modules={[EffectCoverflow, Navigation]}
            {...(loop ? coverflowEffect : basicEffect)}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            breakpoints={breakpoints}
          >
            {children}
          </Swiper>
        </NavigationWrapper>
      </Box>
    </Box>
  );
};

export default SwiperWrapper;
