import React from 'react';

import { CalculateInternetSpeedType, ToggleDrawerType } from './interfaces';

export const toggleDrawer: ToggleDrawerType = (event, toggleAction) => {
  const keyboardEvent = event as React.KeyboardEvent;
  const skipHandler =
    event.type === 'keydown' &&
    (keyboardEvent.key === 'Tab' || keyboardEvent.key === 'Shift');

  if (skipHandler) {
    return;
  }

  toggleAction();
};

export const openInNewTab = (url: string) => {
  window.open(url, '_blank');
};

export const reloadPageTimeout = (timeout = 10, reason?: string) => {
  setTimeout(() => {
    if (reason) {
      console.log(`reloadPageTimeout: ${reason}`);
    }

    window.location.reload();
  }, timeout * 1000);
};

export const calculateInternetSpeed: CalculateInternetSpeedType = ({
  startTime,
  endTime,
}) => {
  const duration = (endTime - startTime) / 1000;
  const bitsLoaded = 12854484 * 8;
  const speedBps = (bitsLoaded / duration).toFixed(2);
  const speedKbps = (+speedBps / 1024).toFixed(2);
  const speedMbps = (+speedKbps / 1024).toFixed(2);
  const roundedSpeed = Math.round(Number(speedMbps));

  return roundedSpeed;
};

const THRESHOLD = 15; // 15 Mbps

export const getIsLowInternetSpeed = (speed: number): boolean =>
  speed < THRESHOLD;

export const delay = (time: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, time));
};
