import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import SelectWrapper from '../../components/SelectWrapper';
import { FormLayout } from '../../../../../modules/auth/components/Form';

import { useDevicesTestState } from '../../../../../modules/auth/pages/DevicesTestPage/hooks/state';
import { useMytaverseEvent } from '../../../../../modules/dashboard/providers';
import { useIdleTimerLogout } from '../../../../../hooks/idleTimer';

const { REACT_APP_IDLE_TIMEOUT_MINS } = process.env;

import ROUTES from '../../../../../constants/routes';

import { STEPS_CONTENT } from '../../constants/devicesSteps';

import { useStyles } from './styles';

const DevicesTestPage = () => {
  const classes = useStyles();

  const { step, resetState } = useDevicesTestState();
  const { currentSkin, currentEventId, currentEvent } = useMytaverseEvent();
  const navigate = useNavigate();
  
  useIdleTimerLogout(Number(REACT_APP_IDLE_TIMEOUT_MINS));

  useEffect(() => resetState, [resetState]);

  const onBackButtonClick = () =>
    currentSkin
      ? navigate(ROUTES.SELECT_AVATAR_SKIN(currentEventId))
      : navigate(ROUTES.AVATAR_CHOOSING(currentEventId));

  const stepsContent = React.useMemo(() => {
    const defaultRoom = currentEvent?.rooms.find((room) => room.isDefault);

    if (defaultRoom && !defaultRoom.isMultiPlayer) {
      return [STEPS_CONTENT[0], STEPS_CONTENT[2]];
    }

    return STEPS_CONTENT;
  }, [currentEvent]);

  return (
    <SelectWrapper
      onBackClick={onBackButtonClick}
      sx={{ content: { marginTop: '0px !important' } }}
    >
      <FormLayout classes={{ container: classes.formContainer }}>
        {stepsContent[step - 1]()}
      </FormLayout>
    </SelectWrapper>
  );
};

export default DevicesTestPage;
