import { useTheme } from '@mui/material';

import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { colorsEK } from '../../../../../constants/colors';
import { ScreenSizes } from '../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();

  const {
    submitButton: { backgroundColorHover },
  } = appTheme.avatarPage.assets;

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        justifyContent: 'center',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        justifyContent: 'center',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        justifyContent: 'center',
      },
      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          justifyContent: 'center',
        },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px 28px',
      width: '100%',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        px: 2,
      },
    },
    title: {
      fontFamily: 'Emirates-Medium !important',
      fontWeight: 400,
      fontSize: 32,
      lineHeight: '42px',
      letterSpacing: 'normal',
      color: colorsEK.black,
      textAlign: 'center',

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        fontSize: 18,
        lineHeight: '24px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        fontSize: 18,
        lineHeight: '24px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        fontSize: 18,
        lineHeight: '24px',
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          fontSize: 18,
          lineHeight: '24px',
        },
    },
    description: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '21px',
      color: '#707070',
      textAlign: 'center',
      marginTop: '10px',

      '@media (max-width: 340px)': {
        fontSize: 14,
        lineHeight: '18px',
      },

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        fontSize: 10,
        lineHeight: '14px',
        marginTop: 1,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        fontSize: 10,
        lineHeight: '14px',
        marginTop: 1,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        fontSize: 10,
        lineHeight: '14px',
        marginTop: 1,
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          fontSize: 10,
          lineHeight: '14px',
          marginTop: 1,
        },
    },
    footer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      alignItems: 'center',
      width: '100%',
      padding: '0px 20px',

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        gridTemplateColumns: '1fr',
        marginTop: 'auto',
        padding: '0px',
      },

      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
    },
    box: {
      display: 'grid',
    },
    nextBtn: {
      display: 'flex',
      alignItems: 'center',
      justifySelf: 'center',
      columnGap: 2,
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '16px',
      backgroundColor: appTheme.mainColor,
      color: colorsEK.white,
      borderRadius: 1,
      transition: 'none',
      boxShadow: 'none',
      padding: '8px 20px',
      textTransform: 'none',

      '& svg path': {
        stroke: colorsEK.white,
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgba(184, 184, 184, 0.5)',
        color: colorsEK.grey_500,

        '& svg path': {
          stroke: colorsEK.grey_500,
        },
      },
      '&:hover': {
        transition: 'none',
        boxShadow: 'none',
        backgroundColor: appTheme.mainColor,
        color: colorsEK.white,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 24,
        fontWeight: 500,
        lineHeight: '27px',
        gap: 1,
        width: '100%',
        backgroundColor: appTheme.mainColor,
        color: 'white',
        padding: '14px',

        '& svg': {
          display: 'none',
        },

        '&:hover': {
          backgroundColor: backgroundColorHover,
          color: colorsEK.white,
        },
      },
      '@media (max-width: 375px)': {
        fontSize: 16,
        lineHeight: '22px',
        paddingY: '8px',
      },

      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        fontSize: 14,
        lineHeight: '22px',
        padding: '6px 12px',
        maxWidth: '100px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        fontSize: 14,
        lineHeight: '22px',
        padding: '6px 12px',
        maxWidth: '100px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        fontSize: 14,
        lineHeight: '22px',
        padding: '6px 12px',
        maxWidth: '100px',
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          fontSize: 14,
          lineHeight: '22px',
          padding: '6px 12px',
          maxWidth: '100px',
        },
    },
    skipBtn: {
      justifySelf: 'flex-end',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '19px',
      color: '#939393',
      textTransform: 'capitalize',
      transition: 'none',
      boxShadow: 'none',
      justifyContent: 'end',
      padding: 0,
      '&:hover': {
        transition: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        color: colorsEK.grey_500,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        display: 'none',
      },
      [breakpoints.down(ScreenSizes.Tablet)]: {
        fontSize: 14,
        lineHeight: '24px',
        padding: 0,
        justifyContent: 'end',
      },

      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        display: 'inline-block',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        display: 'inline-block',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        display: 'inline-block',
      },
      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          display: 'inline-block',
        },
    },
    progressTop: {
      display: 'none',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        display: 'grid',
        marginBottom: '30px',
      },

      '@media (max-width: 340px)': {
        marginBottom: '20px',
      },

      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        display: 'none',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        display: 'none',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        display: 'none',
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          display: 'none',
        },
    },
    progressBottom: {
      display: 'grid',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        display: 'none',
      },
      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        display: 'grid',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        display: 'grid',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        display: 'grid',
      },
      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          display: 'grid',
        },
    },
  };
};
