import { DEVICES_LANDSCAPE_SIZES } from '../../../../../../constants/devicesSizes';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      gap: 5,
      alignItems: 'center',
      justifyContent: 'center',
      marginY: 6,

      '@media (max-width: 1400px)': {
        marginY: 3,
        gap: 3,
      },

      [breakpoints.down(DEVICES_LANDSCAPE_SIZES.TABLET)]: {
        marginY: 1,
        gap: 1,
      },

      '@media (max-width: 767px)': {
        display: 'none',
      },
    },
    keyboard: {
      padding: '28px 26px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 2,
      borderRadius: 2,
      background: 'rgba(200, 200, 200, 0.5)',

      '@media (max-width: 1400px)': {
        padding: '20px 16px',
        gap: 1,
      },
    },

    row: {
      display: 'flex',
      justifyContent: 'center',
      gap: 2,

      '@media (max-width: 1400px)': {
        gap: 1,
      },
    },
  };
};
