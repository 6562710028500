export const isFullRowInSpans = (htmlString: string) => {
  const trimmedString = htmlString.trim();
  const regex = /^(\s*<span>.*?<\/span>\s*)+$/;

  return regex.test(trimmedString);
};

export const sanitizeRow = (str: string) => {
  return str.replace(/<(?!\/?span\b)[^>]+>/g, '');
};
