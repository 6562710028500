import { colorsEK } from '../../../../../../../../constants/colors';

export const useStyles = () => {
  return {
    title: {
      marginTop: 7,
    },
    text: {
      textAlign: 'left',
      color: colorsEK.black,
      letterSpacing: 'normal',
    },
  };
};
