import { useTheme, SxProps, Theme } from '@mui/material';
import { colorsEK, COLORS } from '../../constants/colors';
import { ScreenSizes } from '../../interfaces';

import { INotificationStyles } from './interfaces';

export const useStyles = ({
  color,
  background,
  title,
  isTheme,
}: INotificationStyles) => {
  const { breakpoints } = useTheme();

  if (isTheme) {
    return {
      root: {
        padding: '38px 32px 32px',
        background,
        borderRadius: '8px',
        width: 368,
        boxShadow: '0px 4px 12px 0px #0706150D',
        position: 'relative',
        display: 'flex',
        gap: 2,
        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          width: 328,
          maxWidth: '100%',
          padding: '28px 16px 16px',
          gap: 1,
        },
      },
      closeIcon: {
        fontSize: 12,
        position: 'absolute',
        top: 10,
        right: 10,
        color: '#939393',
        cursor: 'pointer',
      },
      message: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 1.38,
        color:
          background.indexOf('#FF') !== -1 ? colorsEK.grey_500 : colorsEK.white,
        letterSpacing: '-0.4px',
        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          fontSize: 14,
        },
      },
      buttonsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 2,
        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          columnGap: 1,
        },
      },
      button: {
        color: colorsEK.white,
        background: color,
        textTransform: 'none',
        weight: 400,
        fontSize: '14px',
        lineHeight: 1.4,
        borderRadius: 1,
        paddingX: 2,
        paddingY: 1,
        letterSpacing: '-0.25px',

        '&:hover': {
          background: `${color}E6`,
        },

        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          fontSize: '12px',
          paddingX: 1,
          paddingY: 0.5,
        },
      } as SxProps<Theme>,
    };
  }

  return {
    root: {
      padding: '27px 18px 25px',
      background,
      borderRadius: '8px',
      backdropFilter: 'blur(4px)',
      border: '1px solid rgba(140, 140, 140, 0.5)',
      width: 347,
      position: 'relative',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        maxWidth: '100vh',
        padding: '16px 18px',
      },
    },
    closeIcon: {
      fontSize: 10,
      position: 'absolute',
      top: 10,
      right: 10,
      color: COLORS.WHITE,
      cursor: 'pointer',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 14,
      },
    },
    titleContainer: {
      display: 'flex',
      alignItems: title ? 'center' : 'flex-start',
      columnGap: 1,
    },
    icon: {
      fontSize: 21,
      color,
    },
    title: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: '0.1px',
      color: COLORS.WHITE,
    },
    message: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: '0.1px',
      color: 'rgba(255, 255, 255, 0.8)',
      mt: 1.5,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        mt: 1,
      },
    },
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 2,
      mt: 1.5,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        columnGap: 1,
        mt: 1,
      },
    },
    button: {
      color: COLORS.WHITE,
      border: `1px solid ${color}`,
      textTransform: 'capitalize',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 12,
      },
    },
  };
};
