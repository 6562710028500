import axios from 'axios';

import { ICustomStyles } from '../interfaces';

const { REACT_APP_SPATIAL_MANAGER_API_URL } = process.env;

export default class WorldsService {
  static getCustomStyles(
    domainName: string,
  ): Promise<{ customStyles: ICustomStyles | null }> {
    return axios
      .get(`${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/worlds/styles`, {
        params: { domainName },
      })
      .then((res: any) => res.data);
  }
}
