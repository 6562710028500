import React from 'react';
import { withTranslation } from 'react-i18next';

import { Tab, Tabs } from '@mui/material';

import EventTabLabel from './EventTabLabel';

import { EventDrawerTabs } from '../../../constants';

import { IEventTabsView } from './interfaces';

import { useStyles } from './styles';

const EventTabsView = ({
  activeTab,
  onChangeTab,
  t: translate,
}: IEventTabsView) => {
  const classes = useStyles();

  return (
    <Tabs value={activeTab} onChange={onChangeTab} sx={classes.tabs}>
      <Tab
        label={<EventTabLabel label={translate('sidebar.locations')} />}
        value={EventDrawerTabs.Locations}
        sx={classes.tab}
        disableRipple
      />
      <Tab
        label={<EventTabLabel label={translate('sidebar.participants')} />}
        value={EventDrawerTabs.Participants}
        sx={classes.tab}
        disableRipple
      />
    </Tabs>
  );
};

export default withTranslation('common')(EventTabsView);
