import { COLORS } from '../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    backgroundColor: COLORS.DARK_GREY,
    borderRadius: 72,
    m: 0,
    width: 56,
    height: 56,
    boxSizing: 'border-box',
    cursor: 'pointer',

    '& video': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
  },
});
