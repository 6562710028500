import { colors } from '../../constants/colors';

export const classes = {
  logo: ({
    maxWidth = 184,
    maxHeight = 80,
  }: {
    maxWidth?: number;
    maxHeight?: number;
  } = {}) => ({
    height: 32,
    color: colors.oxford,
    '& svg': {
      color: 'inherit',
      width: '100%',
      height: 'auto',
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      objectFit: 'contain',
    },
    '& img': {
      width: 'auto',
      height: 'auto',
      maxWidth: maxWidth,
      maxHeight: maxHeight,
    },
  }),
};
