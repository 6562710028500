import React from 'react';
import { Box, Button } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import { useFullscreen } from '../../../../hooks/fullscreen';
import { IMediaControls } from '../interfaces';

import { useStyles } from './style';

const Controls = ({ targetElement }: IMediaControls) => {
  const classes = useStyles();

  const { toggleFullscreen, isFullscreen } = useFullscreen(targetElement);

  return (
    <Box sx={classes.controls}>
      <Button onClick={toggleFullscreen} sx={classes.button}>
        {isFullscreen ? (
          <FullscreenExitIcon color="inherit" />
        ) : (
          <FullscreenIcon color="inherit" />
        )}
      </Button>
    </Box>
  );
};

export default Controls;
