import { useTheme } from '@mui/material';

import { colorsEK } from '../../../../../../constants/colors';
import { ScreenSizes } from '../../../../../../interfaces';
import { useMytaverse } from '../../../../../../providers/MytaverseProvider';

export const useStyles = (isCompleted: boolean) => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();

  return {
    root: {
      height: 4,
      width: 49,
      background: isCompleted ? appTheme.mainColor : colorsEK.grey,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        width: 22,
        height: 2,
      },
    },
  };
};
