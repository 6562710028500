import { useTheme } from '@mui/material';

import { COLORS } from '../../../../constants/colors';
import { ScreenSizes } from '../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      '& div:nth-of-type(3)': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      height: '100%',
      width: '50vw',
      maxWidth: '480px',
      backgroundColor: 'unset',
    },
    backdrop: {
      '& > :not(style)': {
        height: '100vh',
        width: '100%',
        backgroundColor: COLORS.BACKGROUND_COLOR,
        overflowY: 'scroll',

        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    main: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      py: 2.5,
      px: 3,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        py: 1,
        px: 2,
      },
    },
  };
};
