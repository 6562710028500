import { colors } from '../../../../../constants/colors';
import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../../interfaces';

const TICK =
  "data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.4336 0.947266L5.07895 12.3802L0.949012 8.19523' stroke='white' stroke-width='1.42105'/%3E%3C/svg%3E%0A";

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 1,
      position: 'relative',
    },
    icon: {
      display: 'flex',
      position: 'absolute',
      left: '18px',
      top: '50%',
      transform: 'translateY(-50%)',

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        '& svg': {
          width: '14px !important',
          height: '14px !important',
        },
      },
    },
    select: {
      color: colors.white,
      width: '100%',
      overflow: 'hidden',

      '& .MuiMenu-list': {
        padding: 0,
      },
      '& .MuiInputBase-root .MuiSvgIcon-root': {
        width: '32px',
        height: '32px',
        top: '50%',
        transform: 'translateY(-50%)',
      },
      '& .MuiInputBase-root': {
        opacity: 1,
        width: '100%',
      },
      '& .MuiSelect-select': {
        display: 'flex',
        alignItems: 'center',
        '& p': {
          paddingLeft: '36px',
          color: colors.white,
        },
      },
      '& svg': {
        color: colors.white,
        right: 0,
      },

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        '& .MuiSelect-select': {
          '& p': {
            paddingLeft: '24px',
          },
        },
      },
    },
    paper: {
      backgroundColor: '#B5B5B5',
      borderRadius: '0',
      marginTop: '20px',
      width: '616px',
      boxShadow: 'none',
      '& .MuiTypography-root': {
        color: colors.white,
        fontSize: 18,
        lineHeight: 1.6,
        fontWeight: 500,
      },
      '& .Mui-selected p.MuiTypography-root': {
        color: colors.white,
        opacity: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 4,
      },
      '& .Mui-selected p:after': {
        content: '""',
        backgroundImage: `url("${TICK}")`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: 16,
        height: 16,
        display: 'block',
        color: colors.white,
      },
      '& .MuiMenuItem-root': {
        padding: '15px 25px',
      },
      '& .MuiList-root': {
        padding: 0,
      },

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        marginTop: '10px',
        width: 'calc(100% - 80px)',
        maxWidth: '640px',
        transform: 'none !important',
        minWidth: 'unset !important',
        '& .MuiTypography-root': {
          fontSize: 10,
          lineHeight: '14px',
        },

        '& .MuiMenuItem-root': {
          padding: '8px 14px',
          minHeight: '30px',
        },

        '& .Mui-selected p:after': {
          width: 12,
          height: 12,
        },
      },
      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        maxWidth: '342px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        maxWidth: '260px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        maxWidth: '300px',
      },
    },
  };
};
