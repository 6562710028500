import { useTheme } from '@mui/material';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    homeBarButton: {
      '& button': {
        width: 56,
        height: 56,
      }
    },
    iconContainer: {
      width: 56,
      height: 56,
      position: 'absolute' as 'absolute',
    },
    icon: {
      width: '100%',
      height: 'auto',
      borderRadius: '50%',
      overflow: 'hidden',
      border: '1px solid white',
    }
  };
};