import { useMytaverse } from '../../../../../../../providers/MytaverseProvider';
import { colorsEK } from '../../../../../../../constants/colors';
import { DEVICES_LANDSCAPE_SIZES } from '../../../../../../../constants/devicesSizes';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const { appTheme } = useMytaverse();
  const { breakpoints } = useTheme();

  return {
    button: (isActive = false) => ({
      fontFamily: 'Emirates-Medium !important',
      width: '59px',
      height: '59px',
      minWidth: 'auto',
      margin: '2px',
      fontSize: 32,
      color: isActive ? colorsEK.white : colorsEK.black,
      cursor: 'pointer',
      borderRadius: 1,
      boxShadow: '0px 2px 4px 0px #00000040',
      background: isActive ? appTheme.mainColor : 'rgba(255, 255, 255, 0.5)',

      '&:hover': {
        background: isActive ? appTheme.mainColor : 'rgba(255, 255, 255, 0.5)',
        color: isActive ? colorsEK.white : colorsEK.black,
      },

      '@media (max-width: 1400px)': {
        width: '39px',
        height: '39px',
        fontSize: 22,
      },

      [breakpoints.down(DEVICES_LANDSCAPE_SIZES.TABLET)]: {
        width: '29px',
        height: '29px',
        fontSize: 18,
      },
    }),
    escape: {
      width: 'fit-content !important',
      px: '28px',
      color: 'transparent',

      '&:hover': {
        color: 'transparent',
      },
    },
  };
};
