import { getCatchErrorMessage } from './error';

import { StorageValues } from '../interfaces/storage';
import { SessionStorage } from '../constants/storage';

export const getLocalStorageValue = <T>(key: StorageValues, storageType: string = 'local'): T | null => {
  try {
    const item = storageType === 'session' ? window.sessionStorage.getItem(key) : window.localStorage.getItem(key);

    return item ? (JSON.parse(item) as T) : null;
  } catch (error) {
    throw Error(getCatchErrorMessage(error));
  }
};

export const setLocalStorageValue = (key: StorageValues, value: unknown, storageType: string = 'local') => {
  try {
    storageType === 'session' ? window.sessionStorage.setItem(key, JSON.stringify(value)) : window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    throw Error(getCatchErrorMessage(error));
  }
};

export const removeLocalStorageValue = (key: StorageValues) => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    throw Error(getCatchErrorMessage(error));
  }
};
