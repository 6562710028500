import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { useMytaverseEvent } from '../../../../../modules/dashboard/providers';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import useAvaturnValidate from './hooks/avatar';

import ConsentAvatarFlow from './ConsentFlow';
import NonConsentAvatarFlow from './NonConsentFlow';

import ROUTES from '../../../../../constants/routes';
import { useStyles } from './styles';
import useAsyncEffect from 'use-async-effect';
import AvaturnService from '../../../../../services/AvaturnService';

const ChooseAvatar = () => {
  const classes = useStyles();

  const { isAvaturnValid, isProcessing } = useAvaturnValidate();
  const navigate = useNavigate();
  const { user, currentAvatarId, customAvatarUrl } = useMytaverse();
  const { currentEvent } = useMytaverseEvent();

  useAsyncEffect(async () => {
    if (!currentEvent) {
      navigate(ROUTES.SELECT_EVENT);
    }

    if (currentAvatarId && currentAvatarId !== user.avaturnId) {
      // This means that the user uploaded their photos but didn't finish
      // saving the avatar. Delete the user data from Avaturn.
      await AvaturnService.deleteAvaturn(currentAvatarId);
    }
  }, [currentEvent]);

  return (
    <>
      <Box sx={classes.floor} />
      <Box sx={classes.root}>
        <Box sx={classes.wrapper}>
          {user.isAvatarTermsAccepted === true ? (
            <ConsentAvatarFlow
              isAvaturnValid={isAvaturnValid}
              isProcessing={isProcessing}
              currentCustomAvatarUrl={customAvatarUrl}
            />
          ) : (
            <NonConsentAvatarFlow
              isAvaturnValid={isAvaturnValid}
              isProcessing={isProcessing}
              currentCustomAvatarUrl={customAvatarUrl}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default ChooseAvatar;
