import { useTheme } from '@mui/material';
import { colors } from '../../../../../constants/colors';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    tag: {
      color: colors.white,
      background: colors.myta,
      fontSize: 8,
      lineHeight: 1,
      borderRadius: '3px',
      padding: 0.5,
      height: 'fit-content',
    },
  };
};
