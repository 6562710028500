import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import DevicesTestWrapper from '../DevicesTestWrapper';
import LoadingProgress from '../../../../../components/LoadingProgress';

import { useInitWebcamDevices } from '../../../../../modules/auth/components/WebcamTest/hooks';
import CamerasSelection from './CamerasSelection';
import { useConferenceState } from '../../../../../hooks/conferenceContext';

import { useStyles } from './styles';

const WebcamTest = () => {
  const classes = useStyles();
  const { initializing, videoRef, devices, startCameraStream } =
    useInitWebcamDevices();
  const { activeCameraDeviceId, setActiveCameraDeviceId } =
    useConferenceState();
  const { t: translate } = useTranslation('common');

  const title = translate('ek.devicesTest.webcamTest.title');
  const description = translate('ek.devicesTest.webcamTest.description');

  const handleDeviceChange = async (deviceId: string) => {
    setActiveCameraDeviceId(deviceId);
    await startCameraStream(deviceId);
  };

  if (initializing) {
    return <LoadingProgress />;
  }

  return (
    <DevicesTestWrapper
      title={title}
      description={description}
      disabled={initializing}
    >
      <Box sx={classes.root}>
        <video ref={videoRef} autoPlay playsInline />
        <CamerasSelection
          devices={devices}
          activeDeviceId={activeCameraDeviceId}
          onDeviceChange={handleDeviceChange}
          sx={classes.selection}
          menuProps={{
            PaperProps: {
              sx: classes.paper,
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          }}
        />
      </Box>
      <Typography sx={classes.helper}>
        {translate('devicesTest.webcamTest.helper')}
      </Typography>
    </DevicesTestWrapper>
  );
};

export default WebcamTest;
