import { useTheme } from '@mui/material';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    box: {
      /*width: 184,
      height: 184,

      '& img': {
        width: '100%',
        height: 'auto',
        borderRadius: '50%',
        overflow: 'hidden',
        border: '1px solid white',
      },*/

      '& .assistant .assistant-avatar': {
        position: 'relative',
        borderRadius: '50%',
        overflow: 'hidden',
        border: '1px solid white',
        zIndex: '10'
      },

      '& .assistant .ai-assistant-canvas': {
        position: 'absolute',
        marginLeft: '-288px',
        marginTop: '-96px',
      }
    },
  };
};