import { useMemo } from 'react';
import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../../../../interfaces';
import { COLORS, colors } from '../../../../../../../constants/colors';
import { IStyles } from '../../../../../../../interfaces/styles';

export const useStyles = (): IStyles => {
  const { breakpoints } = useTheme();

  return useMemo(
    () => ({
      button: {
        px: 0,
        mt: 3,
      },
      buttonLabel: {
        display: 'flex',
        alignItems: 'center',
        color: colors.white_50,
        fontSize: 24,
        fontWeight: 500,
        textTransform: 'none',
      },
      group: {
        mt: 3,
        width: '100%',
        display: 'flex',
      },

      groupName: {
        fontSize: 24,
        textAlign: 'center',
        color: colors.white_50,
        fontWeight: 500,
        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          fontSize: 20,
          color: colors.white,
        },
      },
    }),
    [breakpoints],
  );
};
