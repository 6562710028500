export enum GenderType {
  FEMALE = 'female',
  MALE = 'male',
}

export enum SkinToneType {
  LIGHT = '1',
  MEDIUM_LIGHT = '2',
  MEDIUM = '3',
  MEDIUM_DARK = '4',
  DARK = '5',
  VERY_DARK = '6',
}

export interface INonConsentData {
  gender: GenderType | null;
  skinTone: SkinToneType | null;
}
