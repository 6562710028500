export const useStyles = () => {
  return {
    root: {
      position: 'relative',

      fontSize: '16px',
      textAlign: 'left',
      color: 'black',
      paddingTop: 3,
      paddingRight: 3,

      '& .textLayer .markedContent:first-of-child': {
        display: 'none',
      },
    },
    page: {
      display: 'flex',
      flexDirection: 'column',
      gap: 0.5,
      fontSize: 16,
      letterSpacing: '0.01em',

      '& > div:first-of-type': {
        display: 'none',
      },

      '& span': {
        fontWeight: 600,

        '@media (max-width: 767px)': {
          fontSize: 16,
        },
      },

      '@media (max-width: 767px)': {
        fontWeight: 300,
      },
    },
    row: {
      marginY: 1,
    },
  };
};
