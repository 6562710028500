import { ScreenSizes } from '../../../../../../../interfaces';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 1,
    },
    text: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '20px',
      letterSpacing: '0.5px',
    },
    icon: {
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 12,
        width: 12,
        height: 12,
      },
    },
  };
};
