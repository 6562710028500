import React from 'react';
import { Button } from '@mui/material';

import { useEventContext } from '../../../../../providers/EventProvider';

import { useStyles } from './styles';

const UpdateConsentButton = () => {
  const classes = useStyles();

  const { setAvatarConsentOpen } = useEventContext();

  return (
    <Button
      onClick={() => {
        setAvatarConsentOpen(true);
      }}
      sx={classes.button}
    >
      Update Consent Settings
    </Button>
  );
};

export default UpdateConsentButton;
