import React from 'react';

import { Box, Button, Backdrop, Modal, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import { CloseIcon } from '../../../../../icons';

import { IConfirmLogoutModalView } from './interfaces';

import { useStyles } from './styles';
import { withTranslation } from 'react-i18next';

const ConfirmLogoutModalView = ({
  open,
  onClose,
  onExitButtonClick,
  t: translate,
}: IConfirmLogoutModalView) => {
  const classes = useStyles();
  const stayButtonSx = { ...classes.button, ...classes.stayButton };
  const exitButtonSx = { ...classes.button, ...classes.exitButton };

  return (
    <Modal
      sx={classes.root}
      open={open}
      onClose={onClose}
      components={{ Backdrop }}
    >
      <Box component="div" sx={classes.container}>
        <CloseIcon sx={classes.closeIcon} onClick={onClose} />
        <Box component="div" sx={classes.iconContainer}>
          <LogoutIcon />
        </Box>
        <Typography sx={classes.title}>
          {translate('logoutConfirm.title')}
        </Typography>
        <Typography sx={classes.description}>
          {translate('logoutConfirm.description')}
        </Typography>
        <Box component="div" sx={classes.buttonContainer}>
          <Button onClick={onClose} variant="outlined" sx={stayButtonSx}>
            {translate('logoutConfirm.stay')}
          </Button>
          <Button
            onClick={onExitButtonClick}
            variant="outlined"
            sx={exitButtonSx}
          >
            {translate('logoutConfirm.exit')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default withTranslation('common')(ConfirmLogoutModalView);
