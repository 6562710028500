import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import ToolboxHeader from '../../../ToolboxHeader';
import ToolboxCard from './ToolboxCard';

import { IBriefcase } from './interfaces';
import { ToolboxOption } from '../interfaces';

import { classes } from './styles';

const Briefcase = ({
  toolboxDataContext,
  onSetActiveToolboxData,
  t: translate,
}: IBriefcase) => {
  const briefcaseData = toolboxDataContext[ToolboxOption.Briefcase];
  const agendaData = toolboxDataContext[ToolboxOption.Agenda];
  const calendarData = toolboxDataContext[ToolboxOption.Calendar];
  const filesData = toolboxDataContext[ToolboxOption.Files];

  return (
    <>
      <ToolboxHeader
        icon={briefcaseData.icon}
        text={translate(briefcaseData.text)}
        onClose={briefcaseData.onClose}
      />
      <Box sx={classes.content}>
        <ToolboxCard
          icon={agendaData.icon}
          text={translate(agendaData.text)}
          onClick={onSetActiveToolboxData(ToolboxOption.Agenda)}
        />
        <ToolboxCard
          icon={calendarData.icon}
          text={translate(calendarData.text)}
          onClick={onSetActiveToolboxData(ToolboxOption.Calendar)}
        />
        <ToolboxCard
          icon={filesData.icon}
          text={translate(filesData.text)}
          onClick={onSetActiveToolboxData(ToolboxOption.Files)}
        />
      </Box>
    </>
  );
};

export default withTranslation('common')(Briefcase);
