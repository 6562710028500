import React from 'react';
import { withTranslation } from 'react-i18next';
import { Hidden } from '@mui/material';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import DashboardBarButton from '../DashboardBarButton';

import { IFullScreenButton } from './interfaces';
import { useStyles } from './styles';

const FullScreenButtonView = ({
  onClick,
  isFullscreen,
  t: translate,
}: IFullScreenButton) => {
  const classes = useStyles();

  return (
    <DashboardBarButton
      buttonIcon={isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      onClick={onClick}
      sx={classes.button}
    >
      <Hidden lgDown>
        {isFullscreen
          ? translate('tooltips.fullscreenExit')
          : translate('tooltips.fullscreen')}
      </Hidden>
    </DashboardBarButton>
  );
};

export default withTranslation('common')(FullScreenButtonView);
