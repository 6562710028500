import * as React from 'react';

import { useMytaverseEvent } from '../../providers';
import { sendCustomSentryBreadcrumb } from '../../../../helpers/sentry';
import TeleportToRoomDialogView from './TeleportToRoomDialogView';

const TeleportToRoomDialog = () => {
  const {
    openTeleportToRoomDialog,
    setOpenTeleportToRoomDialog,
    selectedTeleportRoom,
    currentRoom,
    teleportToRoom,
    closeLeftMenu,
    currentRegion,
  } = useMytaverseEvent();

  const currentRoomId = currentRoom ? currentRoom.roomId : '';

  const handleKeepSharing = () => {
    setOpenTeleportToRoomDialog(false);
  };

  const handleTeleportToRoom = () => {
    if (
      currentRoomId &&
      selectedTeleportRoom &&
      currentRoomId !== selectedTeleportRoom.roomId
    ) {
      sendCustomSentryBreadcrumb({
        message: `change room id=${selectedTeleportRoom.roomId}, name=${selectedTeleportRoom.name}. With active millicast streams`,
      });
      teleportToRoom(selectedTeleportRoom);
      closeLeftMenu();
      setOpenTeleportToRoomDialog(false);
    }
  };

  return (
    <TeleportToRoomDialogView
      currentRegion={currentRegion}
      handleKeepSharing={handleKeepSharing}
      handleTeleportToRoom={handleTeleportToRoom}
      openTeleportToRoomDialog={openTeleportToRoomDialog}
    />
  );
};

export default TeleportToRoomDialog;
