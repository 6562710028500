import { COLORS } from '../../../../../../../constants/colors';

export const useStyles = () => {
  return {
    root: {
      display: 'none',
      position: 'absolute',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 1,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 2,
      color: COLORS.WHITE,
    },
    text: {
      fontSize: 18,
      fontWeight: 600,
      textTransform: 'capitalize',
    },
  };
};
