import { useTheme } from '@mui/material';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    box: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      height: 458,
      overflow: 'auto',
      paddingRight: 1,

      '&::-webkit-scrollbar': {
        height: '5px',
        width: '5px',
      },

      '&::-webkit-scrollbar-track': {
        backgroundColor: '#D9D9D9',
        borderRadius: '2px',
      },

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#4272DE',
        borderRadius: '2px',
        boxShadow: 'unset',
      },
    },
    smsIcon: {
      fontSize: '2.5rem'
    }
  };
};
