import { Box, Typography, Stack } from '@mui/material';
import { IGamecastVideoStats } from '../interfaces';
import { colors } from '../../../../../../constants/colors';

const GamecastStats = ({ stats }: { stats: IGamecastVideoStats }) => {
  return (
    <Box
      sx={{
        backdropFilter: 'blur(10px)',
        color: colors.white,
        borderRadius: 4,
        padding: '10px 16px',
      }}
    >
      <Stack flexDirection="row" columnGap={1} justifyContent="space-between">
        <Typography>Video Resolution</Typography>
        <Typography>
          {stats.frameWidth}X{stats.frameHeight}
        </Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography>Received(kB)</Typography>
        <Typography>{stats.packetsReceived}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography>Frames Decoded </Typography>
        <Typography>{stats.framesDecoded}</Typography>
      </Stack>

      <Stack flexDirection="row" justifyContent="space-between">
        <Typography>Packets Lost</Typography>
        <Typography>{stats.packetsLost}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography>Bitrate(kbps)</Typography>
        <Typography>{Math.round(stats.totalDecodeTime)}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography>Framerate </Typography>
        <Typography>{stats.framesPerSecond}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography>Frames Dropped </Typography>
        <Typography>{stats.framesDropped}</Typography>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography>Net RTT(ms) </Typography>
        <Typography>{Math.round(stats.totalProcessingDelay)}</Typography>
      </Stack>
    </Box>
  );
};

export default GamecastStats;
