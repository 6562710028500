import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import AppHeader from '../../../components/AppHeader';
import Floor from '../../../../../modules/auth/components/Floor';

import { classes } from './styles';

const LoginWrapper = () => {
  return (
    <Box sx={classes.root}>
      <AppHeader />
      <Outlet />
      <Floor />
    </Box>
  );
};

export default LoginWrapper;
