import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import LoadingProgress from '../../../../../components/LoadingProgress';
import DevicesTestWrapper from '../DevicesTestWrapper';
import ProgressBar from '../ProgressBar';
import ProgressBarSelection from '../ProgressBarSelection';

import {
  useInitHeadphonesDevices,
  useSpeakerTest,
} from '../../../../../modules/auth/components/SpeakerTest/hooks';

import { useStyles } from './styles';

const SpeakerTest = () => {
  const { initializing, devices, activeDeviceId, setActiveDeviceId } =
    useInitHeadphonesDevices();
  const { loading, seconds, duration, isPlaying, handleSound } =
    useSpeakerTest();
  const { t: translate } = useTranslation('common');

  const classes = useStyles({ isPlaying });

  const title = translate('ek.devicesTest.speakerTest.title');
  const description = translate('ek.devicesTest.speakerTest.description');
  const buttonText = translate(
    isPlaying
      ? 'ek.devicesTest.speakerTest.pauseButton'
      : 'ek.devicesTest.speakerTest.playButton',
  );

  if (initializing || loading) {
    return <LoadingProgress />;
  }

  return (
    <DevicesTestWrapper
      title={title}
      description={description}
      disabled={isPlaying}
    >
      <Button disableRipple sx={classes.handleSoundBtn} onClick={handleSound}>
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        {buttonText}
      </Button>
      <Box sx={classes.selectionContainer}>
        <ProgressBar value={seconds} max={duration} />
        <ProgressBarSelection
          devices={devices}
          activeDeviceId={activeDeviceId}
          onDeviceChange={setActiveDeviceId}
          disabled={isPlaying}
          sx={classes.selection}
        />
      </Box>
      <Typography sx={classes.helper}>
        {translate('devicesTest.speakerTest.helper')}
      </Typography>
    </DevicesTestWrapper>
  );
};

export default SpeakerTest;
