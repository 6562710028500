import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DefaultStreamChatGenerics as StreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { useChannelActionContext, useMessageContext } from 'stream-chat-react';

import ContextMenu, {
  IContextMenuContent,
  useContextMenu,
} from '../../../../../../components/ContextMenu';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useStyles } from './styles';

const MessageContextMenu = ({ t: translate }: WithTranslation) => {
  const { anchorEl, open, handleClick, handleClose } = useContextMenu();

  const { message, handleDelete } =
    useMessageContext<StreamChatGenerics>('MessageActions');
  const { setQuotedMessage } =
    useChannelActionContext<StreamChatGenerics>('MessageActionsBox');

  const classes = useStyles(open);

  const handleReply = () => {
    setQuotedMessage(message);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(message.text || '');
  };

  const content: IContextMenuContent[] = [
    {
      text: translate('chatDrawer.reply'),
      onClick: handleReply,
    },
    {
      text: translate('chatDrawer.copy'),
      onClick: handleCopy,
    },
    {
      text: translate('chatDrawer.delete'),
      onClick: handleDelete,
    },
  ];

  return (
    <>
      <MoreVertIcon
        onClick={handleClick}
        sx={classes.icon}
        className="context-menu-icon"
      />
      {open && (
        <ContextMenu
          anchorEl={anchorEl as SVGSVGElement}
          onClose={handleClose}
          content={content}
        />
      )}
    </>
  );
};

export default withTranslation('common')(MessageContextMenu);
