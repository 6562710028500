import { COLORS } from '../../../../../../constants/colors';
import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      p: 0,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      '& .MuiCheckbox-root': {
        color: COLORS.WHITE,
      },
      '& .Mui-checked': {
        color: COLORS.MYTAVERSE_BLUE,
      },
    },

    icon: {
      color: COLORS.LIGHT_GREY,
    },

    generalButton: {
      display: 'flex',
      alignItems: 'center',
      mt: 3,
      justifyContent: 'center',
    },
    slider: {
      width: '100%',
      mt: 3,
      '& span': {
        color: COLORS.MYTAVERSE_BLUE,
      },
      '& p': {
        color: COLORS.WHITE,
        fontSize: 12,
      },
      [breakpoints.down(ScreenSizes.Pc)]: {
        mt: 1,
        width: '94%',
      },
    },
    checkBox: {
      width: '100%',
      mt: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      color: COLORS.WHITE,
      [breakpoints.down(ScreenSizes.Pc)]: {
        flexDirection: 'column',
      },
    },
    checkBoxInput: {
      color: COLORS.MYTAVERSE_BLUE,
    },
  };
};
