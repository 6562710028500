import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      width: '100vw',
      height: '100vh',
    },
    container: (isTitleVisible: boolean) => ({
      display: 'grid',
      gridTemplateRows: isTitleVisible ? 'auto auto 1fr auto' : 'auto 1fr auto',
      height: '100%',
    }),
    header: {
      position: 'relative',
      display: 'flex',
      marginTop: '40px',
      padding: '0 46px',
      justifyContent: 'center',
      [breakpoints.down(ScreenSizes.Pc)]: {
        flexDirection: 'column',
        marginTop: '6px',
        rowGap: 9,
        padding: '0 34px',
      },
      [breakpoints.down(ScreenSizes.Tablet)]: {
        marginTop: '6px',
        rowGap: 5,
        padding: '0 16px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        marginTop: 0,
        rowGap: 0,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        marginTop: 0,
        rowGap: 0,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        marginTop: 0,
        rowGap: 0,
      },
      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          marginTop: 0,
          rowGap: 0,
        },
    },
    backButton: {
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        position: 'absolute',
      },
      [breakpoints.down(ScreenSizes.Mobile)]: {
        position: 'static',
      },
    },
    content: {
      display: 'grid',
      gridTemplateRows: '1fr',
      marginTop: 5.5,
      paddingBottom: 3,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        marginTop: 3,
      },
      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        marginTop: 0,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        marginTop: 0,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        marginTop: 0,
      },
    },
  };
};
