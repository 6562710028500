import React from 'react';
import { IconButton, Typography } from '@mui/material';

import { IProps } from './interfaces';

import { useStyles } from './styles';

const RoomIndicator = ({ icon, text }: IProps) => {
  const classes = useStyles();

  return (
    <IconButton sx={classes.root}>
      {icon}
      <Typography sx={classes.text}>{text}</Typography>
    </IconButton>
  );
};

export default RoomIndicator;
