import { COLORS } from '../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    padding: '6px !important',
    '& svg': {
      width: '14px !important',
      height: '14px !important',
      color: COLORS.RED,
    },
  },
  label: {
    fontSize: 12,
    paddingLeft: 0.25,
    paddingRight: 1,
  },
});
