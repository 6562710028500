import { useCallback } from 'react';
import { SxProps } from '@mui/material';
import html2canvas from 'html2canvas';

import { useMytaverseEvent } from '../../../providers';
import { IXRHrBrandTypes } from '../../../../../interfaces/profile';

import EmiratesGoldenTicketBackground from '../../../../../public/images/ek/tickets/emirates-ticket.png';
import DnataGoldenTicketBackground from '../../../../../public/images/ek/tickets/dnata-ticket.png';
import MarhabaGoldenTicketBackground from '../../../../../public/images/ek/tickets/marhaba-ticket.png';

const useGoldenTicket = () => {
  const { goldenTicketRef } = useMytaverseEvent();

  const getTicketDate = useCallback(
    (userBrand: string): [string, SxProps, SxProps] => {
      let ticket = EmiratesGoldenTicketBackground;

      let mainTextPosition = { top: 166, left: 260 };
      let secondaryTextPosition = { top: 201, left: 1315 };

      switch (userBrand) {
        case IXRHrBrandTypes.Ekas:
        case IXRHrBrandTypes.CC:
          ticket = EmiratesGoldenTicketBackground;
          break;
        case IXRHrBrandTypes.Dnata:
          ticket = DnataGoldenTicketBackground;
          mainTextPosition = { top: 182, left: 100 };

          break;
        case IXRHrBrandTypes.Marhaba:
          ticket = MarhabaGoldenTicketBackground;
          mainTextPosition = { top: 182, left: 100 };

          break;
      }

      return [ticket, mainTextPosition, secondaryTextPosition];
    },
    [],
  );

  const downloadTicket = useCallback(async () => {
    if (!goldenTicketRef.current) {
      return;
    }

    goldenTicketRef.current.style.scale = '1';
    const canvas = await html2canvas(goldenTicketRef.current, {
      useCORS: true,
      backgroundColor: null,
    });
    const dataURL = canvas.toDataURL('image/png');

    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'golden-ticket.png';

    link.click();
    goldenTicketRef.current.style.scale = '0';
  }, []);

  return { getTicketDate, downloadTicket };
};

export default useGoldenTicket;
