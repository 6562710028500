import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { isChrome, isSafari, isIOS } from 'react-device-detect';

import Step from './Step';
import { SAFARI, CHROME, IOS_CHROME } from './contstants';
import { ITutorial } from './interfaces';
import { classes } from './styles';

const Tutorial = ({ onClose, t: translate }: ITutorial) => {
  let settings = isIOS ? IOS_CHROME : CHROME;
  if (isIOS && isSafari) settings = SAFARI;

  const isIOSChrome = isIOS && !isSafari;
  const mobile = isIOSChrome ? 'IOS' : translate('fullscreenTutorial.mobile');

  const { title, steps, device, Icon } = settings;

  return (
    <Box component="div" sx={classes.root}>
      <Box component="div" sx={classes.top}>
        <Icon />
        <Typography sx={classes.text}>
          {translate('fullscreenTutorial.description', {
            browser: title,
            mobile,
          })}
          {translate(
            isIOSChrome
              ? 'fullscreenTutorial.iosChrome'
              : 'fullscreenTutorial.general',
          )}
        </Typography>
      </Box>
      <Box sx={{ ...classes.button, ...(isChrome ? classes.buttonGreen : {}) }}>
        {device}
      </Box>

      {steps.map(({ text, image }, key) => (
        <Step
          key={key}
          title={`${translate('fullscreenTutorial.stepTitle')} ${key + 1}`}
          text={translate(text)}
          image={image}
        />
      ))}

      <Box>
        <Typography sx={classes.text}>
          {translate('fullscreenTutorial.doneText')}
        </Typography>
      </Box>

      <Button
        onClick={onClose}
        sx={{ ...classes.button, ...classes.buttonText, ...classes.buttonRed }}
      >
        {translate('fullscreenTutorial.button')}
      </Button>
    </Box>
  );
};

export default withTranslation('common')(Tutorial);
