import { useTheme } from '@mui/material';

import { SwiperScreenSizes } from '../../../../../interfaces';
import { ISwiperWrapperStyles } from './interfaces';

export const useStyles = ({
  swiperBreakpoints,
  loop,
  sliderWidth,
}: ISwiperWrapperStyles) => {
  const { breakpoints } = useTheme();

  const calculateBasicWidth = (breakpoint: SwiperScreenSizes) => {
    const slidesPerView = (swiperBreakpoints[breakpoint]?.slidesPerView ??
      1) as number;
    const spaceBetween = swiperBreakpoints[breakpoint]?.spaceBetween ?? 0;

    return (
      slidesPerView * (sliderWidth as number) +
      (slidesPerView - 1) * spaceBetween
    );
  };

  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '90%',
      maxWidth: loop ? '1183px' : '810px',
      background: loop ? 'rgba(83, 83, 83, 0.1)' : 'transparent',
      borderRadius: '6px',
      paddingY: loop ? '36px' : '0px',
      paddingX: loop ? '30px' : '0px',

      [breakpoints.down(SwiperScreenSizes.Pc)]: {
        width: '84%',
      },

      [breakpoints.down(SwiperScreenSizes.Laptop)]: {
        padding: '0',
        background: 'transparent',
        width: '100%',
      },

      '& .swiper': {
        maxWidth: loop ? '943px' : calculateBasicWidth(SwiperScreenSizes.Pc),
        width: '100%',
        margin: 0,

        [breakpoints.down(SwiperScreenSizes.Pc)]: {
          maxWidth: loop
            ? '863px'
            : calculateBasicWidth(SwiperScreenSizes.Laptop),
        },

        [breakpoints.down(SwiperScreenSizes.Laptop)]: {
          maxWidth: sliderWidth,
        },
      },

      '& .swiper-slide': {
        width: sliderWidth,

        [breakpoints.down(SwiperScreenSizes.Pc)]: {
          width: '306px',
        },

        [breakpoints.down(SwiperScreenSizes.Laptop)]: {
          width: sliderWidth,
        },
      },
    },
    navigationBtn: {
      display: loop ? 'flex' : 'none',
      [breakpoints.down(SwiperScreenSizes.Laptop)]: {
        display: 'none',
      },
    },
  };
};
