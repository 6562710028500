import { useTheme } from '@mui/material';
import { colors, colorsEK } from '../../../../../constants/colors';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      position: 'relative',
    },
    controls: {
      position: 'absolute',
      right: '12px',
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'flex',
      gap: 2,
    },
    box: {
      width: '100%',
      '& .MuiInput-root:after': {
        display: 'none',
      },
      '& .MuiInput-root:before': {
        display: 'none',
      },
    },
    button: {
      padding: 0,
    },
    icon: {
      width: 16,
      height: 16,
      display: 'block',
      color: '#AFAFAF',
    },
    input: {
      color: colorsEK.white,
      background: '#DDDDDD26',
      borderRadius: '8px',
      padding: '12px 104px 12px 12px',
      fontSize: '14px',
      height: '14px',
      lineHeight: 1,
    },
    micOn: {
      width: 16,
      height: 16,
      display: 'block',
      color: '#aa0707'
    },
  };
};
