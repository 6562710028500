import { Box } from '@mui/material';

import Briefcase from './Briefcase';
import Agenda from './Agenda';
import Calendar from './Calendar';
import Files from './Files';

import { useGetToolboxDataContext } from './hooks';

import {
  checkIsAgendaToolbox,
  checkIsBriefcaseToolbox,
  checkIsCalendarToolbox,
  checkIsFilesToolbox,
} from './helpers';

import { IToolboxContent, ToolboxOption } from './interfaces';

import { useStyles } from './styles';

const ToolboxContent = ({
  activeToolboxOption,
  setActiveToolboxOption,
  onCloseToolbox,
}: IToolboxContent) => {
  const classes = useStyles();

  const toolboxDataContext = useGetToolboxDataContext({
    onCloseToolbox,
    setActiveToolboxOption,
  });
  const context = toolboxDataContext[activeToolboxOption];

  const handleSetActiveToolboxData = (toolboxOption: ToolboxOption) => () => {
    setActiveToolboxOption(toolboxOption);
  };

  return (
    <Box sx={classes.root}>
      {checkIsBriefcaseToolbox(activeToolboxOption) && (
        <Briefcase
          toolboxDataContext={toolboxDataContext}
          onSetActiveToolboxData={handleSetActiveToolboxData}
        />
      )}
      {checkIsAgendaToolbox(activeToolboxOption) && (
        <Agenda context={context} />
      )}
      {checkIsCalendarToolbox(activeToolboxOption) && (
        <Calendar context={context} />
      )}
      {checkIsFilesToolbox(activeToolboxOption) && <Files context={context} />}
    </Box>
  );
};

export default ToolboxContent;
