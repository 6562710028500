import { colors } from '../../../../../../../../constants/colors';
import { ScreenSizes } from '../../../../../../../../interfaces';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      '& img': {
        width: 80,
        height: 100,
        border: `0.5px solid ${colors.white_40}`,
        borderRadius: '5px',
        boxSizing: 'border-box',

        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          width: 'auto',
          height: 85,
          borderWidth: '1px',
        },
      },
    },
    title: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      color: colors.white,

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 12,
      },
    },
  };
};
