import * as Yup from 'yup';

import { EMAIL_SPECIAL_SYMBOLS } from '../../../../../constants/regex';

export const LoginSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .matches(EMAIL_SPECIAL_SYMBOLS, 'validations.email.symbols')
      .email('validations.email.match')
      .required('validations.email.required')
      .max(254, 'validations.email.maxLength'),
    password: Yup.string()
      .min(8, 'validations.password.minLength')
      .required('validations.password.required'),
  });
