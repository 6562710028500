import { SxProps, useTheme } from '@mui/material';

import { COLORS, colors } from '../../../../../constants/colors';
import { ScreenSizes } from '../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    modal: {
      width: 640,
      height: 640,
      border: `2px solid ${COLORS.BLACK}`,
      p: 3,
      pt: 2.5,
    } as SxProps,

    title: {
      width: '100%',
      pb: 3,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [breakpoints.down(ScreenSizes.Pc)]: {
        pb: 1.5,
      },
    },

    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 1,
      '& svg': {
        color: COLORS.LIGHT_GREY,
      },
    },

    titleName: {
      textAlign: 'left',
      color: COLORS.WHITE,
      fontSize: 18,
      lineHeight: 3,
      [breakpoints.down(ScreenSizes.Pc)]: {
        fontSize: 16,
        lineHeight: 1.5,
      },
    },

    titleButton: {
      '& svg': {
        color: COLORS.WHITE,
      },
    },

    tablistRoot: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      maxHeight: 'calc(100% - 60px)',
      [breakpoints.down(ScreenSizes.Pc)]: {
        //maxHeight: 'unset',
        overflow: 'unset',
      },
    },

    tablist: {
      mb: 2,
      '& button': {
        textTransform: 'none',
      },

      '& .MuiTabs-flexContainer': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        columnGap: 3.8,
      },
      '& span': {
        display: 'none',
        lineHeight: 4,
      },
      [breakpoints.down(ScreenSizes.Pc)]: {
        backgroundColor: colors.gray,
        mb: 0,
        mx: -1,
      },
    },

    tab: {
      display: 'flex',
      flexDirection: 'row !important',
      alignItems: 'flex-start',
      // width: '100%',
      '& p': {
        fontSize: 24,
        fontWeight: 500,
        [breakpoints.down(ScreenSizes.Pc)]: {
          fontSize: 18,
        },
      },
    },

    tabSelect: {
      color: COLORS.MEDIUM_GREY,
      pl: 0,
      pr: 0,
      '&.MuiButtonBase-root': {
        py: 1,
      },
      '&.Mui-selected': {
        color: COLORS.WHITE,
        borderBottom: 'none',
      },
    },

    tabContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '95%',
      marginX: 'auto',
    },

    tabContentWrap: {
      width: '100%',
      maxWidth: 520,
      margin: '0 auto',
      [breakpoints.down(ScreenSizes.Pc)]: {
        overflowY: 'auto',
        px: 1,
        pb: 2,
        height: 'calc(100% - 48px)',
      },
    },
  };
};
