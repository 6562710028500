const ROUTES = {
  HOME_PAGE: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  PRIVACY_POLICY: '/privacy',
  CONFIRM_EMAIL: 'confirm',
  FORGOT_PASSWORD: 'reset_password',
  FORGOT_PASSWORD_VERIFICATION: 'verification',
  SELECT_EVENT: '/events',
  EVENT_PAGE: (eventId = ':eventId') => `/events/${eventId}`,
  AVATAR_CHOOSING: (eventId = ':eventId') => `/events/${eventId}/avatars`,
  SELECT_AVATAR_SKIN: (eventId = ':eventId') =>
    `/events/${eventId}/avatar-skins`,
  DEVICES_TEST: (eventId = ':eventId') => `/events/${eventId}/devices`,
  READY_PLAYER: (eventId = ':eventId') => `/events/${eventId}/ready-player-me`,
  AVATURN: (eventId = ':eventId') => `/events/${eventId}/avaturn`,

  GAME_TUTORIAL: '/tutorial',
  REGISTER: 'register',
  CONFIRMED_EMAIL: 'confirmed',
  CONFIRM_EVENT: 'event_subscribe',
  RECOVER_PASSWORD: 'recovery',

  TRY_DEMO: 'try_demo',
};

export default ROUTES;
