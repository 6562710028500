import React from 'react';

import { Avatar } from '@mui/material';

import ParticipantProfileModal from '../../../../components/UserProfileModal';

import { IUserAvatarButtonView } from './interfaces';

import { classes } from './styles';
import { useMytaverse } from '../../../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../../../modules/dashboard/providers';
import UserProfileModal from '../../../../components/UserProfileModal/UserProfileModal';

const UserAvatarButtonView = ({
  profileImage = '',
  showUserSettingModal,
  setShowUserSettingModal,
}: IUserAvatarButtonView) => {
  const isInEvent = useMytaverseEvent();

  const { user } = useMytaverse()

  return (
    <>
      <Avatar
        src={profileImage}
        onClick={() => setShowUserSettingModal(true)}
        sx={classes.root}
      />
      {showUserSettingModal && isInEvent ? (
        <ParticipantProfileModal
          isOpen={showUserSettingModal}
          enableEditing
          onClose={() => setShowUserSettingModal(false)}
          previewingParticipant={user as any || null}
        />
      ) : (
        <UserProfileModal
          isOpen={showUserSettingModal}
          enableEditing
          onClose={() => setShowUserSettingModal(false)}
        />
      )}
    </>
  );
};

export default UserAvatarButtonView;
