import React from 'react';

import { Avatar } from '@mui/material';

import UserProfileModal from '../UserProfileModal/ParticipantProfileModal';

import { IUserAvatarButtonView } from './interfaces';

import { classes } from './styles';
import { useMytaverse } from '../../providers/MytaverseProvider';

const UserAvatarButtonView = ({
  profileImage = '',
  showUserSettingModal,
  setShowUserSettingModal,
}: IUserAvatarButtonView) => {
  const { user } = useMytaverse();

  return (
    <>
      <Avatar
        src={profileImage}
        onClick={() => setShowUserSettingModal(true)}
        sx={classes.root}
      />
      {showUserSettingModal && (
        <UserProfileModal
          isOpen={showUserSettingModal}
          enableEditing
          previewingParticipant={(user as never) || null}
          onClose={() => setShowUserSettingModal(false)}
        />
      )}
    </>
  );
};

export default UserAvatarButtonView;
