import { useEffect, useState } from 'react';

import { useDeviceChangeType } from './interfaces';
import { DEFAULT_DEVICE } from '../../../../auth/components/SpeakerTest/constants';
import { getCatchErrorMessage } from '../../../../../helpers/error';

export const useDeviceChange: useDeviceChangeType = (setState) => {
  useEffect(() => {
    const changeDevice = () => setState(true);

    navigator.mediaDevices?.addEventListener('devicechange', changeDevice);

    return () => {
      navigator.mediaDevices?.removeEventListener('devicechange', changeDevice);
    };
  }, [setState]);
};

export const useInitSpeakerDevices = () => {
  const [initializing, setInitializing] = useState(true);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  useDeviceChange(setInitializing);

  useEffect(() => {
    if (!initializing) return;

    const init = async () => {
      if (!navigator.mediaDevices?.enumerateDevices) {
        throw new Error('enumerateDevices() not supported.');
      }

      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = (devices || []).filter(
          (device) =>
            device.kind === 'audiooutput' &&
            !device.label.toLowerCase().includes('virtual'),
        );

        const noDevices = !audioDevices.length || !audioDevices[0].deviceId;
        const nextDevices = noDevices ? [DEFAULT_DEVICE] : audioDevices;

        setDevices(nextDevices);
        setInitializing(false);
      } catch (error) {
        throw Error(getCatchErrorMessage(error));
      }
    };

    init();
  }, [initializing]);

  return {
    initializing,
    devices,
  };
};
