import { colorsEK, colors } from '../../../../../../../../constants/colors';

export const useStyles = () => {
  return {
    root: {
      display: 'flex',
      gap: 1,
      alignItems: 'center',
    },
    label: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1,
      letterSpacing: '0.01em',

      '.Mui-selected &': {
        //fontWeight: 600,
      },
    },
    badge: {
      backgroundColor: colors.white_20,
      color: colorsEK.white,
      borderRadius: '50%',
      padding: '3px',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1,
      letterSpacing: '0.01em',
      textAlign: 'center',
      minWidth: 20,

      '.Mui-selected &': {
        backgroundColor: '#4272DE',
      },
    },
  };
};
