import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Box, Modal, Backdrop, Fade } from '@mui/material';

import Drawer from '../Drawer';

import { IAppModal } from './interfaces';
import { useStyles } from './styles';

const AppModal = ({
  children,
  isOpen,
  onClick,
  sx = {},
  disableMobileDrawer = false,
}: IAppModal) => {
  const classes = useStyles();

  if (!disableMobileDrawer && isMobileOnly) {
    return (
      <Drawer isOpen={isOpen} onClick={onClick} disableHeader>
        {children}
      </Drawer>
    );
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={onClick}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <Box sx={[classes.modal, ...(Array.isArray(sx) ? sx : [sx])]}>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

export default AppModal;
