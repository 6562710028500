import { MytaverseLogger } from './logger';
import { getCatchErrorMessage } from './error';

type PermissionName = 'geolocation' | 'notifications' | 'camera' | 'microphone';

export class NavigatorPermissionsService {
  static async checkPermission(
    permissionName: PermissionName,
  ): Promise<PermissionStatus | null> {
    if (!navigator?.permissions?.query) {
      MytaverseLogger.error('navigator.permissions API is not available');
      return null;
    }

    try {
      const permissionStatus = await navigator.permissions.query({
        // eslint-disable-next-line
        name: permissionName as any,
      });

      return permissionStatus;
    } catch (error) {
      MytaverseLogger.error(getCatchErrorMessage(error));
      return null;
    }
  }

  static async checkAndEnsurePermission(
    permissionName: PermissionName,
  ): Promise<PermissionStatus | Pick<PermissionStatus, 'state'>> {
    const permissionStatus = await NavigatorPermissionsService.checkPermission(
      permissionName,
    );

    if (permissionStatus) {
      return permissionStatus;
    }

    return { state: 'granted' };
  }
}
