import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import HomeBarButton from '../../../../../../modules/dashboard/components/HomeBarButton';

import OfficeHoursModal from '../OfficeHoursModal';

import { useOpenDevicesSettingsModal } from '../../../../../../modules/dashboard/components/HomeBottomBar/HomeBottomButtonOptions/hooks';

import { ReactComponent as OfficeHoursIcon } from '../../../../../../public/images/ek/office-hours.svg';
import { useMytaverseEvent } from '../../../../../../modules/dashboard/providers';

const OfficeHoursButton = ({ t: translate }: WithTranslation) => {
  const modalData = useOpenDevicesSettingsModal();

  const { currentEvent } = useMytaverseEvent();

  if (
    !currentEvent?.inviteSlotsTags ||
    currentEvent.inviteSlotsTags.length === 0
  ) {
    return null;
  }

  return (
    <>
      <HomeBarButton
        label={translate('ek.officeHours.tooltip')}
        icon={<OfficeHoursIcon />}
        handleClick={modalData.switchOpenModal}
      />

      <OfficeHoursModal
        open={modalData.open}
        handleClose={modalData.closeModal}
      />
    </>
  );
};

export default withTranslation('common')(OfficeHoursButton);
