import { useTheme } from '@mui/material';

import { COLORS, colors } from '../../../../../../../constants/colors';
import { ScreenSizes } from '../../../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      width: '100%',
      mt: 2,
      cursor: 'pointer',
      '&:hover': {
        '& button': {
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          alignItems: 'center',
        },
        '& .img': {
          filter: 'brightness(0.5)',
        },
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        display: 'flex',
        flexDirection: 'column-reverse',
        gap: 1,
      },
    },
    roomImageContainer: {
      border: '1px solid #FFFFFF1A',
      borderRadius: 1,
      width: '100%',
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'hidden',
      '& img': {
        width: '100%',
        height: 'auto',
        maxHeight: '280px !important',
        objectFit: 'cover',
        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          maxHeight: '100px !important',
        },
      },
    },
    roomNameContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      mt: 1,
      '& svg': {
        color: COLORS.GREEN,
        fontSize: 12,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        mt: 0,
      },
    },
    roomName: (isCurrentRoom: boolean) => ({
      color: isCurrentRoom ? COLORS.WHITE : colors.white_50,
      textTransform: 'capitalize',
    }),
    online: {
      display: 'flex',
      alignItems: 'center',
      color: COLORS.WHITE,
      columnGap: 1,
    },
    text: {
      fontWeight: 600,
      letterSpacing: '0.45px',
      fontSize: 18,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 16,
      },
    },
  };
};
