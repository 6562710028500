import React from 'react';
import { Tooltip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { withTranslation } from 'react-i18next';

import { IJoinButton } from './interfaces';
import { useStyles } from '../styles';
import { MINUTES_ALLOW_TO_JOIN } from '../constants';

const JoinButton = ({
  onClick,
  disabled,
  loading,
  t: translate,
}: IJoinButton) => {
  const classes = useStyles();
  const renderButton = () => (
    <LoadingButton
      sx={classes.button}
      onClick={onClick}
      disabled={disabled || loading}
      // loading={loading}
    >
      {translate('ek.officeHours.option.join')}
    </LoadingButton>
  );

  if (disabled && !loading) {
    return (
      <Tooltip
        title={`You can join ${MINUTES_ALLOW_TO_JOIN} minutes before your session.`}
        arrow
        componentsProps={{
          tooltip: { sx: classes.tooltip },
          arrow: { sx: classes.tooltipArrow },
        }}
      >
        <span>{renderButton()}</span>
      </Tooltip>
    );
  }

  return renderButton();
};

export default withTranslation('common')(JoinButton);
