import React, {
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IZoomContext } from './interfaces';
import ZoomVideo, {
  CaptureVideoOption,
  LocalAudioTrack,
  MediaDevice,
  Participant,
  Stream,
  VideoClient,
} from '@zoom/videosdk';
import useAsyncEffect from 'use-async-effect';
import { useMytaverseEvent } from '../../../providers';
import ZoomService from '../../../../../services/ZoomService';
import { useConference } from '../Dolby';
import { useConferenceState } from '../../../../../hooks/conferenceContext';
import { useEventContext } from '../../../../../ek/providers/EventProvider';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const ZoomContext = React.createContext<IZoomContext>({});

export const useZoom = () => useContext(ZoomContext);

type ZoomProviderProps = {
  zmClient: typeof VideoClient;
  children: ReactNode;
};

export const ZoomProvider: React.FC<ZoomProviderProps> = ({
  zmClient,
  children,
}) => {
  const {
    currentParticipant,
    currentRegion,
    currentRoom,
    muted,
    currentEvent,
    isGamecastCrash,
  } = useMytaverseEvent();

  const { isEventRun } = useEventContext();

  const {
    ueWebcamScreenName,
    setVideoStarted,
    ueScreenScreenName,
    ueScreenMediaStreams,
  } = useConference();

  const [loading, setIsLoading] = useState(true);
  const [mediaStream, setMediaStream] = useState<typeof Stream | null>(null);
  const [isSupportGalleryView, setIsSupportGalleryView] =
    useState<boolean>(false);
  const [localAudioTrack, setLocalAudioTrack] =
    useState<LocalAudioTrack | null>(null);
  const [zoomToken, setZoomToken] = useState<string | null>(null);
  const [zoomUE5Token, setZoomUE5Token] = useState<string | null>(null);
  const [isInConference, setIsInConference] = useState(false);
  const [micList, setMicList] = useState<MediaDevice[]>(
    mediaStream?.getMicList() ?? [],
  );
  const [speakerList, setSpeakerList] = useState<MediaDevice[]>(
    mediaStream?.getSpeakerList() ?? [],
  );
  const [cameraList, setCameraList] = useState<MediaDevice[]>(
    mediaStream?.getCameraList() ?? [],
  );
  const [isCurrentParticipantSpeaking, setIsCurrentParticipantSpeaking] =
    useState(false);

  const [zoomConferenceName, setZoomConferenceName] = useState<string | null>(
    null,
  );
  const [zoomUser, setZoomUser] = useState<Participant | null>(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isSharingToScreen, setIsSharingToScreen] = useState(false);
  const [isSharingScreenToUE, setIsSharingScreenToUE] = useState(false);
  const [isLimitedScreenResolution, setIsLimitedScreenResolution] =
    useState(false);
  const [expandedScreenSharing, setExpandedScreenSharing] = useState(false);
  const shareViewRef = useRef<{
    selfShareRef: HTMLCanvasElement | HTMLVideoElement | null;
  }>(null);

  const [isJoiningConference, setIsJoiningConference] = useState(false);

  const { activeSpeakerDeviceId, activeMicroDeviceId, activeCameraDeviceId } =
    useConferenceState();

  useAsyncEffect(async () => {
    if (!zmClient) {
      return;
    }

    try {
      setIsLoading(true);
      checkPermissions();
      await zmClient.init('en-US', 'Global', { patchJsMedia: true });

      const stream = zmClient.getMediaStream();
      const localAudio = ZoomVideo.createLocalAudioTrack();

      setLocalAudioTrack(localAudio);
      setMediaStream(stream);
      setIsSupportGalleryView(stream.isSupportMultipleVideos());
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      console.log(e.reason);
    }

    return () => {
      ZoomVideo.destroyClient();
    };
  }, [zmClient]);

  useAsyncEffect(async () => {
    if (!mediaStream || !mediaStream?.getCameraList().length) {
      return;
    }

    if (
      mediaStream &&
      mediaStream.getCameraList().length &&
      mediaStream.getActiveCamera() !== activeCameraDeviceId
    ) {
      try {
        await mediaStream.switchCamera(activeCameraDeviceId);
      } catch (error) {
        console.log('Switching camera error:', error);
      }
    }
  }, [mediaStream, activeCameraDeviceId]);

  useAsyncEffect(async () => {
    if (
      mediaStream &&
      mediaStream.getSpeakerList().length &&
      mediaStream.getActiveSpeaker() !== activeSpeakerDeviceId
    ) {
      try {
        await mediaStream.switchSpeaker(activeSpeakerDeviceId);
      } catch (error) {
        console.log('Switching speaker error:', error);
      }
    }
  }, [mediaStream, activeSpeakerDeviceId]);

  useAsyncEffect(async () => {
    if (
      mediaStream &&
      mediaStream.getMicList().length &&
      mediaStream.getActiveMicrophone() !== activeMicroDeviceId
    ) {
      try {
        await mediaStream.switchMicrophone(activeMicroDeviceId);
      } catch (error) {
        console.log('Switching microphone error:', error);
      }
    }
  }, [mediaStream, activeMicroDeviceId]);

  const onActiveSpeakerChange = useCallback(
    (payload: any) => {
      if (!zmClient.getCurrentUserInfo()?.muted) {
        if (Array.isArray(payload) && payload.length > 0) {
          const isCurrentUserSpeaking = payload.some(
            (item: { userId: number; displayName: string }) =>
              item.userId === zmClient.getSessionInfo().userId,
          );

          setIsCurrentParticipantSpeaking(isCurrentUserSpeaking);
        }
      }
    },
    [zmClient],
  );

  const stopShareCameraToScreen = useCallback(async () => {
    if (!mediaStream || !currentParticipant) {
      return;
    }

    const mediaStreamId = mediaStream.getActiveVideoId();

    if (mediaStreamId) {
      await Promise.all([
        mediaStream.stopVideo(),
        ZoomService.deleteZoomMediaStream({
          mediaStreamId: `${currentParticipant.participantId}-camera`,
        }),
      ]);
      setIsSharingToScreen(false);
      setVideoStarted(false);
    }
  }, [mediaStream, currentParticipant]);

  const startShareCameraToScreen = useCallback(async () => {
    try {
      const currentZoomUser = zmClient.getCurrentUserInfo();

      if (
        !currentZoomUser.bVideoShare &&
        mediaStream &&
        currentEvent &&
        currentRoom &&
        currentParticipant &&
        ueWebcamScreenName &&
        zoomConferenceName
      ) {
        await mediaStream.startVideo({ cameraId: activeCameraDeviceId });
        mediaStream.muteAudio();
        await ZoomService.createZoomMediaStream({
          mediaStreamId: `${currentParticipant.participantId}-camera`,
          ueScreenName: ueWebcamScreenName,
          owner: {
            id: currentParticipant?.participantId,
            name: currentParticipant?.name,
          },
          source: 'CAMERA',
          muted: true,
          eventId: currentEvent.eventId,
          roomId: currentRoom.roomId,
          participantId: currentParticipant.participantId,
          region: currentRegion ? currentRegion.region : '',
          zoomUserId: currentZoomUser.userId,
          conferenceId: zoomConferenceName,
        });

        setIsSharingToScreen(true);
        setVideoStarted(true);
      }
    } catch (error: unknown) {
      setIsSharingToScreen(false);
    }
  }, [
    activeCameraDeviceId,
    mediaStream,
    currentRoom,
    currentRegion,
    currentEvent,
    ueWebcamScreenName,
    zoomConferenceName,
    currentParticipant,
    zmClient,
  ]);

  const startScreenSharing = useCallback(async () => {
    if (
      !mediaStream ||
      !ueScreenScreenName ||
      !currentParticipant ||
      !currentEvent ||
      !currentRoom ||
      !zoomConferenceName
    ) {
      return;
    }
    const currentZoomUser = zmClient.getCurrentUserInfo();

    if (mediaStream.isStartShareScreenWithVideoElement()) {
      const video = document.getElementById(
        'screen-share-content-video',
      ) as HTMLVideoElement | null;

      if (!video) {
        return;
      }

      await mediaStream.startShareScreen(video);

      //reformat in case of preview
      // video.style.display = 'none';
    } else {
      const canvas = document.getElementById(
        'screen-share-content-canvas',
      ) as HTMLCanvasElement | null;

      if (!canvas) {
        return;
      }

      await mediaStream.startShareScreen(
        canvas,
        isLimitedScreenResolution
          ? {
              captureHeight: 1080,
              captureWidth: 1920,
              optimizedForSharedVideo: true,
            }
          : undefined,
      );

      canvas.style.display = 'none';
    }

    await ZoomService.createZoomMediaStream({
      mediaStreamId: `${currentParticipant.participantId}-screen`,
      ueScreenName: ueScreenScreenName,
      owner: {
        id: currentParticipant?.participantId,
        name: currentParticipant?.name,
      },
      source: 'SCREEN',
      muted: true,
      eventId: currentEvent.eventId,
      roomId: currentRoom.roomId,
      participantId: currentParticipant.participantId,
      region: currentRegion ? currentRegion.region : '',
      zoomUserId: currentZoomUser.userId,
      conferenceId: zoomConferenceName,
    });

    setIsSharingScreenToUE(true);
  }, [
    mediaStream,
    shareViewRef,
    ueScreenScreenName,
    currentParticipant,
    currentEvent,
    zmClient,
    currentRoom,
    currentRegion,
    zoomConferenceName,
    isLimitedScreenResolution,
  ]);

  const stopScreenSharing = useCallback(async () => {
    if (!currentParticipant) {
      return;
    }

    await mediaStream?.stopShareScreen();
    await ZoomService.deleteZoomMediaStream({
      mediaStreamId: `${currentParticipant.participantId}-screen`,
    });
    setIsSharingScreenToUE(false);
  }, [mediaStream, ueScreenScreenName, zmClient]);

  const handleDeviceChange = useCallback(() => {
    if (mediaStream) {
      setMicList(mediaStream.getMicList());
      setSpeakerList(mediaStream.getSpeakerList());

      //TODO update it later
      // if (!isAndroidOrIOSBrowser()) {
      //   setCameraList(mediaStream.getCameraList());
      // }
      setCameraList(mediaStream.getCameraList());
      // setActiveMicrophone(mediaStream.getActiveMicrophone());
      // setActiveSpeaker(mediaStream.getActiveSpeaker());
      // setActiveCamera(mediaStream.getActiveCamera());
    }
  }, [mediaStream, currentParticipant]);

  const handlePassivelyStopShare = useCallback(async () => {
    await stopScreenSharing();
  }, [stopScreenSharing]);

  useEffect(() => {
    zmClient.on('device-change', handleDeviceChange);
    zmClient.on('passively-stop-share', handlePassivelyStopShare);
    zmClient.on('device-permission-change', handleDeviceChange);
    zmClient.on('active-speaker', onActiveSpeakerChange);

    return () => {
      zmClient.off('device-change', handleDeviceChange);
      zmClient.off('passively-stop-share', handlePassivelyStopShare);
      zmClient.off('device-permission-change', handleDeviceChange);
      zmClient.off('active-speaker', onActiveSpeakerChange);
    };
  }, [zmClient, handleDeviceChange, handlePassivelyStopShare]);

  const handleJoinToConference = useCallback(
    async (eventId: string, roomId: string, regionName?: string) => {
      try {
        const sessionName = regionName
          ? `${eventId}-${roomId}-${regionName}`
          : `${eventId}-${roomId}`;

        const currentSession = zmClient.getSessionInfo()?.topic;

        if (
          !mediaStream ||
          !currentParticipant ||
          sessionName === currentSession
        ) {
          return;
        }

        setIsJoiningConference(true);

        if (isInConference) {
          if (isSharingScreenToUE) {
            await stopScreenSharing();
          }

          if (isSharingToScreen) {
            await stopShareCameraToScreen();
          }
          await zmClient.leave();
          setIsInConference(false);
        }

        const [webToken, ue5Token] = await Promise.all([
          await ZoomService.generateZoomToken({
            role: 1,
            expirationSeconds: 10800,
            sessionName: sessionName,
            userIdentity: currentParticipant.participantId,
          }),
          await ZoomService.generateZoomToken({
            role: 1,
            expirationSeconds: 10800,
            sessionName: sessionName,
            userIdentity: currentParticipant.participantId,
          }),
        ]);

        await zmClient.join(
          sessionName,
          webToken.signature,
          currentParticipant.fullName ||
            currentParticipant.email ||
            currentParticipant.participantId,
        );

        if (isEventRun) {
          await mediaStream.startAudio({
            highBitrate: true,
            mute: muted,
            speakerId: activeSpeakerDeviceId,
          });
        }

        if (isCameraOn) {
          await startVideoSharing();
        }

        const currentZoomUser = zmClient.getCurrentUserInfo();

        // await ZoomService.updateParticipantConference({
        //   participantId: currentParticipant.participantId,
        //   conferenceName: sessionName,
        //   zoomUserId: String(currentZoomUser.userId),
        // });

        setIsInConference(true);
        setZoomUser(currentZoomUser);
        setZoomConferenceName(sessionName);
        setZoomToken(webToken.signature);
        setZoomUE5Token(ue5Token.signature);
        setIsJoiningConference(false);
      } catch (error) {
        console.error(error);
        setIsInConference(false);
        setZoomConferenceName(null);
        setIsJoiningConference(false);
      }
    },
    [
      zmClient,
      localAudioTrack,
      mediaStream,
      currentParticipant,
      zoomConferenceName,
      isSharingScreenToUE,
      isSharingToScreen,
      isInConference,
      stopShareCameraToScreen,
      stopScreenSharing,
      isCameraOn,
      muted,
      activeSpeakerDeviceId,
      isEventRun,
    ],
  );

  useEffect(() => {
    setZoomUser(zmClient.getCurrentUserInfo());
  }, [zmClient, mediaStream]);

  useAsyncEffect(async () => {
    if (!mediaStream || !isEventRun) {
      return;
    }

    await mediaStream.startAudio({
      highBitrate: true,
      mute: muted,
      speakerId: activeSpeakerDeviceId,
    });
  }, [mediaStream, activeSpeakerDeviceId, mediaStream, isEventRun]);

  useAsyncEffect(() => {
    if (isGamecastCrash) {
      ZoomVideo.destroyClient();
    }
  }, [isGamecastCrash, ZoomVideo]);

  useAsyncEffect(async () => {
    if (!mediaStream || !isEventRun) {
      return;
    }

    await mediaStream.startAudio({
      highBitrate: true,
      mute: muted,
      speakerId: activeSpeakerDeviceId,
    });
  }, [mediaStream, activeSpeakerDeviceId, mediaStream, isEventRun]);

  useAsyncEffect(() => {
    if (isGamecastCrash) {
      ZoomVideo.destroyClient();
    }
  }, [isGamecastCrash, ZoomVideo]);

  useEffect(() => {
    if (!ueScreenMediaStreams || !ueScreenMediaStreams.length) {
      setExpandedScreenSharing(false);
    }
  }, [ueScreenMediaStreams]);

  useAsyncEffect(async () => {
    // check if participant has joined a meeting
    // otherwise it will throw IMPROPER_MEETING_STATE error
    if (
      !mediaStream ||
      !currentRoom ||
      !zmClient.getSessionInfo()?.isInMeeting
    ) {
      return;
    }

    if (muted) {
      await mediaStream.muteAudio();
      return;
    }

    await mediaStream.unmuteAudio();
  }, [muted, mediaStream, currentRoom]);

  const startVideoSharing = useCallback(
    async (videoRef?: MutableRefObject<HTMLVideoElement>) => {
      if (!mediaStream) {
        return;
      }
      const startVideoOptions: CaptureVideoOption = {
        videoElement: videoRef?.current,
        cameraId: activeCameraDeviceId,
      };

      if (mediaStream?.isSupportVirtualBackground()) {
        Object.assign(startVideoOptions, {
          virtualBackground: { imageUrl: 'blur' },
        });
      }

      await mediaStream.startVideo(startVideoOptions);
      setIsCameraOn(true);
    },
    [isCameraOn, mediaStream, activeCameraDeviceId],
  );

  const requestPermission = async (name: any) => {
    try {
      if (name === 'microphone') {
        await navigator.mediaDevices.getUserMedia({ audio: true });
      } else if (name === 'camera') {
        await navigator.mediaDevices.getUserMedia({ video: true });
      }
    } catch (error) {
      console.error(`[DevicePermissions]: ${name}:`, error);
    }
  };

  const checkPermissions = async () => {
    const permissions = ['microphone', 'camera'];

    for (const name of permissions) {
      //@ts-ignore
      const permission = await navigator.permissions.query({ name });

      console.log(
        `[DevicePermissions]: ${name} permission: ${permission.state}`,
      );

      if (permission.state !== 'granted') {
        await requestPermission(name);
      }
    }
  };

  const handleToggleVideoSharing = useCallback(
    async (videoRef?: MutableRefObject<HTMLVideoElement>) => {
      if (!mediaStream) {
        return;
      }

      if (isCameraOn) {
        await mediaStream.stopVideo();
        setIsCameraOn(false);
        return;
      }

      await startVideoSharing(videoRef);
      setIsCameraOn(true);
    },
    [isCameraOn, mediaStream, startVideoSharing],
  );

  return (
    <ZoomContext.Provider
      value={{
        loading,
        // activeMicrophone,
        // activeSpeaker,
        // activeCamera,
        micList,
        speakerList,
        cameraList,
        handleToggleVideoSharing,
        isCameraOn,
        zoomToken,
        shareViewRef,
        zoomConferenceName,
        zoomUser,
        zoomUE5Token,
        startShareCameraToScreen,
        zmClient,
        stopShareCameraToScreen,
        mediaStream,
        stopScreenSharing,
        isSharingToScreen,
        startScreenSharing,
        isSharingScreenToUE,
        isJoiningConference,
        handleJoinToConference,
        isCurrentParticipantSpeaking,
        isLimitedScreenResolution,
        setIsLimitedScreenResolution,
        setIsCameraOn,
        expandedScreenSharing,
        setExpandedScreenSharing,
      }}
    >
      {children}
    </ZoomContext.Provider>
  );
};
