import React, { MouseEvent } from 'react';

import { Box, IconButton, Tooltip } from '@mui/material';

import { IHomeBarButton } from './intefaces';

import { useStyles } from './styles';

const HomeBarButton = ({
  icon,
  handleClick,
  label,
  showPulseAnimation = false,
  isRed = false,
  disabled = false,
  sx = {},
  buttonSx = {},
}: IHomeBarButton) => {
  const classes = useStyles({
    showPulseAnimation: (showPulseAnimation || isRed) && !disabled,
  });
  const rootClass = { ...classes.root, ...sx };
  const buttonClass = { ...classes.button, ...buttonSx };
  const animationClass = showPulseAnimation
    ? classes.pulseBox
    : isRed
    ? classes.warning
    : {};

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.blur();
    handleClick(event);
  };

  return (
    <Tooltip title={label}>
      <Box sx={rootClass}>
        {!disabled ? <Box sx={animationClass} /> : null}
        <IconButton
          disabled={disabled}
          sx={buttonClass}
          onClick={onClick}
          disableRipple
        >
          {icon}
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default HomeBarButton;
