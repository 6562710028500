import { SkinToneType, GenderType } from '../../interfaces';

export const ToneSkinOptions = [
  { value: SkinToneType.LIGHT },
  { value: SkinToneType.MEDIUM_LIGHT },
  { value: SkinToneType.MEDIUM },
  { value: SkinToneType.MEDIUM_DARK },
  { value: SkinToneType.DARK },
  { value: SkinToneType.VERY_DARK },
];

export const GenderOptions = [
  { value: GenderType.MALE, label: 'Male' },
  { value: GenderType.FEMALE, label: 'Female' },
];
