import { useMemo } from 'react';

import { useMytaverseEvent } from '../../../../providers';
import { useMytaverse } from '../../../../../../providers/MytaverseProvider';
import { useChatState } from '../../../../../../hooks/context';

import { filterListByMessageDate, getLastMessageDate } from './helpers';

import { ISearchChatItem } from './interfaces';

export const useGetSearchChatList = (): ISearchChatItem[] => {
  const { participants } = useMytaverseEvent();
  const { user } = useMytaverse();
  const { privateChannels } = useChatState();

  const filteredParticipants = useMemo(
    () => participants.filter(({ participantId }) => participantId !== user.id),
    [user, participants],
  );

  const searchChatList = useMemo(() => {
    return filteredParticipants.map((participant) => {
      const privateChannel = privateChannels.find((channel) =>
        channel.userIds.includes(participant.streamChatMemberId),
      );

      if (!privateChannel) {
        return {
          participant,
          privateChannel: null,
          lastMessage: '',
          lastMessageDate: 0,
        };
      }

      const { channel } = privateChannel;

      const lastMessage = channel?.lastMessage()?.text || '';
      const lastMessageDate = channel ? getLastMessageDate(channel) : 0;

      return {
        participant,
        privateChannel,
        lastMessage,
        lastMessageDate,
      };
    });
  }, [filteredParticipants, privateChannels]);

  const filteredSearchChatList = useMemo(
    () => filterListByMessageDate(searchChatList),
    [searchChatList],
  );

  return filteredSearchChatList;
};
