import { COLORS, colors } from '../../../../../../constants/colors';

export const classes = {
  root: {
    backgroundColor: COLORS.WHITE,
    px: 2,
    py: 1,
    height: 'calc(100% - 48px)',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    '& .MuiTypography-root': {
      fontSize: 14,
      letterSpacing: '0.1px',
      lineHeight: 1.42,
    },
  },
  text: {
    color: COLORS.NEW_BLACK,
    fontWeight: 500,
  },
  top: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 1,
    '& .MuiTypography-root': {
      textAlign: 'center',
    },
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  stepTitle: {
    color: colors.cyan,
    fontWeight: 700,
  },
  image: {
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  button: {
    mx: 'auto',
    px: 3,
    py: 0.7,
    borderRadius: '100px',
    backgroundColor: colors.cyan,
    color: COLORS.WHITE,
    fontSize: 14,
    fontWeight: 500,
  },
  buttonText: {
    backgroundColor: colors.red,
  },
  buttonGreen: {
    backgroundColor: COLORS.GREEN,
  },
  buttonRed: {
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: colors.red_80,
    },
  },
};
