import { colors } from '../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    overflowY: 'scroll',
    maxHeight: 300,
    '& p': {
      color: colors.white,
      maxWidth: '100%',
      mb: 1,
      wordWrap: 'break-word',
      whiteSpace: 'normal',
    },
  },
});
