import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import TryDemoPageView from './TryDemoPageView';
import CheckYourEmailPageView from './TryDemoPageCheckYourEmailPageView';
import { TryDemoPageSchema } from './validation';
import { IFormValues } from './interfaces';
import UserService from '../../../../services/UserService';
import { IInviteUserToEventData } from '../../../../services/interfaces';
import {
  getErrorNotification,
  useNotification,
} from '../../../../components/Notification';

const TryDemoPage = () => {
  const [loading, setLoading] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const { showNotification, getSuccessNotification } = useNotification();
  const { t: translate } = useTranslation('common');

  const handleSignIn = async (values: IFormValues) => {
    try {
      setLoading(true);

      const invite: IInviteUserToEventData = {
        name: values.name,
        emails: [values.email],
        sendInviteLink: true,
        isDemoUser: true,
      };

      await UserService.publicInviteUserToEvent(invite);
      setCheckEmail(true);
      showNotification(
        getSuccessNotification({ message: translate('tryDemo.emailAlert') }),
      );
    } catch (error) {
      setLoading(false);
      showNotification(getErrorNotification({ message: `${error}` }));
    }
    setLoading(false);
  };

  const {
    values,
    touched,
    errors,
    setFieldTouched,
    handleChange,
    handleSubmit,
  } = useFormik<IFormValues>({
    initialValues: {
      name: '',
      email: '',
    },
    onSubmit: handleSignIn,
    validationSchema: TryDemoPageSchema,
    enableReinitialize: true,
  });

  const handleInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setFieldTouched(event.target.name, false, false);
    handleChange(event);
  };

  const getView = () => {
    if (checkEmail) {
      return (
        <CheckYourEmailPageView
          title={translate('tryDemo.pageTitle')}
          email={values.email}
          loading={loading}
          onSubmit={handleSubmit}
        />
      );
    }

    return (
      <TryDemoPageView
        title={translate('tryDemo.pageTitle')}
        values={values}
        errors={errors}
        touched={touched}
        onChange={handleInputChange}
        loading={loading}
        onSubmit={handleSubmit}
      />
    );
  };

  return getView();
};

export default TryDemoPage;
