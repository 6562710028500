import { useTheme } from '@mui/material';

import { useMytaverse } from '../../../../../providers/MytaverseProvider';

import { ScreenSizes } from '../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  const { appTheme } = useMytaverse();
  const titleColor = appTheme.loginPage.assets.titleColor;
  const subtitleColor = appTheme.loginPage.assets.subtitleColor;

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 1,
      textAlign: 'center',
      alignSelf: 'center',
      [breakpoints.down(ScreenSizes.Tablet)]: {
        width: 'calc(100% - 28px)',
      },
    },
    title: {
      fontFamily: 'Emirates-Medium !important',
      fontSize: 40,
      lineHeight: '56px',
      color: titleColor,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        marginBottom: 1,

        '& span': {
          display: 'block',
        },
      },

      [breakpoints.down(ScreenSizes.Mobile)]: {
        marginTop: 3,
        fontSize: 32,
        lineHeight: '42px',
      },

      '@media (max-width: 340px)': {
        marginTop: 2,
      },

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        fontSize: 32,
        lineHeight: '42px',
        marginTop: 0,
        '& span': {
          display: 'inline-block',
        },
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        fontSize: 32,
        lineHeight: '42px',
        marginTop: 0,
        '& span': {
          display: 'inline-block',
        },
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        fontSize: 22,
        lineHeight: '22px',
        marginTop: 0,
        marginBottom: 0,
        '& span': {
          display: 'inline-block',
        },
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          fontSize: 32,
          lineHeight: '42px',
          marginTop: 0,
          '& span': {
            display: 'inline-block',
          },
        },

      '& span': {
        fontFamily: 'Emirates-Bold !important',
        fontSize: 'inherit',
        lineHeight: 'inherit',
      },
    },
    subtitle: {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: '38px',
      letterSpacing: '0.25px',
      color: subtitleColor,
      [breakpoints.down(ScreenSizes.Pc)]: {
        fontSize: 18,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        display: 'none',
      },
    },
  };
};
