import { useState, useCallback, useEffect, useMemo } from 'react';
import { isDesktop } from 'react-device-detect';
import { useLandscapeDetection } from '../../../../../hooks/orientation';
import { useChatState } from '../../../../../hooks/context';

export const useShowOrientationOverlay = () => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(!isDesktop);
  const { setOpen: setOpenChat } = useChatState();
  const isLandscape = useLandscapeDetection();

  useEffect(() => {
    if (isDesktop) {
      return;
    }

    if (!isLandscape) {
      setOpenChat(false);
      setIsOverlayVisible(true);
    }
  }, [isLandscape]);

  const showOverlay = useMemo(
    () => !isDesktop && isOverlayVisible,
    [isOverlayVisible],
  );

  const handleHideOverlay = useCallback(() => {
    setIsOverlayVisible(false);
  }, []);

  return useMemo(
    () => ({ isLandscape, showOverlay, handleHideOverlay }),
    [isLandscape, showOverlay],
  );
};
