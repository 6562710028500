import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { jwtDecode, InvalidTokenError } from 'jwt-decode';

import { useMytaverse } from '../../../../providers/MytaverseProvider';
import { useManualTimer } from '../../hooks/timer';

import {
  getErrorNotification,
  useNotification,
} from '../../../../components/Notification';
import { setSentryNotAuthUserData } from '../../../../helpers/sentry';
import { getCatchErrorMessage } from '../../../../helpers/error';

import EventsService from '../../../../services/EventsService';
import UserService from '../../../../services/UserService';

import ROUTES from '../../../../constants/routes';

import { IDecodeTokenParams } from './interfaces';

export const useHandleConfirmEvent = () => {
  const [loading, setLoading] = useState(true);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [isConfirmed, setIConfirmed] = useState(false);
  const isFirstRenderRef = useRef(true);

  const [isTokenDecoded, setIsTokenDecoded] = useState(false);
  const [autoLogin, setAutoLogin] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');

  const { token } = useParams<{ token: string }>();

  const { user, setIsAuthenticated, autoLoginUser } = useMytaverse();
  const { timer, startTimer } = useManualTimer();
  const navigate = useNavigate();
  const { showNotification, getBadRequestNotification } = useNotification();

  const confirmEvent = useCallback(async () => {
    try {
      setIsConfirmLoading(true);

      const payload = {
        token: token as string,
        email: inviteEmail,
      };

      const isConfirmed = await EventsService.confirmEvent(payload);

      if (isConfirmed) {
        setIConfirmed(true);
        setIsAuthenticated(true);
        startTimer();
      } else {
        showNotification(
          getErrorNotification({
            message: 'Could not confirm event',
          }),
        );
      }
    } catch (error: unknown) {
      throw Error(getCatchErrorMessage(error));
    } finally {
      setIsConfirmLoading(false);
      sessionStorage.removeItem('inviteToken');
    }
  }, [token, inviteEmail, startTimer, showNotification, setIsAuthenticated]);

  const handleError = useCallback(
    (message: string) => {
      navigate(ROUTES.LOGIN, { replace: true });
      sessionStorage.removeItem('inviteToken');
      showNotification(getBadRequestNotification({ message }));
    },
    [navigate, showNotification, getBadRequestNotification],
  );

  const redirectTo = useCallback(async () => {
    const { invitationResult, invitationExpired, error } =
      await UserService.checkUserExistsInApp(token as string);

    if (error || invitationExpired) {
      handleError(
        error ||
          'Your invitation link is expired. Please ask invitation again.',
      );
      return;
    }

    const redirectUrl = invitationResult
      ? `${ROUTES.LOGIN}?welcome-back=1`
      : `${ROUTES.LOGIN}/${ROUTES.REGISTER}?email=${encodeURIComponent(
          inviteEmail,
        )}`;

    navigate(redirectUrl);
  }, [inviteEmail, handleError, navigate, token]);

  useEffect(() => {
    try {
      const { email, autoLogin = false }: IDecodeTokenParams = jwtDecode(
        token || '',
      );

      setAutoLogin(autoLogin);
      setInviteEmail(email);
    } catch (error: unknown) {
      const errorMessage = getCatchErrorMessage(error as InvalidTokenError);
      console.log(errorMessage);
      handleError(errorMessage);
    } finally {
      setIsTokenDecoded(true);
    }
  }, [token, handleError]);

  useEffect(() => {
    if (
      !token ||
      !isTokenDecoded ||
      isConfirmed ||
      isConfirmLoading ||
      !isFirstRenderRef.current
    ) {
      return;
    }

    const userId = localStorage.getItem('userId');

    sessionStorage.setItem('inviteToken', token);

    setSentryNotAuthUserData(inviteEmail);

    if (!autoLogin && !userId) {
      // confirm event for NOT logged user
      isFirstRenderRef.current = false;
      redirectTo();
      return;
    }

    if (autoLogin) {
      autoLoginUser({ token });
    }

    // confirm event for logged user
    const isAuthUserDataReady = !!user;

    if (!isAuthUserDataReady) return;

    const isEmailEqual = user.email === inviteEmail;

    isFirstRenderRef.current = false;

    if (isEmailEqual) {
      setLoading(false);
      confirmEvent();
    } else {
      redirectTo();
    }
  }, [
    isConfirmed,
    isConfirmLoading,
    token,
    user,
    isTokenDecoded,
    confirmEvent,
    inviteEmail,
    navigate,
    redirectTo,
    autoLogin,
    autoLoginUser,
  ]);

  // useEffect(() => {
  //   if (timer < 1) {
  //     navigate(ROUTES.SELECT_EVENT);
  //
  //     return () => {
  //       loadEvents(true);
  //     };
  //   }
  // }, [timer, navigate, loadEvents]);

  return {
    isLoading: loading || isConfirmLoading,
    isConfirmed,
    timer,
  };
};
