import React, { useEffect } from 'react';

import { Box, IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

//import GameCastLoader from './GameCastLoader';
import GameCastLoader from '../../../../../ek/modules/dashboard/components/GameCastLoader';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import { useMytaverseEvent } from '../../../providers';
import { useConference } from '../Dolby';

import { useStyles } from './styles';
import { useEventContext } from '../../../../../ek/providers/EventProvider';

const GameCast = () => {
  const {
    currentRoom,
    setSharingWindowFullscreen,
    sharingWindowFullsceen,
    isOpenLeftMenu,
    handleMinimizeSharingScreen,
    isMinimizedScreenSharing,
  } = useMytaverseEvent();
  const classes = useStyles(isOpenLeftMenu);

  const { screenMediaStreams } = useConference();
  const { isEventRun } = useEventContext();

  // useEffect(() => {
  //   if (streamSession && currentRoom && streamSession.attachClientInput) {
  //     streamSession.attachClientInput();
  //   }
  // }, [streamSession, currentRoom]);

  if (!currentRoom || !isEventRun) {
    return <GameCastLoader />;
  }

  return (
    <Box>
      {screenMediaStreams.length && !isMinimizedScreenSharing ? (
        <>
          <IconButton
            sx={classes.fullscreen}
            id="gameFull"
            onClick={setSharingWindowFullscreen}
          >
            {sharingWindowFullsceen ? (
              <FullscreenExitIcon />
            ) : (
              <FullscreenIcon />
            )}
          </IconButton>
          <IconButton
            sx={classes.fullscreenCollapse}
            id="gameCollapse"
            onClick={handleMinimizeSharingScreen}
          >
            <CloseFullscreenIcon />
          </IconButton>
        </>
      ) : null}
    </Box>
  );
};

export default GameCast;
