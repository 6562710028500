import React from 'react';

import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import EventsDropdown from '../../../../../components/EventsDropdown';

import { IEventDrawerHeaderView } from './interfaces';

import { useStyle } from './styles';

const EventDrawerHeaderView = ({
  onCloseEventDrawer,
  onChangeEvent,
}: IEventDrawerHeaderView) => {
  const classes = useStyle();

  return (
    <Box sx={classes.root}>
      <EventsDropdown
        onChangeEvent={onChangeEvent}
        menuProps={{
          PaperProps: {
            sx: classes.menuProps,
          },
        }}
      />
      <IconButton onClick={onCloseEventDrawer}>
        <CloseIcon sx={classes.icon} />
      </IconButton>
    </Box>
  );
};

export default EventDrawerHeaderView;
