import { SxProps } from '@mui/material';

import { COLORS } from '../../constants/colors';

export const useStyles = () => {
  return {
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: 24,
      backdropFilter: 'blur(12px)',
      backgroundColor: COLORS.NEW_BLACK,
      maxHeight: '90vh',
      maxWidth: '80vw',
      overflow: 'auto',
      outline: 'none',
    } as SxProps,
  };
};
