import { withTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { FormButton, FormInput, FormLayout } from '../../components/Form';
import PageTitleContent from '../../components/PageTitleContent';
import LegalNotes from '../../components/LegalNotes';

import { ITryDemoPageView } from './interfaces';

import { useStyles } from './styles';

const TryDemoPageView = ({
  title,
  values,
  errors,
  touched,
  onChange,
  loading,
  onSubmit,
  t: translate,
}: ITryDemoPageView) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root}>
      <PageTitleContent
        title={title}
        subtitle={translate('tryDemo.subtitle')}
      />
      <LegalNotes>
        <FormLayout classes={{ container: classes.layoutContainer }}>
          <Typography sx={classes.formTitle}>
            {translate('tryDemo.title')}
          </Typography>
          <form onSubmit={onSubmit} noValidate>
            <FormInput
              type="text"
              name="name"
              value={values.name}
              errorMessage={errors.name ? translate(errors.name) : ''}
              showError={!loading && touched.name}
              placeholder={translate('tryDemo.namePlaceholder')}
              onChange={onChange}
            />

            <FormInput
              type="email"
              name="email"
              value={values.email}
              errorMessage={errors.email ? translate(errors.email) : ''}
              showError={!loading && touched.email}
              placeholder={translate('tryDemo.emailPlaceholder')}
              onChange={onChange}
            />

            <FormButton loading={loading}>
              {translate('tryDemo.startFreeTest')}
            </FormButton>
          </form>
        </FormLayout>
      </LegalNotes>
    </Box>
  );
};

export default withTranslation('common')(TryDemoPageView);
