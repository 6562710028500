import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingProgress from '../../../../components/LoadingProgress';
import SelectAvatarSkinPageView from './SelectAvatarSkinPageView';

import { useMytaverseEvent } from '../../../dashboard/providers';
import { useDetectSwiperLoop } from '../../hooks';

import { sendCustomSentryBreadcrumb } from '../../../../helpers/sentry';

import ROUTES from '../../../../constants/routes';

import { IAvatarSkin } from '../../../../interfaces/avatarSkin';
import useAsyncEffect from 'use-async-effect';
import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { useIdleTimerLogout } from '../../../../hooks/idleTimer';

const { REACT_APP_IDLE_TIMEOUT_MINS } = process.env;

const SelectAvatarSkinPage = () => {
  const { selectSkin, setCurrentSkin, currentEventId, currentEvent } =
    useMytaverseEvent();

  const { setCustomAvatarUrl, currentAvatarId } = useMytaverse();

  const navigate = useNavigate();

  const [loadingSkins, setLoadingSkins] = useState(true);
  const [avatarSkins, setAvatarSkins] = useState<IAvatarSkin[]>([]);

  useIdleTimerLogout(Number(REACT_APP_IDLE_TIMEOUT_MINS));

  useAsyncEffect(async () => {
    if (!currentAvatarId || !currentEvent) {
      return navigate(ROUTES.AVATAR_CHOOSING(currentEventId));
    }

    setLoadingSkins(true);

    const currentAvatar = currentEvent.avatars.find(
      (avatar) => avatar.avatarId === currentAvatarId,
    );
    const avatarSkins = currentAvatar ? currentAvatar.skins : [];

    setAvatarSkins(avatarSkins);
    setLoadingSkins(false);
  }, [currentEventId, currentAvatarId, currentEvent]);

  const handleBackButtonClick = () =>
    navigate(ROUTES.AVATAR_CHOOSING(currentEventId));

  const handleAvatarSkinClick = (skin: IAvatarSkin) => {
    setCustomAvatarUrl(null);
    setCurrentSkin(skin);
    selectSkin(skin);
    sendCustomSentryBreadcrumb({ message: 'select avatar skin' });
    setLoadingSkins(false);
    navigate(ROUTES.DEVICES_TEST(currentEventId));
  };

  const loop = useDetectSwiperLoop(avatarSkins.length);

  if (loadingSkins || loop === null) {
    return <LoadingProgress fullHeight />;
  }

  return (
    <SelectAvatarSkinPageView
      avatarSkins={avatarSkins}
      loop={loop}
      onBackButtonClick={handleBackButtonClick}
      onAvatarSkinClick={handleAvatarSkinClick}
    />
  );
};

export default SelectAvatarSkinPage;
