import { useCallback, useRef, useState, useEffect } from 'react';
import useAsyncEffect from 'use-async-effect';
import { isIOS, isSafari } from 'react-device-detect';

import { useConferenceState } from '../../../../hooks/conferenceContext';
import { useDeviceChange } from '../../../dashboard/components/HomeBottomBar/DevicesSettingsModal/hooks';
import { MytaverseLogger } from '../../../../helpers/logger';
import { getCatchErrorMessage } from '../../../../helpers/error';

import MillicastService from '../../../dashboard/components/HomeBottomBar/ShareScreen/helpers';

import { IDevicePermissionState } from '../../../../interfaces/permissions';
import { NavigatorPermissionsService } from '../../../../helpers/permissionsService';

export const useInitWebcamDevicesMobile = (
  isIOSChrome: boolean,
  initWebcamDevices: (state: IDevicePermissionState) => Promise<void>,
) => {
  const [permissionsState, setPermissionsState] =
    useState<IDevicePermissionState | null>(null);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const { activeCameraDeviceId } = useConferenceState();

  const checkPermissions = useCallback(async () => {
    const permissionData =
      await NavigatorPermissionsService.checkAndEnsurePermission('camera');

    if (permissionsState !== permissionData.state) {
      await initWebcamDevices(permissionData.state);

      setPermissionsState(permissionData.state);
    }
  }, [initWebcamDevices, permissionsState]);

  useEffect(() => {
    if (!isIOSChrome) {
      return;
    }

    setPermissionsState(null);
  }, [isIOSChrome, activeCameraDeviceId]);

  useEffect(() => {
    if (!isIOSChrome) {
      return;
    }

    const init = async () => {
      await checkPermissions();

      if (!timeoutIdRef.current) {
        timeoutIdRef.current = setTimeout(init, 1000);
      }
    };

    init();

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    };
  }, [isIOSChrome, checkPermissions]);
};

export const useInitWebcamDevices = () => {
  const [initializing, setInitializing] = useState(true);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const unmountedRef = useRef(false);

  const { activeCameraDeviceId, setActiveCameraDeviceId } =
    useConferenceState();

  const isIOSChrome = isIOS && !isSafari;

  const startCameraStream = useCallback(async (deviceId: string) => {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        deviceId: { exact: deviceId },
      },
    });

    if (unmountedRef.current) {
      MillicastService.stopMediaStreamTracks(mediaStream);
      return;
    }

    setMediaStream(mediaStream);

    const video = videoRef.current;

    if (!video) {
      return;
    }

    video.srcObject = mediaStream;
    video.onloadedmetadata = () => {
      video.play();
    };
  }, []);

  const initWebcamDevices = useCallback(
    async (state: IDevicePermissionState) => {
      switch (state) {
        case 'prompt': {
          try {
            await navigator.mediaDevices.getUserMedia({
              audio: false,
              video: true,
            });
          } catch (e) {
            console.error(e);
            setInitializing(false);
          }

          break;
        }
        case 'denied': {
          const video = videoRef.current;

          if (video) {
            video.srcObject = null;
          }

          setDevices([]);
          setMediaStream(null);
          setInitializing(false);
          break;
        }
        case 'granted': {
          if (!initializing) {
            return;
          }

          if (!navigator.mediaDevices?.enumerateDevices) {
            throw new Error('enumerateDevices() not supported.');
          }

          try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const cameraDevices = (devices || []).filter(
              (device) => device.kind === 'videoinput',
            );

            localStorage.setItem(
              'cameraDevices',
              JSON.stringify(cameraDevices),
            );

            const activeDeviceId =
              cameraDevices.find(
                ({ deviceId }) => deviceId === activeCameraDeviceId,
              )?.deviceId || cameraDevices[0]?.deviceId;

            setDevices(cameraDevices);
            setInitializing(false);

            if (activeDeviceId) {
              setActiveCameraDeviceId(activeDeviceId);
              await startCameraStream(activeDeviceId);
            }
          } catch (error) {
            setInitializing(false);
            throw Error(getCatchErrorMessage(error));
          }
          break;
        }
      }
    },
    [
      activeCameraDeviceId,
      initializing,
      setActiveCameraDeviceId,
      startCameraStream,
    ],
  );

  useInitWebcamDevicesMobile(isIOSChrome, initWebcamDevices);

  useEffect(() => {
    setInitializing(true);
  }, [activeCameraDeviceId]);

  useDeviceChange(setInitializing);

  useAsyncEffect(async () => {
    if (isIOSChrome) {
      return;
    }

    try {
      const permissionData =
        await NavigatorPermissionsService.checkAndEnsurePermission('camera');

      initWebcamDevices(permissionData.state);

      if ('onchange' in permissionData) {
        permissionData.onchange = () => {
          setInitializing(true);
          initWebcamDevices(permissionData.state);
        };
      }
    } catch (error) {
      initWebcamDevices('denied');
      MytaverseLogger.error(getCatchErrorMessage(error));
    }
  }, [initWebcamDevices, isIOSChrome]);

  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (mediaStream) {
        MillicastService.stopMediaStreamTracks(mediaStream);
      }
    };
  }, [mediaStream]);

  return {
    initializing,
    devices,
    videoRef,
    startCameraStream,
  };
};
