import { useTheme } from '@mui/material';

import { IStyles } from '../../../../interfaces/styles';
import { ScreenSizes } from '../../../../interfaces';
import { colorsEK } from '../../../../constants/colors';

export const useStyles = (): IStyles => {
  const { breakpoints } = useTheme();

  return {
    button: {
      background: 'rgba(184, 184, 184, 0.1)',
      borderRadius: 1,
      border: 'none',
      color: colorsEK.grey_300,
      gap: 1.5,
      '& svg': {
        fill: colorsEK.grey_300,
        width: 16,
        height: 16,
      },
      '& p': {
        fontSize: 16,
        lineHeight: 1,
        letterSpacing: 'normal',
        fontWeight: 400,
      },
      padding: '8px 20px',

      '&:hover': {
        backgroundColor: 'rgba(184, 184, 184, 0.5)',
        color: colorsEK.grey_500,
      },
    },
  };
};
