import React from 'react';
import { useTranslation } from 'react-i18next';

import DevicesSettingsVideoView from './DevicesSettingsVideoView';

import { useConferenceState } from '../../../../../../hooks/conferenceContext';
import { DeviceSettingVideoProps } from './interfaces';
import { useZoom } from '../../../DashboardContent/Zoom/ZoomProvider';

const DevicesSettingsVideo = ({
  setCheckVideo,
  checkVideo,
}: DeviceSettingVideoProps) => {
  const { activeCameraDeviceId, activeMicroDeviceId, setActiveCameraDeviceId } =
    useConferenceState();
  const { cameraList } = useZoom();

  const { t: translate } = useTranslation('common');

  return (
    <DevicesSettingsVideoView
      cameras={cameraList}
      translate={translate}
      selectedMicrophone={activeMicroDeviceId}
      selectedCamera={activeCameraDeviceId}
      selectCamera={setActiveCameraDeviceId}
      changingDevice={false}
      setCheckVideo={setCheckVideo}
      checkVideo={checkVideo}
    />
  );
};

export default DevicesSettingsVideo;
