import { COLORS } from '../../../../../../constants/colors';
import { IStyles } from '../../../../../../interfaces/styles';
import { ScreenSizes } from '../../../../../../interfaces';
import { useTheme } from '@mui/material';

export const useStyles = (): IStyles => {
  const { breakpoints } = useTheme();

  return {
    root: {
      p: 0,
      width: '100%',
      mt: 3,
    },

    permissionsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        width: '100%',
        height: 'auto',
        maxWidth: 325,
      },
    },

    subRoot: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '& > div': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
    },

    stack: {
      '& .MuiFormControl-root': { mt: '10px !important' },
    },

    button: {
      width: '100%',
      maxWidth: 512,
      height: 308,
      mt: 2,
      backgroundColor: COLORS.GREY,
      color: COLORS.LIGHT_GREY,

      '&:hover': {
        backgroundColor: COLORS.MYTAVERSE_BLUE,
      },
      [breakpoints.down(ScreenSizes.Pc)]: {
        height: 188,
      },
    },
  };
};
