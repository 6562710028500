import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: 1,
      padding: '28px 48px',
      [breakpoints.down(ScreenSizes.Pc)]: {
        padding: '28px 32px',
      },
      [breakpoints.down(ScreenSizes.Tablet)]: {
        padding: '8px 16px',
        '& svg': {
          width: 125,
        },
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 820px)
        and (max-device-width: 1180px)`]: {
        padding: '16px 23px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        paddingBottom: 1,
        paddingTop: 0,
      },
    },
    box: {
      display: 'flex',
      flex: 1,
    },
  };
};
