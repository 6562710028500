import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { useMytaverseEvent } from '../../providers';
import Media from './Media';
import Footer from '../../../../components/Footer';

import { IAppLoader } from './interfaces';

import { useStyles } from './styles';
import LoadingProgress from '../../../../components/LoadingProgress';

const AppLoader = ({ children, t: translate }: IAppLoader) => {
  const classes = useStyles();

  const { currentEvent } = useMytaverseEvent();

  return (
    <Box sx={classes.root}>
      <Box sx={classes.container}>
        <Box sx={classes.content}>
          <Box sx={classes.left}>
            <Typography sx={classes.title}>
              {translate('loading.launchingExperience')}
            </Typography>
            <Typography sx={classes.text}>
              {translate('loading.footer')}
            </Typography>
            {children}
            <Typography sx={classes.text}>
              {translate('loading.teleporting')}
            </Typography>
          </Box>
          <Box sx={classes.right}>
            {currentEvent && !!children ? (
              window.location.hostname.includes('localhost') ? (
                <Typography>
                  Video disabled for localhost in
                  &quot;src/modules/dashboard/components/AppLoader.tsx&quot;
                </Typography>
              ) : (
                <Media loadingAsset={currentEvent?.loadingScreenAsset} />
              )
            ) : (
              <LoadingProgress height="100%" />
            )}
          </Box>
        </Box>
        <Footer sx={classes.footer} custom />
      </Box>
    </Box>
  );
};

export default withTranslation('common')(AppLoader);
