import React from 'react';
import {
  Box,
  CircularProgress as ProgressBar,
  Typography,
} from '@mui/material';

import { useStyles } from './styles';

interface IProps {
  value: number;
  total: number;
}

const CircularProgress = ({ value, total }: IProps) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root}>
      <ProgressBar value={100} sx={classes.base} variant="determinate" />
      <ProgressBar
        value={(value / total) * 100}
        sx={classes.progress}
        variant="determinate"
      />
      <Typography sx={classes.step}>
        {value}/{total}
      </Typography>
    </Box>
  );
};

export default CircularProgress;
