// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Director, Publish } from '@millicast/sdk';
import { IMillicastPublisher } from '../../../../../interfaces/millicastPublisher';
import EventsService from '../../../../../services/EventsService';
import {
  IMillicast,
  IMillicastLockScreenData,
  IMillicastTokens,
} from '../../../../../interfaces/millicastTokens';
import $api from '../../../../../http/axios';

export default class MillicastService {
  static async broadcast(
    token: any,
    streamName: string,
    mediaStream: MediaStream,
  ): Promise<Publish> {
    const tokenGenerator = () =>
      Director.getPublisher({
        token,
        streamName,
      });
    const publisher = new Publish(streamName, tokenGenerator);

    const broadcastOptions = {
      mediaStream,
      codec: 'vp8',
    };

    try {
      // @ts-ignore
      await publisher.connect(broadcastOptions);
    } catch (e) {
      // console.log('Connection failed, handle error', e);
    }

    return publisher;
  }

  static stopMediaStreamTracks(mediaStream: MediaStream) {
    try {
      if (mediaStream && mediaStream.getTracks) {
        mediaStream
          .getTracks()
          .forEach((track: { stop: () => any }) => track.stop());
      }
    } catch (e) {
      console.error(
        `Cant' stop media stream tracks mediaStream: ${JSON.stringify(
          mediaStream,
        )}`,
      );
    }
  }

  static async getMillicastStream(streamName: string): Promise<IMillicast> {
    return $api
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/millicast/streams/${streamName}`,
      )
      .then((res) => res.data)
      .catch(() => {
        throw new Error("Can't load millicast stream");
      });
  }

  static async lockMillicastScreen(
    streamName: string,
    lockScreenData: IMillicastLockScreenData,
  ): Promise<IMillicast> {
    return $api
      .post(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/millicast/streams/${streamName}/lock`,
        lockScreenData,
      )
      .then((res) => res.data)
      .catch(() => {
        throw new Error("Can't lock millicast stream");
      });
  }

  static async unlockMillicastScreen(streamName: string): Promise<IMillicast> {
    return $api
      .delete(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/millicast/streams/${streamName}/unlock`,
      )
      .then((res) => res.data)
      .catch(() => {
        throw new Error("Can't lock millicast stream");
      });
  }

  static async stopStream(publisher: IMillicastPublisher): Promise<void> {
    publisher.stop();

    this.stopMediaStreamTracks(publisher.options.mediaStream);

    await EventsService.stopStreamVideo(publisher.streamName);
  }

  static updatePublishToken(
    publishTokenId: string,
    screenName: string,
  ): Promise<IMillicastTokens> {
    return $api
      .patch(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/millicast/publish-tokens/${publishTokenId}`,
        {
          screenName,
        },
      )
      .then((res) => res.data)
      .catch(() => {
        throw new Error("Can't update millicast publish token");
      });
  }

  static updateSubscribeToken(
    subscribeTokenId: string,
    screenName: string,
  ): Promise<IMillicastTokens> {
    return $api
      .patch(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/millicast/subscribe-tokens/${subscribeTokenId}`,
        {
          screenName,
        },
      )
      .then((res) => res.data)
      .catch(() => {
        throw new Error("Can't update millicast subscribe token");
      });
  }
}
