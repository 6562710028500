import React from 'react';
import { Box, SelectChangeEvent } from '@mui/material';
import { SxProps } from '@mui/system';

import { ReactComponent as HeadphonesIcon } from '../../../../../public/images/ek/headphone.svg';

import SingleSelect from '../../../../../components/SingleSelect';

import { IProgressBarSelection } from './interfaces';

import { useStyles } from './styles';

const ProgressBarSelection = ({
  devices,
  activeDeviceId,
  onDeviceChange,
  disabled,
  sx = {},
}: IProgressBarSelection) => {
  const classes = useStyles();
  const rootSx = { ...classes.root, ...sx } as SxProps;

  const options = React.useMemo(
    () =>
      devices.map((device) => ({
        label: device.label,
        value: device.deviceId,
      })),
    [devices],
  );

  const handleChange = React.useCallback(
    (event: SelectChangeEvent) => {
      onDeviceChange(event.target.value);
    },
    [onDeviceChange],
  );

  return (
    <Box sx={rootSx}>
      <Box sx={classes.icon}>
        <HeadphonesIcon />
      </Box>
      <SingleSelect
        options={options}
        activeValue={activeDeviceId || devices[0].deviceId}
        onChange={handleChange}
        disabled={disabled}
        emptyPlaceholder="No available devices"
        sx={classes.select}
        menuProps={{
          PaperProps: {
            sx: classes.paper,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }}
      />
    </Box>
  );
};

export default ProgressBarSelection;
