import { useTheme } from '@mui/material';

import { colors } from '../../../../../constants/colors';
import { ScreenSizes } from '../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 2,
      width: '100%',
      marginTop: '32px',
      marginBottom: '32px',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        rowGap: 1,
        marginTop: '32px',
        marginBottom: '32px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        marginTop: '23px',
        marginBottom: '30px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 980px)`]: {
        marginTop: '16px',
        marginBottom: '23px',
        rowGap: 1,
      },
    },
    section: {
      position: 'relative',
    },
    textContent: {
      position: 'absolute',
      content: '""',
      inset: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '14px 32px',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: '7px 10px',
      },
    },
    text: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '27px',
      color: colors.white,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 12,
        lineHeight: '16px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        fontSize: 16,
        lineHeight: '24px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        fontSize: 12,
        lineHeight: '18px',
      },
    },
    speed: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '27px',
      color: colors.white,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 12,
        lineHeight: '16px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        fontSize: 16,
        lineHeight: '24px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        fontSize: 12,
        lineHeight: '18px',
      },
    },
  };
};
