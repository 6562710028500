import React, { useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { SVGType } from '../../../../../interfaces';

import { useStyles } from './styles';
import { usePocAi } from '../providers/PocAiProvider';
import { POC_SCREENS } from '../constants';

interface IQuestionBox {
  title: string;
  icon: SVGType;
}

const QuestionBox = ({ title, icon: Icon }: IQuestionBox) => {
  const classes = useStyles();

  const { handleSendRequest, navigate } = usePocAi();

  const handleSendMessage = useCallback(() => {
    handleSendRequest(title);
    navigate(POC_SCREENS.CHAT);
  }, [title]);

  return (
    <Button onClick={handleSendMessage} sx={classes.box}>
      <Box sx={classes.icon}>
        <Icon />
      </Box>

      <Typography sx={classes.title}>{title}</Typography>
    </Button>
  );
};

export default QuestionBox;
