import { colorsEK } from '../../../../../../../constants/colors';

export const useStyles = () => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
    },
    slotBox: (isBooked = false) => ({
      fontFamily: '"Noto Sans", sans-serif',
      borderRadius: 1,
      borderLeft: `8px solid ${isBooked ? '#58A022' : '#4272DE'}`,
      backgroundColor: '#1B1B1B',
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      paddingX: 1,
      paddingY: 1.5,
      color: colorsEK.white,
      width: '100%',
    }),
    content: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    slotTime: {
      color: colorsEK.white,
      fontSize: 14,
      fontWeight: 600,
      linehHeight: 1.5,
      letterSpacing: '0.02em',
    },
    title: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.4,
    },
    time: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.4,
      letterSpacing: '0.02em',
      textWrap: 'nowrap',
    },
    info: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: '0.03em',
      color: colorsEK.grey_300,
    },
    button: {
      color: colorsEK.white,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1,
      paddingY: 0.8,
      paddingX: 3.5,
      border: `1.5px solid ${colorsEK.white}`,
      textTransform: 'none',
      borderRadius: 99,

      '& .MuiCircularProgress-root': {
        color: colorsEK.white,
      },

      '&:disabled': {
        color: '#444444',
        borderColor: '#444444',

        '& .MuiCircularProgress-root': {
          color: '#444444',
        },
      },
    },
    right: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
      gap: 2,
    },
    clock: {
      display: 'flex',
      gap: 0.5,
      alignItems: 'center',
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 0.5,
    },
    cancelButton: {
      color: '#C1C1C1',
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1,
      paddingY: 1,
      paddingX: 3,
    },
    tooltip: {
      fontFamily: '"Noto Sans", sans-serif',
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: '0.02em',
      background: '#0C0B0B99',
      color: '#979797',
      border: '1px solid #909090',
      borderRadius: 6,
      paddingY: 1,
      paddingX: 3,
      maxWidth: 228,
      textAlign: 'center',
    },
    tooltipArrow: {
      color: '#909090',
    },
  };
};
