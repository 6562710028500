import React from 'react';
import { Box } from '@mui/material';

import { classes, Video } from './styles';

const HomeBottomButtonOptionsCamView = () => (
  <Box component="div" sx={classes.root}>
    <Video autoPlay id="videoElement" />
  </Box>
);

export default HomeBottomButtonOptionsCamView;
