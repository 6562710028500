import React from 'react';
import { Box, Button } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';

import { usePocAi } from '../../providers/PocAiProvider';
import { POC_SCREENS, enableDebugControls } from '../../constants';

import { useStyles } from './styles';

const DefaultScreen = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();

  const { navigate, setOpen, setNavigationHistory, queryBedrockTest, generateAudioPlaybackTest, generateGreetingsAudio, stopPlayback } = usePocAi();

  return (
    <Box sx={classes.box}>
      <Button
        sx={classes.button}
        onClick={() => navigate(POC_SCREENS.HOW_TO)}
      >
        {translate('poc.howTo.title')}
      </Button>
      <Button
        sx={{ ...classes.button, ...classes.blueButton }}
        onClick={() => navigate(POC_SCREENS.CHAT)}
      >
        {translate('poc.askMe.title')}
      </Button>
      <Button sx={classes.hideButton} onClick={() => {
        setOpen(false);
        setNavigationHistory([]);
        navigate(POC_SCREENS.DEFAULT);
      }}>
        {translate('poc.hideNoa')}
      </Button>

      { enableDebugControls &&
        <>
          <Button sx={{ ...classes.button, ...classes.blueButton }} onClick={() => queryBedrockTest()}>Bedrock test</Button>
          <Button sx={{ ...classes.button, ...classes.blueButton }} onClick={() => generateAudioPlaybackTest()}>Audio test</Button>
          <Button sx={{ ...classes.button, ...classes.blueButton }} onClick={() => generateGreetingsAudio()}>Greetings test</Button>
          <Button sx={{ ...classes.button, ...classes.blueButton }} onClick={() => stopPlayback()}>Stop playback test</Button>
        </>
      }
    </Box>
  );
};

export default withTranslation('common')(DefaultScreen);
