import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import WorkIcon from '@mui/icons-material/Work';

import IconPopover from '../../IconPopover';
import ToolboxContent from './ToolboxContent';

import { ToolboxOption } from './ToolboxContent/interfaces';
import { isMobileOnly } from 'react-device-detect';

const ToolBox = () => {
  const [activeToolboxOption, setActiveToolboxOption] = useState<ToolboxOption>(
    ToolboxOption.Briefcase,
  );

  const { t: translate } = useTranslation('common');

  return (
    <IconPopover
      icon={<WorkIcon />}
      label={translate('toolbox.briefcase')}
      isDrawer={isMobileOnly}
    >
      {(handleClose) => (
        <ToolboxContent
          activeToolboxOption={activeToolboxOption}
          setActiveToolboxOption={setActiveToolboxOption}
          onCloseToolbox={handleClose}
        />
      )}
    </IconPopover>
  );
};

export default ToolBox;
