import { SxProps, useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../../../interfaces';
import { colors, colorsEK } from '../../../../../../constants/colors';
import { useMytaverse } from '../../../../../../providers/MytaverseProvider';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  const { appTheme } = useMytaverse();

  const {
    submitButton: { backgroundColor, backgroundColorHover },
  } = appTheme.avatarPage.assets;

  return {
    root: {
      width: '50%',
      paddingX: '40px',

      '@media (max-width: 1500px)': {
        paddingX: '30px',
      },

      [breakpoints.down(ScreenSizes.PcLarge)]: {
        paddingX: '25px',
        width: '60%',
      },
      [breakpoints.down(ScreenSizes.Pc)]: {
        width: '65%',
      },

      '@media (max-width: 767px)': {
        width: '100%',
        height: '100%',
        paddingX: '30px',
      },
    },
    content: {
      width: '100%',
      maxWidth: '750px',
      textAlign: 'center',

      '@media (max-width: 767px)': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    },
    avatarDesktop: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 'calc(50% - 80px)',
      transform: 'translateY(-50%) translateX(-100%)',
      maxWidth: '610px',
      maxHeight: 'calc(100vh - 200px)',

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },

      [breakpoints.down(ScreenSizes.PcLarge)]: {
        left: '20px',
        transform: 'translateY(-50%) translateX(0)',
      },

      '@media (max-width: 767px)': {
        display: 'none',
      },
    } as SxProps,
    avatar: {
      position: 'relative',
      width: '100%',
      paddingY: '16px',
      display: 'none',
      marginTop: 'auto',
      flexGrow: 1,
      overflow: 'hidden',

      '@media (max-width: 767px)': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',

        '& img': {
          display: 'flex',
          maxWidth: '100%',

          maxHeight: '45vh',
          margin: '0 auto',
        },
      },

      '@media (max-width: 400px)': {
        '& img': {
          maxHeight: '35dvh',
        },
      },
    },
    title: {
      fontFamily: 'Emirates-Medium !important',
      fontSize: 40,
      lineHeight: '56px',
      color: colorsEK.black,
      marginBottom: '20px',

      '& span': {
        fontFamily: 'Emirates-Bold !important',
        fontSize: 'inherit',
        lineHeight: 'inherit',
      },

      '@media (max-width: 1500px)': {
        fontSize: 34,
        lineHeight: '48px',
      },

      [breakpoints.down(ScreenSizes.PcLarge)]: {
        fontSize: 32,
        lineHeight: '42px',
      },

      '@media (max-width: 767px)': {
        fontSize: 40,
        lineHeight: '44px',
      },

      '@media (max-width: 340px)': {
        fontSize: 32,
        lineHeight: '34px',
      },
    },
    subtitle: {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: '38px',
      letterSpacing: '0.25px',
      color: colorsEK.black,

      '@media (max-width: 1500px)': {
        fontSize: 20,
        lineHeight: '32px',
      },

      [breakpoints.down(ScreenSizes.PcLarge)]: {
        fontSize: 18,
        lineHeight: '28px',
      },

      '@media (max-width: 767px)': {
        fontSize: 16,
        lineHeight: '26px',
      },

      /*'@media (max-width: 375px)': {
        fontSize: 12,
        lineHeight: '16px',
      },*/
    },
    secondary: {
      '@media (max-width: 460px)': {
        display: 'none',
      },
    },
    actions: {
      marginTop: '46px',
      display: 'flex',
      gap: 2,
      justifyContent: 'center',

      '@media (max-width: 1500px)': {
        marginTop: '38px',
      },

      [breakpoints.down(ScreenSizes.PcLarge)]: {
        marginTop: '30px',
      },

      '@media (max-width: 767px)': {
        flexDirection: 'column',
        marginTop: 'auto',
      },

      '& > *': {
        width: '50%',

        '@media (max-width: 767px)': {
          width: '100%',
        },
      },
    },
    button: {
      maxWidth: '365px',
      color: colors.white,
      fontWeight: 500,
      background: backgroundColor,
      fontSize: 24,
      lineHeight: '28px',
      paddingY: '12px',
      textTransform: 'none',
      height: 'fit-content',
      border: `2px solid ${backgroundColor}`,

      '&:hover': {
        background: backgroundColorHover,
        borderColor: backgroundColorHover,
      },

      '&:disabled': {
        color: colors.white,
        background: `${backgroundColor}4D`,
        borderColor: `${backgroundColor}4D`,
      },

      '@media (max-width: 1500px)': {
        fontSize: 20,
        lineHeight: '24px',
        paddingY: '10px',
      },

      [breakpoints.down(ScreenSizes.PcLarge)]: {
        fontSize: 18,
        lineHeight: '22px',
      },

      '@media (max-width: 767px)': {
        fontSize: 24,
        lineHeight: '28px',
        maxWidth: 'unset',
        paddingY: '12px',
      },

      '@media (max-width: 375px)': {
        fontSize: 16,
        lineHeight: '22px',
        paddingY: '8px',
      },
    },
    buttonSecondary: {
      backgroundColor: colors.white,
      color: backgroundColor,
      border: `2px solid ${backgroundColor}`,

      '&:hover': {
        color: colors.white,
        background: backgroundColor,
        borderColor: backgroundColor,
      },

      '&:disabled': {
        color: colors.white,
        borderColor: `${backgroundColor}4D`,
        background: `${backgroundColor}4D`,
      },
    },
    boxConsent: {
      display: 'flex',
      flexDirection: 'column',
      gap: 1.5,
      alignItems: 'center',

      '& > *': {
        width: '100%',
      },

      '@media (max-width: 767px)': {
        flexDirection: 'column-reverse',
      },
    },
  };
};
