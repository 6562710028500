import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { WithTranslation, withTranslation } from 'react-i18next';

import Slot from '../../Slot';
import LoadingProgress from '../../../../../../../../components/LoadingProgress';

import { useStyles } from './styles';

interface ITabContent extends WithTranslation {
  slots: any[];
  isLoading: boolean;
  noSlotsText: string;
  onClick?: () => void;
}

const TabContent = ({
  slots,
  isLoading,
  noSlotsText,
  onClick,
  t: translate,
}: ITabContent) => {
  const classes = useStyles();

  const renderSlots = () => {
    if (slots.length > 0) {
      return slots.map((slot) => <Slot key={slot.eventId} slot={slot} />);
    }

    return <Typography sx={classes.noSlotsText}>{noSlotsText}</Typography>;
  };

  if (isLoading) {
    return <LoadingProgress />;
  }

  return (
    <Box sx={classes.root}>
      <Box sx={classes.slots}>{renderSlots()}</Box>

      {onClick && slots.length > 0 ? (
        <Box sx={classes.actions}>
          <Button onClick={onClick} sx={classes.button}>
            {translate('ek.officeHours.showMore')}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export default withTranslation('common')(TabContent);
