import { Box, Typography } from '@mui/material';
import { useMytaverseEvent } from '../../../providers';
import { useStyles } from './styles';

const MessagesTab = () => {
  const { developersTerminalMessages } = useMytaverseEvent();
  const classes = useStyles();

  return (
    <Box sx={classes.root} className='no-drag'>
      {developersTerminalMessages.map((message) => (
        <Typography key={message}>{message}</Typography>
      ))}
    </Box>
  );
};

export default MessagesTab;
