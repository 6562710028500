import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

export const DeviceOrientationIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="134"
    height="160"
    viewBox="0 0 134 160"
    fill="none"
    {...props}
  >
    <path
      d="M0.118391 29.9225L12.0842 42.072L24.2868 30.1603L20.6923 26.5104L15.0668 32.0017C14.8743 30.7658 14.775 29.5155 14.7874 28.2596C14.9127 15.5251 25.3278 5.35816 38.1191 5.48403L38.1694 0.372265C22.6032 0.219082 9.80525 12.712 9.65274 28.2091C9.63814 29.6934 9.76048 31.1726 9.97995 32.6356L3.78345 26.344L0.118391 29.9225Z"
      fill="black"
    />
    <path
      d="M133.758 130.031L121.673 118L109.588 130.031L113.219 133.646L118.79 128.099C118.994 129.333 119.106 130.582 119.106 131.838C119.106 144.573 108.791 154.842 95.9994 154.842L95.9994 159.954C111.566 159.954 124.241 147.336 124.241 131.838C124.241 130.354 124.104 128.876 123.87 127.415L130.128 133.646L133.758 130.031Z"
      fill="black"
    />
    <mask id="path-3-inside-1_1679_3715" fill="white">
      <rect x="38" y="26" width="58" height="108" rx="2" />
    </mask>
    <rect
      x="38"
      y="26"
      width="58"
      height="108"
      rx="2"
      stroke="black"
      strokeWidth="10"
      mask="url(#path-3-inside-1_1679_3715)"
    />
  </SvgIcon>
);
