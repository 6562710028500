import React from 'react';
import { Box } from '@mui/material';

import AssistantImage from '../../../../../public/images/poc/assistant.jpg';

import { useStyles } from './styles';

import { assistantSize } from '../constants';

const Assistant = () => {
  const classes = useStyles();

  return (
    <Box sx={classes.box}>
      {/*<img src={AssistantImage} />*/}
      <div className="assistant">
        <img className="assistant-avatar" id="ai-assistant" src={AssistantImage}
          style={{
            width: assistantSize.width + 'px',
            height: assistantSize.height + 'px',
            /*marginLeft: assistantSize.height / 2 + 'px',
            marginTop: assistantSize.height / 2 + 'px'*/
          }} />
        <canvas id="ai-assistant-canvas" className="ai-assistant-canvas"></canvas>
      </div>
    </Box>
  );
};

export default Assistant;
