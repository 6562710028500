import { useEffect } from 'react';
import { EventHandler, StreamChat } from 'stream-chat';
import { DefaultStreamChatGenerics as StreamChatGenerics } from 'stream-chat-react/dist/types/types';
import {
  ChatMessageType,
  useNotification,
} from '../../../components/Notification';
import { getCatchErrorMessage } from '../../../helpers/error';
import {
  useRedirectToGlobalChat,
  useRedirectToPrivateChat,
  useStreamChannels,
} from '../../../hooks/channel';
import { useChatState } from '../../../hooks/context';
import { useSound } from '../../../hooks/media';
import { useMytaverse } from '../../../providers/MytaverseProvider';
import { useNotificationContext } from '../../../providers/NotificationProvider';
import NotificationSound from '../../../public/sounds/notification.wav';
import ChannelService from '../../../services/ChannelService';
import { checkIsStreamChannelUnread } from '../helpers';
import {
  checkChannelFormCurrentEvent,
  checkHasUnreadPrivateChannel,
  checkIsEventChannel,
  getPrivateChannelId,
} from '../helpers/channel';
import { useMytaverseEvent } from '../providers';
import {
  IUseChatHandlers,
  IUseCheckInitialUnreadChannels,
  IUseCheckUnreadChannel,
  IUseInitializeStreamClientConnection,
  IUseNewChannel,
  IUseSetInitialChannels,
} from './interfaces';

const { REACT_APP_EK_ENV } = process.env;

export const useChatHandlers = ({
  currentEvent,
  streamChatToken,
}: IUseChatHandlers) => {
  const { user, tokens } = useMytaverse();
  const { streamChatMemberId, participants } = useMytaverseEvent();
  const {
    open: isOpenChat,
    streamClient,
    setStreamClient,
    eventStreamChannel,
    setEventStreamChannel,
    privateChannels,
    setPrivateChannels,
    addPrivateChannel,
    setInitialLoading,
    setIsUnreadEventChannel,
    setIsUnreadPrivateChannel,
  } = useChatState();

  const userId = streamChatMemberId || '';
  const currentEventId = currentEvent?.eventId || '';

  useInitializeStreamClientConnection({
    streamChatToken,
    streamClient,
    setStreamClient,
    user,
    streamChatMemberId: userId,
    tokens,
  });

  useSetInitialChannels({
    streamClient,
    setEventStreamChannel,
    setPrivateChannels,
    currentEventId,
    userId,
  });

  useCheckInitialUnreadChannels({
    eventChannel: eventStreamChannel,
    privateChannels,
    setInitialLoading,
    setIsUnreadEventChannel,
    setIsUnreadPrivateChannel,
  });

  useCheckUnreadChannel({
    privateChannels,
    setIsUnreadEventChannel,
    setIsUnreadPrivateChannel,
    streamClient,
    userId,
    currentEventId,
    isOpenChat,
    participants,
  });

  useNewChannel({
    streamClient,
    hasEventStreamChannel: !!eventStreamChannel,
    setEventStreamChannel,
    addPrivateChannel,
    currentEventId,
    userId,
  });
};

export const useInitializeStreamClientConnection = ({
  streamChatToken,
  streamClient,
  setStreamClient,
  user,
  streamChatMemberId,
  tokens,
}: IUseInitializeStreamClientConnection) => {
  useEffect(() => {
    if (!streamChatMemberId || !streamChatToken) return;

    const initializeStreamClientConnection = async () => {
      try {
        const apiKey = tokens?.streamChat || '';
        const client = new StreamChat<StreamChatGenerics>(apiKey);

        const name = REACT_APP_EK_ENV ? user.hrData.name : user.name;

        const chatUser = {
          id: streamChatMemberId,
          name,
        };

        await client.connectUser(chatUser, streamChatToken);

        setStreamClient(client);
      } catch (error: unknown) {
        throw Error(getCatchErrorMessage(error));
      }
    };

    initializeStreamClientConnection();

    return () => {
      streamClient?.disconnectUser();
      setStreamClient(null);
    };
  }, [user, streamChatToken, streamChatMemberId]);
};

export const useSetInitialChannels = ({
  streamClient,
  setEventStreamChannel,
  setPrivateChannels,
  currentEventId,
  userId,
}: IUseSetInitialChannels) => {
  const { getEventStreamChannel, getMapEventChannels } = useStreamChannels();

  useEffect(() => {
    if (!streamClient || !currentEventId || !userId) return;

    const setInitialChannels = async () => {
      const eventStreamChannel = await getEventStreamChannel({
        streamClient,
        userId,
        eventId: currentEventId,
      });

      setEventStreamChannel(eventStreamChannel);

      const eventChannels = await ChannelService.getEventChannels(
        currentEventId,
      );
      const privateChannels = await getMapEventChannels(
        streamClient,
        eventChannels,
        userId,
      );

      setPrivateChannels(privateChannels.filter(({ channel }) => channel));
    };

    setInitialChannels();
  }, [streamClient, currentEventId, userId]);
};

export const useCheckInitialUnreadChannels = ({
  eventChannel,
  privateChannels,
  setInitialLoading,
  setIsUnreadEventChannel,
  setIsUnreadPrivateChannel,
}: IUseCheckInitialUnreadChannels) => {
  useEffect(() => {
    const isEventChannelUnread = checkIsStreamChannelUnread(eventChannel);

    setIsUnreadEventChannel(isEventChannelUnread);

    if (!privateChannels.length) {
      setInitialLoading(false);
      return;
    }

    const hasUnreadPrivateChannel =
      checkHasUnreadPrivateChannel(privateChannels);

    setIsUnreadPrivateChannel(hasUnreadPrivateChannel);
    setInitialLoading(false);
  }, [eventChannel, privateChannels]);
};

export const useCheckUnreadChannel = ({
  privateChannels,
  setIsUnreadEventChannel,
  setIsUnreadPrivateChannel,
  streamClient,
  userId,
  currentEventId,
  isOpenChat,
  participants,
}: IUseCheckUnreadChannel) => {
  const [play] = useSound(NotificationSound, {
    volume: 0.15,
  });
  const { showChatNotification } = useNotification();
  const redirectToPrivateChat = useRedirectToPrivateChat();
  const redirectToGlobalChat = useRedirectToGlobalChat();
  const { isEventRun } = useNotificationContext();

  useEffect(() => {
    if (!streamClient) return;

    const handleNewMessage: EventHandler<StreamChatGenerics> = (evt) => {
      const participant = participants?.find(
        (participant) =>
          participant.streamChatMemberId === evt?.message?.user?.id,
      );
      if (evt?.message?.user && participant?.fullName)
        evt.message.user.name = participant?.fullName;

      const readUserId = evt.user?.id || '';
      const isCurrentUser = readUserId === userId;

      if (isCurrentUser || isOpenChat) return;

      const streamChannelId = evt.channel_id || '';
      const isCurrentEvent = checkChannelFormCurrentEvent(
        streamChannelId,
        currentEventId,
      );

      if (!isCurrentEvent) return;

      const isEventChannel = checkIsEventChannel(
        streamChannelId,
        currentEventId,
      );

      if (!isEventRun) return;

      if (isEventChannel) {
        setIsUnreadEventChannel(true);
        play();
        showChatNotification({
          message: evt.message as ChatMessageType,
          onReply: redirectToGlobalChat,
        });
      } else {
        setIsUnreadPrivateChannel(true);
        play();
        showChatNotification({
          message: evt.message as ChatMessageType,
          onReply: redirectToPrivateChat,
        });
      }
    };

    const handleReadMessage: EventHandler<StreamChatGenerics> = (evt) => {
      const readUserId = evt.user?.id || '';
      const streamChannelId = evt.channel_id || '';
      const isCurrentUser = readUserId === userId;

      if (!isCurrentUser) return;

      const isCurrentEvent = checkChannelFormCurrentEvent(
        streamChannelId,
        currentEventId,
      );

      if (!isCurrentEvent) return;

      const isEventChannel = checkIsEventChannel(
        streamChannelId,
        currentEventId,
      );

      if (isEventChannel) {
        setIsUnreadEventChannel(false);
      } else {
        const hasUnreadPrivateChannel =
          checkHasUnreadPrivateChannel(privateChannels);

        setIsUnreadPrivateChannel(hasUnreadPrivateChannel);
      }
    };

    streamClient.on('message.new', handleNewMessage);
    streamClient.on('message.read', handleReadMessage);

    return () => {
      streamClient.off('message.new', handleNewMessage);
      streamClient.on('message.read', handleReadMessage);
    };
  }, [currentEventId, privateChannels, streamClient, userId, isOpenChat]);
};

export const useNewChannel = ({
  streamClient,
  hasEventStreamChannel,
  setEventStreamChannel,
  addPrivateChannel,
  currentEventId,
  userId,
}: IUseNewChannel) => {
  const { getEventStreamChannel, getMapEventChannel } = useStreamChannels();

  useEffect(() => {
    if (!streamClient || !currentEventId || !userId) {
      return;
    }

    const newChannelSubscription = streamClient.on(
      'notification.added_to_channel',
      async (event) => {
        const streamChannelId = event.channel_id || '';
        const isCurrentEvent = checkChannelFormCurrentEvent(
          streamChannelId,
          currentEventId,
        );

        if (!isCurrentEvent) return;

        const isEventChannel = checkIsEventChannel(
          streamChannelId,
          currentEventId,
        );

        if (isEventChannel) {
          if (hasEventStreamChannel) return;

          const eventStreamChannel = await getEventStreamChannel({
            streamClient,
            userId: userId,
            eventId: currentEventId,
          });

          setEventStreamChannel(eventStreamChannel);
          return;
        }

        const privateChannelId = getPrivateChannelId(streamChannelId);
        const eventChannel = await ChannelService.getEventChannel(
          currentEventId,
          privateChannelId,
        );
        const newPrivateChannel = await getMapEventChannel(
          streamClient,
          eventChannel,
          userId,
        );

        addPrivateChannel(newPrivateChannel);
      },
    );

    return () => {
      newChannelSubscription.unsubscribe();
    };
  }, [streamClient, currentEventId, userId, hasEventStreamChannel]);
};
