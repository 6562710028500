import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useManualTimer } from '../../../../auth/hooks/timer';
import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../components/Notification';
import { useMytaverseEvent } from '../../../providers';

import { getNotification } from '../../../../../components/Notification/helpers';

import { mytaverseWebsocketLogger } from '../../../../../providers/MytaverseProvider';

import { UseHandleUEConnectionType } from './interfaces';

import { reloadPageTimeout } from '../../../../../helpers';
import { StreamingProviders } from '../../../providers/MytaverseEventProvider/interfaces';

export const useHandleUEConnection: UseHandleUEConnectionType = () => {
  const {
    isTeleporting,
    isTeleportingToRoomByUnreal,
    setIsTeleportingToRoomByUnreal,
    setUe5WebsocketConnected,
    streamingProvider,
    setUe5CoreWeaveDisabled,
  } = useMytaverseEvent();

  const { timer, intervalId, startTimer, clearTimer } = useManualTimer();
  const { showNotification } = useNotification();
  const { t: translate } = useTranslation('common');

  const isTeleportRun = isTeleporting || isTeleportingToRoomByUnreal;

  useEffect(() => {
    if (timer < 1 && !!intervalId) {
      clearTimer();
      showNotification(
        getNotification({
          message: translate('notifications.kickOutError'),
          type: NOTIFICATION_TYPES.ERROR,
          withReloadButton: true,
          closeManually: true,
        }),
      );
      reloadPageTimeout(120, 'UE5 disconnected');
    }
  }, [clearTimer, intervalId, showNotification, timer, translate]);

  const handleUe5WsConnected = useCallback(() => {
    mytaverseWebsocketLogger.log('UE5 connected');

    setTimeout(() => {
      setUe5WebsocketConnected(true);
    }, 3000);

    if (streamingProvider === StreamingProviders.CoreWeave) {
      setTimeout(() => {
        setUe5CoreWeaveDisabled(false);
      }, 3000);
    }

    if (isTeleportRun) {
      if (isTeleportingToRoomByUnreal) {
        mytaverseWebsocketLogger.log(
          'Close teleport modal due to UE5 connected',
        );
        setIsTeleportingToRoomByUnreal(false);
      }
    }

    if (intervalId) {
      clearTimer();
    }
  }, [
    isTeleportingToRoomByUnreal,
    setIsTeleportingToRoomByUnreal,
    isTeleportRun,
    intervalId,
    clearTimer,
    streamingProvider,
  ]);

  const handleUe5WsDisconnected = useCallback(() => {
    if (!isTeleportRun) {
      mytaverseWebsocketLogger.error('UE5 disconnected');
    } else {
      mytaverseWebsocketLogger.log('UE5 disconnected due teleport to room');
    }

    setUe5WebsocketConnected(false);

    //for CoreWeave there is no need to display notification and reload page
    if (streamingProvider === StreamingProviders.CoreWeave) {
      setUe5CoreWeaveDisabled(true);
      return;
    }

    if (isTeleportRun) {
      return;
    }

    startTimer();
  }, [isTeleportRun, startTimer, streamingProvider]);

  return {
    handleUe5WsConnected,
    handleUe5WsDisconnected,
  };
};
