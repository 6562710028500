enum ParticipantScopes {
  ReadProfile = 'read:profile',
  WriteProfile = 'write:profile',

  ReadEvents = 'read:events',
  WriteEvents = 'write:events',

  JoinEvent = 'join:events',

  ReadCalendar = 'read:calendar',
  WriteCalendar = 'write:calendar',

  ReadChats = 'read:chats',
  WriteChats = 'write:chats',

  ReadGamecast = 'read:gamecast',
  WriteGamecast = 'write:gamecast',

  ReadCustomers = 'read:customers',
  WriteCustomers = 'write:customers',

  TrackAnalytics = 'track:analytics',

  ReadPixelStreamingLogs = 'read:pixel-streaming-logs',
  WritePixelStreamingLogs = 'write:pixel-streaming-logs',

  ReadCoreweave = 'read:coreweave',
  WriteCoreweave = 'write:coreweave',

  ReadDolby = 'read:dolby',
  WriteDolby = 'write:dolby',
  StreamDolby = 'stream:dolby',

  UploadFiles = 'upload:files',

  ReadMaps = 'read:maps',
  WriteMaps = 'write:maps',

  ReadParticipants = 'read:participants',
  WriteParticipants = 'write:participants',

  ReadRooms = 'read:rooms',
  WriteRooms = 'write:rooms',

  ReadAvatars = 'read:avatars',
  WriteAvatars = 'write:avatars',

  ReadSkins = 'read:skins',
  WriteSkins = 'write:skins',

  ReadOrganizations = 'read:organizations',
  WriteOrganizations = 'write:organizations',

  ReadWorlds = 'read:worlds',
  CreateWorlds = 'create:worlds',
  UpdateWorlds = 'update:worlds',
  DeleteWorlds = 'delete:worlds',
}

export default ParticipantScopes;
