import { useMytaverseEvent } from '../../../../providers';

import { IActiveChannelItem } from './interfaces';
import { IParticipant } from '../../../../../../interfaces/participants';
import { IActivePrivateChannel } from '../../../../../../interfaces/channel';

export const useGetActiveChannelData = () => {
  const { participants, streamChatMemberId } = useMytaverseEvent();

  const getActiveChannelData = (
    activePrivateChannel: IActivePrivateChannel,
  ): IActiveChannelItem | null => {
    if (!activePrivateChannel) return null;

    const memberId = activePrivateChannel.userIds.find(
      (userId) => userId !== streamChatMemberId,
    );
    const participant = participants.find(
      (participant) => participant.streamChatMemberId === memberId,
    ) as IParticipant;

    return { participant, privateChannel: activePrivateChannel };
  };

  return getActiveChannelData;
};
