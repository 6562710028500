import { MeasurementConfig } from '@cloudflare/speedtest';

const MeasurementOptions: MeasurementConfig[] = [
  { type: 'download', bytes: 1e5, count: 1, bypassMinDuration: true }, // initial download estimation

  { type: 'download', bytes: 1e5, count: 9 },
  { type: 'download', bytes: 1e6, count: 8 },
  { type: 'download', bytes: 1e7, count: 6 },
  { type: 'download', bytes: 2.5e7, count: 4 },
  { type: 'download', bytes: 1e8, count: 3 },
  { type: 'download', bytes: 2.5e8, count: 2 },
];

export const configOptions = {
  measureUploadLoadedLatency: false,
  measureDownloadLoadedLatency: false,
  measurements: MeasurementOptions,
};
