import { useCallback, useState } from 'react';

import { toggleDrawer } from '../../../../helpers';
import { useChatState } from '../../../../hooks/context';
import { useMytaverse } from '../../../../providers/MytaverseProvider';
import { ToggleDrawerType } from '../../interfaces';
import { useConference } from '../DashboardContent/Dolby';
import ChatDrawerView from './ChatDrawerView';

const ChatDrawer = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { selectedLanguage } = useMytaverse();
  const { screenMediaStreams } = useConference();
  const {
    open: isOpenChat,
    setOpen,
    initialLoading,
    streamClient,
    activeTab,
  } = useChatState();

  const handleToggleDrawer: ToggleDrawerType = useCallback(
    (event) => {
      toggleDrawer(event, () => setOpen(!isOpenChat));
    },
    [isOpenChat, setOpen],
  );

  const autoClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if (!streamClient) {
    return null;
  }

  return (
    <ChatDrawerView
      isOpenDrawer={isOpenChat}
      toggleDrawer={handleToggleDrawer}
      streamClient={streamClient}
      isLoading={initialLoading}
      language={selectedLanguage}
      activeTab={activeTab}
      hasScreenMediaStreams={!!screenMediaStreams?.length}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      autoClose={autoClose}
    />
  );
};

export default ChatDrawer;
