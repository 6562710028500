import { useTheme } from '@mui/material';

import { getSelectEventsSwiperMaxWidth } from './helpers';

import { colors } from '../../../../constants/colors';

import { SwiperScreenSizes } from '../../../../interfaces';
import { ISwiperWrapperStyles } from './interfaces';

export const useStyles = ({
  swiperBreakpoints,
  loop,
  sliderWidth,
}: ISwiperWrapperStyles) => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    content: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '100%',
      '& .swiper': {
        maxWidth: getSelectEventsSwiperMaxWidth(
          swiperBreakpoints[SwiperScreenSizes.Pc],
          typeof sliderWidth === 'number'
            ? sliderWidth
            : sliderWidth[SwiperScreenSizes.Pc].width,
        ),
        width: '100%',
        margin: 0,
        [breakpoints.down(SwiperScreenSizes.Pc)]: {
          maxWidth: getSelectEventsSwiperMaxWidth(
            swiperBreakpoints[SwiperScreenSizes.Laptop],
            typeof sliderWidth === 'number'
              ? sliderWidth
              : sliderWidth[SwiperScreenSizes.Laptop].width,
          ),
        },
        [breakpoints.down(SwiperScreenSizes.Laptop)]: {
          maxWidth: getSelectEventsSwiperMaxWidth(
            swiperBreakpoints[SwiperScreenSizes.Tablet],
            typeof sliderWidth === 'number'
              ? sliderWidth
              : sliderWidth[SwiperScreenSizes.Tablet].width,
          ),
        },
        [breakpoints.down(SwiperScreenSizes.Tablet)]: {
          maxWidth: getSelectEventsSwiperMaxWidth(
            swiperBreakpoints[SwiperScreenSizes.Mobile],
            typeof sliderWidth === 'number'
              ? sliderWidth
              : sliderWidth[SwiperScreenSizes.Mobile].width,
          ),
        },
      },
      '& .swiper-wrapper': {
        justifyContent: loop ? 'space-between' : 'center',
        alignItems: 'center',
      },
      '& .swiper-slide:last-child': {
        marginRight: loop ? 'auto' : '0px !important', // override element.style
      },
    },
    navigationBtn: {
      [breakpoints.down(SwiperScreenSizes.Tablet)]: {
        display: 'none',
      },
    },
    pagination: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
      px: 4,
      minHeight: '10px',
      marginTop: '45px',
      [breakpoints.down(SwiperScreenSizes.Laptop)]: {
        width: '100%',
        justifyContent: 'space-between',
        marginTop: '30px',
        minHeight: 'unset',
      },
      '& .swiper-pagination': {
        py: 1,
        bottom: '5px',
        [breakpoints.up(SwiperScreenSizes.Laptop)]: {
          display: 'flex',
          bottom: 0,
          position: 'relative',
          //marginTop: '45px',
          backgroundColor: colors.black_60,
          borderRadius: '100px',
          border: `1px solid ${colors.white_40}`,
          padding: '9px 16px',
          maxWidth: 'fit-content',
        },
        [breakpoints.down(SwiperScreenSizes.Laptop)]: {
          paddingBottom: '2px',
        },
        '& .swiper-pagination-bullet': {
          backgroundColor: colors.oxford_60,
          opacity: 1,

          [breakpoints.up(SwiperScreenSizes.Laptop)]: {
            backgroundColor: colors.white_60,
          },
        },
        '& .swiper-pagination-bullet-active': {
          backgroundColor: colors.white,
          [breakpoints.down(SwiperScreenSizes.Laptop)]: {
            backgroundColor: colors.oxford_60,
            transform: 'scale(1.25)',
          },
        },
      },
      '& .swiper-pagination-lock': {
        display: 'none',
      },
    },
    paginationNavigationBtn: {
      display: 'none',
      [breakpoints.down(SwiperScreenSizes.Tablet)]: {
        display: 'flex',
      },
    },
  };
};
