import { ChangeEvent, useMemo, useState, useCallback } from 'react';

import _ from 'lodash';

export const useSearchField = <T>(searchList: T[], searchField: string) => {
  const [inputValue, setInputValue] = useState('');

  const filteredParticipants = useMemo(() => {
    return searchList.filter((searchItem) => {
      const searchValue = _.get(searchItem, searchField);

      return searchValue?.toLowerCase().includes(inputValue.toLowerCase());
    });
  }, [inputValue, searchField, searchList]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    },
    [],
  );

  const handleInputClear = useCallback(() => {
    setInputValue('');
  }, []);

  return {
    inputValue,
    filteredData: filteredParticipants,
    handleInputChange,
    handleInputClear,
  };
};
