import {
  isAndroid,
  isIOS,
  isMobileOnly,
  isTablet,
  isWinPhone,
} from 'react-device-detect';

import { FollowingStatus } from '../../../../interfaces/event';
import { IMapFollower, MapFollowersType, DeviceTypeEnum } from './interfaces';
import { IFollowerData } from '../../../../interfaces/followers';
import { WsConnectionClientType } from '../../../../interfaces';

export const mapFollowers: MapFollowersType = (followers) => {
  const mapData = followers.reduce<IMapFollower>(
    (acc, follower) => {
      if (follower.clientType === WsConnectionClientType.UE) return acc;

      const followerData: IFollowerData = {
        adminId: follower.following,
        userId: follower.participantId,
      };

      if (follower.followingStatus === FollowingStatus.Pending) {
        acc.pending.push(followerData);
      } else {
        acc.accepted.push(followerData);
      }

      return acc;
    },
    {
      pending: [],
      accepted: [],
    },
  );

  return mapData;
};

export const getDeviceProfile = () => {
  if (isMobileOnly) {
    if (isIOS) {
      return DeviceTypeEnum.PhoneIOS;
    }

    if (isAndroid) {
      return DeviceTypeEnum.PhoneAndroid;
    }

    if (isWinPhone) {
      return DeviceTypeEnum.PhoneWin;
    }

    return DeviceTypeEnum.Phone;
  }

  if (isTablet) {
    if (isIOS) {
      return DeviceTypeEnum.TabletIOS;
    }

    if (isAndroid) {
      return DeviceTypeEnum.TabletAndroid;
    }

    return DeviceTypeEnum.Tablet;
  }

  return DeviceTypeEnum.Desktop;
};
