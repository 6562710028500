import React, { useCallback } from 'react';
import {
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
} from '@mui/material';

import GenderRadio from './GenderRadio';
import SkinToneRadio from './SkinToneRadio';
import { ToneSkinOptions, GenderOptions } from './constants';
import { INonConsentData } from '../../interfaces';
import { useStyles } from './styles';

const CustomUIForm = ({
  data,
  onChange,
}: {
  data: INonConsentData;
  onChange: React.Dispatch<React.SetStateAction<INonConsentData>>;
}) => {
  const classes = useStyles();

  const { skinTone } = data;

  const handleChange = useCallback((property: 'gender' | 'skinTone') => {
    return function (event: React.ChangeEvent<HTMLInputElement>) {
      onChange((prevState) => ({
        ...prevState,
        [property]: event.target.value,
      }));
    };
  }, []);

  return (
    <Box sx={classes.form}>
      <FormControl sx={classes.control}>
        <FormLabel id="skin-tone-radio-buttons-group-label" sx={classes.label}>
          Skin Tone:
        </FormLabel>
        <RadioGroup
          aria-labelledby="skin-tone-radio-buttons-group-label"
          value={skinTone}
          row
          onChange={handleChange('skinTone')}
          sx={classes.skinControl}
        >
          {ToneSkinOptions.map(({ value }) => (
            <SkinToneRadio
              key={value}
              value={value}
              name="radio-buttons"
              inputProps={{ 'aria-label': value }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default CustomUIForm;
