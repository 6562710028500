import { CHAT_DRAWER_WIDTH, SHARED_WINDOW_HEIGHT } from '../../constants';

import { INotificationProviderStyles } from './interfaces';
import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../interfaces';

export const useStyles = ({
  hasSharedScreen,
  isOpenChat,
}: INotificationProviderStyles) => {
  const { breakpoints } = useTheme();

  return {
    root: {
      '&&': {
        '& .SnackbarContainer-root': {
          transition:
            'top 225ms ease 0ms,right 200ms ease 0ms,bottom 225ms ease 0ms,left 225ms ease 0ms,margin 225ms ease 0ms,max-width 225ms ease 0ms',
        },
        '& .SnackbarContainer-right': {
          right: isOpenChat ? CHAT_DRAWER_WIDTH + 32 : 32,

          [breakpoints.down(ScreenSizes.Pc)]: {
            right: 32,
            zIndex: 1200,
          },
        },
        '& .SnackbarContainer-top': {
          top: hasSharedScreen ? SHARED_WINDOW_HEIGHT + 10 : 70,
        },

        [breakpoints.down(ScreenSizes.Tablet)]: {
          '& .SnackbarContainer-right': {
            right: isOpenChat ? CHAT_DRAWER_WIDTH + 8 : 8,
          },
          '& .SnackbarContainer-root': {
            maxWidth: 'calc(100% - 50px)',
          },
        },
      },
    },
  };
};
