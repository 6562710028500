import { COLORS } from '../../constants/colors';
import { DRAWER_WIDTH } from '../../constants';

export const classes = {
  root: {
    '& .MuiPaper-root': {
      backgroundColor: COLORS.BACKGROUND_COLOR,
      backdropFilter: 'blur(12px)',
      width: DRAWER_WIDTH,
      maxWidth: '80%',
      px: 1,
    },
  },
};
