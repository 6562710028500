import React from 'react';
import { withTranslation } from 'react-i18next';

import { ReactComponent as FullscreenIcon } from '../../../../public/images/ek/fullscreen/zoomIn.svg';
import { ReactComponent as FullscreenExitIcon } from '../../../../public/images/ek/fullscreen/zoomOut.svg';

import DashboardBarButton from '../../../../modules/dashboard/components/DashboardTopBar/DashboardTopBarContent/DashboardBarButton';

import { IFullScreenButton } from './interfaces';
import { useStyles } from './styles';

const FullScreenButtonView = ({
  onClick,
  isFullscreen,
  t: translate,
}: IFullScreenButton) => {
  const classes = useStyles();

  return (
    <DashboardBarButton
      buttonIcon={isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      onClick={onClick}
      sx={classes.button}
    >
      {isFullscreen
        ? translate('tooltips.fullscreenExit')
        : translate('tooltips.fullscreen')}
    </DashboardBarButton>
  );
};

export default withTranslation('common')(FullScreenButtonView);
