import { useMytaverse } from '../../../../../../providers/MytaverseProvider';

import { IStyles } from '../../../../../../interfaces/styles';

const baseSwiperButtonsStyles = {
  inset: 0,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  position: 'absolute',
  top: '50%',

  cursor: 'pointer',

  width: '40px',
  height: '40px',
  background: '#F1F1F1',
  borderRadius: '50%',

  border: '2px solid #D8D8D8',

  '&::after': {
    content: '""',
  },
};

export const useStyles = (): IStyles => {
  const { appTheme } = useMytaverse();

  return {
    prevBtn: {
      ...baseSwiperButtonsStyles,
      transform: 'translateY(-50%) translateX(-50%)',
      left: '0',

      '& svg path': {
        stroke: appTheme.mainColor,
      },
    },
    nextBtn: {
      ...baseSwiperButtonsStyles,

      transform: 'translateY(-50%) translateX(50%)',
      right: '0',
      left: 'unset',

      '& svg path': {
        stroke: appTheme.mainColor,
      },
    },
  };
};
