import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import useAsyncEffect from 'use-async-effect';
import { useTranslation } from 'react-i18next';

import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import DolbyConference from '@voxeet/voxeet-web-sdk/types/models/Conference';
import { Participant as DolbyParticipant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import { ParticipantInfo } from '@voxeet/voxeet-web-sdk/types/models/Options';
import { MediaStreamWithType } from '@voxeet/voxeet-web-sdk/types/models/MediaStream';

import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../components/Notification';
import { useMytaverseEvent } from '../../../providers';
import { useNotificationContext } from '../../../../../providers/NotificationProvider';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';

import {
  getConferenceAlias,
  getConferenceConfig,
  getGameSessionId,
  getNextSelectedCamera,
  getSpatialType,
} from './helpers';
import { getCatchErrorMessage } from '../../../../../helpers/error';

import dolbyLogger from './logger';

import DolbyService from './DolbyService';

import {
  IMediaStream,
  IParticipant,
  IParticipantRegion,
} from '../../../../../interfaces/participants';
import { ParticipantPosition } from '../interfaces';
import {
  ConferenceError,
  IConferenceContext,
  IConferenceQueue,
  IDolbyMediaStream,
} from './interfaces';
import {
  ILastWebsocketJsonMessage,
  SpatialType,
  WebsocketAction,
} from '../../../../../interfaces/webSocketConnectionInfo';
import { IEvent } from '../../../../../interfaces/event';
import { IRoom } from '../../../../../interfaces/rooms';
import { useConferenceState } from '../../../../../hooks/conferenceContext';
import EventsService from '../../../../../services/EventsService';
import { NavigatorPermissionsService } from '../../../../../helpers/permissionsService';
import { IWebsocketMessageFlag } from '../../../providers/MytaverseEventProvider/interfaces';
import { useZoom } from '../Zoom/ZoomProvider';

const { ParticipantState } = WebsocketAction;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const ConferenceContext = React.createContext<IConferenceContext>({});

export const useConference = () => useContext(ConferenceContext);

type ConferenceProviderProps = {
  children: ReactNode;
};

export const ConferenceProvider: React.FC<ConferenceProviderProps> = ({
  children,
}) => {
  const [isDolbyInitialized, setIsDolbyInitialized] =
    React.useState<boolean>(false);
  const [isConferenceInitialized, setConferenceInitialized] =
    React.useState<boolean>(false);
  const [joiningToConference, setJoiningToConference] =
    React.useState<boolean>(false);
  const [changingDevice, setChangingDevice] = React.useState(false);
  const [previewingParticipant, setPreviewingParticipant] =
    React.useState<IParticipant | null>(null);
  const [conference, setConference] = React.useState<DolbyConference | null>(
    null,
  );
  const [conferencesQueue, setConferencesQueue] = React.useState<
    IConferenceQueue[]
  >([]);
  const [isFirstPersonView, setIsFirstPersonView] =
    React.useState<IWebsocketMessageFlag>({
      flag: false,
      timestamp: 0,
    });
  const [videoStarted, setVideoStarted] = React.useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = React.useState<boolean>(false);
  const [sharingMedia, setSharingMedia] = React.useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [streamQuality, setStreamQuality] = React.useState<any>('fhd');
  const [microphones, setMicrophones] = React.useState<any[]>([]);
  const [speakers, setSpeakers] = React.useState<any[]>([]);
  const [cameras, setCameras] = React.useState<any[]>([]);
  const [disabledPersonView, setDisabledPersonView] = React.useState(false);
  const [spatialTypeName, setSpatialTypeName] =
    React.useState<SpatialType | null>(null);
  const [speakingParticipantIds, setSpeakingParticipantsIds] = React.useState<
    string[]
  >([]);

  const [ueDolbyMediaStreams, setUEDolbyMediaStreams] = React.useState<
    IDolbyMediaStream[]
  >([]);
  const [ueScreenMediaStreams, setUEScreenMediaStreams] = React.useState<
    IDolbyMediaStream[]
  >([]);
  const [ueWebcamMediaStreams, setUEWebcamMediaStreams] = React.useState<
    IDolbyMediaStream[]
  >([]);

  const shareWebcamToUEMediaStream = React.useRef<IMediaStream | null>(null);
  const startShareDolbyCameraToUE = React.useRef<boolean>(false);

  const [ueWebcamScreenName, setUEWebcamScreenName] = useState<string | null>(
    null,
  );

  const [ueScreenScreenName, setUEScreenScreenName] = useState<string | null>(
    null,
  );

  const [cameraMediaStreams, setCameraMediaStreams] = useState<IMediaStream[]>(
    [],
  );
  const [screenMediaStreams, setScreenMediaStreams] = useState<IMediaStream[]>(
    [],
  );

  const [isConferenceListener, setIsConferenceListener] =
    React.useState<boolean>(false);

  const [lastParticipantPosition, setLastParticipantPosition] =
    React.useState<ParticipantPosition | null>(null);

  const [currentConferenceSpatialType, setCurrentConferenceSpatialType] =
    React.useState<SpatialType>(SpatialType.SpatialAudio);
  const [currentConferenceSpatialScale, setCurrentConferenceSpatialScale] =
    React.useState<number | null>(80);

  const {
    setActiveSpeakerDeviceId,
    setActiveCameraDeviceId,
    setActiveMicroDeviceId,
    activeCameraDeviceId,
    activeMicroDeviceId,
  } = useConferenceState();
  const {
    setEventLoaded,
    currentRoomDolbySpatialAudioScale,
    muted,
    dolbyToken,
    setSpeakingParticipants: onChangeSpeakingParticipants,
    currentRoom,
    currentRegion,
    currentEvent,
    participants,
    currentParticipant,
    currentParticipantId,
    setCurrentParticipant,
    participantsSound,
    setSharingWindowFullscreen,
    sharingWindowFullsceen,
    setIsMinimizedScreenSharing,
    canSpeak,
  } = useMytaverseEvent();
  const { setExpandedScreenSharing } = useZoom();
  const { sendMessageToEvent, user } = useMytaverse();
  const { showNotification, getDolbyNotification } = useNotification();
  const { setHasSharedScreen } = useNotificationContext();
  const { t: translate } = useTranslation('common');

  useEffect(() => {
    try {
      const isAudioScaleChanged =
        !isConferenceListener &&
        isConferenceInitialized &&
        currentRoomDolbySpatialAudioScale &&
        currentConferenceSpatialType === SpatialType.SpatialAudio;
      if (isAudioScaleChanged) {
        dolbyLogger.info(
          `Admin changed dolby spatial audio scale to ${currentRoomDolbySpatialAudioScale}`,
        );
        setCurrentConferenceSpatialScale(currentRoomDolbySpatialAudioScale);
        DolbyService.setSpatialEnvironment(currentRoomDolbySpatialAudioScale);
      }
    } catch (error: unknown) {
      if (canSpeak) {
        //showNotification(getDolbyNotification());
      }
      throw Error(getCatchErrorMessage(error));
    }
  }, [
    isConferenceInitialized,
    currentRoomDolbySpatialAudioScale,
    currentConferenceSpatialType,
    isConferenceListener,
    canSpeak,
  ]);

  useEffect(() => {
    try {
      DolbyService.toggleAudio(muted);
    } catch (error: unknown) {
      if (canSpeak) {
        //showNotification(getDolbyNotification());
      }
      throw Error(getCatchErrorMessage(error));
    }
  }, [muted, canSpeak]);

  // React.useEffect(() => {
  //   if (dolbyToken && dolbyToken.length !== 0) {
  //     try {
  //       DolbyService.initializeDolbySDK(dolbyToken);
  //       setIsDolbyInitialized(true);
  //     } catch (error: unknown) {
  //       if (canSpeak) {
  //         //showNotification(getDolbyNotification());
  //       }
  //       throw Error(getCatchErrorMessage(error));
  //     }
  //   }
  // }, [dolbyToken, canSpeak]);

  const setParticipantMediaStream = React.useCallback(
    async (
      participantId: string,
      eventName: string,
      mediaStream: MediaStreamWithType,
    ) => {
      if (!currentParticipant || !mediaStream) {
        return;
      }

      if (
        startShareDolbyCameraToUE.current &&
        mediaStream &&
        mediaStream.type === 'Camera' &&
        participantId === currentParticipantId
      ) {
        shareWebcamToUEMediaStream.current = {
          stream: mediaStream as MediaStream,
          type: mediaStream.type,
          participantId,
        } as IMediaStream;
      }

      if (currentParticipant.participantId === participantId) {
        setCurrentParticipant({ ...currentParticipant, mediaStream });
      }

      if (
        mediaStream.type === 'Camera' &&
        mediaStream.getVideoTracks().length === 0
      ) {
        setCameraMediaStreams((prev) =>
          prev.filter((s) => s.participantId !== participantId),
        );
      }

      if (
        mediaStream.type === 'Camera' &&
        mediaStream.active &&
        mediaStream.getVideoTracks().length
      ) {
        setCameraMediaStreams((prev) => {
          return [
            ...prev.filter((p) => p.participantId !== participantId),
            {
              stream: mediaStream as MediaStream,
              type: mediaStream.type,
              participantId,
            } as IMediaStream,
          ];
        });
      }

      if (mediaStream.type === 'ScreenShare') {
        if (eventName === 'streamRemoved') {
          setScreenMediaStreams((prev) =>
            prev.filter((ms) => {
              return ms.stream.id !== mediaStream.id;
            }),
          );
          setHasSharedScreen(false);
          setIsMinimizedScreenSharing(false);
          if (sharingWindowFullsceen) {
            setSharingWindowFullscreen();
          }
        } else if (mediaStream.active && mediaStream.getVideoTracks().length) {
          setScreenMediaStreams((prev) => {
            return [
              ...prev,
              {
                stream: mediaStream as MediaStream,
                type: mediaStream.type,
                participantId,
              } as IMediaStream,
            ];
          });
        }
      }
    },
    [
      currentParticipant,
      sharingWindowFullsceen,
      ueWebcamScreenName,
      currentEvent,
      currentRoom,
      currentRegion,
      conference,
    ],
  );

  const onStreamAdded = React.useCallback(
    (dolbyParticipant: DolbyParticipant, mediaStream: MediaStreamWithType) => {
      if (mediaStream && dolbyParticipant.info.externalId) {
        setParticipantMediaStream(
          dolbyParticipant.info.externalId,
          'streamAdded',
          mediaStream,
        );
      }
    },
    [setParticipantMediaStream],
  );

  const onStreamUpdated = React.useCallback(
    (dolbyParticipant: DolbyParticipant, mediaStream: MediaStreamWithType) => {
      if (mediaStream && dolbyParticipant.info.externalId) {
        setParticipantMediaStream(
          dolbyParticipant.info.externalId,
          'streamUpdated',
          mediaStream,
        );
      }
    },
    [setParticipantMediaStream],
  );

  const onStreamRemoved = React.useCallback(
    (dolbyParticipant: DolbyParticipant, mediaStream: MediaStreamWithType) => {
      if (!currentParticipant) {
        return;
      }

      if (mediaStream && dolbyParticipant.info.externalId && currentEvent) {
        setParticipantMediaStream(
          dolbyParticipant.info.externalId,
          'streamRemoved',
          mediaStream,
        );

        const streamOwner = screenMediaStreams.some(
          (s) => s.participantId === dolbyParticipant.info.externalId,
        );

        //TODO move out this logic check why does Voxeet stream messages listener not working
        if (streamOwner) {
          sendMessageToEvent(currentEvent.eventId, {
            action: 'STOP_DOLBY_SCREEN_SHARING',
            region: currentRegion ? currentRegion.region : '',
            mediaStreamId: mediaStream.id,
            participantId: currentParticipant.participantId,
          });
        }
      }
    },
    [
      setParticipantMediaStream,
      screenMediaStreams,
      currentRegion,
      currentParticipant,
    ],
  );

  useAsyncEffect(async () => {
    if (
      currentParticipant &&
      shareWebcamToUEMediaStream.current &&
      !ueDolbyMediaStreams.find(
        (ms) =>
          ms.mediaStreamId === shareWebcamToUEMediaStream.current?.stream.id,
      ) &&
      ueWebcamScreenName &&
      currentEvent &&
      currentRoom &&
      currentParticipant.dolbyParticipantId &&
      conference &&
      conference.id &&
      shareWebcamToUEMediaStream.current?.stream.getVideoTracks().length !== 0
    ) {
      const mediaStream = shareWebcamToUEMediaStream.current?.stream;

      await EventsService.createDolbyMediaStream({
        mediaStreamId: mediaStream.id,
        videoTrackId: mediaStream.getVideoTracks()[0].id,
        ueScreenName: ueWebcamScreenName,
        owner: {
          id: currentParticipant?.participantId,
          name: currentParticipant?.name,
        },
        source:
          shareWebcamToUEMediaStream.current.type === 'Camera'
            ? 'CAMERA'
            : 'SCREEN',
        muted: true,
        eventId: currentEvent.eventId,
        roomId: currentRoom.roomId,
        participantId: currentParticipant.participantId,
        region: currentRegion ? currentRegion.region : '',
        dolbyParticipantId: currentParticipant.dolbyParticipantId,
        conferenceId: conference.id,
      });

      shareWebcamToUEMediaStream.current = null;
      startShareDolbyCameraToUE.current = false;
    }

    if (ueDolbyMediaStreams.length !== 0) {
      await Promise.allSettled(
        ueDolbyMediaStreams
          .filter(
            (dolbyMediaStream) =>
              dolbyMediaStream.participantId === currentParticipantId,
          )
          .map(async (dolbyMediaStream) => {
            const cameraMediaStream = cameraMediaStreams.find(
              (ms) => ms.stream.id === dolbyMediaStream.mediaStreamId,
            );
            const screeenMediaStream = screenMediaStreams.find(
              (ms) => ms.stream.id === dolbyMediaStream.mediaStreamId,
            );

            if (!cameraMediaStream && !screeenMediaStream) {
              console.log(
                `Clean inactive dolby stream ${dolbyMediaStream.mediaStreamId}`,
              );
              await EventsService.deleteDolbyMediaStream({
                mediaStreamId: dolbyMediaStream.mediaStreamId,
              });
            }
          }),
      );
    }
  }, [
    currentParticipant,
    ueWebcamScreenName,
    currentEvent,
    currentRoom,
    currentRegion,
    shareWebcamToUEMediaStream.current,
    ueDolbyMediaStreams,
    cameraMediaStreams,
    screenMediaStreams,
  ]);

  useEffect(() => {
    const audio: HTMLAudioElement | null = document.querySelector('.vxt-audio');

    if (audio && isConferenceInitialized) {
      audio.volume = participantsSound;
    }
  }, [isConferenceInitialized, participantsSound]);

  const onChangeAddUpdateParticipant = React.useCallback(() => {}, [
    participants,
  ]);

  const setSpeakingParticipantsIdsHandler = React.useCallback(
    (ids: string[]) => {
      setSpeakingParticipantsIds(ids);
    },
    [setSpeakingParticipantsIds],
  );

  React.useEffect(() => {
    if (!onChangeSpeakingParticipants || !conference) {
      return;
    }

    let speakingParticipantIds: string[] = [];

    const interval = setInterval(async () => {
      const participants: any[] = await Promise.all(
        [
          ...(VoxeetSDK.conference.current?.status === 'joined'
            ? VoxeetSDK.conference.participants.values()
            : []),
        ]
          .filter((p) => p.info.externalId)
          .map(
            (p) =>
              new Promise((resolve) => {
                VoxeetSDK.conference.current?.status === 'joined'
                  ? VoxeetSDK.conference.isSpeaking(
                      p,
                      (isSpeaking: boolean) => {
                        resolve({
                          participantId: p.info.externalId,
                          isSpeaking,
                        });
                      },
                    )
                  : false;
              }),
          ),
      );

      const newSpeakingParticipantIds: string[] = participants
        .filter(({ isSpeaking }) => isSpeaking)
        .map(({ participantId }) => participantId);

      if (
        speakingParticipantIds.length !== newSpeakingParticipantIds.length ||
        !newSpeakingParticipantIds.every(
          (newId: string) => speakingParticipantIds.indexOf(newId) !== -1,
        )
      ) {
        setSpeakingParticipantsIdsHandler(newSpeakingParticipantIds);
      }

      speakingParticipantIds = newSpeakingParticipantIds;
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [conference, onChangeSpeakingParticipants]);

  const openSession = React.useCallback(
    async (participantInfo: ParticipantInfo) => {
      try {
        await DolbyService.openSession(participantInfo);
        dolbyLogger.log(
          `Participant session opened (participantId: ${participantInfo.externalId} name: ${participantInfo.name})`,
        );

        const dolbySessionParticipant = DolbyService.getSessionParticipant();

        if (dolbySessionParticipant && dolbySessionParticipant.id) {
          setCurrentParticipant((prev) => {
            if (!prev) {
              return prev;
            }
            return {
              ...prev,
              dolbyParticipantId: dolbySessionParticipant.id,
            };
          });
        }
      } catch (error: unknown) {
        if (canSpeak) {
          //showNotification(getDolbyNotification());
        }
        throw Error(getCatchErrorMessage(error));
      }
    },
    [canSpeak],
  );

  useEffect(() => {
    if (isConferenceInitialized) {
      setEventLoaded(true);
    }
  }, [isConferenceInitialized]);

  const leaveConference = React.useCallback(async () => {
    try {
      if (conference) {
        setConferenceInitialized(false);
        await DolbyService.leaveConference();

        dolbyLogger.log(
          `Left Dolby Conference: ${conference.alias} ${new Date().getTime()}`,
        );
        setConference(null);
      }
    } catch (error: unknown) {
      if (canSpeak) {
        //showNotification(getDolbyNotification());
      }
      throw Error(getCatchErrorMessage(error));
    }
  }, [conference, canSpeak]);

  const createAndJoinToConference = React.useCallback(
    async (
      currentEvent: IEvent,
      currentRoom: IRoom,
      currentRegion: IParticipantRegion | null,
    ) => {
      if (!isDolbyInitialized || joiningToConference) {
        dolbyLogger.log('Saving next conference to queue');
        setConferencesQueue((prev) => [
          ...prev,
          { currentEvent, currentRoom, currentRegion },
        ]);

        return;
      }

      let isJoinedToConferenceAsListener = false;

      try {
        setJoiningToConference(true);
        setConferenceInitialized(false);

        const gameSessionId = getGameSessionId(currentRoom.gameSessionId || '');

        if (
          DolbyService.getConferenceId() !== null &&
          screenMediaStreams.find(
            (s) => s.participantId === currentParticipantId,
          )
        ) {
          if (canSpeak) {
            /*showNotification(
              getDolbyNotification({
                title: translate('notifications.conferenceNotification'),
                message: translate(
                  'notifications.dolby.stoppedSharingScreenOnChangeConference',
                ),
                type: NOTIFICATION_TYPES.SUCCESS,
              }),
            );*/
          }

          dolbyLogger.log('Stop sharing screen');
          await DolbyService.stopShareScreen();
        }

        setCameraMediaStreams([]);
        setScreenMediaStreams([]);

        const conferenceAlias = getConferenceAlias({
          currentRegion,
          currentEvent,
          currentRoom,
          gameSessionId,
        });
        const newConference = await DolbyService.createConference(
          conferenceAlias,
          currentRegion?.regionSpatialType !== SpatialType.OneToMany,
        );
        const spatialType = getSpatialType(currentRegion);
        const { audioConfig, conferenceScale } = getConferenceConfig({
          spatialType,
          dolbySpatialAudioScale: currentRoom?.dolbySpatialAudioScale,
        });

        setSpatialTypeName(spatialType);

        try {
          if (
            !canSpeak
            // || (spatialType == SpatialType.OneToMany &&
            //   !currentParticipant?.isSpeaker)
          ) {
            await DolbyService.joinToConferenceAsListener(
              newConference,
              audioConfig,
            );
            isJoinedToConferenceAsListener = true;

            // showNotification(
            //   getDolbyNotification({
            //     title: translate('notifications.conferenceNotification'),
            //     message: translate('notifications.speakersConferenceOnly'),
            //     type: NOTIFICATION_TYPES.SUCCESS,
            //     closeManually: true,
            //   }),
            // );
          } else {
            await DolbyService.joinToConference(
              newConference,
              muted,
              audioConfig,
            );
          }
        } catch (error: unknown) {
          if (!(error instanceof Error)) {
            if (canSpeak) {
              //showNotification(getDolbyNotification());
            }
            throw Error(getCatchErrorMessage(error));
          }

          if (error.name === ConferenceError.MaxCapacityError) {
            try {
              await DolbyService.joinToConferenceAsListener(
                newConference,
                audioConfig,
              );
              isJoinedToConferenceAsListener = true;
              if (canSpeak) {
                /*showNotification(
                  getDolbyNotification({
                    title: translate('notifications.conferenceWarning'),
                    message: translate(
                      'notifications.conferenceAtMaxCapacityError',
                    ),
                    type: NOTIFICATION_TYPES.WARNING,
                    closeManually: true,
                  }),
                );*/
              }
            } catch (error: unknown) {
              if (canSpeak) {
                //showNotification(getDolbyNotification());
              }
              throw Error(getCatchErrorMessage(error));
            }
          } else if (error.message === 'Already joining a conference') {
            dolbyLogger.log('Saving next conference to queue');
            setConferencesQueue((prev) => [
              ...prev,
              { currentEvent, currentRoom, currentRegion },
            ]);
          } else if (error.name === ConferenceError.NotAllowedError) {
            await DolbyService.joinToConferenceAsListener(
              newConference,
              audioConfig,
            );
            isJoinedToConferenceAsListener = true;
            if (canSpeak) {
              /*showNotification(
                getDolbyNotification({
                  title: translate('notifications.conferenceWarning'),
                  message: translate('notifications.noMicPermissions'),
                  type: NOTIFICATION_TYPES.WARNING,
                  closeManually: true,
                }),
              );*/
            }
          } else {
            if (canSpeak) {
              // showNotification(getDolbyNotification());
            }
            throw Error(getCatchErrorMessage(error));
          }
        }

        setIsConferenceListener(isJoinedToConferenceAsListener);

        if (!isJoinedToConferenceAsListener) {
          if (conferenceScale) {
            DolbyService.setSpatialEnvironment(conferenceScale);
          }

          setCurrentConferenceSpatialType(spatialType);
          setCurrentConferenceSpatialScale(conferenceScale);
        }

        setConference(newConference);
        setConferenceInitialized(true);

        if (!isJoinedToConferenceAsListener && videoStarted) {
          await DolbyService.toggleVideo(true);
        }

        dolbyLogger.log(
          `Joined to conference ${newConference.alias} ${
            isJoinedToConferenceAsListener ? 'as listener' : ''
          } (spatialType: ${spatialType}, conferenceScale: ${conferenceScale})`,
        );
      } catch (error: unknown) {
        if (error instanceof Error && error.name.includes('NotAllowedError')) {
          if (canSpeak) {
            /*showNotification(
              getDolbyNotification({
                message: `${error.message}, ${translate(
                  'notifications.checkBrowserSettings',
                )}`,
                closeManually: true,
              }),
            );*/
          }
        }

        if (canSpeak) {
          //showNotification(getDolbyNotification());
        }
        throw Error(getCatchErrorMessage(error));
      } finally {
        setJoiningToConference(false);
      }
    },
    [
      isDolbyInitialized,
      joiningToConference,
      openSession,
      currentRoom,
      muted,
      videoStarted,
      currentParticipant,
      currentParticipantId,
      screenMediaStreams,
      user,
      canSpeak,
    ],
  );

  useAsyncEffect(async () => {
    if (currentEvent && currentRoom) {
      const gameSessionId = getGameSessionId(currentRoom.gameSessionId || '');

      const conferenceAlias = getConferenceAlias({
        currentRegion,
        currentEvent,
        currentRoom,
        gameSessionId,
      });

      if (!conference || conferenceAlias !== conference?.alias) {
        setConferencesQueue((prev) => [
          ...prev,
          { currentEvent, currentRoom, currentRegion },
        ]);
      }
    } else if (!currentRoom && conference) {
      await leaveConference();
    }
  }, [currentEvent, currentRoom, currentRegion, conference, leaveConference]);

  // useAsyncEffect(async () => {
  //   if (!joiningToConference && conferencesQueue.length !== 0) {
  //     const lastConference = conferencesQueue[conferencesQueue.length - 1];
  //
  //     setConferencesQueue([]);
  //
  //     await createAndJoinToConference(
  //       lastConference.currentEvent,
  //       lastConference.currentRoom,
  //       lastConference.currentRegion,
  //     );
  //   }
  // }, [joiningToConference, conferencesQueue]);

  useAsyncEffect(async () => {
    const initDevices = async () => {
      const audioOutput = await VoxeetSDK.mediaDevice.enumerateAudioDevices(
        'output',
      );
      const audioInput = await VoxeetSDK.mediaDevice.enumerateAudioDevices(
        'input',
      );
      const videoInput = await VoxeetSDK.mediaDevice.enumerateVideoDevices(
        'input',
      );

      setMicrophones(audioInput);
      setSpeakers(audioOutput);
      setCameras(videoInput);
    };

    initDevices();

    navigator.mediaDevices?.addEventListener('devicechange', initDevices);

    return () => {
      navigator.mediaDevices?.removeEventListener('devicechange', initDevices);
    };
  }, []);

  const reconnectToConference = React.useCallback(async () => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (currentEvent && currentRoom) {
      dolbyLogger.log(
        `Reconnecting to dolby conference. Current participant status: ${VoxeetSDK.session?.participant?.status}`,
      );

      dolbyLogger.log(
        JSON.stringify({ currentEvent, currentRoom, currentRegion }),
      );

      timeoutId = setTimeout(() => {
        setConferencesQueue((prev) => [
          ...prev,
          { currentEvent, currentRoom, currentRegion },
        ]);
        timeoutId = null;
      }, 1000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [currentEvent, currentRoom, currentRegion]);

  const onErrorConference = React.useCallback(
    async (error: any) => {
      if (error?.name === ConferenceError.NotAllowedError) {
        return;
      }

      // TODO show notification
      dolbyLogger.error(`Conference error: ${JSON.stringify(error)}`);

      await reconnectToConference();
    },
    [reconnectToConference],
  );

  const onJoinedToConference = React.useCallback(async () => {}, []);

  const onLeftConference = React.useCallback(async () => {}, []);

  React.useEffect(() => {
    VoxeetSDK.conference.on('error', onErrorConference);

    return () => {
      VoxeetSDK.conference.removeListener('error', onErrorConference);
    };
  }, [onErrorConference]);

  React.useEffect(() => {
    VoxeetSDK.conference.on('joined', onJoinedToConference);

    return () => {
      VoxeetSDK.conference.removeListener('joined', onJoinedToConference);
    };
  }, [onJoinedToConference]);

  React.useEffect(() => {
    VoxeetSDK.conference.on('left', onLeftConference);

    return () => {
      VoxeetSDK.conference.removeListener('left', onLeftConference);
    };
  }, [onLeftConference]);

  React.useEffect(() => {
    const intervalId = setInterval(async () => {
      if (
        ['Left', 'Warning', 'Error', 'Kicked'].includes(
          VoxeetSDK.session?.participant?.status,
        )
      ) {
        await reconnectToConference();
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [reconnectToConference]);

  React.useEffect(() => {
    VoxeetSDK.conference.on('streamAdded', onStreamAdded);

    return () => {
      VoxeetSDK.conference.removeListener('streamAdded', onStreamAdded);
    };
  }, [onStreamAdded]);

  React.useEffect(() => {
    VoxeetSDK.conference.on('streamUpdated', onStreamUpdated);

    return () => {
      VoxeetSDK.conference.removeListener('streamUpdated', onStreamUpdated);
    };
  }, [onStreamUpdated]);

  React.useEffect(() => {
    VoxeetSDK.conference.on('participantAdded', onChangeAddUpdateParticipant);
    VoxeetSDK.conference.on('participantUpdated', onChangeAddUpdateParticipant);

    return () => {
      VoxeetSDK.conference.removeListener(
        'participantAdded',
        onChangeAddUpdateParticipant,
      );
      VoxeetSDK.conference.removeListener(
        'participantUpdated',
        onChangeAddUpdateParticipant,
      );
    };
  }, [onChangeAddUpdateParticipant]);

  React.useEffect(() => {
    VoxeetSDK.conference.on('streamRemoved', onStreamRemoved);

    return () => {
      VoxeetSDK.conference.removeListener('streamRemoved', onStreamRemoved);
    };
  }, [onStreamRemoved]);

  const setParticipantPosition = React.useCallback(
    (participantPosition: ParticipantPosition) => {
      setLastParticipantPosition((prev) => {
        if (!prev) {
          return participantPosition;
        }

        if (
          prev.timestamp !== 0 &&
          participantPosition.timestamp < prev.timestamp
        ) {
          return prev;
        }

        if (
          prev.x !== participantPosition.x ||
          prev.y !== participantPosition.y ||
          prev.z !== participantPosition.z ||
          prev.r !== participantPosition.r
        ) {
          return participantPosition;
        }

        return prev;
      });
    },
    [],
  );

  useEffect(() => {
    if (lastParticipantPosition && !isConferenceListener) {
      try {
        DolbyService.setParticipantPosition(lastParticipantPosition);
      } catch (error: unknown) {
        if (canSpeak) {
          //showNotification(getDolbyNotification());
        }
        throw Error(getCatchErrorMessage(error));
      }
    }
  }, [
    lastParticipantPosition,
    isConferenceInitialized,
    isConferenceListener,
    currentConferenceSpatialScale,
    canSpeak,
  ]);

  useAsyncEffect(async () => {
    const permissionData = await NavigatorPermissionsService.checkPermission(
      'microphone',
    );

    if (!permissionData) {
      return;
    }

    navigator.permissions
      .query({
        name: 'microphone' as any,
      })
      .then((permissionStatus) => {
        permissionStatus.onchange = (async (action: any) => {
          if (action.currentTarget.state === 'granted') {
            const audioOutput =
              await VoxeetSDK.mediaDevice.enumerateAudioDevices('output');
            const audioInput =
              await VoxeetSDK.mediaDevice.enumerateAudioDevices('input');

            await setMicrophones(audioInput);
            await setSpeakers(audioOutput);
            if (currentEvent && currentRoom) {
              setConferencesQueue((prev) => [
                ...prev,
                { currentEvent, currentRoom, currentRegion },
              ]);
            }
          }
        }) as any;
      });
    navigator.permissions
      .query({
        name: 'camera' as any,
      })
      .then(async (permissionStatus) => {
        permissionStatus.onchange = async (action: any) => {
          if (action.currentTarget.state === 'granted') {
            const videoInput =
              await VoxeetSDK.mediaDevice.enumerateVideoDevices('input');

            await setCameras(videoInput);

            const nextSelectedCamera = getNextSelectedCamera(videoInput);

            setActiveCameraDeviceId(nextSelectedCamera);
          }
        };
      });
  }, [currentEvent, currentRoom, currentRegion, currentParticipant]);

  const startShareDolbyCamera = useCallback(async () => {
    try {
      const currentParticipantCameraMediaStream = cameraMediaStreams.find(
        (ms) => ms.participantId === currentParticipantId,
      );

      if (!currentParticipantCameraMediaStream) {
        startShareDolbyCameraToUE.current = true;
        await DolbyService.startCamera(activeCameraDeviceId);
        setVideoStarted(true);
      } else {
        shareWebcamToUEMediaStream.current =
          currentParticipantCameraMediaStream;
      }
    } catch (error: unknown) {
      if (error instanceof Error && error.name.includes('NotAllowedError')) {
        if (canSpeak) {
          /*showNotification(
            getDolbyNotification({
              message: `${error.message}, ${translate(
                'notifications.checkBrowserSettings',
              )}`,
              closeManually: true,
            }),
          );*/
        }
      }

      if (canSpeak) {
        //showNotification(getDolbyNotification());
      }
      throw Error(getCatchErrorMessage(error));
    }
  }, [
    videoStarted,
    setVideoStarted,
    showNotification,
    getDolbyNotification,
    activeCameraDeviceId,
    cameraMediaStreams,
    currentParticipantId,
    canSpeak,
  ]);

  const stopShareDolbyCamera = useCallback(async () => {
    const cameraMediaStream = cameraMediaStreams.find(
      (cameraMediaStream) =>
        cameraMediaStream.participantId === currentParticipantId,
    );

    if (cameraMediaStream) {
      await EventsService.deleteDolbyMediaStream({
        mediaStreamId: cameraMediaStream.stream.id,
      });

      shareWebcamToUEMediaStream.current = null;
      startShareDolbyCameraToUE.current = false;
    }
  }, [cameraMediaStreams, currentParticipantId]);

  const handleToggleVideo = useCallback(async () => {
    try {
      await DolbyService.toggleVideo(!videoStarted, activeCameraDeviceId);
      setVideoStarted(!videoStarted);
    } catch (error: unknown) {
      if (error instanceof Error && error.name.includes('NotAllowedError')) {
        if (canSpeak) {
          /*showNotification(
            getDolbyNotification({
              message: `${error.message}, ${translate(
                'notifications.checkBrowserSettings',
              )}`,
              closeManually: true,
            }),
          );*/
        }
      }

      if (canSpeak) {
        //showNotification(getDolbyNotification());
      }
      throw Error(getCatchErrorMessage(error));
    }
  }, [
    videoStarted,
    setVideoStarted,
    showNotification,
    getDolbyNotification,
    activeCameraDeviceId,
    canSpeak,
  ]);

  const setStreamQualityHandle = useCallback(
    (quality: any) => {
      setStreamQuality(quality);
    },
    [setStreamQuality],
  );

  const setFullscreenHandle = useCallback(() => {
    setIsFullscreen(!isFullscreen);
  }, [setIsFullscreen, isFullscreen]);

  const selectMicrophoneHandle = useCallback(
    async (microphoneId: string): Promise<void> => {
      if (!microphoneId) {
        return;
      }

      try {
        setChangingDevice(true);
        // await DolbyService.selectMicrophone(microphoneId);
        setActiveMicroDeviceId(microphoneId);
        setChangingDevice(false);
      } catch (error: unknown) {
        setChangingDevice(false);
        if (canSpeak) {
          //showNotification(getDolbyNotification());
        }
        throw Error(getCatchErrorMessage(error));
      }
    },
    [
      setChangingDevice,
      setActiveMicroDeviceId,
      showNotification,
      getDolbyNotification,
      canSpeak,
    ],
  );

  const selectSpeakerHandle = useCallback(
    async (speakerId: string): Promise<void> => {
      if (!speakerId) {
        return;
      }

      try {
        setChangingDevice(true);
        // await DolbyService.selectSpeaker(speakerId);
        setActiveSpeakerDeviceId(speakerId);
        setChangingDevice(false);
      } catch (error: unknown) {
        setChangingDevice(false);
        if (canSpeak) {
          //showNotification(getDolbyNotification());
        }
        throw Error(getCatchErrorMessage(error));
      }
    },
    [setChangingDevice, showNotification, getDolbyNotification, canSpeak],
  );

  const selectCameraHandle = useCallback(
    async (cameraId: string): Promise<void> => {
      if (!cameraId) {
        return;
      }

      try {
        setChangingDevice(true);
        // await DolbyService.selectCamera(cameraId);
        setActiveCameraDeviceId(cameraId);
        setChangingDevice(false);
      } catch (error: unknown) {
        setChangingDevice(false);
        if (canSpeak) {
          //showNotification(getDolbyNotification());
        }
        throw Error(getCatchErrorMessage(error));
      }
    },
    [showNotification, getDolbyNotification, canSpeak],
  );

  const handleDolbyWSMessages = React.useCallback(
    async (lastJsonMessage: ILastWebsocketJsonMessage) => {
      const { action, zoomScreenStreams, zoomWebcamStreams } = lastJsonMessage;

      if (!action) {
        return;
      }

      switch (action) {
        case ParticipantState:
          if (zoomScreenStreams) {
            setUEScreenMediaStreams((prev) => {
              if (prev.length !== zoomScreenStreams.length) {
                return zoomScreenStreams;
              }

              const zoomMediaStreamIds = zoomScreenStreams.map(
                (dms) => dms.mediaStreamId,
              );

              if (
                !prev.every((dms) =>
                  zoomMediaStreamIds.includes(dms.mediaStreamId),
                )
              ) {
                return zoomScreenStreams;
              }

              return prev;
            });
          }
          if (zoomWebcamStreams) {
            setUEWebcamMediaStreams((prev) => {
              if (prev.length !== zoomWebcamStreams.length) {
                return zoomWebcamStreams;
              }

              const zoomMediaStreamIds = zoomWebcamStreams.map(
                (dms) => dms.mediaStreamId,
              );

              if (
                !prev.every((dms) =>
                  zoomMediaStreamIds.includes(dms.mediaStreamId),
                )
              ) {
                return zoomWebcamStreams;
              }

              return prev;
            });
          }
          break;
        default:
          break;
      }
    },
    [],
  );

  return (
    <ConferenceContext.Provider
      value={{
        isDolbyInitialized,
        isConferenceInitialized,

        conference,
        isFirstPersonView,
        sharingMedia,
        previewingParticipant,
        streamQuality,
        changingDevice,
        isFullscreen,
        muted,
        videoStarted,
        microphones,
        speakers,
        cameras,
        speakingParticipantIds,
        disabledPersonView,
        spatialTypeName,
        setScreenMediaStreams,
        setDisabledPersonView,
        setSharingMedia,
        setPreviewingParticipant,
        setIsFirstPersonView,
        setStreamQuality: setStreamQualityHandle,
        openSession,
        leaveConference,
        createAndJoinToConference,
        setFullscreen: setFullscreenHandle,
        toggleVideo: handleToggleVideo,
        selectMicrophone: selectMicrophoneHandle,
        selectSpeaker: selectSpeakerHandle,
        selectCamera: selectCameraHandle,
        setParticipantPosition,
        isConferenceListener,
        activeMicroDeviceId,
        cameraMediaStreams,
        screenMediaStreams,

        startShareDolbyCamera,
        stopShareDolbyCamera,
        setVideoStarted,
        startShareDolbyCameraToUE,
        ueWebcamScreenName,
        setUEWebcamScreenName,
        ueScreenScreenName,
        setUEScreenScreenName,
        ueDolbyMediaStreams,
        shareWebcamToUEMediaStream,
        handleDolbyWSMessages,
        ueWebcamMediaStreams,
        ueScreenMediaStreams,
      }}
    >
      {children}
    </ConferenceContext.Provider>
  );
};
