import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../../interfaces';

import LoadingBg from '../../../../../public/images/ek/loading-bg.png';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  //const { appTheme } = useMytaverse();

  return {
    root: {
      width: '100vw',
      minHeight: '100vh',
      position: 'relative',
      backgroundImage: `url(${LoadingBg})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',

      display: 'grid',
      gridTemplateRows: 'auto 1fr',

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        '& a': {
          display: 'none',
        },
      },

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        minHeight: '100dvh',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        minHeight: '100dvh',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        minHeight: '100dvh',
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          minHeight: '100dvh',
        },
    },

    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: 'max-content',

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        overflow: 'auto',
      },

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        marginTop: '-45px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        marginTop: '-45px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        marginTop: '-45px',
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          marginTop: '-45px',
        },

      [`@media only screen and (max-height: 340px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          marginTop: '-45px',
        },
    },

    text: {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: '38px',

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 16,
        lineHeight: '22px',
        color: '#707070',
      },

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        fontSize: 14,
        lineHeight: '16px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        fontSize: 14,
        lineHeight: '16px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        fontSize: 14,
        lineHeight: '16px',
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          fontSize: 14,
          lineHeight: '16px',
        },
    },
    title: {
      fontFamily: 'Emirates-Medium !important',
      fontWeight: 400,
      fontSize: 40,
      lineHeight: '56px',

      '& span': {
        fontFamily: 'Emirates-Bold !important',
        fontWeight: 700,
      },

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 24,
        lineHeight: '32px',
      },

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        fontSize: 22,
        lineHeight: '28px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        fontSize: 22,
        lineHeight: '28px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        fontSize: 22,
        lineHeight: '28px',
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          fontSize: 22,
          lineHeight: '28px',
        },
    },
    media: {
      width: '48%',
      maxWidth: '1040px',
      marginTop: '24px',
      marginBottom: '32px',

      '& iframe': {
        borderRadius: 2,
      },

      [breakpoints.down(ScreenSizes.PcLarge)]: {
        width: '54%',
      },

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        maxWidth: 'unset !important',
        marginTop: '19px',
        marginBottom: '25px',
      },

      '@media (max-width: 460px)': {
        width: '80%',
      },

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        maxWidth: 'calc((100dvh - 400px)* 16 / 9)',
        width: '45%',
        marginY: 2,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        maxWidth: 'calc((100dvh - 400px)* 16 / 9)',
        width: '45%',
        marginY: 2,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        maxWidth: '300px',
        width: '35%',
        marginY: 2,
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          maxWidth: 'calc((100dvh - 400px)* 16 / 9)',
          width: '45%',
          marginY: 2,
        },
    },
    progress: {
      maxWidth: '390px',
    },
  };
};
