import { useTheme } from '@mui/material';

import { useMytaverse } from '../../../../providers/MytaverseProvider';
import { ScreenSizes } from '../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();

  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '32px 40px',
      [breakpoints.down(ScreenSizes.Pc)]: {
        padding: '29px 32px',
      },
      '@media (max-width: 460px)': {
        padding: '14px 16px 14px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        padding: '10px 20px',
        zIndex: 1,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        padding: '10px 20px',
        zIndex: 1,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        padding: '8px 20px',
        zIndex: 1,
      },
      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          padding: '10px 20px',
          zIndex: 1,
        },
    },
    box: {
      flex: 1,
      display: 'flex',
    },
    logo: {
      justifyContent: 'center',
    },
    eventsDropdown: {
      [breakpoints.down(ScreenSizes.Tablet)]: {
        display: 'none',
      },
      '& .MuiInputBase-root': {
        maxWidth: 300,
      },
    },
    menuList: {
      '& .MuiMenuItem-root': {
        maxWidth: 300,
      },
    },
    actionsContainer: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 2,
      flex: 1,
      justifyContent: 'flex-end',
      [breakpoints.down(ScreenSizes.Tablet)]: {
        columnGap: '4px',
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        '& button:first-of-type': {
          display: 'none',
        },
        '& button': {
          paddingRight: '20px',
          '& svg path': {
            stroke: appTheme.mainColor,
          },
        },
      },
      '& svg': {
        color: appTheme.languageButton.assets.iconColor,
      },
    },
  };
};
