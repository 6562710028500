import { Box } from '@mui/material';
import { isDesktop } from 'react-device-detect';
import { CSSProperties } from 'react';
import { useZoom } from '../../ZoomProvider';
import { useConference } from '../../../Dolby';

const ZoomSharingComponents = ({
  style = {
    width: '1px',
    height: '1px',
    display: isDesktop ? 'inline' : 'none',
  },
}: {
  style?: CSSProperties;
}) => {
  const { zoomUser } = useZoom();

  const { ueScreenMediaStreams } = useConference();

  return (
    <Box>
      <video
        id="screen-share-content-video"
        style={
          ueScreenMediaStreams[0] &&
          (ueScreenMediaStreams[0] as any).zoomUserId &&
          zoomUser?.userId !== (ueScreenMediaStreams[0] as any).zoomUserId
            ? {
                width: '1px',
                height: '1px',
                display: isDesktop ? 'inline' : 'none',
              }
            : style
        }
      ></video>
      <canvas
        id="share-view-canvas"
        style={
          ueScreenMediaStreams[0] &&
          (ueScreenMediaStreams[0] as any).zoomUserId &&
          zoomUser?.userId !== (ueScreenMediaStreams[0] as any).zoomUserId
            ? style
            : {
                width: '1px',
                height: '1px',
                display: isDesktop ? 'inline' : 'none',
              }
        }
      />
      <canvas
        id="screen-share-content-canvas"
        style={
          ueScreenMediaStreams[0] &&
          (ueScreenMediaStreams[0] as any).zoomUserId &&
          zoomUser?.userId !== (ueScreenMediaStreams[0] as any).zoomUserId
            ? {
                width: '1px',
                height: '1px',
                display: isDesktop ? 'inline' : 'none',
              }
            : style
        }
      ></canvas>
    </Box>
  );
};

export default ZoomSharingComponents;
