import React, { useCallback, useRef, useState } from 'react';

import { StreamingProviders } from '../interfaces';
import useAsyncEffect from 'use-async-effect';
import EventsService from '../../../../../services/EventsService';
import {
  IEvent,
  IGetStreamingProviderResponse,
  IPixelStreamingClient,
} from '../../../../../interfaces/event';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';

interface IPixelStreamingProviderProps {
  currentEvent?: IEvent;
}

export const usePixelStreamingProvider = ({
  currentEvent,
}: IPixelStreamingProviderProps) => {
  const { navigateToEventsPage } = useMytaverse();

  const [streamingProvider, setStreamingProvider] =
    React.useState<StreamingProviders | null>(null);

  const [pixelStreamingClient, setPixelStreamingClient] =
    useState<IPixelStreamingClient | null>(null);

  const totalRetriesToLoadPixelStreamingProvider = useRef(0);
  // increasing capacity for stream group can take up to 10 minutes
  const maxRetryTimeInMinutes = 10;
  const retryStartTime = useRef<number | null>(null);

  const loadPixelStreamingProvider =
    useCallback(async (): Promise<IGetStreamingProviderResponse | null> => {
      if (!currentEvent) {
        return null;
      }

      const loadPixelStreamingProvider = async (resolve: any) => {
        if (!retryStartTime.current) {
          retryStartTime.current = Date.now();
        }

        totalRetriesToLoadPixelStreamingProvider.current += 1;
        const pixelStreamingProviderResponse =
          await EventsService.getEventStreamingProvider(currentEvent.eventId);

        if (pixelStreamingProviderResponse.pixelStreamingClient) {
          return resolve(pixelStreamingProviderResponse);
        }

        const elapsedTimeInMinutes =
          (Date.now() - retryStartTime.current) / 60000;
        if (elapsedTimeInMinutes > maxRetryTimeInMinutes) {
          console.log('Max retry time reached, stopping further attempts.');
          return resolve(pixelStreamingProviderResponse);
        }

        // after 5 retries, delay fixed to 10 sec
        const delay =
          totalRetriesToLoadPixelStreamingProvider.current > 5
            ? 10000
            : totalRetriesToLoadPixelStreamingProvider.current * 1000;
        console.log(
          `Retrying to get pixel streaming provider with ${delay}ms delay`,
        );

        setTimeout(() => loadPixelStreamingProvider(resolve), delay);
      };

      totalRetriesToLoadPixelStreamingProvider.current = 0;
      retryStartTime.current = null;

      return new Promise(loadPixelStreamingProvider);
    }, [currentEvent]);

  useAsyncEffect(async () => {
    if (!currentEvent) {
      return;
    }

    console.log('Loading pixel streaming provider');

    const { streamingProvider, pixelStreamingClient }: any =
      await loadPixelStreamingProvider();

    if (!streamingProvider) {
      return navigateToEventsPage(
        'notifications.noAvailableStreamingProviders',
      );
    }

    setStreamingProvider(streamingProvider);
    console.log(`Selected ${streamingProvider} pixel streaming provider`);

    if (pixelStreamingClient) {
      setPixelStreamingClient(pixelStreamingClient);

      console.log(
        `Pixel streaming client id: ${pixelStreamingClient.clientId}`,
      );
    }
  }, [currentEvent]);

  return {
    streamingProvider,
    setStreamingProvider,

    pixelStreamingClient,
    setPixelStreamingClient,
  };
};
