import React, { useCallback, useMemo } from 'react';

import EventPageView from './EventPageView';

import { useMytaverseEvent } from '../../../../../modules/dashboard/providers';
import { useChatHandlers } from '../../../../../modules/dashboard/hooks/channel';
import { useIdleTimer } from 'react-idle-timer';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import ZoomVideo from '@zoom/videosdk';
import { ZoomProvider } from '../../../../../modules/dashboard/components/DashboardContent/Zoom/ZoomProvider';
import { PocAiProvider } from '../../../../../modules/dashboard/components/PocAi';

const { REACT_APP_IDLE_TIMEOUT_MINS } = process.env;

const EventPage = () => {
  const {
    showControls,
    currentEvent,
    streamChatToken,
    openAdminSettingsModal,
    gameReadyToPlay,
    streamingProvider,
    isHideWebUI,
  } = useMytaverseEvent();
  const { logoutUser } = useMytaverse();

  const zmClient = useMemo(() => {
     return ZoomVideo.createClient()
  }, []);

  useChatHandlers({ currentEvent, streamChatToken });

  const idleTimer = useIdleTimer({
    timeout: Number(REACT_APP_IDLE_TIMEOUT_MINS || 10) * 60000, // in minutes
    onIdle: () => logoutUser(false),
    onActive: () => console.log('Idle Timer Active'),
    element: document,
  });

  return (
    <ZoomProvider zmClient={zmClient as never}>
      <PocAiProvider>
        <EventPageView
          showControls={showControls && !isHideWebUI.flag}
          openAdminSettingsModal={openAdminSettingsModal}
          gameReadyToPlay={gameReadyToPlay}
          streamingProvider={streamingProvider}
        />
      </PocAiProvider>
    </ZoomProvider>
  );
};

export default EventPage;
