import React from 'react';

import { SwiperSlide } from 'swiper/react';

import SwiperWrapper from '../SwiperWrapper';
import SelectEventItem from './SelectEventItem';

import { SLIDER_WIDTH } from './constants';

import { SwiperScreenSizes } from '../../../../../interfaces';
import { ISelectEventContent } from './interfaces';

const breakpoints = {
  [SwiperScreenSizes.Mobile]: {
    allowTouchMove: true,
  },
  [SwiperScreenSizes.Tablet]: {
    allowTouchMove: true,
  },
  [SwiperScreenSizes.Laptop]: {
    allowTouchMove: false,
  },
  [SwiperScreenSizes.Pc]: {
    allowTouchMove: false,
  },
};

const basicBreakpoints = (slides: number) => ({
  [SwiperScreenSizes.Mobile]: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
    allowTouchMove: true,
  },
  [SwiperScreenSizes.Tablet]: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
    allowTouchMove: true,
  },
  [SwiperScreenSizes.Laptop]: {
    slidesPerView: slides,
    slidesPerGroup: slides,
    spaceBetween: slides > 1 ? 30 : 0,
    allowTouchMove: false,
  },
  [SwiperScreenSizes.Pc]: {
    slidesPerView: slides,
    slidesPerGroup: slides,
    spaceBetween: slides > 1 ? 40 : 0,
    allowTouchMove: false,
  },
});

const SelectEventContent = ({ events, loop }: ISelectEventContent) => {
  const eventsCount = events.length;

  return (
    <SwiperWrapper
      breakpoints={loop ? breakpoints : basicBreakpoints(events.length)}
      loop={loop}
      sliderWidth={SLIDER_WIDTH}
    >
      {events.map((event) => (
        <SwiperSlide key={event.eventId}>
          <SelectEventItem
            event={event}
            eventsCount={eventsCount}
            key={event.eventId}
          />
        </SwiperSlide>
      ))}
    </SwiperWrapper>
  );
};

export default SelectEventContent;
