import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';

import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { IAppLogo } from './interfaces';

import { classes } from './styles';
import { useEvenHostname } from '../../../../hooks/event';

const AWS_LOGO_SIZE = { maxHeight: 45 };

const AppLogo = ({ to, color }: IAppLogo) => {
  const { appTheme } = useMytaverse();
  const { isAwsEvent } = useEvenHostname();

  const Logo = appTheme.customerLogo;

  const logoSize = isAwsEvent() ? AWS_LOGO_SIZE : {};

  const LogoContent = (
    <Box
      sx={
        color
          ? { ...classes.logo(logoSize), ...{ color } }
          : classes.logo(logoSize)
      }
    >
      {typeof Logo === 'string' ? <img src={Logo} alt="Event Logo" /> : Logo}
    </Box>
  );

  // if (to) {
  //   return <Link to={to}>{LogoContent}</Link>;
  // }

  return LogoContent;
};

export default AppLogo;
