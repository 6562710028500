import React from 'react';
import { Box, Typography } from '@mui/material';

import SearchField from '../../../SearchField';

import SearchChatParticipants from './SearchChatParticipants';
import { ISearchChatView } from './interfaces';
import { useStyles } from './styles';

const SearchChatView = ({
  results,
  inputValue,
  onInputChange,
  onInputClear,
  onItemClick,
}: ISearchChatView) => {
  const classes = useStyles();

  const noSearchResult = !results.length && !!inputValue;

  return (
    <Box sx={classes.root}>
      <Box sx={classes.inputContainer}>
        <SearchField
          value={inputValue}
          onChange={onInputChange}
          onClear={onInputClear}
        />
      </Box>
      {noSearchResult ? (
        <Typography sx={classes.noSearchResult}>
          No results for “{inputValue}”
        </Typography>
      ) : (
        <SearchChatParticipants results={results} onItemClick={onItemClick} />
      )}
    </Box>
  );
};

export default SearchChatView;
