import React from 'react';

import { LinearProgress } from '@mui/material';

import { getProgressBarValue } from '../../../../../modules/auth/components/ProgressBar/helpers';

import { IProgressBar } from './interfaces';

import { useStyles } from './styles';

const ProgressBar = ({
  value,
  max = 100,
  isCompleted = false,
}: IProgressBar) => {
  const classes = useStyles({ isCompleted });

  const progressBarValue = getProgressBarValue(value, max);

  return (
    <LinearProgress
      value={progressBarValue}
      variant="determinate"
      sx={classes.root}
    />
  );
};

export default ProgressBar;
