import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import ScreenWrapper from './ScreenWrapper';
import { useStyles } from './styles';

import { useMytaverse } from '../../../../../../providers/MytaverseProvider';
import UpdateConsentButton from '../UpdateConsentButton';

interface ICustomiseScreen extends WithTranslation {
  onClick: () => void;
  onSkipClick: () => void;
  isAvaturnValid: boolean;
  isProcessing: boolean;
}

const CustomiseScreen = ({
  onClick,
  onSkipClick,
  isAvaturnValid,
  isProcessing,
  t: translate,
}: ICustomiseScreen) => {
  const classes = useStyles();

  const { currentAvatarId, customAvatarUrl } = useMytaverse();

  let skipDisabled: boolean;
  let buttonTranslate: string;

  if (currentAvatarId && (!customAvatarUrl || !isAvaturnValid)) {
    skipDisabled = true;
    buttonTranslate = translate('ek.selectAvatar.customiseButton');
  } else if (currentAvatarId && customAvatarUrl && isAvaturnValid) {
    skipDisabled = false;
    buttonTranslate = translate('ek.selectAvatar.customiseButton');
  } else {
    skipDisabled = true;
    buttonTranslate = translate('ek.selectAvatar.createButton');
  }

  return (
    <ScreenWrapper
      title={translate('ek.selectAvatar.title')}
      subtitle={translate('ek.selectAvatar.customiseScreen.description1')}
      secondarySubtitle={translate(
        'ek.selectAvatar.customiseScreen.description2',
      )}
      actions={
        <>
          <Box sx={classes.boxConsent}>
            <LoadingButton
              onClick={onSkipClick}
              disabled={skipDisabled}
              sx={{ ...classes.button, ...classes.buttonSecondary }}
              loading={isProcessing}
            >
              {translate('ek.selectAvatar.skipButton')}
            </LoadingButton>
            <UpdateConsentButton />
          </Box>

          <Button onClick={onClick} sx={classes.button}>
            {buttonTranslate}
          </Button>
        </>
      }
    />
  );
};

export default withTranslation('common')(CustomiseScreen);
