import React from 'react';
import { MytaverseEventProvider } from '../modules/dashboard/providers';

import LoadingProgress from '../components/LoadingProgress';

import { useCheckUniqueApplicationAccessPerAccount } from '../hooks/permissions';
import { useInitPrivateRoutesState } from './hooks';

const EventPageContainer: React.FC = () => {
  //useCheckUniqueApplicationAccessPerAccount();

  const initializing = useInitPrivateRoutesState();
  if (initializing) {
    return <LoadingProgress height="100%" />;
  }

  return (
    <>
      <MytaverseEventProvider />
      <video
        style={{
          visibility: 'hidden',
          position: 'fixed',
          background: '#000000',
        }}
        playsInline
        autoPlay
        id="gamecastVideoTag"
        muted
        onContextMenu={(event) => {
          event.preventDefault();
          return false;
        }}
      ></video>
    </>
  );
};

export default EventPageContainer;
