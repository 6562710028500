import React, { Dispatch, SetStateAction } from 'react';
import { IAvatarSkin } from '../../../../interfaces/avatarSkin';
import {
  IEvent,
  IFollower,
  IPixelStreamingClient, PlayerSession,
} from '../../../../interfaces/event';
import {
  IParticipant,
  IParticipantRegion,
} from '../../../../interfaces/participants';
import { IRoom } from '../../../../interfaces/rooms';
import { IFollowerData } from '../../../../interfaces/followers';
import { EventDrawerTabs } from '../../constants';
import { IInitMessage } from '../../components/DashboardContent/Pureweb/interfaces';
import PurewebClientOptions from '../../components/DashboardContent/Pureweb/helpers';
import { SendToBriefcasePropsTypeFull } from '../../components/DashboardContent/interfaces';
import {
  IMillicastEventTokens,
  IMillicastStreamTokens,
  IMillicastTokens,
} from '../../../../interfaces/millicastTokens';
import { IPointOfInterest } from '../../../../interfaces/pointsOfInterest';
import { IStreamService } from '../../../../interfaces/streamService';
import { IShareMediaParams } from '../../../../interfaces/shareMediaParams';
import { IMillicastPublisher } from '../../../../interfaces/millicastPublisher';
import { PixelStreaming } from '@tensorworks/libspsfrontend';
import { IGamecastVideoStats } from '../../components/DashboardContent/GameCast/interfaces';
import { IEventParticipant } from '../../../../interfaces/eventParticipant';
import { IUser } from '../../../../interfaces/user';

export interface EventParticipantDataType {
  currentEvent?: IEvent;
  currentEventParticipant: IEventParticipant | null;
}

export type UpdateParticipantType = (
  participantId: string,
  newParticipantInfo: IParticipant,
) => void;

export interface ParticipantsStateHookProps {
  currentParticipantId: string;
  currentEventId: string | undefined;
  currentParticipant: IParticipant | undefined;
  setCurrentParticipant: React.Dispatch<
    React.SetStateAction<IParticipant | undefined>
  >;
  participants: IParticipant[];
  setParticipants: React.Dispatch<React.SetStateAction<IParticipant[]>>;
  currentRoom: IRoom | null;
  setCurrentRoom: React.Dispatch<React.SetStateAction<IRoom | null>>;
  currentRegion: IParticipantRegion | null;
  setCurrentRegion: React.Dispatch<
    React.SetStateAction<IParticipantRegion | null>
  >;
  setIsParticipantRoomChanged: React.Dispatch<React.SetStateAction<boolean>>;
  rooms: IRoom[];
}

export type SetParticipantStateType = (
  participantId: string,
  data: {
    eventId?: string | null;
    roomId?: string | null;
    region?: IParticipantRegion | null;
    regions?: IParticipantRegion[];
    gameSessionId?: string | null;
    isDemoUser?: boolean;
  },
) => void;

export type NewRegionsType = {
  newRegion: IParticipantRegion | null;
  newRegions: IParticipantRegion[];
};

export enum SendMutedStateMessageEnum {
  MUTE = 'MUTE',
  UNMUTE = 'UNMUTE',
}

export enum InitMessageActions {
  INIT = 'INIT',
}

export enum DeviceTypeEnum {
  // Phone Types
  Phone = 'Phone',
  PhoneIOS = 'IOSPhone',
  PhoneAndroid = 'AndroidPhone',
  PhoneWin = 'WindowsPhone',

  // Tablet Types
  Tablet = 'Tablet',
  TabletIOS = 'IOSTablet',
  TabletAndroid = 'AndroidTablet',

  // Desktop Types
  Desktop = 'Desktop',
}

export interface UseInitMessageProps {
  token?: string;
  idToken?: string;
  websocketSessionId: string;
  dolbyToken: string;
  currentParticipant?: IParticipant;
  currentEventParticipant?: IEventParticipant | null;
  currentEvent?: IEvent;
  teleportingToRoom: IRoom | null;
  user: IUser;
  setPlayerSession: React.Dispatch<React.SetStateAction<PlayerSession | null>>,
}

export enum StreamingProviders {
  Pureweb = 'PUREWEB',
  GameCast = 'GAMECAST',
  CoreWeave = 'COREWEAVE',
}

export interface IMapFollower {
  pending: IFollowerData[];
  accepted: IFollowerData[];
}

export type MapFollowersType = (followers: IFollower[]) => IMapFollower;

export interface IWebsocketMessageFlag {
  flag: boolean;
  timestamp: number;
}

export interface IMytaverseEventContext {
  currentEventId: string;
  currentParticipantId: string;

  isOpenLeftMenu: boolean;

  openLeftMenu: (tab?: EventDrawerTabs) => void;
  closeLeftMenu: () => void;
  setParticipantsSound: (level: number) => void;
  participantsSound: number;
  enableSoulmachinesAssistant: boolean;
  setEnableSoulmachinesAssistant: React.Dispatch<React.SetStateAction<boolean>>;
  eventLoaded: boolean;
  setEventLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  leftMenuTab: EventDrawerTabs;
  setLeftMenuTab: (tab: EventDrawerTabs) => void;
  setGameSound: (level: number) => void;
  initMessageHandler: () => Promise<IInitMessage | null>;
  gameSound: number;
  clientOptions: PurewebClientOptions | null;
  rooms: IRoom[];
  reaction: string | null;
  emoji: string | null;
  setEmoji: (emoji: string | null) => void;
  setReaction: (reaction: string | null) => void;
  sharingWindowFullsceen: boolean;
  setSharingWindowFullscreen: () => void;
  handleMinimizeSharingScreen: () => void;
  isMinimizedScreenSharing: boolean;
  setIsMinimizedScreenSharing: React.Dispatch<React.SetStateAction<boolean>>;
  currentEvent: IEvent | undefined;
  goldenTicketRef: React.MutableRefObject<any>;
  currentEventParticipant: IEventParticipant | null;

  currentRoom: IRoom | null | undefined;
  setCurrentRoom: Dispatch<SetStateAction<IRoom | null>>;
  gameCastStreamId: string;
  setGameCastStreamId: Dispatch<SetStateAction<string>>;
  gameCastStreamRegion: string;
  setGameCastStreamRegion: Dispatch<SetStateAction<string>>;
  currentRegion: IParticipantRegion | null;
  setCurrentRegion: (region: IParticipantRegion | null) => void;
  gameCastStreamRequestSended: boolean;
  setGameCastStreamRequestSended: React.Dispatch<React.SetStateAction<boolean>>;
  participants: IParticipant[];
  setParticipants: React.Dispatch<React.SetStateAction<IParticipant[]>>;
  currentParticipant: IParticipant | undefined;
  setCurrentParticipant: React.Dispatch<
    React.SetStateAction<IParticipant | undefined>
  >;
  shareMillicastVideoWithSound: boolean;
  setShareMillicastVideoWithSound: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  teleportingToRoom: IRoom | null;
  setTeleportingToRoom: Dispatch<SetStateAction<IRoom | null>>;
  teleportToRoom: (room: IRoom | null) => void;
  isTeleportingToRoomByUnreal: boolean;
  setIsTeleportingToRoomByUnreal: Dispatch<SetStateAction<boolean>>;

  teleportToParticipant: (participant: IParticipant | null) => Promise<void>;
  currentRoomDolbySpatialAudioScale: number;
  setRoomDolbySpatialAudioScale: (
    roomId: string,
    dolbySpatialAudioScale: number,
  ) => void;
  updateRoomScale: (value: number) => void;
  previousSkin: IAvatarSkin | null;
  ue5WebsocketConnected: boolean;
  setUe5WebsocketConnected: React.Dispatch<React.SetStateAction<boolean>>;
  selectSkin: (skin: IAvatarSkin) => Promise<void>;
  loading: boolean;
  isTeleporting: boolean;
  setIsTeleporting: React.Dispatch<React.SetStateAction<boolean>>;
  openAdminSettingsModal: boolean;
  setOpenAdminSettingsModal: React.Dispatch<React.SetStateAction<boolean>>;

  muted: boolean;
  poiPreviewSrc: SendToBriefcasePropsTypeFull | null;
  setPoiPreviewSrc: React.Dispatch<
    React.SetStateAction<SendToBriefcasePropsTypeFull | null>
  >;
  setMuted: (value?: boolean) => void;
  dolbyToken: string;
  streamChatToken: string;
  streamChatMemberId: string;
  millicastTokens: IMillicastEventTokens | null;
  getMillicastStreamTokens: (
    screenName: string,
  ) => Promise<IMillicastStreamTokens | null>;
  streamingProvider: StreamingProviders | null;
  setStreamingProvider: Dispatch<SetStateAction<StreamingProviders | null>>;
  pointsOfInterest: IPointOfInterest[] | null;
  resetPreviousSkin: () => void;
  streamService?: IStreamService;
  userFiles: IPointOfInterest[];
  setUserFiles: React.Dispatch<React.SetStateAction<IPointOfInterest[]>>;
  micUsingByMillicast: boolean;
  setMicUsingByMillicast: React.Dispatch<React.SetStateAction<boolean>>;
  setParticipantState: SetParticipantStateType;
  updateParticipant: UpdateParticipantType;
  setSpeakingParticipants: (speakingParticipantIds: string[]) => void;

  isShareScreenModalOpen: boolean;
  openShareScreenModal: (params: IShareMediaParams) => Promise<void>;
  closeShareScreenModal: (unlockScreen?: boolean) => Promise<void>;

  openMillicastScreenBroadcastingDataModal: boolean;
  setOpenMillicastScreenBroadcastingDataModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;

  loadingShareScreenModal: boolean;
  setLoadingShareScreenModal: React.Dispatch<React.SetStateAction<boolean>>;

  isShareVideoModalOpen: boolean;
  openShareVideoModal: (params: IShareMediaParams) => Promise<void>;
  closeShareVideoModal: (unlockScreen?: boolean) => Promise<void>;

  loadingShareVideoModal: boolean;
  setLoadingShareVideoModal: React.Dispatch<React.SetStateAction<boolean>>;
  leftMenuScrollHandler: ((page: number) => void) | null;
  setLeftMenuScrollHandler: React.Dispatch<
    React.SetStateAction<((page: number) => void) | null>
  >;
  canSpeak: boolean;
  shareMediaParams?: IShareMediaParams | null;
  setShareMediaParams: React.Dispatch<
    React.SetStateAction<IShareMediaParams | null>
  >;
  gamecastStats: IGamecastVideoStats | null;
  setGamecastStats: React.Dispatch<
    React.SetStateAction<IGamecastVideoStats | null>
  >;
  showGamecastStats: boolean;
  setShowGamecastStats: React.Dispatch<React.SetStateAction<boolean>>;
  showControls: boolean;
  setShowControls: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentSkin: React.Dispatch<React.SetStateAction<IAvatarSkin | null>>;
  currentSkin: IAvatarSkin | null;

  initMessageSended: boolean;
  openLeaveRegionDialog: boolean;
  setOpenLeaveRegionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openCameraPublisherDialog: boolean;
  setOpenCameraPublisherDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openTeleportToRoomDialog: boolean;
  setOpenTeleportToRoomDialog: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTeleportRoom: IRoom | null;
  setSelectedTeleportRoom: React.Dispatch<React.SetStateAction<IRoom | null>>;
  initScreenMediaStream: (force?: boolean) => Promise<void>;
  handleStopScreenStream: (streamId: string) => Promise<void>;
  setInitMessageSended: React.Dispatch<React.SetStateAction<boolean>>;
  isStreamLoading: boolean;
  isStreamLoaded: boolean;
  setIsStreamLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStreamLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
  //streamSession: GameCastStreamSession | null;
  shareVideoPublishers: IMillicastPublisher | null;
  setShareVideoPublishers: (publisher: IMillicastPublisher | null) => void;

  shareVideoMediaStream: MediaStream | null;
  setShareVideoMediaStream: React.Dispatch<
    React.SetStateAction<MediaStream | null>
  >;

  shareScreenPublishers?: IMillicastPublisher[];
  setShareScreenPublishers: (publisher: any) => void;
  developersTerminalMessages: string[];
  setDevelopersTerminalMessages: React.Dispatch<React.SetStateAction<string[]>>;
  openDevelopersTerminal: boolean;
  setOpenDevelopersTerminal: React.Dispatch<React.SetStateAction<boolean>>;
  shareScreenMediaStreams?: MediaStream[];
  setShareScreenMediaStreams: React.Dispatch<
    React.SetStateAction<MediaStream[]>
  >;

  resetPreviousCustomSkin: () => void;
  previousCustomAvatar: string | null;
  startShareVideo: (
    shareVideoPublishers: any,
    shareVideoTokens: IMillicastTokens,
    muted: boolean,
  ) => void;
  stopShareVideo: () => void;

  startShareScreen: (
    shareScreenPublishers: IMillicastPublisher,
    shareScreenTokens: IMillicastTokens,
    muted: boolean,
  ) => void;
  stopShareScreen: (streamId: string) => void;
  cleanStates: () => void;

  trackAnalytics: (type: string, payload: any) => Promise<void>;

  pendingFollowersData: IFollowerData[];
  setPendingFollowersData: Dispatch<SetStateAction<IFollowerData[]>>;
  acceptedFollowersData: IFollowerData[];
  setAcceptedFollowersData: Dispatch<SetStateAction<IFollowerData[]>>;

  handleMillicastWSMessage: (lastJSONMessage: any) => void;

  gameReadyToPlay: boolean;
  setGameReadyToPlay: Dispatch<SetStateAction<boolean>>;

  ue5CoreWeaveDisabled: boolean;
  setUe5CoreWeaveDisabled: Dispatch<SetStateAction<boolean>>;
  pixelStreamingClient: IPixelStreamingClient | null;
  events: IEvent[];
  webSocketAddress: string | null;
  pixelStreaming: PixelStreaming | null;
  coreweaveLoading: boolean;
  dataChannelOpened: boolean;
  playStream: boolean;
  connectedPod: string;
  rootElement: HTMLElement | null;
  setRootElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setPixelStreaming: React.Dispatch<
    React.SetStateAction<PixelStreaming | null>
  >;
  setWebSocketAddress: React.Dispatch<React.SetStateAction<string | null>>;
  togglePlayStream: () => void;
  handleInitializeButton: () => void;
  isPlayButtonPressed: boolean;
  isGamecastCrash: boolean;
  setIsGamecastCrash: React.Dispatch<React.SetStateAction<boolean>>;
  playerSession: PlayerSession | null,
  setPlayerSession: React.Dispatch<React.SetStateAction<PlayerSession | null>>,
  showExitButton: IWebsocketMessageFlag;
  setShowExitButton: Dispatch<SetStateAction<IWebsocketMessageFlag>>;
  isHideWebUI: IWebsocketMessageFlag;
  setIsHideWebUI: Dispatch<SetStateAction<IWebsocketMessageFlag>>;

  gameCastRef: any;
}
