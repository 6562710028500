import { keyframes, useTheme } from '@mui/system';

import { ScreenSizes } from '../../../../../../interfaces';
import { COLORS } from '../../../../../../constants/colors';

const bounce = keyframes`
  0%, 100% {
    margin-right: 0
  }
  40%, 60% {
    margin-right: 8px
  }
`;

const bounceReverse = keyframes`
  0%, 100% {
    margin-right: 0
  }
  40%, 60% {
    margin-right: -8px
  }
`;

const showButton = keyframes`
  0%, 99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    button: {
      justifyContent: 'start',
      boxSizing: 'border-box',

      [breakpoints.up(ScreenSizes.TabletHorizontal)]: {
        animation: `${showButton} 0.01s ease-in`,
        animationDelay: '0.8s',
        animationFillMode: 'forwards',
        opacity: 0,

        '&:hover': {
          backgroundColor: COLORS.MYTAVERSE_BLACK,
          '& span.MuiTypography-root': {
            maxWidth: '200px',
            opacity: 1,
            animation: `${bounce} 0.5s ease-in`,
            animationDelay: '0.25s',
          },
        },
      },

      '& .MuiTypography-root': {
        fontSize: 'inherit',
        fontWeight: 'inherit',
        letterSpacing: 'inherit',
        lineHeight: 'inherit',
      },
    },
    info: {
      overflow: 'hidden',
      display: 'inline-block',
      maxWidth: 0,
      opacity: 0,

      [breakpoints.up(ScreenSizes.TabletHorizontal)]: {
        transition: 'all 0.3s ease-in',
        animation: `${bounceReverse} 0.5s ease-in`,
        animationDelay: '0.25s',
      },
    },
    indicator: {
      color: `${COLORS.GREEN} !important`,
      width: '8px',
      height: '8px',
      mr: '0 !important',
    },
    content: {
      fontSize: 'inherit',
      color: 'inherit',
      whiteSpace: 'nowrap',
      display: 'flex',
      gap: 0.5,
    },
  };
};
