import React from 'react';
import { isDesktop, isIOS } from 'react-device-detect';

import { useFullscreen } from '../../../../hooks/fullscreen';
import { sendCustomSentryBreadcrumb } from '../../../../../../helpers/sentry';

import FullScreenDrawer, { useDrawer } from '../../FullScreenDrawer';
import FullScreenButtonView from './FullScreenButtonView';

import { NavigatorStandalone } from './interfaces';

const { REACT_APP_EK_ENV } = process.env;

const FullScreenButton = () => {
  const { toggleFullscreen, isFullscreen } = useFullscreen();

  const { open: isOpenDrawer, toggleDrawer } = useDrawer();

  const handleFullscreenClick = () => {
    sendCustomSentryBreadcrumb({ message: 'show in full screen' });
    toggleFullscreen();
  };

  const handleMobileDrawer = () => {
    sendCustomSentryBreadcrumb({ message: 'show in full tutorial' });
    toggleDrawer();
  };

  if (isDesktop) {
    return (
      <FullScreenButtonView
        isFullscreen={isFullscreen}
        onClick={handleFullscreenClick}
      />
    );
  }

  if (
    !!REACT_APP_EK_ENV ||
    (isIOS && (window.navigator as NavigatorStandalone)?.standalone) ||
    (!isIOS && window.matchMedia('(display-mode: standalone)').matches)
  ) {
    return null;
  }

  return (
    <>
      <FullScreenButtonView onClick={handleMobileDrawer} />
      <FullScreenDrawer isOpen={isOpenDrawer} onClick={handleMobileDrawer} />
    </>
  );
};

export default FullScreenButton;
