import axios, { AxiosResponse } from 'axios';
import $api from '../http/axios';

export default class StreamService {
  static checkServiceLatency(region: string): Promise<number> {
    return $api
      .get(`https://ec2.${region}.amazonaws.com/ping`)
      .then((res) => res.data.requestDuration);
  }

  static awsPing(region = 'us-east-1'): Promise<AxiosResponse> {
    return axios.get(`https://ec2.${region}.amazonaws.com/ping`);
  }
}
