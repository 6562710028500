import React from 'react';
import { withTranslation } from 'react-i18next';

import { Button, Typography } from '@mui/material';

import { ReactComponent as ArrowBackIcon } from '../../../../../public/images/ek/back.svg';

import { IBackButton } from './interfaces';

import { useStyles } from './styles';

const BackButton = ({ onClick, sx = {}, t: translate }: IBackButton) => {
  const classes = useStyles();
  const rootSx = { ...classes.root, ...sx };

  return (
    <Button onClick={onClick} sx={rootSx}>
      <ArrowBackIcon />
      <Typography sx={classes.text}>{translate('back')}</Typography>
    </Button>
  );
};

export default withTranslation('common')(BackButton);
