import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      width: '100vw',
      height: '100vh',
    },
    container: {
      display: 'grid',
      gridTemplateRows: 'auto auto 1fr auto',
      height: '100%',
    },
    header: {
      position: 'relative',
      display: 'flex',
      marginTop: '74px',
      padding: '0 46px',
      [breakpoints.down(ScreenSizes.Pc)]: {
        flexDirection: 'column',
        marginTop: '6px',
        rowGap: 9,
        padding: '0 34px',
      },
      [breakpoints.down(ScreenSizes.Tablet)]: {
        marginTop: '6px',
        rowGap: 5,
        padding: '0 16px',
      },
      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        marginTop: '24px',
        rowGap: 0,
      },
    },
    box: {
      flex: 1,
      [breakpoints.down(ScreenSizes.Pc)]: {
        '&:last-child': {
          display: 'none',
        },
      },
    },
    backButton: {
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        position: 'absolute',
      },
      [breakpoints.down(ScreenSizes.Mobile)]: {
        position: 'static',
      },
    },
    content: {
      display: 'grid',
      gridTemplateRows: '1fr',
      alignItems: 'center',
      marginTop: 2,
      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        marginTop: 0,
      },
    },
  };
};
