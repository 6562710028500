import React from 'react';

import DrawerView from './DrawerView';

import { IDrawer } from './interfaces';

const Drawer = ({
  isOpen,
  onClick,
  children,
  title,
  icon,
  disableHeader,
}: IDrawer) => {
  return (
    <DrawerView
      isOpen={isOpen}
      onClick={onClick}
      title={title}
      icon={icon}
      disableHeader={disableHeader}
    >
      {children}
    </DrawerView>
  );
};

export default Drawer;
