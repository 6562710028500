export enum POC_SCREENS {
  DEFAULT = 'DEFAULT',
  HOW_TO = 'HOW_TO',
  CHAT = 'CHAT',
}

export const assistantSize = {
  width: 192,
  height: 192
}

// the following flags are for debug purproses
export const enableDebugControls = false;
export const enableOnEventSelectionpage = false;

export const greetingsText = 'Hello @userName@. Welcome to the metaverse. My name is Noa and I am here to help you. You can always click on the assistant button on the bottom right to ask me any question you have. Please feel free to roam around and let me know if you need my help. You can ask me simple things like: "how do I walk in the metaverse" to very specific questions about your company and industry. I will do my best to help you in any way I can';