import { differenceInMilliseconds, format, fromUnixTime } from 'date-fns';

export const convertToGST = (unixTimestamp: number) => {
  const date = fromUnixTime(unixTimestamp);

  // Create a new date representing GST by adding 4 hours
  return new Date(date.getTime() + 4 * 60 * 60 * 1000);
};

export const getTimeFormat = (date: Date) => {
  return format(date, 'h:mm a')
    .toLowerCase()
    .replace('am', 'a.m.')
    .replace('pm', 'p.m.');
};

export const getMinutesBeforeEventStart = (unixTimestamp: number) => {
  const date = fromUnixTime(unixTimestamp);
  const diffMs = differenceInMilliseconds(date, new Date());

  return Math.floor(diffMs / (1000 * 60));
};

export const getTimeBeforeEvent = (unixTimestamp: number) => {
  const diffMs = getMinutesBeforeEventStart(unixTimestamp);

  if (diffMs <= 0) {
    return '';
  }

  const days = Math.floor(diffMs / (60 * 24));
  const hours = Math.floor((diffMs % (60 * 24)) / 60);
  const minutes = diffMs % 60;

  const parts: string[] = [];

  if (days) {
    parts.push(`${days}d`);
  }

  if (hours) {
    parts.push(`${hours}h`);
  }

  if (minutes) {
    parts.push(`${minutes}min`);
  }

  return parts.join(' ');
};
