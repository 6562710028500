import { useMytaverseEvent } from '../modules/dashboard/providers';

import { getInitialMapParticipants } from '../helpers/participant';

import EventsService from '../services/EventsService';

export const useUpdateInitialParticipants = () => {
  const { setParticipants, currentEventId } = useMytaverseEvent();

  const updateInitialParticipants = async () => {
    const eventId = currentEventId || '';
    const participants = await EventsService.getEventParticipants(eventId);
    const participantsInfo = await EventsService.getEventConnections(
      eventId,
    );
    const participantRoles = await EventsService.getEventParticipantsRoles(
      eventId,
    );

    setParticipants(
      getInitialMapParticipants(
        participants,
        participantsInfo,
        participantRoles,
      ),
    );
  };

  return updateInitialParticipants;
};
