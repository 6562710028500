import { useTheme } from '@mui/material';

import { COLORS, colors } from '../../../../../constants/colors';
import { ScreenSizes } from '../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    tabs: {
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      '& button': {
        textTransform: 'none',
        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          p: 1,
        },
      },
      '& .MuiTabs-flexContainer': {
        gap: 3,
        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          justifyContent: 'center',
        },
      },

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        backgroundColor: colors.gray,
        mx: -2,
      },
    },
    tab: {
      color: colors.white_50,
      py: 1,
      px: 0,
      '&.Mui-selected': {
        color: COLORS.WHITE,
        borderColor: 'transparent',
      },
      '& .MuiBox-root': {
        fontSize: [20, 20, 24, 24],
      },
    },
  };
};
