import React from 'react';

import { Box } from '@mui/material';

import ArrowButton from '../../ArrowButton';
import ParticipantSearchItem from '../ParticipantSearchItem';
import SelectionArrowButton from '../../SelectionArrowButton';
import CloseDrawerButton from '../../CloseDrawerButton';
import ChatChannel from '../../ChatChannel';

import ParticipantStatusIcon from '../../../../../../components/ParticipantStatusIcon';

import { IPrivateChatView } from './interfaces';

import { useStyles } from './styles';

const PrivateChatView = ({
  participant,
  channel,
  showClearBtn,
  onArrowBtnClick,
  onClickBackButton,
}: IPrivateChatView) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root}>
      <Box sx={classes.chatHeader}>
        <ArrowButton onClick={onClickBackButton} sx={classes.arrowButton} />
        <ParticipantSearchItem
          classes={{ avatar: classes.avatar, name: classes.participantName }}
          item={participant}
          actions={
            <>
              <ParticipantStatusIcon
                isOnline={!!participant?.roomId}
                sx={classes.statusIcon}
              />
              <SelectionArrowButton
                downIcon={!showClearBtn}
                onClick={onArrowBtnClick}
                sx={classes.selectionBtn}
              />
            </>
          }
        />
        <CloseDrawerButton sx={classes.closeIcon} />
      </Box>
      <ChatChannel channel={channel} showClearChat={showClearBtn} />
    </Box>
  );
};

export default PrivateChatView;
