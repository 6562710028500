export const useStyles = () => {
  return {
    button: {
      fontFamily: '"Noto Sans", sans-serif',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.4,
      color: '#939393',
      minWidth: 'auto',
      textTransform: 'none',
      width: 'fitContent',

      '&:hover': {
        background: 'transparent',
      },
    },
  };
};
