import { useTheme } from '@mui/material';

import { colors } from '../../../../../constants/colors';
import { ScreenSizes } from '../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    selectionContainer: {
      width: '100%',
      position: 'relative',
      marginTop: 4,
      marginBottom: 4,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        marginTop: 2,
        marginBottom: 2,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        marginTop: '20px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        marginTop: '10px',
        marginBottom: '10px',
      },
    },
    selection: {
      position: 'absolute',
      content: '""',
      inset: 0,
      padding: '14px 18px',
      '& .MuiSelect-select p': {
        color: 'white !important',
        fontSize: '18px !important',
        fontWeight: '500 !important',
      },
      '& .MuiSvgIcon-root': {
        color: 'white !important',
      },
      '& .MuiInputBase-root': {
        [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
          width: '100%',

          '& p': {
            fontSize: 14,
          },
        },
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        '& .MuiSelect-select p': {
          fontSize: '12px !important',
        },

        '& svg': {
          width: '20px !important',
          height: '20px !important',
        },
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        '& svg': {
          width: '20px !important',
          height: '20px !important',
        },
      },
    },
    helper: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      color: '#707070',
      marginBottom: 4,
      textAlign: 'center',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 16,
        marginBottom: 2,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        marginTop: 1,
        marginBottom: 4,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        fontSize: 14,
        lineHeight: '14px',
        marginBottom: 2,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        fontSize: 10,
        lineHeight: '14px',
        marginBottom: 1,
      },
    },
  };
};
