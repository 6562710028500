import { SxProps } from '@mui/material';

import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { colorsEK } from '../../../../../constants/colors';

export const useStyles = () => {
  const { appTheme } = useMytaverse();

  const {
    submitButton: { backgroundColorHover },
  } = appTheme.avatarPage.assets;

  return {
    root: {
      '& .MuiBackdrop-root': {
        backgroundColor: '#00000080',
      },
    },
    container: {
      display: 'flex',
      gap: 2,
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'start',
      padding: '24px 35px 24px 55px',
      height: 628,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 725,

      background: colorsEK.white,

      borderRadius: 1,
      maxHeight: '90vh',
      maxWidth: '90vw',
      overflow: 'auto',

      '&:focus-visible': {
        outline: 'none',
      },

      '@media (max-width: 767px)': {
        padding: '20px 26px 20px 44px',
        width: '100%',
        height: '100%',
        maxHeight: '100dvh',
        maxWidth: '100dvw',
      },
    } as SxProps,
    closeIcon: {
      position: 'absolute',
      top: 13,
      right: 13,
      color: '#939393',
      fontSize: 14,
      cursor: 'pointer',
    },
    iconContainer: {
      display: 'flex',
      background: appTheme.mainColor,
      borderRadius: 9,
      padding: 1,
      '& svg': {
        color: colorsEK.white,
        width: 20,
        height: 20,
      },
    },
    title: {
      fontFamily: 'Emirates-Medium !important',
      fontWeight: 400,
      fontSize: 32,
      lineHeight: 1.5,
      color: colorsEK.black,
      marginTop: 2,

      '@media (max-width: 767px)': {
        fontSize: 28,
        lineHeight: 1.4,
      },
    },
    pdf: {
      position: 'relative',
      overflow: 'auto',
      width: '100%',
      minHeight: '150px',
      alignItems: 'center',
      // marginBottom: 2,

      '&::-webkit-scrollbar': {
        height: '5px',
        width: '5px',
      },

      '&::-webkit-scrollbar-track': {
        backgroundColor: '#D9D9D9',
        borderRadius: '2px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: appTheme.mainColor,
        borderRadius: '2px',
        boxShadow: 'unset',
      },
    },
    overlay: {
      position: 'sticky',
      bottom: 0,
      left: 0,
      right: 0,
      height: '30%',
      background:
        'linear-gradient(180.1deg, rgba(255, 255, 255, 0) 0.09%, #FFFFFF 70%)',
    },

    button: {
      padding: '14px',
      width: 365,
      maxWidth: '100%',
      fontWeight: 500,
      borderRadius: '6px',
      fontSize: 24,
      lineHeight: 1.125,
      color: colorsEK.white,
      backgroundColor: appTheme.mainColor,
      textTransform: 'none',
      marginTop: 'auto',

      '&:hover': {
        backgroundColor: appTheme.mainColor,
      },

      '&:disabled': {
        color: colorsEK.white,
        background: `${appTheme.mainColor}4D`,
        cursor: 'no-drop',
      },
    },
  };
};
