import { useCallback, useEffect, useMemo, useState } from 'react';

import { GenderType, INonConsentData, SkinToneType } from '../../interfaces';
import { useMytaverse } from '../../../../../../../providers/MytaverseProvider';
import AvaturnService from '../../../../../../../services/AvaturnService';
import { IUser } from '../../../../../../../interfaces/user';
import { getHRData } from '../../../../../../helpers/hr';

const useCustomAvaturn = () => {
  const [isProcessing, setIsProcessing] = useState(false);

  const { user, setUser } = useMytaverse();

  const hrData = useMemo(
    () => (user?.hrData ? getHRData(user.hrData) : {}),
    [user?.hrData],
  );

  const [data, setData] = useState<INonConsentData>({
    gender: GenderType.MALE,
    skinTone: SkinToneType.LIGHT,
  });

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      gender:
        hrData?.gender?.toLowerCase() === 'm'
          ? GenderType.MALE
          : GenderType.FEMALE,
    }));
  }, [hrData?.gender]);

  const createAvatar = useCallback(async () => {
    if (!data.gender || !data.skinTone || !user?.hrData) {
      return;
    }

    setIsProcessing(true);

    try {
      const newAvaturn = await AvaturnService.createNewAvaturn(
        hrData.ixrCredentialStatus,
        hrData.brand,
        { gender: data.gender, skinTone: data.skinTone },
      );

      setUser((prevState: IUser) => ({
        ...prevState,
        avaturnId: newAvaturn.avaturnId,
      }));
    } catch (error) {
      console.log(error);
    }

    setIsProcessing(false);
  }, [data, setUser, hrData]);

  return { isProcessing, data, setData, createAvatar };
};

export default useCustomAvaturn;
