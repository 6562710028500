import { useTheme } from '@mui/material';

import { useMytaverse } from '../../../../../../providers/MytaverseProvider';
import { colors } from '../../../../../../constants/colors';
import { SLIDER_WIDTH } from '../constants';

import { IStyles } from '../../../../../../interfaces/styles';
import { SwiperScreenSizes } from '../../../../../../interfaces';

export const useStyles = (): IStyles => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();

  return {
    root: {
      width: SLIDER_WIDTH,
      backgroundColor: colors.white,
      borderRadius: 1.25,
      boxSizing: 'border-box',
      boxShadow: 'none',
      cursor: 'pointer',
      position: 'relative',

      [breakpoints.down(SwiperScreenSizes.Pc)]: {
        width: '306px',
      },

      [breakpoints.down(SwiperScreenSizes.Laptop)]: {
        width: SLIDER_WIDTH,
      },
    },
    image: {
      objectFit: 'cover',
      height: '45vh',
      maxHeight: '467px',

      [breakpoints.down(SwiperScreenSizes.Pc)]: {
        height: '38vh',
      },

      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        maxHeight: '125px',
      },
    },
    content: {
      backgroundColor: appTheme.mainColor,
      textAlign: 'center',
      padding: '24px 0px',
      '&:last-child': {
        padding: '24px 0px',
      },

      '@media (max-width: 340px)': {
        padding: '8px 0px',
        '&:last-child': {
          padding: '8px 0px',
        },
      },

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        padding: '8px 0px',
        '&:last-child': {
          padding: '8px 0px',
        },
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        padding: '8px 0px',
        '&:last-child': {
          padding: '8px 0px',
        },
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        padding: '6px 0px',
        '&:last-child': {
          padding: '6px 0px',
        },
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          padding: '8px 0px',
          '&:last-child': {
            padding: '8px 0px',
          },
        },
    },
    eventName: {
      fontFamily: 'Poppins !important',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '27px',
      color: colors.white,

      '@media (max-width: 340px)': {
        fontSize: 18,
        lineHeight: '23px',
      },

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        fontSize: 18,
        lineHeight: '23px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        fontSize: 18,
        lineHeight: '23px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        fontSize: 18,
        lineHeight: '23px',
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          fontSize: 18,
          lineHeight: '23px',
        },
    },
    chip: {
      position: 'absolute',
      top: 34,
      left: 4,
    },
    chipCapacity: {
      position: 'absolute',
      top: 4,
      left: 4,
      background: appTheme.mainColor,
      color: colors.white,
    },
    version: {
      position: 'absolute',
      top: 4,
      right: 4,
      background: colors.black_25,
      color: colors.white,
    },
    mobileContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 1,
    },
    icon: {
      marginRight: 1,
    },
    swipeText: {
      marginX: 1,
    },
    container: {
      position: 'relative',
      textAlign: 'center',
      //marginBottom: 2,
    },
  };
};
