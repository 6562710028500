import axios, { AxiosRequestConfig } from 'axios';
import { BadRequest } from '../helpers/error';

import {
  ISlots,
  IAvailableInviteSlotsParams,
  ISlot,
} from '../interfaces/officeHours';

const { REACT_APP_SPATIAL_MANAGER_API_URL } = process.env;

export default class OfficeHoursService {
  static getAvailableInviteSlots(
    params: IAvailableInviteSlotsParams,
    config: AxiosRequestConfig = {},
  ): Promise<ISlots> {
    const { eventId, ...restParams } = params;

    return axios
      .get(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/profile/events/${eventId}/invite-slots/available`,
        { ...config, params: restParams || {} },
      )
      .then((res: any) => res.data)
      .catch(() => {
        throw new BadRequest(new Error(`Can't load available invite slots`));
      });
  }

  static bookSlot(eventId: string): Promise<{ slot: ISlot }> {
    return axios
      .post(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/profile/events/${eventId}/invite-slots/book`,
      )
      .then((res: any) => res.data);
  }

  static cancelSlot(eventId: string): Promise<{ slot: ISlot }> {
    return axios
      .post(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/profile/events/${eventId}/invite-slots/cancel`,
      )
      .then((res: any) => res.data);
  }
}
