import { colors } from '../../../../../constants/colors';
import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 2,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: 1,
      },
    },
    headerText: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 1,
      color: colors.white,
    },
    text: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '24px',
      letterSpacing: '0.025em',
    },
    closeIcon: {
      color: colors.white,
      opacity: 0.6,
      cursor: 'pointer',
      '&:hover': {
        opacity: 1,
      },
    },
  };
};
