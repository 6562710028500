import React from 'react';

import { RouteObject } from 'react-router/lib/router';

import LoginWrapper from '../ek/modules/auth/components/LoginWrapper';
import LoginPage from '../ek/modules/auth/pages/LoginPage';
import TryDemoPage from '../modules/auth/pages/TryDemoPage';
import EventConfirmationPage from '../modules/auth/pages/EventConfirmationPage';
import SignUpPage from '../modules/auth/pages/SignUpPage';
import EmailConfirmationPage from '../modules/auth/pages/EmailConfirmationPage';
import EmailConfirmedPage from '../modules/auth/pages/EmailConfirmedPage';
import ForgotPasswordPage from '../modules/auth/pages/ForgotPasswordPage';
import ForgotPasswordVerificationPage from '../modules/auth/pages/ForgotPasswordVerificationPage';
import PasswordRecovery from '../modules/auth/pages/PasswordRecovery';
import EventPageContainer from './EventPageContainer';
import SelectEventPage from '../ek/modules/auth/pages/SelectEventPage';
import SelectAvatarPage from '../ek/modules/auth/pages/SelectAvatarPage';
import SelectAvatarSkinPage from '../modules/auth/pages/SelectAvatarSkinPage';
import EventPage from '../ek/modules/dashboard/pages/EventPage';
import ReadyPlayerMe from '../modules/auth/pages/ReadyPlayerMe';
import DevicesTestPage from '../ek/modules/auth/pages/DevicesTestPage';
import PageNotFoundPage from '../modules/auth/pages/PageNotFoundPage';

import ROUTES from '../constants/routes';
import Avaturn from '../ek/modules/auth/pages/Avaturn';
import { ConferenceProvider } from '../modules/dashboard/components/DashboardContent/Dolby';
import { EventProvider } from '../ek/providers/EventProvider';

const APP_ROUTES: RouteObject[] = [
  {
    path: ROUTES.HOME_PAGE,
    element: <LoginWrapper />,
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
      {
        path: ROUTES.LOGIN,
        element: <LoginPage />,
      },
      {
        path: `${ROUTES.CONFIRM_EVENT}/:token`,
        element: <EventConfirmationPage />,
      },
      {
        path: `${ROUTES.TRY_DEMO}`,
        element: <TryDemoPage />,
      },
      {
        path: ROUTES.REGISTER,
        element: <SignUpPage />,
      },
      {
        path: ROUTES.CONFIRM_EMAIL,
        element: <EmailConfirmationPage />,
      },
      {
        path: `${ROUTES.CONFIRMED_EMAIL}/:token`,
        element: <EmailConfirmedPage />,
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        element: <ForgotPasswordPage />,
      },
      {
        path: `${ROUTES.FORGOT_PASSWORD}/${ROUTES.FORGOT_PASSWORD_VERIFICATION}`,
        element: <ForgotPasswordVerificationPage />,
      },
      {
        path: `${ROUTES.RECOVER_PASSWORD}/:token`,
        element: <PasswordRecovery />,
      },
    ],
  },
  {
    path: ROUTES.SELECT_EVENT,
    element: <SelectEventPage />,
  },
  {
    path: ROUTES.EVENT_PAGE(),
    element: (
      <EventProvider>
        <EventPageContainer />
      </EventProvider>
    ),
    children: [
      {
        index: true,
        element: (
          <ConferenceProvider>
            <EventPage />
          </ConferenceProvider>
        ),
      },
      {
        path: ROUTES.AVATAR_CHOOSING(),
        element: <SelectAvatarPage />,
      },
      {
        path: ROUTES.SELECT_AVATAR_SKIN(),
        element: <SelectAvatarSkinPage />,
      },
      {
        path: ROUTES.READY_PLAYER(),
        element: <ReadyPlayerMe />,
      },
      {
        path: ROUTES.AVATURN(),
        element: <Avaturn />,
      },
      {
        path: ROUTES.DEVICES_TEST(),
        element: <DevicesTestPage />,
      },
    ],
  },
  {
    path: '*',
    element: <PageNotFoundPage />,
  },
];

export default APP_ROUTES;
