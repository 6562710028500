import { colors } from '../../../../../../../../constants/colors';
import { ScreenSizes } from '../../../../../../../../interfaces';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: 3,
      padding: '18px 8px',
      height: 332,
    },
    emptyContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: 3,
      padding: '18px 8px',
      height: 332,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        height: 'calc(100% - 100px)',
      },
    },
    text: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: colors.white,
      opacity: 0.4,
    },
  };
};
