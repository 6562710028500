import { styled } from '@mui/material';

export const classes = {
  root: {
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0,
    width: '100%',
  },
};

export const Video = styled('video')({
  left: 0,
  top: 0,
  width: '100%',
  position: 'absolute',
});
