import { useTheme } from '@mui/material';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';

import { colors, colorsEK } from '../../../../../constants/colors';
import { ScreenSizes } from '../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();

  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 1,
      backgroundColor: 'rgba(184, 184, 184, 0.1)',
      borderBottom: '1px solid transparent',
      borderRadius: 1,
      padding: '8px 20px',
      cursor: 'pointer',
      color: colorsEK.grey_300,
      gap: 1.5,

      '& p': {
        lineHeight: 1,
        color: colorsEK.grey_300,
        fontWeight: 400,
      },

      '& svg': {
        height: 16,
      },

      '&:hover': {
        backgroundColor: 'rgba(184, 184, 184, 0.5)',
        color: colorsEK.grey_500,
      },

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        backgroundColor: 'transparent',
        paddingLeft: '30px',

        '& svg path': {
          stroke: appTheme.mainColor,
          height: 30,
        },

        '& svg': {
          width: 18,
          height: 30,
        },

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    icon: {
      color: colors.oxford_60,
      width: 24,
      height: 24,
    },
    text: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: '0.1px',
      color: colors.oxford_60,
      textTransform: 'capitalize',

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        display: 'none',
      },
    },
  };
};
