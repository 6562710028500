import React from 'react';

import { Box, Typography } from '@mui/material';

import { ITabLabel } from './interfaces';

import { useStyles } from './styles';

const TabLabel = ({ icon: Icon, text }: ITabLabel) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root}>
      <Icon sx={classes.icon} />
      <Typography sx={classes.text}>{text}</Typography>
    </Box>
  );
};

export default TabLabel;
