import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Skeleton, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import PlaceIcon from '@mui/icons-material/Place';

import RoomIndicator from '../RoomIndicator';
import { ReactComponent as Teleport } from '../../../../../../../public/images/teleport.svg';

import { IRoomCard } from './interfaces';

import { useStyles } from './styles';

const RoomCard = ({
  room,
  currentRoomId,
  participantCount,
  onClick,
  t: translate,
}: IRoomCard) => {
  const classes = useStyles();

  return (
    <Box component="div" sx={classes.root} onClick={onClick}>
      <Box component="div" sx={classes.roomImageContainer}>
        {currentRoomId ? (
          currentRoomId !== room.roomId ? (
            <RoomIndicator
              icon={<Teleport />}
              text={translate('sidebar.teleport')}
            />
          ) : (
            <RoomIndicator
              icon={<PlaceIcon fontSize="inherit" color="inherit" />}
              text={translate('sidebar.here')}
            />
          )
        ) : null}
        {room.roomImage ? (
          <img src={room.roomImage.url} className="img" alt="room" />
        ) : (
          <Skeleton variant="rectangular" width="100%" height={300} />
        )}
      </Box>
      <Box component="div" sx={classes.roomNameContainer}>
        <Typography
          sx={{
            ...classes.roomName(currentRoomId === room.roomId),
            ...classes.text,
          }}
          component="div"
        >
          {room.name}
        </Typography>
        {participantCount ? (
          <Box component="div" sx={classes.online}>
            <CircleIcon />
            <Typography sx={classes.text}>{participantCount}</Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default withTranslation('common')(RoomCard);
