import React from 'react';

import ConfirmLogoutModalView from './ConfirmLogoutModalView';

import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { sendCustomSentryBreadcrumb } from '../../../../helpers/sentry';

import { IConfirmLogoutModal } from './interfaces';

const ConfirmLogoutModal = ({ open, onClose }: IConfirmLogoutModal) => {
  const { logoutUser } = useMytaverse();

  const handleExitButtonClick = () => {
    sendCustomSentryBreadcrumb({
      message: 'log out in event configuration page',
    });
    logoutUser(false);
    onClose();
  };

  return (
    <ConfirmLogoutModalView
      open={open}
      onClose={onClose}
      onExitButtonClick={handleExitButtonClick}
    />
  );
};

export default ConfirmLogoutModal;
