import { SxProps, useTheme } from '@mui/material';

import { IStyles } from '../../interfaces/styles';
import { ScreenSizes } from '../../interfaces';

export const useStyles = (): IStyles => {
  const { breakpoints } = useTheme();

  return {
    root: {
      '& div': {
        border: 'none',
      },
    },

    container: {
      width: 540,
      height: 320,
      pt: 0,
      border: 'none',
      borderRadius: '4px',
    } as SxProps,

    content: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'auto',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        mx: -1,
      },
    },
    contentWrapper: {
      height: 'calc(100% - 40px)',
    },
  };
};
