import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Button, Backdrop, Modal, Typography } from '@mui/material';

import { IConfirmLogoutView } from './interfaces';

import { useStyles } from './styles';
import { useModalStyles } from '../../../../auth/hooks/useModalStyles';
import { CloseIcon } from '../../../../../../icons';

const ConfirmModalView = ({
  message,
  open,
  onCancel,
  onConfirm,
  confirmButtonName,
  cancelButtonName,
  t: translate,
}: IConfirmLogoutView) => {
  const modalClasses = useModalStyles();

  const classes = useStyles();

  const cancelButtonSx = {
    ...modalClasses.button,
    ...modalClasses.buttonSecondary,
    ...classes.button,
    ...classes.cancelButton,
  };
  const confirmButtonSx = {
    ...modalClasses.button,
    ...modalClasses.buttonPrimary,
    ...classes.button,
  };

  return (
    <Modal
      sx={{ ...modalClasses.root, ...classes.root }}
      open={open}
      components={{ Backdrop }}
    >
      <Box
        component="div"
        sx={{ ...modalClasses.container, ...classes.container }}
      >
        <CloseIcon sx={classes.close} onClick={onCancel} />
        <Typography sx={classes.message}>{message}</Typography>
        <Box component="div" sx={classes.actions}>
          {onCancel ? (
            <Button onClick={onCancel} variant="outlined" sx={cancelButtonSx}>
              {cancelButtonName || 'No'}
            </Button>
          ) : null}
          {onConfirm ? (
            <Button onClick={onConfirm} variant="outlined" sx={confirmButtonSx}>
              {confirmButtonName || 'Yes'}
            </Button>
          ) : null}
        </Box>
      </Box>
    </Modal>
  );
};

export default withTranslation('common')(ConfirmModalView);
