import React from 'react';
import { Box, Typography } from '@mui/material';
import { WithTranslation, withTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useMytaverse } from '../../../../../../providers/MytaverseProvider';

import { IPocMessage, PocMessageType } from '../../interfaces';
import PocTag from '../../PocTag';

import { useStyles } from './styles';

interface IMessage extends WithTranslation {
  message: IPocMessage;
}

const Message = ({ message, t: translate }: IMessage) => {
  const classes = useStyles();

  const { user } = useMytaverse();

  const { type, timestamp, text } = message;
  const isAssistantMessage = type === PocMessageType.ASSISTANT;

  const userName = user?.firstName || user?.name || translate('poc.user');

  return (
    <Box sx={classes.box(isAssistantMessage)}>
      <Box sx={classes.content}>
        <Box sx={classes.header}>
          <Box sx={classes.headerName}>
            <Typography sx={classes.name}>
              {isAssistantMessage ? translate('poc.noa') : userName}
            </Typography>
            {isAssistantMessage ? <PocTag /> : null}
          </Box>

          <Typography sx={classes.time}>
            {format(new Date(timestamp), 'h:mm:ss a')}
          </Typography>
        </Box>
        <Typography sx={classes.text}>{text}</Typography>
      </Box>
    </Box>
  );
};

export default withTranslation('common')(Message);
