import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

import App from './App';

import reportWebVitals from './reportWebVitals';
import {
  checkIsFetchCategory,
  checkIsTransactionCategory,
  getSentryEnvironment,
  sanitizedBreadcrumb,
} from './helpers/sentry';

import { SentryEnvironment, SentryBreadcrumbCategory } from './interfaces';

let integrations = [];

if (getSentryEnvironment() === SentryEnvironment.Production) {
  const maskSentryReplayMedia =
    process.env.REACT_APP_SENTRY_REPLAY_MASK_MEDIA === 'true' ? true : false;
  integrations = [
    new BrowserTracing(),
    new Sentry.Breadcrumbs({ dom: false, console: true }),
    new Sentry.Replay({
      maskAllText: maskSentryReplayMedia,
      maskAllInputs: maskSentryReplayMedia,
      blockAllMedia: maskSentryReplayMedia,
      networkDetailAllowUrls: [
        window.location.origin,
        'amazonaws.com',
        'mytaverse.com',
        'pureweb.io',
        'dolby.io',
        'corewweave',
      ],
    }),
  ];
} else {
  integrations = [
    new BrowserTracing(),
    new Sentry.Breadcrumbs({ dom: false, console: true }),
  ];
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: Number(
    process.env.REACT_APP_SENTRY_REPLAY_SESSION_RATE || 0.1,
  ),
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: Number(
    process.env.REACT_APP_SENTRY_REPLAY_ERROR_RATE || 1.0,
  ),

  environment: getSentryEnvironment(),
  integrations: integrations,
  beforeBreadcrumb(breadcrumb, hint) {
    if (!breadcrumb.category || !hint) {
      return breadcrumb;
    }

    const category = breadcrumb.category as SentryBreadcrumbCategory;

    if (
      checkIsTransactionCategory(category) ||
      checkIsFetchCategory(category)
    ) {
      return null;
    }

    const updatedBreadcrumb = sanitizedBreadcrumb(breadcrumb, hint);

    return updatedBreadcrumb;
  },
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
