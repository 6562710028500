import { COLORS } from '../../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    width: '100%',
    height: '100%',
  },
  inputContainer: {
    padding: '16px 24px',
  },
  noSearchResult: {
    fontWeight: 400,
    fontSize: 22,
    lineHeight: 1.27,
    color: COLORS.WHITE,
    padding: '0px 24px',
  },
  scrollContent: {
    backgroundColor: COLORS.BACKGROUND_COLOR,
    backdropFilter: 'blur(6px)',
    padding: '8px 24px',
  },
  scrollRoot: {
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
  },
  statusIcon: {
    marginLeft: 'auto',
  },
  resultItem: (lastMessage: string) => ({
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      '& .MuiButtonBase-root': {
        display: 'block',
      },
      '.participantStatusIcon': {
        display: 'none',
      },
    },
    '&:hover .MuiTypography-root:last-of-type': {
      opacity: lastMessage ? 1 : 0.6,
    },
  }),
  resultItemName: (isUnreadChannel: boolean) => ({
    opacity: isUnreadChannel ? 1 : 0.6,
  }),
  resultItemAction: {
    display: 'none',
    marginLeft: 'auto',
  },
  loading: {
    position: 'absolute',
    right: 3,
  },
});
