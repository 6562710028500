import { useCallback } from 'react';

import GameCastService from '../../../../../services/GameCastService';

import {
  IGetStreamGroupParams,
  ILoadStreamGroupProps,
} from '../../../../../interfaces/gamecast';

const useLoadStreamGroupConfig = () => {
  const loadStreamGroup = useCallback(
    async ({ eventId }: ILoadStreamGroupProps) => {
      return (await GameCastService.getStreamGroup(eventId)) || null;
    },
    [],
  );

  const loadStreamGroupConfig = useCallback(
    async ({ eventId, streamGroupId, region }: IGetStreamGroupParams) => {
      return await GameCastService.getStreamGroupConfig({
        eventId,
        streamGroupId,
        region,
      });
    },
    [],
  );

  const loadEventStreamGroupConfig = useCallback(
    async (eventId: string) => {
      const streamGroup = await loadStreamGroup({ eventId });

      if (!streamGroup?.streamGroupId) {
        return null;
      }

      return await loadStreamGroupConfig({
        eventId,
        streamGroupId: streamGroup.streamGroupId,
        region: streamGroup.region,
      });
    },
    [loadStreamGroup, loadStreamGroupConfig],
  );

  return {
    loadEventStreamGroupConfig,
  };
};

export default useLoadStreamGroupConfig;
