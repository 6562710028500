import { Box, IconButton } from '@mui/material';

import { useStyles } from './styles';
import React, { ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface IModalBox {
  onClose: () => void;
  onBack: () => void;
  title: ReactNode;
  children: ReactNode;
}

const ModalBox = ({ children, title, onClose, onBack }: IModalBox) => {
  const classes = useStyles();

  return (
    <Box sx={classes.box}>
      <Box sx={classes.header}>
        <IconButton onClick={onBack} sx={classes.button}>
          <ArrowBackIcon />
        </IconButton>
        <IconButton onClick={onClose} sx={classes.button}>
          <CloseIcon />
        </IconButton>
      </Box>
      {title}
      {children}
    </Box>
  );
};

export default ModalBox;
