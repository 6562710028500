import React, { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';

import UserProfileModalHeader from './UserProfileModalHeader';
import UserProfileForm from './UserProfileForm';
import ParticipantProfilePreview from './ParticipantProfilePreview';
import UploadImageModal from './UploadImageModal';
import LoadingProgress from '../LoadingProgress';

import { useMytaverse } from '../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../modules/dashboard/providers';

import { checkIsAdminRole } from '../../helpers/role';
import {
  getIsAdminParticipantBusy,
  getIsParticipantBusy,
} from '../../helpers/followMe';

import AppModal from '../AppModal';

import { IUserProfileModal } from './interfaces';
import { WebsocketAction } from '../../interfaces/webSocketConnectionInfo';
import { EventRole } from '../../interfaces/event';

import { useStyles } from './styles';
import { IMytaverseFile } from '../../interfaces/file';

const ParticipantProfileModal = ({
  onClose,
  isOpen,
  enableEditing = false,
  previewingParticipant = null,
}: IUserProfileModal) => {
  const classes = useStyles();

  const [editing, setEditing] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [updImage, setUpdImage] = useState<string | File | IMytaverseFile>('');

  const { changingUserData, sendMessageToEvent, user } = useMytaverse();
  const {
    currentEvent,
    pendingFollowersData,
    acceptedFollowersData,
    currentParticipant,
    participants,
  } = useMytaverseEvent();

  const previewingParticipantData = useMemo(
    () =>
      participants.find(
        (participant) =>
          participant.participantId === previewingParticipant?.participantId,
      ),
    [participants, previewingParticipant?.participantId],
  );
  const isParticipantOnline = !!previewingParticipantData?.roomId;

  const getShowFollowMe = (): boolean => {
    const isCurrentParticipant =
      previewingParticipantData?.participantId ===
      currentParticipant?.participantId;

    if (isCurrentParticipant) {
      return false;
    }

    const followersData = pendingFollowersData.concat(acceptedFollowersData);

    const isBusyCurrentParticipant = getIsParticipantBusy(
      followersData,
      currentParticipant?.participantId || '',
    );

    if (isBusyCurrentParticipant) return false;

    const previewingParticipantRole =
      previewingParticipantData?.eventRole || EventRole.None;
    const isPreviewingParticipantAdmin = checkIsAdminRole(
      previewingParticipantRole,
    );
    const previewingParticipantId = previewingParticipant?.participantId || '';

    if (isPreviewingParticipantAdmin) {
      const isAdminPreviewingParticipantBusy = getIsAdminParticipantBusy(
        followersData,
        previewingParticipantId,
      );

      return !isAdminPreviewingParticipantBusy;
    }

    const isGuestPreviewingParticipantBusy = getIsParticipantBusy(
      followersData,
      previewingParticipantId,
    );

    return !isGuestPreviewingParticipantBusy;
  };

  const handleFollow = () => {
    sendMessageToEvent(currentEvent?.eventId || '', {
      action: WebsocketAction.FollowPlayer,
      ownerId: previewingParticipantData?.participantId,
      followerId: currentParticipant?.participantId,
      ownerName: previewingParticipantData?.fullName,
      ownerGameSessionId: previewingParticipantData?.gameSessionId,
    });
    onClose();
  };

  const handleSendMessageToEvent = () => {
    if (currentEvent?.eventId) {
      sendMessageToEvent(currentEvent?.eventId as string, {
        action: WebsocketAction.ChangeParticipantProfile,
        participantId: user.id,
      });
    }
  };

  useEffect(() => {
    if (!editing) {
      setUpdImage('');
    }
  }, [editing]);

  return (
    <AppModal isOpen={isOpen} onClick={onClose} sx={classes.container}>
      <Box sx={classes.content}>
        <UserProfileModalHeader onClose={onClose} />
        <Box sx={classes.contentWrapper}>
          {changingUserData ? (
            <LoadingProgress height="100%" />
          ) : editing ? (
            <UserProfileForm
              openUpload={openUpload}
              setOpenUpload={setOpenUpload}
              updImage={updImage}
              currentEventId={currentEvent?.eventId}
              onUpdateMessage={handleSendMessageToEvent}
              onClose={() => setEditing(false)}
            />
          ) : (
            <ParticipantProfilePreview
              user={previewingParticipant}
              enableEditing={enableEditing}
              showFollowMe={isParticipantOnline ? getShowFollowMe() : false}
              onEditClick={() => setEditing(!editing)}
              onFollow={handleFollow}
            />
          )}
        </Box>
      </Box>
      <UploadImageModal
        open={openUpload}
        setUpdImage={setUpdImage}
        setOpen={setOpenUpload}
      />
    </AppModal>
  );
};

export default ParticipantProfileModal;
