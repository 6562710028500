import { COLORS } from '../../../constants/colors';

export const useStyles = () => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      px: 1,
      py: 1.5,
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 1,
    },
    icon: {
      color: COLORS.WHITE_6,
      width: 24,
      height: 24,
    },
    title: {
      color: COLORS.WHITE,
      fontWeight: 400,
      fontSize: 18,
      lineHeight: 1.33,
      letterSpacing: '0.45px',
    },
    closeButton: {
      width: 24,
      height: 24,
    },
    closeIcon: {
      color: COLORS.WHITE_6,
    },
  };
};
