import { TabPanel } from '@mui/lab';
import React, { useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { Box, Stack, Button } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';

import HomeBottomButtonOptionsCam from '../../HomeBottomButtonOptionsCam/HomeBottomButtonOptionsCam';
import HomeBottomButtonOptionsSettings from '../../components/HomeBottomButtonOptionsSettings/HomeBottomButtonOptionsSettings';
import LoadingProgress from '../../../../../../components/LoadingProgress';
import PermissionsGuide from '../../../../../../public/images/ChromeOperation.jpg';
import { DeviceSettingVideoViewProps } from './interfaces';
import { useStyles } from './styles';
import { NavigatorPermissionsService } from '../../../../../../helpers/permissionsService';
import { useConference } from '../../../DashboardContent/Dolby';
import { useConferenceState } from '../../../../../../hooks/conferenceContext';

const DevicesSettingsVideoView = ({
  setCheckVideo,
  checkVideo,
  cameras,
  selectedCamera,
  selectCamera,
  changingDevice,
  translate,
}: DeviceSettingVideoViewProps) => {
  const classes = useStyles();
  const [showGuide, setShowGuide] = useState(false);
  const { activeCameraDeviceId } = useConferenceState();

  useAsyncEffect(async () => {
    const grantPermissions = (state: string) => {
      setShowGuide(state !== 'granted');
    };

    const permissionStatus =
      await NavigatorPermissionsService.checkAndEnsurePermission('camera');

    grantPermissions(permissionStatus.state);

    if ('onchange' in permissionStatus) {
      permissionStatus.onchange = () => {
        grantPermissions(permissionStatus.state);
      };
    }
  }, []);

  return (
    <TabPanel value="camera" sx={classes.root}>
      {showGuide ? (
        <Box sx={classes.permissionsWrapper}>
          <img src={PermissionsGuide} alt="" />
        </Box>
      ) : (
        <Box sx={classes.subRoot} component="div">
          <Stack spacing={2} sx={classes.stack}>
            <HomeBottomButtonOptionsSettings
              devices={cameras}
              handleSelect={(e) => selectCamera(e.target.value)}
              label={translate('settingsModal.camera')}
              selectedValue={selectedCamera}
              SelectIcon={VideocamIcon}
            />
          </Stack>
          <Box component="div" sx={{ width: '100%' }}>
            {changingDevice ? (
              <LoadingProgress />
            ) : checkVideo ? (
              <Button
                onClick={() => setCheckVideo(!checkVideo)}
                sx={{ width: '100%', px: 0 }}
              >
                <HomeBottomButtonOptionsCam
                  selectedCam={activeCameraDeviceId}
                />
              </Button>
            ) : (
              <Button
                onClick={() => setCheckVideo(!checkVideo)}
                sx={classes.button}
              >
                {translate('settingsModal.checkConfig')}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </TabPanel>
  );
};

export default DevicesSettingsVideoView;
