import React, { ReactNode, useCallback, useContext } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

import OfficeHoursService from '../../../../../../../services/OfficeHoursService';
import {
  IAvailableInviteSlotsParams,
  ISlot,
} from '../../../../../../../interfaces/officeHours';
import useMytaverseFetch from '../../../../../../../hooks/useMytaverseFetch';
import { MytaverseLogger } from '../../../../../../../helpers/logger';
import {
  getErrorNotification,
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../../../components/Notification';

import { IOfficeHoursContext } from './interfaces';
import { getNotification } from '../../../../../../../components/Notification/helpers';
import { useMytaverseEvent } from '../../../../../../../modules/dashboard/providers';

export const OfficeHoursContext = React.createContext<IOfficeHoursContext>(
  {} as IOfficeHoursContext,
);

export const useOfficeHours = () => useContext(OfficeHoursContext);

type MytaverseProviderProps = {
  children: ReactNode;
};

//TODO: Implement pagination currently the whole data is returned
export const OfficeHoursProvider: React.FC<MytaverseProviderProps> = ({
  children,
}) => {
  const { showNotification } = useNotification();

  const { currentEvent } = useMytaverseEvent();

  const loadSlots = useCallback(
    async (params: IAvailableInviteSlotsParams, config: AxiosRequestConfig) => {
      const { slots, lastKey } =
        await OfficeHoursService.getAvailableInviteSlots(params, config);

      return {
        data: slots,
        lastKey,
      };
    },
    [],
  );

  const loadScheduledSlots = useCallback(
    async (params: IAvailableInviteSlotsParams, config: AxiosRequestConfig) => {
      const { slots, lastKey } =
        await OfficeHoursService.getAvailableInviteSlots(
          { ...params, isScheduled: true },
          config,
        );

      return {
        data: slots,
        lastKey,
      };
    },
    [],
  );

  const {
    data: slots,
    loading: isSlotsLoading,
    callLoadData,
    setData: setSlots,
  } = useMytaverseFetch(loadSlots);

  const {
    data: scheduledSlots,
    loading: isScheduledSlotsLoading,
    callLoadData: getScheduledSlots,
    setData: setScheduledSlots,
  } = useMytaverseFetch(loadScheduledSlots);

  const resetState = useCallback(() => {
    setSlots([]);
    setScheduledSlots([]);
  }, [setScheduledSlots, setSlots]);

  const loadSlotsData = useCallback(() => {
    if (currentEvent?.eventId) {
      callLoadData({ eventId: currentEvent?.eventId });
      getScheduledSlots({ eventId: currentEvent?.eventId });
    }
  }, [currentEvent?.eventId]);

  const handleBook = useCallback(
    async (eventId: string) => {
      try {
        const { slot } = await OfficeHoursService.bookSlot(eventId);

        if (slot) {
          if (currentEvent?.eventId) {
            getScheduledSlots({ eventId: currentEvent.eventId });
          }

          setSlots((prevState: ISlot[]) =>
            prevState.map((item) => (item.eventId === eventId ? slot : item)),
          );
        }

        showNotification(
          getNotification({
            message: 'Your session was successfully booked',
            type: NOTIFICATION_TYPES.SUCCESS,
          }),
        );
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const { message, response } = error;
          MytaverseLogger.error(
            JSON.stringify({ message, data: response?.data }),
          );

          showNotification(
            getErrorNotification({
              message: 'Your session was not booked',
              closeManually: true,
            }),
          );
        }
      }
    },
    [getScheduledSlots, setSlots, currentEvent?.eventId],
  );

  const handleCancel = useCallback(
    async (eventId: string) => {
      try {
        const { slot } = await OfficeHoursService.cancelSlot(eventId);

        if (slot) {
          setScheduledSlots((prevState: ISlot[]) =>
            prevState.filter((item) => item.eventId !== eventId),
          );
          setSlots((prevState: ISlot[]) =>
            prevState.map((item) => (item.eventId === eventId ? slot : item)),
          );
        }

        showNotification(
          getNotification({
            message: 'Your session was successfully cancelled',
            type: NOTIFICATION_TYPES.SUCCESS,
          }),
        );
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const { message, response } = error;
          MytaverseLogger.error(
            JSON.stringify({ message, data: response?.data }),
          );

          showNotification(
            getErrorNotification({
              message: 'You can not cancel this session',
              closeManually: true,
            }),
          );
        }
      }
    },
    [getScheduledSlots, setSlots],
  );

  return (
    <OfficeHoursContext.Provider
      value={{
        upcomingSlots: slots ?? [],
        isSlotsLoading,
        loadUpcomingSlots: callLoadData,

        bookSlot: handleBook,
        cancelSlot: handleCancel,

        scheduledSlots: scheduledSlots ?? [],
        isScheduledSlotsLoading,
        loadScheduledSlots: getScheduledSlots,

        resetState,
        loadSlots: loadSlotsData,
      }}
    >
      {children}
    </OfficeHoursContext.Provider>
  );
};
