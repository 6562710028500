import { Box, styled, useTheme } from '@mui/material';

import { useMytaverse } from '../../../../../../providers/MytaverseProvider';
import { colorsEK } from '../../../../../../constants/colors';
import { ScreenSizes } from '../../../../../../interfaces';

export const StepBox = styled(Box)(() => {
  const { breakpoints } = useTheme();
  return {
    position: 'relative',
    width: 62,
    height: 62,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',

    '& svg': {
      fontSize: 36,
      width: '65%',
      height: '65%',
    },
    [breakpoints.down(ScreenSizes.PcLarge)]: {
      width: 46,
      height: 46,
      '& svg': {
        fontSize: 22,
      },
    },

    [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
      width: 28,
      height: 28,
      '& svg': {
        fontSize: 14,
      },
    },
  };
});

export const useStyles = () => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();

  return {
    completed: {
      background: appTheme.mainColor,
      color: colorsEK.white,
      borderColor: appTheme.mainColor,
    },
    loadingWrapper: {
      '&:before': {
        content: "''",
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        border: `4px solid ${colorsEK.grey}`,
        background: 'transparent',
        borderRadius: '50%',

        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          border: `2px solid ${colorsEK.grey}`,
        },
      },
    },
    loading: {
      color: appTheme.mainColor,

      '& svg': {
        width: '100%',
        height: '100%',
      },
    },
    default: {
      border: `4px solid ${colorsEK.grey}`,
      background: 'transparent',
      color: colorsEK.grey,

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        border: `2px solid ${colorsEK.grey}`,
      },
    },
    circle: {
      position: 'relative',
      width: 62,
      height: 62,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        fontSize: 36,
        width: '65%',
        height: '65%',
      },
      [breakpoints.down(ScreenSizes.PcLarge)]: {
        width: 46,
        height: 46,
        '& svg': {
          fontSize: 22,
        },
      },

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        width: 28,
        height: 28,
        '& svg': {
          fontSize: 14,
        },
      },
    },
    step: {
      fontWeight: 500,
      fontSize: 32,
      lineHeight: '44px',
      color: colorsEK.grey,
      [breakpoints.down(ScreenSizes.PcLarge)]: {
        fontSize: 16,
      },

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 14,
        lineHeight: '19px',
      },
    },
  };
};
