import { useTranslation } from 'react-i18next';
import HomeBarButton from '../../HomeBarButton';
import SettingsOverscanIcon from '@mui/icons-material/SettingsOverscan';
import { useMytaverseEvent } from '../../../providers';

const ToggleScreenSharingView = () => {
  const { t: translate } = useTranslation('common');
  const { isMinimizedScreenSharing, setIsMinimizedScreenSharing } =
    useMytaverseEvent();

  if (!isMinimizedScreenSharing) {
    return null;
  }

  return (
    <HomeBarButton
      handleClick={() => setIsMinimizedScreenSharing(false)}
      icon={<SettingsOverscanIcon />}
      label={translate('tooltips.expandView')}
    />
  );
};

export default ToggleScreenSharingView;
