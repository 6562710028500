import React from 'react';
import { useNavigate } from 'react-router-dom';

import SelectEventItemView from './SelectEventItemView';

import { sendCustomSentryBreadcrumb } from '../../../../../../helpers/sentry';
import ROUTES from '../../../../../../constants/routes';

import { ISelectEventItem } from './interfaces';

const SelectEventItem = ({
  event,
  eventsCount,
}: ISelectEventItem & { eventsCount: number }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    sendCustomSentryBreadcrumb({ message: `select event "${event.name}"` });
    navigate(ROUTES.AVATAR_CHOOSING(event.eventId));
  };

  return (
    <SelectEventItemView
      event={event}
      eventsCount={eventsCount}
      onClick={handleClick}
    />
  );
};

export default SelectEventItem;
