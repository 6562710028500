import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { MessageText, MessageTimestamp } from 'stream-chat-react';

import ChatQuotedMessage from '../ChatQuotedMessage';
import ChatAttachments from '../ChatAttachments';
import MessageContextMenu from './MessageContextMenu';

import { IChatMessageView } from './interfaces';

import { useStyles } from './styles';
import { useMytaverseEvent } from '../../../providers';

const ChatMessageView = ({
  message,
  isMyMessage,
  firstMessageOfGroup = false,
  t: translate,
}: IChatMessageView) => {
  const { participants } = useMytaverseEvent();

  const classes = useStyles({
    isMyMessage,
    firstMessageOfGroup,
  });

  const participant = participants.find(
    (participant) => participant.streamChatMemberId === message.user?.id,
  );

  const messageAuthor = isMyMessage
    ? translate('chatDrawer.you')
    : participant?.name || participant?.email || '';

  return (
    <Box sx={classes.root}>
      <Box sx={classes.infoContainer}>
        {firstMessageOfGroup && (
          <Box sx={classes.userContainer}>
            <Box sx={classes.userName}>{messageAuthor}</Box>
            <Box sx={classes.date}>
              <MessageTimestamp format="h:mm A" />
            </Box>
          </Box>
        )}
        <Box sx={classes.messageContainer}>
          <Box>
            <Box sx={classes.message}>
              {message.quoted_message ? (
                <ChatQuotedMessage message={message} />
              ) : (
                <MessageText />
              )}
            </Box>
            {!!message.attachments && message.attachments.length > 0 && (
              <ChatAttachments attachments={message.attachments} />
            )}
          </Box>
          <MessageContextMenu />
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation('common')(ChatMessageView);
