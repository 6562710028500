import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

import { ReactComponent as PlaneIcon } from '../../../../../../public/images/ek/plane.svg';

import { ILoadingStepIcon } from './interfaces';

import { useStyles, StepBox } from './styles';

const LoadingStepIcon = ({ loading, completed, step }: ILoadingStepIcon) => {
  const classes = useStyles();

  if (completed) {
    return (
      <StepBox sx={classes.completed}>
        {step === 4 ? <PlaneIcon /> : <DoneIcon />}
      </StepBox>
    );
  }

  if (loading) {
    return (
      <StepBox sx={classes.loadingWrapper}>
        <CircularProgress size="inherit" thickness={3} sx={classes.loading} />
        {step && (
          <Typography position="absolute" sx={classes.step}>
            {step}
          </Typography>
        )}
      </StepBox>
    );
  }

  return (
    <StepBox sx={classes.default}>
      {step && <Typography sx={classes.step}>{step}</Typography>}
    </StepBox>
  );
};

export default LoadingStepIcon;
