import { Box, SwipeableDrawer } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Chat, Streami18n, SupportedTranslations } from 'stream-chat-react';

import LoadingProgress from '../../../../components/LoadingProgress';
import ArrowButton from './ArrowButton';
import ChatHeader from './ChatHeader';
import ChatTabs from './ChatTabs';
import EventTab from './EventTab';
import PrivateTab from './PrivateTab';

import { checkIsGlobalTab } from './helpers';
import { IChatDrawerView } from './interfaces';

import 'stream-chat-react/dist/css/index.css';
import { useStyles } from './styles';
import { createGlobalStyle } from 'styled-components';
import { isDesktop } from 'react-device-detect';

// SwipeableDrawer doesn't have sx prop implemented
// so we use global style to override it and add 20px margin
// to prevent scrollbar being stuck behind SwipeableDrawer on desktop
const GlobalStyle = createGlobalStyle<{ $addMarginRight: boolean; }>`
  .PrivateSwipeArea-root {
    &:nth-child(2) {
      margin-right: ${props => (props.$addMarginRight ? '20px' : '0')};
    }
  }
`;

const ChatDrawerView = ({
  isOpenDrawer,
  toggleDrawer,
  streamClient,
  isLoading,
  language,
  activeTab,
  hasScreenMediaStreams,
  isExpanded,
  setIsExpanded,
  autoClose,
}: IChatDrawerView) => {
  const [isHovered, setIsHovered] = useState(false);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const isGlobalTab = checkIsGlobalTab(activeTab);
  const classes = useStyles({ isLoading, hasScreenMediaStreams, isExpanded });
  const i18nInstance = new Streami18n({
    language: language.toLowerCase() as SupportedTranslations,
  });

  useEffect(() => {
    const waitTime = parseFloat(
      process.env.REACT_APP_CHAT_AUTO_CLOSE_TIMEOUT_SECONDS || '120',
    );

    if (!isHovered && timeoutIdRef.current === null) {
      timeoutIdRef.current = setTimeout(() => {
        autoClose();
        timeoutIdRef.current = null;
      }, waitTime * 1000);
    }

    if (isHovered && timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    };
  }, [isHovered, autoClose]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <GlobalStyle $addMarginRight={isDesktop} />
      <SwipeableDrawer
        anchor="right"
        open={isOpenDrawer}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        hideBackdrop
        disableBackdropTransition
        sx={classes.root}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Chat client={streamClient} darkMode i18nInstance={i18nInstance}>
          <ChatHeader />
          {isLoading ? (
            <LoadingProgress />
          ) : (
            <>
              <ChatTabs sx={classes.tabs} />
              {isGlobalTab ? <EventTab /> : <PrivateTab />}
            </>
          )}
        </Chat>
        <Box sx={classes.collapseWrap}>
          <ArrowButton
            onClick={() => setIsExpanded(!isExpanded)}
            forwardIcon={isExpanded}
            sx={classes.collapse}
          />
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default ChatDrawerView;
