import { COLORS, colors } from '../../../constants/colors';

import { IStyles } from '../../../interfaces/styles';

export const classes: IStyles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 2,
    py: 1,
    background: colors.gray,
    '& p': {
      color: COLORS.WHITE,
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 500,
      ml: 1,
    },
    '& svg': {
      color: COLORS.WHITE,
    },
  },
  iconButton: {
    p: 0,
  },
  closeIcon: {
    color: COLORS.MEDIUM_GREY,
  },
};
