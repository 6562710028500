import React, { useState } from 'react';
import { Box } from '@mui/material';

import { keyboardLayout, groupMappings } from './constants';

import KeyButton from './KeyButton';
import HelpBox from './HelpBox';

import { useStyles } from './styles';

const Keyboard = () => {
  const [highlightedKeys, setHighlightedKeys] = useState<string[]>([]);

  const classes = useStyles();
  const handleKeyPress = (key: string) => {
    if (!key) {
      return;
    }

    const group = groupMappings[key] || [];
    setHighlightedKeys(group);
  };

  return (
    <Box sx={classes.root}>
      <Box sx={classes.keyboard}>
        {keyboardLayout.map((row, rowIndex) => (
          <Box key={rowIndex} sx={classes.row}>
            {row.map((key, keyIndex) => (
              <KeyButton
                key={keyIndex}
                name={key}
                isActive={highlightedKeys.includes(key)}
                onClick={() => handleKeyPress(key)}
              />
            ))}
          </Box>
        ))}
      </Box>

      <HelpBox helpers={highlightedKeys} />
    </Box>
  );
};

export default Keyboard;
