import React, { useCallback } from 'react';

import { Box } from '@mui/material';

import RoomCard from './RoomCard';

import { useMytaverseEvent } from '../../../../providers';

import { sendCustomSentryBreadcrumb } from '../../../../../../helpers/sentry';

import { IRoom } from '../../../../../../interfaces/rooms';
import { GetRoomParticipantCountType, IRoomsTab } from './interfaces';

const RoomsTab = ({ rooms, participants }: IRoomsTab) => {
  const {
    teleportToRoom,
    closeLeftMenu,
    currentRoom,
    shareVideoPublishers,
    shareScreenPublishers,
    setOpenTeleportToRoomDialog,
    setSelectedTeleportRoom,
  } = useMytaverseEvent();

  const currentRoomId = currentRoom ? currentRoom.roomId : '';

  const getRoomParticipantCount: GetRoomParticipantCountType = (roomId) => {
    const count = participants.filter(
      (participant) => participant.roomId === roomId,
    ).length;

    return count;
  };

  const onRoomClick = useCallback(
    (room: IRoom) => {
      if (currentRoomId && currentRoomId !== room.roomId) {
        sendCustomSentryBreadcrumb({
          message: `change room id=${room.roomId}, name=${room.name}`,
        });
        teleportToRoom(room);
        closeLeftMenu();
      }
    },
    [teleportToRoom, closeLeftMenu, currentRoomId],
  );

  const handleSharingModalTeleport = (room: IRoom) => {
    if (!room) {
      return;
    }
    setSelectedTeleportRoom(room);
    setOpenTeleportToRoomDialog(true);
  };

  return (
    <Box component="div">
      {rooms.map((room) => (
        <RoomCard
          key={room.roomId}
          room={room}
          currentRoomId={currentRoomId}
          participantCount={getRoomParticipantCount(room.roomId)}
          onClick={() =>
            (shareScreenPublishers && shareScreenPublishers.length) ||
            shareVideoPublishers
              ? handleSharingModalTeleport(room)
              : onRoomClick(room)
          }
        />
      ))}
    </Box>
  );
};

export default RoomsTab;
