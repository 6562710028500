export const EscKey = 'Spacebar';

export const keyTextMappings: { [key: string]: string } = {
  W: 'Walk Forward',
  S: 'Move Backward',
  A: 'Move Left',
  D: 'Move Right',

  Q: 'Ask Question',
  T: 'Teleport',
  Y: 'Yes',
  N: 'No',

  H: 'Spread Love',
  J: 'Dance',
  //Z: 'Hoverboard',
  C: 'Toggle view',

  B: 'Bow',
  M: 'Control panel',
  //[EscKey]: 'Jump',

  1: 'Wave Hand',
  2: 'Applaud',
  3: 'Celebrate',
  6: 'Shake Head',

  7: 'Show off',
  8: 'Laugh',
  9: 'Thumbs Up',
  0: 'Thumbs Down',
};

export const keyboardLayout = [
  ['1', '2', '3', '', '6', '7', '8', '9', '0'],
  ['Q', 'W', '', '', 'T', 'Y', '', '', '', ''],
  ['A', 'S', 'D', '', '', 'H', 'J', '', ''],
  ['', '', 'C', '', 'B', 'N', 'M'],
  ['Spacebar'],
];

export const groupMappings: { [key: string]: string[] } = {
  W: ['W', 'S', 'A', 'D'],
  S: ['W', 'S', 'A', 'D'],
  A: ['W', 'S', 'A', 'D'],
  D: ['W', 'S', 'A', 'D'],

  Q: ['Q', 'T', 'Y', 'N'],
  T: ['Q', 'T', 'Y', 'N'],
  Y: ['Q', 'T', 'Y', 'N'],
  N: ['Q', 'T', 'Y', 'N'],

  H: ['H', 'J', 'C'],
  J: ['H', 'J', 'C'],
  C: ['H', 'J', 'C'],

  B: ['B', 'M'],
  M: ['B', 'M'],
  //[EscKey]: ['B', 'M', EscKey],

  1: ['1', '2', '3', '6'],
  2: ['1', '2', '3', '6'],
  3: ['1', '2', '3', '6'],
  6: ['1', '2', '3', '6'],

  7: ['7', '8', '9', '0'],
  8: ['7', '8', '9', '0'],
  9: ['7', '8', '9', '0'],
  0: ['7', '8', '9', '0'],
};
