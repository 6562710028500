import React from 'react';
import { isDesktop } from 'react-device-detect';

import { useFullscreen } from '../../../../modules/dashboard/hooks/fullscreen';
import { sendCustomSentryBreadcrumb } from '../../../../helpers/sentry';

import FullScreenButtonView from './FullScreenButtonView';

const FullScreenButton = () => {
  const { toggleFullscreen, isFullscreen } = useFullscreen();

  const handleFullscreenClick = () => {
    sendCustomSentryBreadcrumb({ message: 'show in full screen' });
    toggleFullscreen();
  };

  if (isDesktop) {
    return (
      <FullScreenButtonView
        isFullscreen={isFullscreen}
        onClick={handleFullscreenClick}
      />
    );
  }

  return null;
};

export default FullScreenButton;
