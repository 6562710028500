import React, { useState } from 'react';

import UserAvatarButtonView from './UserAvatarButtonView';

import { useMytaverse } from '../../providers/MytaverseProvider';

const UserAvatarButton = () => {
  const [showUserSettingModal, setShowUserSettingModal] = useState(false);

  const { user } = useMytaverse();

  if (!user) {
    return null;
  }

  return (
    <UserAvatarButtonView
      profileImage={user.profileImage.url}
      showUserSettingModal={showUserSettingModal}
      setShowUserSettingModal={setShowUserSettingModal}
    />
  );
};

export default UserAvatarButton;
