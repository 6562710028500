import React, { useState, useCallback } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import ParticipantItem from '../ParticipantItem';

import { IParticipantList } from './interfaces';

import { useStyles } from './styles';

const ParticipantList = ({
  name,
  participants,
  currentParticipantId,
  isCollapsible = false,
}: IParticipantList) => {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpanded = useCallback(
    () => setIsExpanded((prevState) => !prevState),
    [],
  );

  if (isCollapsible) {
    const Icon = isExpanded ? ArrowDropUpIcon : ArrowDropDownIcon;

    return (
      <Box component="div" position="relative">
        <IconButton
          onClick={handleExpanded}
          aria-expanded={isExpanded}
          disableRipple
          sx={classes.button}
        >
          <Box sx={classes.buttonLabel}>
            <Typography fontSize="inherit">{name}</Typography>
            <Icon />
          </Box>
        </IconButton>

        {isExpanded
          ? participants.map((participant) => (
              <ParticipantItem
                key={participant.participantId}
                participant={participant}
                currentParticipantId={currentParticipantId}
              />
            ))
          : null}
      </Box>
    );
  }

  return (
    <Box component="div">
      <Box sx={classes.group} component="div">
        <Typography sx={classes.groupName}>{name}</Typography>
      </Box>
      {participants.map((participant) => (
        <ParticipantItem
          key={participant.participantId}
          participant={participant}
          currentParticipantId={currentParticipantId}
        />
      ))}
    </Box>
  );
};

export default ParticipantList;
