import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { IHeader } from './interfaces';
import { useStyles } from './styles';

const DrawerHeader = ({ title, icon: Icon, onClick }: IHeader) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root} component="div">
      <Box sx={classes.titleContainer} component="div">
        {!!Icon && <Icon sx={classes.icon} />}
        {title && <Typography sx={classes.title}>{title}</Typography>}
      </Box>

      <IconButton sx={classes.closeButton} onClick={onClick}>
        <CloseIcon sx={classes.closeIcon} />
      </IconButton>
    </Box>
  );
};

export default DrawerHeader;
