import { colors } from '../../../../constants/colors';

export const useStyles = () => ({
  root: {
    width: 500,
    height: 400,
    background: colors.gray,
    position: 'absolute',
    zIndex: 100,

    '& .MuiTabs-root': {
      width: '100%',
    },

    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },

    '& .MuiTab-root': {
      color: 'white',
      width: '50%',
      textTransform: 'capitalize',
    },

    '& .Mui-selected': {
      fontWeight: 'bold',
    },

    '& .MuiTabs-indicator': {
      color: colors.mytaLight,
    },
  },
});
