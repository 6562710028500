import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ImagePreview from './ImagePreview';
import VideoPreview from './VideoPreview';

import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../components/Notification';
import { useMytaverseEvent } from '../../providers';

import { sendCustomSentryBreadcrumb } from '../../../../helpers/sentry';

import { PoiMediaType } from '../DashboardContent/interfaces';

const { AUDIO, IMAGE, VIDEO, WEB_BROWSER } = PoiMediaType;

export const usePointsOfInterestsPreview = () => {
  const { showNotification } = useNotification();
  const { setPoiPreviewSrc, poiPreviewSrc } = useMytaverseEvent();
  const { t: translate } = useTranslation('common');

  const [component, setComponent] = useState<JSX.Element | null>(null);
  const [selectedPoiType, setSelectedPoiType] = useState<PoiMediaType | null>(
    null,
  );

  const handleClick = () => {
    setPoiPreviewSrc(null);
    setSelectedPoiType(null);
  };

  useEffect(() => {
    if (!poiPreviewSrc) {
      setComponent(null);
      return;
    }

    if (!poiPreviewSrc.downloadUrl && poiPreviewSrc.mediaType) {
      showNotification({
        message: translate('notifications.poiUrlError'),
        type: NOTIFICATION_TYPES.WARNING,
      });
      sendCustomSentryBreadcrumb({ message: 'POI url error' });

      return;
    }

    const downloadUrl = poiPreviewSrc.downloadUrl as string;

    switch (poiPreviewSrc.mediaType) {
      case VIDEO:
        setComponent(<VideoPreview src={downloadUrl} />);
        setSelectedPoiType(VIDEO);
        break;
      case IMAGE:
        setComponent(
          <ImagePreview downloadUrl={downloadUrl}>
            <img src={poiPreviewSrc.downloadUrl} alt="" />
          </ImagePreview>,
        );
        setSelectedPoiType(IMAGE);
        break;
      case WEB_BROWSER:
        setComponent(<iframe name="poi_view" src={downloadUrl} />);
        setSelectedPoiType(WEB_BROWSER);
        break;
      // case FILE:
      //   setComponent(<iframe name="poi_view" src={downloadUrl} />);
      //   setSelectedPoiType(FILE);
      //   break;
      default:
        break;
    }
  }, [poiPreviewSrc]);

  return {
    handleClick,
    component,
    baseUrl: poiPreviewSrc ? poiPreviewSrc.downloadUrl : null,
    poiName: poiPreviewSrc ? poiPreviewSrc.fileName : 'POI',
    selectedPoiType,
  };
};
