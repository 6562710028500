import React from 'react';
import { Box } from '@mui/material';
import { PixelStreamingWrapper } from './PixelStreamingWrapper';

const CoreWeave = () => {
  return (
    <Box>
      <PixelStreamingWrapper />
    </Box>
  );
};

export default CoreWeave;
