import { COLORS } from '../../../../../../constants/colors';
import { IStyles } from '../../../../../../interfaces/styles';

export const useStyles = (): IStyles => {
  return {
    root: {
      mt: 4,
      width: '100%',
    },

    label: {
      color: COLORS.WHITE,
      border: 'none',
      width: '100% !important',
      maxWidth: '480px',
      textAlign: 'left',
      left: '-15px !important',
    },

    select: {
      flexDirection: 'row-reverse',
      width: '100%',
      height: 40,
      mt: 8,
      color: COLORS.WHITE,
      '& .MuiInput-input': {
        textAlign: 'center',
        ml: 1,
      },

      '&.Mui-disabled': {
        color: COLORS.WHITE,
        cursor: 'no-drop',
      },
      '& .MuiInput-input.Mui-disabled': {
        cursor: 'no-drop',
        WebkitTextFillColor: 'unset',
      },

      '&.MuiInputBase-formControl': {
        border: 1,
        borderColor: COLORS.GREY,
        borderRadius: 1,
      },

      '& svg': {
        color: COLORS.WHITE,
      },
      '& .selectIcon': {
        ml: 1,
        opacity: 0.5,
        color: COLORS.AZURE,
      },
    },
  };
};
