import { useTheme } from '@mui/material';

import { COLORS } from '../../../constants/colors';
import { ScreenSizes } from '../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      mb: 2,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        mb: 1.5,
      },
    },
    icon: {
      mr: 1.8,
      width: 17,
      height: 17,
      color: COLORS.WHITE,
    },
  };
};
