import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTimer } from '../../hooks/timer';
import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { getCatchErrorMessage } from '../../../../helpers/error';

import LoginService from '../../../../services/LoginService';

import ROUTES from '../../../../constants/routes';

export const useConfirmEmail = () => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(true);

  const { token } = useParams<{ token: string }>();

  const timer = useTimer();

  const confirmEmail = useCallback(async (): Promise<void> => {
    try {
      await LoginService.confirmEmail(token as string);
    } catch (error: unknown) {
      throw Error(getCatchErrorMessage(error));
    } finally {
      setIsConfirmLoading(false);
    }
  }, [token]);

  useEffect(() => {
    if (!token) return;

    confirmEmail();
  }, [confirmEmail, token]);

  return { isConfirmLoading, timer };
};
