export const BOLD_FONT = 'g_d0_f1';
export const EXCEPTIONS = [
  'Policy',
  'linked',
  'hereby',
  'Mytaverse',
  'platform',
];
export const EXCEPTIONS_LISTS = ['third', 'grant', 'your', ' in'];
export const EXCEPTIONS_NEW_ROW = [
  'Mytaverse)',
  'Mytaverse’s',
  'Service,',
  'Party',
  'Adult',
  'Maturity',
  'Content',
  'Privacy',
  'Intellectual',
  'User',
];
