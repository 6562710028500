import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMytaverseEvent } from '../../../../providers';

import { getProgressStepTitle } from '../../../AppLoader';
import { useZoom } from '../../Zoom/ZoomProvider';

export const useCoreWeaveProgressBar = ({
  setShowNavigation,
}: {
  setShowNavigation: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { zmClient } = useZoom();
  const { initMessageSended, currentRoom } = useMytaverseEvent();
  const { t: translate } = useTranslation('common');

  const secondStepLoading = !zmClient;
  const secondStepCompleted = !!zmClient;
  const thirdStepLoading = secondStepCompleted && !initMessageSended;
  const thirdStepCompleted = secondStepCompleted && initMessageSended;
  const forthStepLoading = thirdStepCompleted && !currentRoom;
  const forthStepCompleted = thirdStepCompleted && !!currentRoom;
  const title = getProgressStepTitle({
    secondStepLoading,
    thirdStepLoading,
  });

  useEffect(() => {
    if (forthStepCompleted) {
      setShowNavigation(true);
    }
  }, [forthStepCompleted]);

  return {
    secondStepLoading,
    secondStepCompleted,
    thirdStepLoading,
    thirdStepCompleted,
    forthStepLoading,
    forthStepCompleted,
    title: translate(title),
  };
};
