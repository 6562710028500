import { IStyles } from '../../../../../interfaces/styles';
import { colorsEK } from '../../../../../constants/colors';

export const classes: IStyles = {
  emptyPlaceholder: {
    fontWeight: 400,
    fontSize: [26, 30],
    textAlign: ['center', 'center'],
    mt: [4, 10],
    mb: 5,
  },

  version: {
    position: 'fixed',
    bottom: 16,
    left: 16,
    fontSize: 10,
    paddingX: 1,
    paddingY: 0.5,
    borderRadius: 2,
    background: 'rgba(184, 184, 184, 0.1)',
    fill: colorsEK.grey_300,
  },
};
