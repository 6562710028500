import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { keyTextMappings } from '../constants';

import KeyButton from '../KeyButton';

import { IHelpBox } from './interfaces';
import { useStyles } from './styles';

const HelpBox = ({ helpers, t: translate }: IHelpBox) => {
  const classes = useStyles();

  const render = () => {
    if (!helpers.length) {
      return (
        <Typography sx={classes.text}>
          {translate('ek.keyboard.helper')}
        </Typography>
      );
    }

    return helpers.map((key: string) => (
      <Box key={key} sx={classes.helper}>
        <KeyButton name={key} isActive />
        <Typography sx={classes.helperText}>{keyTextMappings[key]}</Typography>
      </Box>
    ));
  };

  return <Box sx={classes.root}>{render()}</Box>;
};

export default withTranslation('common')(HelpBox);
