import { MouseEvent, useState } from 'react';

import { useDrawer } from '../../../../components/Drawer';

export const useToggleIconPopover = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    evt.preventDefault();
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    handleClick,
    handleClose,
  };
};

export const useToggleIconPopoverDrawer = (isDrawer: boolean) => {
  const { anchorEl, handleClick, handleClose } = useToggleIconPopover();
  const { open: openDrawer, toggleDrawer, closeDrawer } = useDrawer();

  if (isDrawer) {
    const handleClick = (evt: MouseEvent<HTMLButtonElement>) => {
      evt.stopPropagation();
      evt.preventDefault();
      toggleDrawer();
    };

    return {
      open: openDrawer,
      handleClick,
      handleClose: closeDrawer,
      anchorEl: null,
    };
  }

  return { open: !!anchorEl, anchorEl, handleClick, handleClose };
};
