import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    content: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '16px 54px',
      justifyContent: 'center',
      padding: '8px 26px',
      height: '100%',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: '8px 16px',
      },
    },
  };
};
