import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import ScreenWrapper from './ScreenWrapper';
import { useStyles } from './styles';
import useAvaturnValidate from '../hooks/avatar';
import { useMytaverse } from '../../../../../../providers/MytaverseProvider';

interface IFinalScreen extends WithTranslation {
  onClick: () => void;
  onSkipClick: () => void;
}

const FinalScreen = ({ onClick, onSkipClick, t: translate }: IFinalScreen) => {
  const classes = useStyles();

  const { currentAvatarId, customAvatarUrl } = useMytaverse();
  const { isAvaturnValid, isProcessing } = useAvaturnValidate();

  let skipDisabled: boolean;
  let buttonTranslate: string;

  if (currentAvatarId && (!customAvatarUrl || !isAvaturnValid)) {
    skipDisabled = false;
    buttonTranslate = translate('ek.selectAvatar.customiseButton');
  } else if (currentAvatarId && customAvatarUrl && isAvaturnValid) {
    skipDisabled = false;
    buttonTranslate = translate('ek.selectAvatar.customiseButton');
  } else {
    skipDisabled = false;
    buttonTranslate = translate('ek.selectAvatar.createButton');
  }

  return (
    <ScreenWrapper
      title={translate('ek.selectAvatar.finalScreen.title')}
      subtitle={translate('ek.selectAvatar.finalScreen.description1')}
      secondarySubtitle={translate('ek.selectAvatar.finalScreen.description2')}
      actions={
        <>
          <Button
            onClick={onClick}
            sx={{ ...classes.button, ...classes.buttonSecondary }}
          >
            {buttonTranslate}
          </Button>
          <LoadingButton
            onClick={onSkipClick}
            sx={classes.button}
            disabled={skipDisabled}
            loading={isProcessing}
          >
            {translate('ek.selectAvatar.skipButton')}
          </LoadingButton>
        </>
      }
    />
  );
};

export default withTranslation('common')(FinalScreen);
