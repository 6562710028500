import { useMemo } from 'react';
import { COLORS } from '../../../../../../../constants/colors';
import { IStyles } from '../../../../../../../interfaces/styles';

export const useStyles = () =>
  useMemo(
    () => ({
      root: {
        display: 'flex',
        mt: 3,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:hover': {
          '.onlineIndicator': {
            display: 'none',
          },
          '.helpers': {
            display: 'flex',
          },
          '& p': {
            color: COLORS.WHITE,
          },

          cursor: 'pointer',
        },
      },

      rootPosition: {
        display: 'flex',
        alignItems: 'center',
      },

      avatar: {
        width: 40,
        height: 40,
      },

      username: {
        color: COLORS.LIGHT_GREY,
        fontSize: 20,
        ml: 2,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textTransform: 'capitalize',
        maxWidth: 335,
        textOverflow: 'ellipsis',

        '@media (max-width: 1650px)': {
          maxWidth: 250,
        },

        '@media (max-width: 1340px)': {
          maxWidth: 180,
        },

        '@media (max-width: 962px)': {
          fontSize: 18,
        },

        '@media (max-width: 800px)': {
          maxWidth: 400,
        },

        '@media (max-width: 480px)': {
          maxWidth: 200,
        },

        '@media (max-width: 350px)': {
          maxWidth: 120,
        },
      } as IStyles,

      statusIcon: {
        mr: 1.6,
      },

      micIcon: {
        color: COLORS.WHITE,
        ml: 1,
      },

      tools: {
        display: 'none',
      },

      iconButton: {
        p: 0,
        width: 40,
        height: 40,
        minWidth: 0,
        display: 'block',
        opacity: 0.6,
        '&:hover': {
          opacity: 1,
        },
      },

      icon: {
        color: COLORS.WHITE,
        opacity: 0.6,
        '&:hover': {
          opacity: 1,
        },
      },
    }),
    [],
  );
