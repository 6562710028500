import { StreamChat } from 'stream-chat';
import { DefaultStreamChatGenerics as StreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { Channel } from 'stream-chat/dist/types/channel';
import { StreamMessage } from 'stream-chat-react/dist/context/ChannelStateContext';

export type IStreamChat = StreamChat<StreamChatGenerics>;

export type ISetStreamChat = (streamChat: IStreamChat | null) => void;

export type IStreamChannel = Channel<StreamChatGenerics>;

export type ISetStreamChannel = (
  eventStreamChannel: IStreamChannel | null,
) => void;

export type IStreamMessage = StreamMessage<StreamChatGenerics>;

export interface IEventChannel {
  id: string;
  eventId: string;
  createdAt: string;
  updatedAt: string;
  userIds: string[];
}

export interface IPrivateChannel extends IEventChannel {
  channel: IStreamChannel;
}

export type IActivePrivateChannel = IPrivateChannel | null;

export interface GetChannelProps {
  streamClient: IStreamChat;
  userId: string;
  eventId: string;
  channelId?: string;
}

export enum ChatTabs {
  Global = 'global',
  Private = 'private',
}
