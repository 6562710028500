import { colorsEK } from '../../../../../../../constants/colors';

export const useStyles = () => {
  return {
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
      marginX: 'auto',
      marginY: 4,
      width: 'fit-content',

      '@media (max-width: 767px)': {
        gap: 6,
        marginY: 3,
      },
    },
    control: {
      display: 'flex',
      flexDirection: 'row',
      gap: 3,
      alignItems: 'center',

      '@media (max-width: 767px)': {
        flexDirection: 'column',
        alignItems: 'start',
      },
    },
    label: {
      fontSize: '20px',
      lineHeight: 1,
      color: colorsEK.black,
      width: '98px',
      textAlign: 'right',
      '&.Mui-focused': {
        color: colorsEK.black,
      },
      '@media (max-width: 767px)': {
        width: 'auto',
      },
    },
    controlLabel: (isChecked = false) => ({
      marginX: 0,
      '& .MuiFormControlLabel-label': {
        fontSize: '20px',
        lineHeight: 1,
        color: isChecked ? colorsEK.black : colorsEK.grey_300,
      },
    }),
    genderControl: {
      gap: 4,
    },
    skinControl: {
      gap: 1,
      '@media (max-width: 767px)': {
        gap: 0,
      },
    },
  };
};
