import { useMytaverse } from '../../../../../../providers/MytaverseProvider';
import { colorsEK } from '../../../../../../constants/colors';

export const useStyles = () => {
  const { appTheme } = useMytaverse();

  const {
    submitButton: { backgroundColorHover },
  } = appTheme.avatarPage.assets;

  return {
    text: {
      fontFamily: '"Noto Sans", sans-serif',
      fontSize: 16,
      fontWeight: 300,
      lineHeight: 1.4,
      textAlign: 'left',
      width: '100%',
      letterSpacing: '0.27px',
      color: colorsEK.black,
    },
    link: {
      color: colorsEK.black,
      textDecorationColor: colorsEK.black,
      textDecorationThickness: '0.2px',
    },
  };
};
