import {
  IParticipant,
  IParticipantInfo,
  IParticipantRoles,
} from '../interfaces/participants';
import { SpatialType } from '../interfaces/webSocketConnectionInfo';
import { EventRole } from '../interfaces/event';

export const getInitialMapParticipants = (
  participants: IParticipant[],
  participantsInfo: IParticipantInfo[],
  participantsRoles: IParticipantRoles,
): IParticipant[] => {
  const mapParticipants = participants.map((participant) => {
    const participantRoom = participantsInfo.find(
      (participantInfo) => participantInfo.participantId === participant.participantId,
    );

    if (participantRoom) {
      participant.roomId = participantRoom.roomId;
      participant.region = participantRoom.region
        ? {
            region: participantRoom.region,
            timestamp: Date.now(),
            state: 'joined',
            regionSpatialType: SpatialType.SpatialAudio,
          }
        : null;
      participant.gameSessionId = participantRoom.gameSessionId || null;
    }

    participant.eventRole = getParticipantRole(
      participant.participantId,
      participantsRoles,
    );

    return participant;
  });

  return mapParticipants;
};

const getParticipantRole = (
  participantUserId: string,
  participantsRoles: IParticipantRoles,
): EventRole => {
  if (!participantsRoles) {
    return EventRole.None;
  }

  const isAdmin =
    participantsRoles && participantsRoles.admin
      ? participantsRoles.admin.includes(participantUserId)
      : false;

  if (isAdmin) return EventRole.Admin;

  const isModerator =
    participantsRoles && participantsRoles.moderator
      ? participantsRoles?.moderator.includes(participantUserId)
      : false;

  if (isModerator) return EventRole.Moderator;

  const isCoordinator =
    participantsRoles && participantsRoles.coordinator
      ? participantsRoles?.coordinator.includes(participantUserId)
      : false;

  if (isCoordinator) return EventRole.Coordinator;

  return EventRole.None;
};
