import React, { useEffect } from 'react';

import HomeBottomButtonOptionsMicView from './HomeBottomButtonOptionsMicView';

import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../../../../../components/Notification';

const HomeBottomButtonOptionsMic = ({ selectedMicrophone }: any) => {
  const [level, setLevel] = React.useState<number>(0);

  const { showNotification } = useNotification();

  useEffect(() => {
    const timeoutIDs: NodeJS.Timer[] = [];
    let unmuted = false;

    navigator.mediaDevices
      .getUserMedia({
        audio: { deviceId: { exact: selectedMicrophone } },
        video: false,
      })
      .then((stream) => {
        const context = new AudioContext();
        const src = context.createMediaStreamSource(stream);
        const analyser = context.createAnalyser();
        src.connect(analyser);
        analyser.fftSize = 64;

        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        function renderFrame() {
          if (unmuted) {
            return;
          }

          requestAnimationFrame(renderFrame);
          analyser.getByteFrequencyData(dataArray);

          for (let i = 0; i < bufferLength; i++) {
            const timeoutID = setTimeout(() => {
              if (unmuted) {
                return;
              }
              setLevel(() => dataArray[i]);
            }, 12);

            timeoutIDs.push(timeoutID);
          }
        }
        renderFrame();
      })
      .catch(() => {
        showNotification({
          message: 'Something wrong with your mic',
          type: NOTIFICATION_TYPES.ERROR,
        });
      });

    return () => {
      unmuted = true;

      setTimeout(() => {
        timeoutIDs.map((timeoutID) => clearTimeout(timeoutID));
      }, 0);
    };
  }, []);

  return <HomeBottomButtonOptionsMicView level={level} />;
};

export default HomeBottomButtonOptionsMic;
