import { useTheme } from '@mui/material';
import { isDesktop } from 'react-device-detect';

import { colors } from '../../../../../constants/colors';
import { IStyles } from '../../../../../interfaces/styles';
import { ScreenSizes } from '../../../../../interfaces';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';

export const useStyles = (): IStyles => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();

  const titleColor = appTheme.loginPage.assets.titleColor;

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: isDesktop ? '52px' : '45px',
      marginTop: isDesktop ? '58px' : '0px',

      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 980px)`]: {
        rowGap: '12px',
      },
    },

    layoutContainer: {
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: '0 30px',
      },

      '& form': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 3,
        mb: 3,
      },
    },
    formTitle: {
      fontFamily: 'Emirates-Medium !important',
      fontSize: 32,
      lineHeight: 1.5,
      color: titleColor,
      mb: 3,
      textAlign: 'center',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 24,
      },
    },
    link: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: '0.1px',
      color: colors.oxford,
      opacity: 0.4,
      textDecoration: 'none',
    },
    divider: {
      mb: 2,
      '& .MuiDivider-wrapper': {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 1.43,
        letterSpacing: '0.1px',
        color: colors.oxford,
        opacity: 0.6,
      },
      '&::before': {
        borderTop: `1px solid ${colors.oxford_20}`,
      },
      '&::after': {
        borderTop: `1px solid ${colors.oxford_20}`,
      },
    },
    socialButtonsContainer: {
      display: 'flex',
      columnGap: '17px',
    },
    button: {
      fontSize: 16,
      lineHeight: 20,
    },
  };
};
