import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Typography, Hidden } from '@mui/material';

import { useMytaverseEvent } from '../../../../../modules/dashboard/providers';
import Media from '../../../../../modules/dashboard/components/AppLoader/Media';

import LoadingProgress from '../../../../../components/LoadingProgress';
import AppHeader from '../../../components/AppHeader';
import { IAppLoader } from './interfaces';

import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../../constants/routes';

const AppLoader = ({
  children,
  t: translate,
  isLandscape = false,
}: IAppLoader) => {
  const classes = useStyles();

  const { currentEvent, setShowControls, currentEventId } = useMytaverseEvent();

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(ROUTES.DEVICES_TEST(currentEventId));
  };

  useEffect(() => {
    setShowControls(false);

    return () => {
      setShowControls(true);
    };
  }, []);

  return (
    <Box sx={classes.root}>
      <AppHeader
        isAuthorized
        onBackClick={handleClick}
        isHideLogo={isLandscape}
      />
      <Box sx={classes.content}>
        <Box sx={classes.textWrapper}>
          <Typography sx={classes.title}>
            {translate('ek.loading.launchingExperience')}{' '}
            <Typography sx={classes.title} component="span">
              {translate('ek.loading.launchingExperienceBold')}
            </Typography>
          </Typography>
          <Typography sx={classes.text}>
            {translate('ek.loading.text1')}
          </Typography>
          <Hidden lgDown>
            <Typography sx={classes.text}>
              {translate('ek.loading.text2')}
            </Typography>
          </Hidden>
        </Box>

        <Box sx={classes.media}>
          {currentEvent && !!children ? (
            <Media loadingAsset={currentEvent?.loadingScreenAsset} />
          ) : (
            <LoadingProgress height="100%" />
          )}
        </Box>
        <Box sx={classes.progress}>{children}</Box>
      </Box>
    </Box>
  );
};

export default withTranslation('common')(AppLoader);
