import React from 'react';

import { embedYouTubeUrl } from '../constants';
import { IYoutubeEmbed } from '../interfaces';

import { Iframe, VideoResponsive } from './style';

const YoutubeEmbed = ({ embedId }: IYoutubeEmbed) => (
  <VideoResponsive>
    <Iframe
      width="400"
      height="400"
      src={`${embedYouTubeUrl}${embedId}?modestbranding=1&rel=0&showinfo=0&autoplay=1`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="YouTube Video"
    />
  </VideoResponsive>
);

export default YoutubeEmbed;
