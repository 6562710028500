import React, { useCallback } from 'react';

import { SelectChangeEvent } from '@mui/material';

import SingleSelect from '../../SingleSelect';

import { useMytaverseEvent } from '../../../modules/dashboard/providers';

import { sendCustomSentryBreadcrumb } from '../../../helpers/sentry';

import { IEventsDropdown } from './intefaces';
import ROUTES from '../../../constants/routes';
import { useNavigate } from 'react-router-dom';

const EventsDropdown = ({ sx = {}, menuProps }: IEventsDropdown) => {
  const navigate = useNavigate();
  const { closeLeftMenu, events, currentEventId } = useMytaverseEvent();

  const options = events.map((event) => ({
    label: event.name,
    value: event.eventId,
  }));

  const activeValue =
    currentEventId ||
    sessionStorage.getItem('selectedEventId') ||
    events[0]?.eventId ||
    '';

  const handleChange = useCallback(
    (e: SelectChangeEvent) => {
      const event = events.find((event) => event.eventId === e.target.value);

      if (event) {
        sendCustomSentryBreadcrumb({
          message: `change event id=${event.eventId}, name=${event.name}`,
        });
        navigate(ROUTES.EVENT_PAGE(event.eventId));
        closeLeftMenu();
      }
    },
    [closeLeftMenu, events],
  );

  return (
    <SingleSelect
      options={options}
      activeValue={activeValue}
      onChange={handleChange}
      emptyPlaceholder="No available events"
      sx={sx}
      menuProps={menuProps}
    />
  );
};

export default EventsDropdown;
