import { withTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { FormButton, FormLayout } from '../../components/Form';
import PageTitleContent from '../../components/PageTitleContent';
import LegalNotes from '../../components/LegalNotes';
import { TryDemoPageCheckYourEmailPageView } from './interfaces';
import { useStyles } from './styles';
import { MarkEmailReadIcon } from '../../../../icons';

const CheckYourEmailPageView = ({
  title,
  email,
  loading,
  onSubmit,
  t: translate,
}: TryDemoPageCheckYourEmailPageView) => {
  const classes = useStyles();
  return (
    <Box sx={classes.root}>
      <PageTitleContent
        title={title}
        subtitle={translate('tryDemo.subtitle')}
      />
      <LegalNotes>
        <FormLayout classes={{ container: classes.layoutContainer }}>
          <MarkEmailReadIcon />

          <Typography sx={classes.title}>
            {translate('tryDemo.checkEmail.title')}
          </Typography>
          <Typography sx={classes.subtitle}>
            {translate('tryDemo.checkEmail.subtitle')}
          </Typography>
          <Typography sx={classes.title}>{email}</Typography>
          <Typography sx={classes.subtitle}>
            {translate('tryDemo.checkEmail.reminder')}
          </Typography>

          <form onSubmit={onSubmit} noValidate>
            <FormButton loading={loading}>
              {translate('tryDemo.checkEmail.resendEmail')}
            </FormButton>
          </form>
        </FormLayout>
      </LegalNotes>
    </Box>
  );
};

export default withTranslation('common')(CheckYourEmailPageView);
