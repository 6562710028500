import React, { useCallback } from 'react';

import { SelectChangeEvent } from '@mui/material';

import EventsDropdownView from './EventsDropdownView';

import { sendCustomSentryBreadcrumb } from '../../helpers/sentry';

import { IEventsDropdown } from './intefaces';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import { useMytaverseEvent } from '../../modules/dashboard/providers';

const EventsDropdown = ({ onChangeEvent, menuProps }: IEventsDropdown) => {
  const navigate = useNavigate();
  const { events, currentEventId } = useMytaverseEvent();

  const dropdownEvent =
    currentEventId ||
    sessionStorage.getItem('selectedEventId') ||
    events[0]?.eventId ||
    '';

  const handleChange = useCallback(
    (e: SelectChangeEvent) => {
      const event = events.find((event) => event.eventId === e.target.value);

      if (event) {
        sendCustomSentryBreadcrumb({
          message: `change event id=${event.eventId}, name=${event.name}`,
        });

        navigate(ROUTES.EVENT_PAGE(event.eventId));
        onChangeEvent();
      }
    },
    [events, onChangeEvent],
  );

  return (
    <EventsDropdownView
      currentEventId={dropdownEvent}
      events={events}
      onChange={handleChange}
      menuProps={menuProps}
    />
  );
};

export default EventsDropdown;
