import React from 'react';
import { Box, Typography } from '@mui/material';

import { useStyles } from './styles';

interface ITabLabel {
  label: string;
  count: number;
}

const TabLabel = ({ label, count }: ITabLabel) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root}>
      <Typography component="span" sx={classes.label}>
        {label}
      </Typography>
      <Typography component="span" sx={classes.badge}>
        {count}
      </Typography>
    </Box>
  );
};

export default TabLabel;
