import React from 'react';
import { withTranslation } from 'react-i18next';

import { Box, Typography, Button } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EditIcon from '@mui/icons-material/Edit';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import UserAvatar from '../UserAvatar';
import ProfileIInfoItem from '../ProfileIInfoItem';
import OverflowTitle from '../../OverflowTitle';

import { IUserProfilePreviewView } from './interfaces';

import { useStyles } from './styles';

const UserProfilePreviewView = ({
  user,
  fullUserName,
  onOpenLinkedIn,
  enableEditing,
  onEditClick,
  t: translate,
}: IUserProfilePreviewView) => {
  const { profileImage, email, company, phoneNumber, linkedIn } = user;

  const placeholder = 'Add it';

  const showButtons = enableEditing;

  const classes = useStyles();

  return (
    <Box sx={classes.root}>
      <Box sx={classes.content}>
        <UserAvatar src={profileImage?.url || ''} />
        <Box sx={classes.userData}>
          <OverflowTitle title={fullUserName} sx={classes.name} />
          <OverflowTitle title={company || placeholder} sx={classes.company} />
          <ProfileIInfoItem icon={MailIcon}>
            <OverflowTitle title={email} sx={classes.text} />
          </ProfileIInfoItem>
          <ProfileIInfoItem icon={LocalPhoneIcon}>
            <OverflowTitle
              title={phoneNumber || placeholder}
              sx={classes.text}
            />
          </ProfileIInfoItem>
          <ProfileIInfoItem icon={LinkedInIcon}>
            <OverflowTitle
              title={linkedIn || placeholder}
              sx={{ ...classes.text, ...classes.handledText(!!linkedIn) }}
              onClick={linkedIn ? onOpenLinkedIn : null}
            />
          </ProfileIInfoItem>
        </Box>
      </Box>
      {showButtons && (
        <Box sx={classes.button}>
          {enableEditing && (
            <Button sx={classes.actionBtn} type="submit" onClick={onEditClick}>
              <EditIcon sx={classes.actionIcon} />
              <Typography sx={classes.actionText}>
                {translate('userProfile.edit')}
              </Typography>
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default withTranslation('common')(UserProfilePreviewView);
