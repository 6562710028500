import React from 'react';

import ProgressBar from '../../ProgressBar';

import { useMicrophoneTest } from '../../../../../../modules/auth/components/MicrophoneTest/MicrophoneTestProgressBar/hooks';

import { IMicrophoneTestProgressBar } from './interfaces';

const MicrophoneTestProgressBar = ({
  activeDeviceId,
  permissionState,
}: IMicrophoneTestProgressBar) => {
  const { volume } = useMicrophoneTest({ activeDeviceId, permissionState });

  const finalVolume = Math.min(volume, 100);

  return <ProgressBar value={finalVolume} />;
};

export default MicrophoneTestProgressBar;
