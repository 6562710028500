import { colors } from '../../../../../constants/colors';

import { IStyles } from '../../../../../interfaces/styles';
import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../../interfaces';

const baseSwiperButtonsStyles = {
  position: 'relative',
  inset: 0,
  width: 55,
  height: 55,
  borderRadius: '50%',
  border: `1px solid ${colors.white_40}`,
  backgroundColor: colors.black_60,
  flexShrink: 0,
  margin: 0,
  '& svg': {
    fontSize: 40,
    color: colors.white,
  },
  '&::after': {
    content: '""',
  },
};

const baseSwiperButtonsMobileStyles = {
  width: 35,
  height: 35,
  '& svg': {
    fontSize: 24,
  },
};

export const useStyles = (): IStyles => {
  const { breakpoints } = useTheme();

  return {
    prevBtn: {
      ...baseSwiperButtonsStyles,
      [breakpoints.down(ScreenSizes.Tablet)]: {
        ...baseSwiperButtonsMobileStyles,
      },
    },
    nextBtn: {
      ...baseSwiperButtonsStyles,
      [breakpoints.down(ScreenSizes.Tablet)]: {
        ...baseSwiperButtonsMobileStyles,
      },
      transform: 'rotate(180deg)',
    },
  };
};
