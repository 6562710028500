export const useStyles = () => {
  return {
    img: {
      maxHeight: '100%',
      width: '100%',
      objectFit: 'cover',
      aspectRatio: '16/9',
    },
  };
};
