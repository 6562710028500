import React from 'react';

import { IconButton } from '@mui/material';

import { ReactComponent as LogoutIcon } from '../../../../public/images/ek/exit.svg';

import { ILogoutButtonViewProps } from './interfaces';

import { useStyles } from './styles';

const LogoutButtonView = ({
  logoutUser,
  iconColor,
  sx = {},
}: ILogoutButtonViewProps) => {
  const classes = useStyles(iconColor);
  const rootSx = { ...classes.root, ...sx };

  return (
    <IconButton onClick={logoutUser} sx={rootSx} disableRipple>
      <LogoutIcon />
    </IconButton>
  );
};

export default LogoutButtonView;
