import React from 'react';
import { withTranslation } from 'react-i18next';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import Drawer from '../../../../../components/Drawer';
import Tutorial from './Tutorial';

import { IDrawerView } from './interfaces';

const FullScreenDrawerView = ({
  isOpen,
  onClick,
  t: translate,
}: IDrawerView) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClick={onClick}
      title={translate('tooltips.fullscreen')}
      icon={FullscreenIcon}
    >
      <Tutorial onClose={onClick} />
    </Drawer>
  );
};

export default withTranslation('common')(FullScreenDrawerView);
