import { create } from 'zustand';
import { IConferenceState } from './interfaces';
import {
  setSentrySelectedSpeaker,
  setSentrySelectedCamera,
  setSentrySelectedMicrophone,
} from '../helpers/sentry';

const initialState = {
  activeSpeakerDeviceId: 'default',
  activeCameraDeviceId: '',
  activeMicroDeviceId: 'default',
};

const getDevice = (ls: string, id: string) => {
  const devicesStr = localStorage.getItem(ls);

  if (!devicesStr) {
    return;
  }

  const devices: MediaDeviceInfo[] = JSON.parse(devicesStr);

  return devices.find((device) => device.deviceId === id);
};

const logSpeakerDevice = (activeSpeakerDeviceId: string) => {
  const device = getDevice('audioDevices', activeSpeakerDeviceId);
  setSentrySelectedSpeaker(device?.label || '');
};

const logCameraDevice = (activeCameraDeviceId: string) => {
  const device = getDevice('cameraDevices', activeCameraDeviceId);
  setSentrySelectedCamera(device?.label || '');
};

const logMicrophoneDevice = (activeMicroDeviceId: string) => {
  const device = getDevice('microDevices', activeMicroDeviceId);
  setSentrySelectedMicrophone(device?.label || '');
};

export const useConferenceState = create<IConferenceState>((set) => ({
  ...initialState,
  setInitialState: (initialState) => set(initialState),
  setActiveSpeakerDeviceId: (activeSpeakerDeviceId) =>
    set(() => {
      localStorage.setItem('selectedSpeaker', activeSpeakerDeviceId);

      logSpeakerDevice(activeSpeakerDeviceId);

      return { activeSpeakerDeviceId };
    }),
  setActiveCameraDeviceId: (activeCameraDeviceId) =>
    set(() => {
      localStorage.setItem('selectedCamera', activeCameraDeviceId);

      logCameraDevice(activeCameraDeviceId);

      return { activeCameraDeviceId };
    }),
  setActiveMicroDeviceId: (activeMicroDeviceId) =>
    set(() => {
      localStorage.setItem('selectedMicrophone', activeMicroDeviceId);

      logMicrophoneDevice(activeMicroDeviceId);

      return { activeMicroDeviceId };
    }),
  resetState: () => set(initialState),
}));
