import { useTheme } from '@mui/material';
import { IStyles } from '../../../interfaces/styles';
import { ScreenSizes } from '../../../interfaces';

export const useStyles = (fontColor: string): IStyles => {
  const { breakpoints } = useTheme();

  const iconStyle = {
    width: 32,
    height: 32,
    borderRadius: '50%',
    objectFit: 'cover',
    [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
      width: 24,
      height: 24,
    },
  };

  return {
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    iconButton: {
      width: 32,
      height: 32,
      '& img': iconStyle,
    },
    img: iconStyle,
    text: {
      flexGrow: 1,
      fontSize: 16,
      ml: 1,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '100%',
      maxWidth: 300,
      overflow: 'hidden',
      color: fontColor,
      fontWeight: 500,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 14,
        fontWeight: 400,
      },
    },
  };
};
