import React from 'react';

import HomeBarButton from '../../HomeBarButton';
import { usePocAi } from '../providers/PocAiProvider';
import { VirtualAssistantIcon } from './VirtualAssistantIcon';
import { useStyles } from './styles';

const PocAiButton = () => {
  const { setOpen, open, playBackOnFirstTime } = usePocAi();
  const classes = useStyles();

  return (
    <HomeBarButton
      sx={classes.homeBarButton}
      label={'Noa Assistant'}
      icon={<VirtualAssistantIcon />}
      handleClick={() => {
        if (!open) {
          playBackOnFirstTime();
        }
        setOpen((prevState) => !prevState);
      }}
    />
  );
};

export default PocAiButton;
