import { IiXRHrData } from '../../interfaces/profile';

export const getHRData = (hrData: IiXRHrData) => {
  return Object.fromEntries(
    Object.entries(hrData).map(([key, value]) => [
      key,
      value && typeof value === 'string' ? value.toUpperCase() : value,
    ]),
  );
};
