import React from 'react';
import { Box } from '@mui/material';

import usePdfViewer from './usePdfViewer';
import { sanitizeRow, isFullRowInSpans } from './helpers';

import { useStyles } from './styles';
import LoadingProgress from '../../../../../../components/LoadingProgress';

const PdfViewer = ({ file }: { file: string }) => {
  const classes = useStyles();

  const { textContent, isLoading } = usePdfViewer(file);

  const renderParagraph = (html: string, key: number) => {
    return (
      <div
        key={key}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    );
  };

  if (isLoading) {
    return <LoadingProgress size={30} height="auto" />;
  }

  return (
    <Box sx={classes.root}>
      {textContent.map((page: string, pageIndex) => {
        return (
          <Box key={pageIndex} sx={classes.page}>
            {page.split('\n').map((str, strIndex) => {
              if (str.trim()) {
                const html = sanitizeRow(str);
                const isFullInSpans = isFullRowInSpans(html);

                if (isFullInSpans) {
                  return (
                    <Box key={strIndex} sx={classes.row}>
                      {renderParagraph(html, strIndex)}
                    </Box>
                  );
                }

                return renderParagraph(html, strIndex);
              }

              return null;
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default PdfViewer;
