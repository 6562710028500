import React, { useCallback } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import HomeBarButton from '../../HomeBarButton';

import { useStyles } from './styles';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { WebsocketAction } from '../../../../../interfaces/webSocketConnectionInfo';
import { useMytaverseEvent } from '../../../providers';

const ExitButton = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();

  const { sendMessageToUnreal } = useMytaverse();
  const { showExitButton } = useMytaverseEvent();

  if (!showExitButton.flag) {
    return null;
  }

  const handleExitClick = useCallback(() => {
    sendMessageToUnreal({ action: WebsocketAction.ToggleExitButton });
  }, []);

  return (
    <HomeBarButton
      label={translate('logoutConfirm.exit')}
      handleClick={handleExitClick}
      buttonSx={classes.root}
      icon={
        <>
          <CloseIcon />
          <Typography sx={classes.label}>
            {translate('logoutConfirm.exit')}
          </Typography>
        </>
      }
    />
  );
};

export default withTranslation('common')(ExitButton);
