import React from 'react';
import { withTranslation } from 'react-i18next';

import SelectWrapper from '../../components/SelectWrapper';
import ChooseAvatar from '../../components/ChooseAvatar';
import { AvaturnPolicyModal } from '../../components/PolicyModal';

import { ISelectAvatarPageView } from './interfaces';

const { REACT_APP_EK_ENV } = process.env;

const SelectAvatarPageView = ({ onBackButtonClick }: ISelectAvatarPageView) => {
  return (
    <SelectWrapper
      hideFloor
      onBackClick={onBackButtonClick}
      sx={{
        container: { gridTemplateRows: 'auto 1fr' },
        content: { marginTop: '0px !important' },
      }}
    >
      {REACT_APP_EK_ENV ? <AvaturnPolicyModal /> : null}
      <ChooseAvatar />
    </SelectWrapper>
  );
};

export default withTranslation('common')(SelectAvatarPageView);
