import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import useAsyncEffect from 'use-async-effect';

import { useMytaverse } from '../../../../providers/MytaverseProvider';

import EventsService from '../../../../services/EventsService';
import { IEventParticipant } from '../../../../interfaces/eventParticipant';

import { IAvatarSkin } from '../../../../interfaces/avatarSkin';
import { IEvent, PlayerSession } from '../../../../interfaces/event';
import {
  IParticipant,
  IParticipantRegion,
  ParticipantState,
} from '../../../../interfaces/participants';
import { IRoom } from '../../../../interfaces/rooms';
import { IStreamService } from '../../../../interfaces/streamService';
import ParticipantsService from '../../../../services/ParticipantsService';
import ROUTES from '../../../../constants/routes';
import {
  Outlet,
  useLocation,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { IPointOfInterest } from '../../../../interfaces/pointsOfInterest';
import { useNotification } from '../../../../components/Notification';

import { EventDrawerTabs } from '../../constants';
import {
  useAvatarMessage,
  useEventParticipantData,
  useInitMessage,
  useParticipantsState,
} from './hooks';
import {
  IMytaverseEventContext,
  IWebsocketMessageFlag,
  SendMutedStateMessageEnum,
  StreamingProviders,
  UpdateParticipantType,
} from './interfaces';
import AnalyticsService from '../../../../services/AnalyticsService';
import { SendToBriefcasePropsTypeFull } from '../../components/DashboardContent/interfaces';
import { useChatState } from '../../../../hooks/context';
import PurewebClientOptions from '../../components/DashboardContent/Pureweb/helpers';
import { useInitGameCastStream } from './hooks/streamProviders';
import { usePixelStreamingProvider } from './hooks/pixelStreamingProvider';
import { IFollowerData } from '../../../../interfaces/followers';
import { WebsocketAction } from '../../../../interfaces/webSocketConnectionInfo';
import { MytaverseLogger } from '../../../../helpers/logger';
import { WsConnectionClientType } from '../../../../interfaces';
import LoadingProgress from '../../../../components/LoadingProgress';
import { useCoreweave } from '../../components/DashboardContent/CoreWeave/CoreWeaveProvider';
import { PixelStreaming } from '@tensorworks/libspsfrontend';
import { useGamecast } from '../../components/DashboardContent/GameCast/GameCastProvider';
import { useBackButton } from './hooks/eventListeners';
import { IGamecastVideoStats } from '../../components/DashboardContent/GameCast/interfaces';

import {
  IXRHrCredStatusTypes,
  IXRHrPersonaTypes,
} from '../../../../interfaces/profile';

const { REACT_APP_MULTI_PLAYER_OFFICE_MAP } = process.env;

export const useMytaverseEvent = () =>
  useOutletContext<IMytaverseEventContext>();

const { REACT_APP_EK_ENV } = process.env;

export const MytaverseEventProvider = () => {
  const { eventId: currentEventId } = useParams();

  const {
    token,
    idToken,
    userId,
    user,
    sendJSONMessageToWebSocket,
    sendMessageToUnreal,
    sendMessageToEvent,
    websocketSessionId,
    setCurrentAvatarId,
    setCustomAvatarUrl,
  } = useMytaverse();

  const [openLeftMenu, setOpenLeftMenu] = React.useState<boolean>(false);
  const [leftMenuTab, setLeftMenuTab] = React.useState<EventDrawerTabs>(
    EventDrawerTabs.Locations,
  );
  const [reaction, setReaction] = React.useState<string | null>(null);
  const [emoji, setEmoji] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [openAdminSettingsModal, setOpenAdminSettingsModal] = useState(false);
  const [muted, setMuted] = useState(true);
  const [currentEvent, setCurrentEvent] = React.useState<IEvent>();
  const [eventLoaded, setEventLoaded] = React.useState<boolean>(false);
  const [rooms, setRooms] = React.useState<IRoom[]>([]);
  const [currentRoom, setCurrentRoom] = React.useState<IRoom | null>(null);
  const [leftMenuScrollHandler, setLeftMenuScrollHandler] = useState<
    ((page: number) => void) | null
  >(null);
  const [developersTerminalMessages, setDevelopersTerminalMessages] = useState<
    string[]
  >([]);
  const [openLeaveRegionDialog, setOpenLeaveRegionDialog] =
    React.useState(false);
  const [gameReadyToPlay, setGameReadyToPlay] = React.useState(true);
  const [openCameraPublisherDialog, setOpenCameraPublisherDialog] =
    React.useState(false);
  const [ue5WebsocketConnected, setUe5WebsocketConnected] =
    React.useState(false);
  const [ue5CoreWeaveDisabled, setUe5CoreWeaveDisabled] = useState(false);
  const [currentRegion, setCurrentRegion] =
    React.useState<IParticipantRegion | null>(null);
  const [
    currentRoomDolbySpatialAudioScale,
    setCurrentRoomDolbySpatialAudioScale,
  ] = React.useState<number>(80);
  const [isTeleporting, setIsTeleporting] = useState(false);
  const [poiPreviewSrc, setPoiPreviewSrc] =
    React.useState<SendToBriefcasePropsTypeFull | null>(null);
  const [initMessageSended, setInitMessageSended] = React.useState(false);
  const [previousSkin, setPreviousSkin] = React.useState<IAvatarSkin | null>(
    null,
  );
  const [currentEventParticipant, setCurrentEventParticipant] =
    React.useState<IEventParticipant | null>(null);
  const [gameCastStreamRequestSended, setGameCastStreamRequestSended] =
    useState(false);
  const [previousCustomAvatar, setPreviousCustomAvatar] = React.useState<
    string | null
  >(null);
  const [pointsOfInterest, setPointsOfInterest] = React.useState<
    IPointOfInterest[] | null
  >(null);
  const [userFiles, setUserFiles] = React.useState<IPointOfInterest[]>([]);
  const [isGamecastCrash, setIsGamecastCrash] = React.useState(false);
  const [currentParticipant, setCurrentParticipant] = React.useState<
    IParticipant | undefined
  >();
  const [teleportingToRoom, setTeleportingToRoom] =
    React.useState<IRoom | null>(null);
  const [isTeleportingToRoomByUnreal, setIsTeleportingToRoomByUnreal] =
    React.useState(false);
  const [participants, setParticipants] = React.useState<IParticipant[]>([]);
  const [loadingNewParticipants, setLoadingNewParticipants] =
    React.useState(false);
  const [streamService, setStreamService] = React.useState<IStreamService>();
  const [openTeleportToRoomDialog, setOpenTeleportToRoomDialog] =
    React.useState(false);
  const [selectedTeleportRoom, setSelectedTeleportRoom] =
    React.useState<IRoom | null>(null);
  const [dolbyToken, setDolbyToken] = React.useState<string>('');
  const [streamChatToken, setStreamChatToken] = React.useState<string>('');
  const [playerSession, setPlayerSession] =
    React.useState<PlayerSession | null>(null);
  const [streamChatMemberId, setStreamChatMemberId] =
    React.useState<string>('');
  const [enableSoulmachinesAssistant, setEnableSoulmachinesAssistant] =
    useState(false);
  const [showExitButton, setShowExitButton] =
    React.useState<IWebsocketMessageFlag>({
      flag: false,
      timestamp: 0,
    });
  const [isHideWebUI, setIsHideWebUI] = React.useState<IWebsocketMessageFlag>({
    flag: false,
    timestamp: 0,
  });
  const [showControls, setShowControls] = React.useState<boolean>(true);
  const [sharingWindowFullsceen, setSharingWindowFullscreen] =
    React.useState(false);
  const [isMinimizedScreenSharing, setIsMinimizedScreenSharing] =
    React.useState(false);
  const [openDevelopersTerminal, setOpenDevelopersTerminal] = useState(false);
  const [pendingFollowersData, setPendingFollowersData] = useState<
    IFollowerData[]
  >([]);
  const [acceptedFollowersData, setAcceptedFollowersData] = useState<
    IFollowerData[]
  >([]);
  const [preserveAcceptedFollowersId, setPreserveAcceptedFollowersId] =
    useState<string[]>([]);
  const [isParticipantRoomChanged, setIsParticipantRoomChanged] =
    useState(false);
  const [events, setEvents] = useState<IEvent[]>([]);
  const [coreweavePixelStreaming, setCoreweavePixelStreaming] =
    useState<PixelStreaming | null>(null);
  const [gamecastStats, setGamecastStats] =
    useState<IGamecastVideoStats | null>(null);
  const [showGamecastStats, setShowGamecastStats] = useState(false);
  const { streamingProvider, setStreamingProvider, pixelStreamingClient } =
    usePixelStreamingProvider({ currentEvent });

  const clientOptions = React.useMemo(() => {
    if (
      !streamService ||
      !currentEvent ||
      streamingProvider !== StreamingProviders.Pureweb
    ) {
      return null;
    }

    const purewebClientOptions = new PurewebClientOptions(
      streamService.purewebProjectId,
      streamService.purewebModelId,
    );

    if (currentEvent.region) {
      purewebClientOptions.regionOverride = currentEvent.region;
    }

    return purewebClientOptions;
  }, [currentEvent, streamService, streamingProvider]);

  const { setParticipantState } = useParticipantsState({
    currentParticipantId: userId as string,
    currentEventId,

    currentParticipant,
    setParticipants,

    participants,
    setCurrentParticipant,

    currentRoom,
    setCurrentRoom,

    currentRegion,
    setCurrentRegion,
    setIsParticipantRoomChanged,
    rooms,
  });

  const {
    participantsSound,
    gameSound,
    setGameSoundHandle,
    setParticipantsSoundHandle,
  } = useEventParticipantData({
    currentEvent,
    currentEventParticipant,
  });

  const { pathname } = useLocation();
  const { showNotification, getBadRequestNotification } = useNotification();
  const { setOpen: setOpenChat } = useChatState();

  const { initMessageHandler } = useInitMessage({
    currentEvent,
    currentParticipant,
    currentEventParticipant,
    dolbyToken,
    teleportingToRoom,
    token,
    idToken,
    websocketSessionId,
    user,
    setPlayerSession,
  });

  const goldenTicketRef = useRef<HTMLElement>(null);

  const { avatarMessage } = useAvatarMessage({
    currentSkin: { avatarId: user?.avaturnId || '' } as IAvatarSkin,
    currentEvent,
    customAvatarUrl: user?.customAvatarUrl || '',
  });

  const {
    connectedPod,
    coreweaveLoading,
    dataChannelOpened,
    handleInitializeButton,
    isPlayButtonPressed,
    playStream,
    rootElement,
    setRootElement,
    setWebSocketAddress,
    togglePlayStream,
    webSocketAddress,
  } = useCoreweave({
    currentEvent,
    currentParticipant,
    currentRoom,
    gameSound,
    initMessageHandler,
    initMessageSended,
    muted,
    pixelStreamingClient,
    coreweavePixelStreaming,
    setGameReadyToPlay,
    setInitMessageSended,
    setCoreweavePixelStreaming,
    ue5CoreWeaveDisabled,
    streamingProvider,
    avatarMessage,
    setParticipantState,
  });

  const {
    streamId: gameCastStreamId,
    setStreamId: setGameCastStreamId,
    streamRegion: gameCastStreamRegion,
    setStreamRegion: setGameCastStreamRegion,
  } = useInitGameCastStream(
    currentEvent,
    streamingProvider,
    pixelStreamingClient,
  );

  const trackAnalytics = React.useCallback(
    async (type: string, payload: any = {}) => {
      if (token && currentEvent && user && websocketSessionId) {
        await AnalyticsService.track(token, type, new Date().getTime(), {
          eventId: currentEventId,
          participantId: userId,
          sessionId: websocketSessionId,
          payload: {
            event: {
              id: currentEvent.eventId,
              name: currentEvent.name,
            },
            participant: {
              id: user.id,
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
            },
            ...payload,
          },
        });
      }
    },
    [token, currentEvent, user, websocketSessionId],
  );

  const {
    isStreamLoaded,
    isStreamLoading,
    setIsStreamLoaded,
    setIsStreamLoading,
    //streamSession,
    videoRef,
    gameCastRef
  } = useGamecast({
    currentEventId,
    currentRoom,
    trackAnalytics,
    gameSound,
    currentParticipant,
    setGameCastStreamId,
    initMessageHandler,
    gameCastStreamRequestSent: gameCastStreamRequestSended,
    setGameCastStreamRequestSent: setGameCastStreamRequestSended,
    setInitMessageSent: setInitMessageSended,
    gameCastStreamId,
    gameCastStreamRegion,
    setGameCastStreamRegion,
    setStreamingProvider,
    setIsGamecastCrash,
    setCurrentRoom,
    isGamecastCrash,
    setGamecastStats,
    currentEventParticipant,
    websocketSessionId,
  });

  const loadSessionIds = useCallback(() => {
    const websocketSessionIdsStr = sessionStorage.getItem('websocketSessionId');

    return websocketSessionIdsStr ? JSON.parse(websocketSessionIdsStr) : [];
  }, []);

  useEffect(() => {
    if (!websocketSessionId) {
      return;
    }

    const websocketSessionIds = loadSessionIds();
    websocketSessionIds.push(websocketSessionId);

    sessionStorage.setItem(
      'websocketSessionId',
      JSON.stringify(websocketSessionIds),
    );
  }, [websocketSessionId]);

  const cleanStates = useCallback(() => {
    setRooms([]);
    setParticipants([]);
    setOpenChat(false);
    setCurrentParticipant(undefined);
    setCurrentRoom(null);
    setCurrentEvent(undefined);

    sendJSONMessageToWebSocket({
      action: 'LEAVE_EVENT',
    });
  }, [
    setRooms,
    setParticipants,
    setCurrentParticipant,
    setCurrentRoom,
    setCurrentEvent,
    sendJSONMessageToWebSocket,
  ]);

  useBackButton({
    cleanStates,
    initMessageSended,
    ue5WebsocketConnected,
    setUe5WebsocketConnected,
    currentEventId: currentEventId ?? null,
  });

  useEffect(() => {
    if (currentRoom) {
      localStorage.setItem('currentRoomId', currentRoom.roomId);
      console.log(`Room changed to ${currentRoom.name}`);
    }
  }, [currentRoom]);

  useEffect(() => {
    if (userFiles) {
      localStorage.setItem('userFiles', JSON.stringify(userFiles) as string);
    }
  }, [userFiles, currentRoom]);

  useEffect(() => {
    if (currentRoom) {
      setUserFiles([]);
    }
  }, [currentRoom]);

  useAsyncEffect(async () => {
    if (!currentRoom) {
      return;
    }

    if (pointsOfInterest) {
      setPointsOfInterest(null);
    }

    let pointsInterest: IPointOfInterest[];

    try {
      pointsInterest = await EventsService.getPointOfInterest(
        currentRoom.roomId,
      );
    } catch (e) {
      pointsInterest = [];
    }

    if (pointsInterest.length) {
      setPointsOfInterest(pointsInterest);
    }
  }, [currentRoom]);

  useEffect(() => {
    if (!currentEventId) {
      return;
    }

    sendJSONMessageToWebSocket({
      action: muted
        ? SendMutedStateMessageEnum.MUTE
        : SendMutedStateMessageEnum.UNMUTE,
      timestamp: Date.now(),
    });
  }, [muted, currentEventId, sendJSONMessageToWebSocket]);

  const canSpeak = useMemo(() => {
    let canSpeak = !!currentRoom?.isMultiPlayer;

    if (currentRoom?.map?.ueIdentifier === REACT_APP_MULTI_PLAYER_OFFICE_MAP) {
      canSpeak =
        user?.hrData?.persona?.toUpperCase() ===
        IXRHrPersonaTypes.HR_Facilitator;
    }

    return canSpeak;
  }, [currentRoom, user]);
  // useAsyncEffect(async () => {
  //   if (loadingNewParticipants) {
  //     return;
  //   }
  //
  //   const loadingParticipants = participants.filter(
  //     (p) => p.state === ParticipantState.Loading,
  //   );
  //
  //   if (loadingParticipants.length === 0) {
  //     return;
  //   }
  //
  //   setLoadingNewParticipants(true);
  //   const loadedParticipants = await Promise.allSettled(
  //     loadingParticipants.map(async (p: IParticipant) => {
  //       const participant = await EventsService.getParticipantProfile(
  //         p.participantId,
  //       );
  //
  //       return {
  //         ...p,
  //         ...EventsService.prepareParticipant(participant),
  //       };
  //     }),
  //   );
  //
  //   if (loadedParticipants.length !== 0) {
  //     setParticipants((prev) => {
  //       return prev.map((participant) => {
  //         const loadedParticipant = loadedParticipants.find(
  //           (p) =>
  //             p.status === 'fulfilled' &&
  //             p.value.participantId === participant.participantId,
  //         );
  //
  //         if (!loadedParticipant || loadedParticipant.status !== 'fulfilled') {
  //           return participant;
  //         }
  //
  //         return {
  //           ...loadedParticipant.value,
  //           key: participant.key,
  //           eventId: participant.eventId,
  //           roomId: participant.roomId,
  //           region: participant.region,
  //           regions: participant.regions,
  //           gameSessionId: participant.gameSessionId,
  //           state: ParticipantState.Loaded,
  //         };
  //       });
  //     });
  //   }
  //
  //   setLoadingNewParticipants(false);
  // }, [loadingNewParticipants, participants]);

  useEffect(() => {
    if (currentRoom && currentRoom.dolbySpatialAudioScale) {
      setCurrentRoomDolbySpatialAudioScale(currentRoom.dolbySpatialAudioScale);
    }
  }, [currentRoom]);

  const checkIsParticipantInEvent = useCallback(async () => {
    if (!currentEventId) {
      return;
    }

    let isParticipantInEvent = false;
    const participantsInfo = await EventsService.getEventConnections(
      currentEventId,
    );

    const webSocketConnections = participantsInfo
      .find((participant) => participant.participantId === userId)
      ?.webSocketConnections.reduce(
        (
          acc: [],
          connection: {
            sessionId: string;
            clientType: WsConnectionClientType;
          },
        ) => {
          if (connection.clientType === WsConnectionClientType.Webapp) {
            return [...acc, connection.sessionId];
          }

          return acc;
        },
        [],
      );

    if (webSocketConnections?.length > 0) {
      const tabSessionIds = loadSessionIds();

      if (!tabSessionIds.includes(webSocketConnections[0])) {
        isParticipantInEvent = true;
      }
    }

    return isParticipantInEvent;
  }, [currentEventId]);

  useAsyncEffect(async () => {
    if (pathname === ROUTES.EVENT_PAGE(currentEventId)) {
      // TODO refactor this
      // const isParticipantInEvent = await checkIsParticipantInEvent();
      //
      // if (isParticipantInEvent) {
      //   navigate(ROUTES.SELECT_EVENT);
      //
      //   showNotification(
      //     getNotification({
      //       message: translate('notifications.participantAlreadyInEvent'),
      //       type: NOTIFICATION_TYPES.WARNING,
      //     }),
      //   );
      //
      //   MytaverseLogger.log(
      //     translate('notifications.participantAlreadyInEvent'),
      //   );
      //
      //   return;
      // }
    }
  }, [pathname, currentEventId]);

  // TODO merge it with load participant settings data
  useAsyncEffect(async () => {
    try {
      if (currentEventId && userId) {
        setLoading(true);
        setEventLoaded(false);

        setPreviousCustomAvatar(null);
        setPreviousSkin(null);

        const [events, eventParticipant] = await Promise.all([
          ParticipantsService.getCurrentParticipantEvents(),
          EventsService.getEvent(currentEventId),
        ]);

        setEvents(
          events.events.map(
            (participantEvent) => participantEvent.event as IEvent,
          ),
        );
        setCurrentEvent(eventParticipant.event);

        const rooms = eventParticipant.event?.rooms || [];

        setRooms(rooms);
        const defaultRoom = rooms.find((room) => room.isDefault);
        setTeleportingToRoom(defaultRoom || null);

        const participants = eventParticipant.event?.participants
          ? eventParticipant.event?.participants.map((participant) => ({
              ...participant,
              fullName: participant.name,
              state: ParticipantState.Loaded,
            }))
          : [];

        setParticipants(participants);

        const currentParticipant = participants.find(
          (participant) => participant.participantId === userId,
        );
        setCurrentParticipant(currentParticipant);

        const { event, ...currentEventParticipant } = eventParticipant;
        setCurrentEventParticipant(currentEventParticipant);

        if (eventParticipant.event?.streamService) {
          setStreamService(eventParticipant.event?.streamService);
        }

        setDolbyToken(eventParticipant.dolbyToken as string);
        setStreamChatToken(eventParticipant.streamChatToken as string);
        setStreamChatMemberId(eventParticipant.streamChatMemberId as string);

        // const followingData = await EventsService.getFollowingData(
        //   currentEventId,
        // );
        //
        // const { pending, accepted } = mapFollowers(followingData.followers);
        //
        // setPendingFollowersData(pending);
        // setAcceptedFollowersData(accepted);

        if (
          !REACT_APP_EK_ENV ||
          user.hrData.ixrCredentialStatus.toUpperCase() ===
            IXRHrCredStatusTypes.FT
        ) {
          const avatarParticipant =
            await ParticipantsService.getCurrentParticipantAvatar();

          setCurrentAvatarId(avatarParticipant.avaturnId || null);
          setCustomAvatarUrl(avatarParticipant.customAvatarUrl || null);
        }

        setLoading(false);
      } else {
        setCurrentEvent(undefined);

        setRooms([]);
        setCurrentRoom(null);

        setParticipants([]);
        setCurrentParticipant(undefined);
      }
    } catch (error) {
      showNotification(
        getBadRequestNotification({ message: (error as Error).message }),
      );
    }
  }, [userId, currentEventId]);

  const ownerTeleportToRoomPreserveData = React.useCallback(() => {
    if (acceptedFollowersData.length === 0 || !currentParticipant) return;

    const followers = acceptedFollowersData
      .filter(
        (follower) => follower.adminId === currentParticipant.participantId,
      )
      .map((follower) => follower.userId);

    if (followers.length === 0) return;

    setPreserveAcceptedFollowersId([...new Set(followers)]);
  }, [currentParticipant, acceptedFollowersData]);

  const ownerTeleportToRoom = React.useCallback(() => {
    if (
      preserveAcceptedFollowersId.length === 0 ||
      !currentParticipant ||
      !currentEventId
    )
      return;

    sendJSONMessageToWebSocket({
      action: WebsocketAction.OwnerTeleport,
      ownerId: currentParticipant.participantId,
      followerIds: [...preserveAcceptedFollowersId],
      ownerRoomId: currentParticipant.roomId,
      gameSessionId: currentParticipant.gameSessionId || null,
    });
  }, [
    currentParticipant,
    acceptedFollowersData,
    preserveAcceptedFollowersId,
    sendMessageToEvent,
    currentEventId,
  ]);

  useEffect(() => {
    if (isParticipantRoomChanged) {
      ownerTeleportToRoom();
      setIsParticipantRoomChanged(false);
      setPreserveAcceptedFollowersId([]);
    }
  }, [
    isParticipantRoomChanged,
    ownerTeleportToRoom,
    setPreserveAcceptedFollowersId,
  ]);

  useEffect(() => {
    if (
      currentEvent &&
      currentParticipant &&
      currentParticipant.eventId !== currentEvent.eventId
    ) {
      sendJSONMessageToWebSocket({
        action: 'JOIN_TO_EVENT',
        timestamp: Date.now(),
        eventId: currentEvent.eventId,
        analyticsPayload: {
          event: {
            id: currentEvent.eventId,
            name: currentEvent.name,
          },
          participant: {
            id: currentParticipant.participantId,
            name: `${currentParticipant.firstName} ${currentParticipant.lastName}`,
            email: currentParticipant.email,
          },
        },
      });
    }
  }, [currentEvent, currentParticipant]);

  const updateParticipant: UpdateParticipantType = useCallback(
    (participantId, newParticipantInfo) => {
      if (
        currentParticipant &&
        currentParticipant.participantId === participantId
      ) {
        setCurrentParticipant((prevParticipant) => ({
          ...prevParticipant,
          ...newParticipantInfo,
          isSpeaker: !!prevParticipant?.isSpeaker,
        }));
      }
      setParticipants((prevParticipants) =>
        prevParticipants.map((prevParticipant) =>
          prevParticipant.participantId === participantId
            ? {
                ...prevParticipant,
                ...newParticipantInfo,
              }
            : prevParticipant,
        ),
      );
    },
    [currentParticipant],
  );

  const resetPreviousSkin = useCallback(() => {
    if (previousSkin) {
      setPreviousSkin(null);
    }
  }, [previousSkin, setPreviousSkin]);

  const resetPreviousCustomSkin = useCallback(() => {
    if (previousCustomAvatar) {
      setPreviousCustomAvatar(null);
    }
  }, [previousCustomAvatar, setPreviousCustomAvatar]);

  const setRoomDolbySpatialAudioScale = useCallback(
    (roomId: string, dolbySpatialAudioScale: number) => {
      if (currentRoom && currentRoom.roomId === roomId) {
        setCurrentRoomDolbySpatialAudioScale(dolbySpatialAudioScale);
      }
      /*
      setCurrentRoom((prev) => {
        if (!prev || prev.id !== roomId) {
          return prev;
        }

        return {
          ...prev,
          dolbySpatialAudioScale,
        };
      });
       */

      setRooms((prev) =>
        prev.map((room) => {
          if (room.roomId === roomId) {
            return {
              ...room,
              dolbySpatialAudioScale,
            };
          }

          return room;
        }),
      );
    },
    [currentRoom],
  );

  const updateRoomScale = useCallback(
    async (value: number) => {
      if (currentRoom && currentEventId) {
        await EventsService.updateRoomSpatialScale(
          currentRoom.roomId as string,
          value,
          currentEventId,
        );

        setRoomDolbySpatialAudioScale(currentRoom.roomId, value);
      }
    },
    [currentRoom, currentEventId, setRoomDolbySpatialAudioScale],
  );

  const setSpeakingParticipants = React.useCallback(
    (speakingParticipantIds: string[]) => {
      if (currentParticipant) {
        const speaking: boolean =
          speakingParticipantIds.indexOf(currentParticipant.participantId) !==
          -1;

        if (currentParticipant.speaking !== speaking) {
          setCurrentParticipant({
            ...currentParticipant,
            speaking,
          });
        }
      }
    },
    [currentParticipant, setCurrentParticipant, setParticipants],
  );

  const teleportToRoom = React.useCallback(
    (room: IRoom | null) => {
      setTeleportingToRoom(room);
      //TODO add is speaker to message
      if (room && currentParticipant && !isTeleporting) {
        MytaverseLogger.log(`Teleporting to room: ${room.roomId} ${room.name}`);
        setIsTeleporting(true);
        ownerTeleportToRoomPreserveData();
        sendJSONMessageToWebSocket({
          action: WebsocketAction.TeleportToRoom,
          roomId: room.roomId,
          isSpeaker: currentParticipant.isSpeaker,
        });
      }
    },
    [
      setTeleportingToRoom,
      sendJSONMessageToWebSocket,
      currentParticipant,
      setIsTeleporting,
      isTeleporting,
      ownerTeleportToRoomPreserveData,
    ],
  );

  const teleportToParticipant = React.useCallback(
    async (participant: IParticipant | null) => {
      if (!participant || !currentRoom) {
        return;
      }

      MytaverseLogger.log(
        `Teleporting to participant: ${participant.participantId} ${participant.fullName}`,
      );

      const { roomId } = await ParticipantsService.getParticipantRoom(
        participant.participantId,
      );

      if (currentRoom.roomId !== roomId) {
        setIsTeleporting(true);
        ownerTeleportToRoomPreserveData();
      }

      sendMessageToUnreal({
        action: WebsocketAction.TeleportToParticipant,
        participantId: participant.participantId,
        gamelift: {
          playerSession,
        },
        roomId,
      });
      // sendJSONMessageToWebSocket({
      //   action: WebsocketAction.TeleportToParticipant,
      //   participantId: participant.participantId,
      //   roomId,
      // });
    },
    [currentRoom, sendMessageToUnreal, ownerTeleportToRoomPreserveData],
  );

  const teleportRef = useRef<NodeJS.Timeout | null>(null);
  const ue5WebsocketConnectedRef = useRef(false);

  useEffect(() => {
    ue5WebsocketConnectedRef.current = ue5WebsocketConnected;
  }, [ue5WebsocketConnected]);

  useEffect(() => {
    if (isTeleporting && currentRoom && !teleportRef.current) {
      teleportRef.current = setInterval(() => {
        if (ue5WebsocketConnectedRef.current && teleportRef.current) {
          setIsTeleporting(false);
          clearInterval(teleportRef.current);
        }
      }, 5000);

      return;
    }

    if (teleportRef.current && !isTeleporting) {
      clearInterval(teleportRef.current);
      teleportRef.current = null;
      return;
    }

    return () => {
      if (teleportRef.current) {
        clearInterval(teleportRef.current);
        teleportRef.current = null;
      }
    };
  }, [isTeleporting, currentRoom]);

  useEffect(() => {
    if (isTeleportingToRoomByUnreal) {
      ownerTeleportToRoomPreserveData();
    }
  }, [isTeleportingToRoomByUnreal, ownerTeleportToRoomPreserveData]);

  const closeLeftMenu = React.useCallback(() => {
    setOpenLeftMenu(false);
  }, [setOpenLeftMenu]);

  const openLeftMenuHandler = React.useCallback(
    (tab: any) => {
      setOpenLeftMenu(true);
      if (tab) {
        setLeftMenuTab(tab);
      }
    },
    [setOpenLeftMenu, setLeftMenuTab],
  );

  const setMutedHandler = React.useCallback(
    (value: boolean | undefined) => {
      setMuted(value || !muted);
    },
    [muted, setMuted],
  );

  const setEmojiHandler = React.useCallback(
    (emoji: string | null) => {
      setEmoji(emoji);

      sendJSONMessageToWebSocket({
        action: 'SEND_EMOJI',
        emoji,
      });
    },
    [setEmoji, sendJSONMessageToWebSocket],
  );

  const setReactionHandler = React.useCallback(
    (reaction: string | null) => {
      setReaction(reaction);

      sendJSONMessageToWebSocket({
        action: 'TOGGLE_REACTION',
        reactionId: reaction,
      });
    },
    [setReaction, sendJSONMessageToWebSocket],
  );

  const setSharingWindowFullscreenHandle = useCallback(() => {
    setSharingWindowFullscreen(!sharingWindowFullsceen);
  }, [setSharingWindowFullscreen, sharingWindowFullsceen]);

  if (loading) {
    return <LoadingProgress fullHeight />;
  }

  return (
    <Outlet
      context={{
        currentEventId: currentEventId as string,
        currentParticipantId: userId as string,

        currentEventParticipant,

        isOpenLeftMenu: openLeftMenu,
        reaction,
        emoji,
        muted,
        eventLoaded,
        userFiles,
        previousSkin,
        previousCustomAvatar,
        leftMenuTab,
        rooms,
        currentEvent,
        currentRoom,
        currentRegion,
        currentParticipant,
        clientOptions,
        gameCastStreamRequestSended,
        isTeleporting,
        setIsTeleporting,
        setGameCastStreamRequestSended,
        setCurrentParticipant,
        initMessageHandler,
        gameReadyToPlay,
        setGameReadyToPlay,
        participants,
        teleportingToRoom,
        pointsOfInterest,
        loading,
        dolbyToken,
        streamChatToken,
        streamChatMemberId,
        setLeftMenuScrollHandler,
        streamService,
        openLeaveRegionDialog,
        leftMenuScrollHandler,
        showControls,
        poiPreviewSrc,
        initMessageSended,
        sharingWindowFullsceen,
        participantsSound,
        selectedTeleportRoom,
        gameSound,
        openTeleportToRoomDialog,
        openAdminSettingsModal,
        openCameraPublisherDialog,
        setOpenCameraPublisherDialog,
        isStreamLoaded,
        isStreamLoading,
        setIsStreamLoaded,
        setIsStreamLoading,
        //streamSession,
        videoRef,
        setOpenAdminSettingsModal,
        streamingProvider,
        gameCastStreamId,
        developersTerminalMessages,
        setDevelopersTerminalMessages,
        setGameCastStreamId,
        gameCastStreamRegion,
        setGameCastStreamRegion,
        setStreamingProvider,
        setGameSound: setGameSoundHandle,
        setUe5WebsocketConnected,
        ue5WebsocketConnected,
        isMinimizedScreenSharing,
        openDevelopersTerminal,
        setOpenDevelopersTerminal,
        setIsMinimizedScreenSharing,
        setOpenTeleportToRoomDialog,
        //goldenTicketToPDF,
        goldenTicketRef,
        canSpeak,
        setSelectedTeleportRoom,
        playerSession,
        setPlayerSession,
        setParticipantsSound: setParticipantsSoundHandle,
        setOpenLeaveRegionDialog,
        setSharingWindowFullscreen: setSharingWindowFullscreenHandle,
        setInitMessageSended,
        setPoiPreviewSrc,
        setShowControls,
        setParticipants,
        openLeftMenu: openLeftMenuHandler,
        closeLeftMenu,
        setReaction: setReactionHandler,
        setEventLoaded,
        setMuted: setMutedHandler,
        setEmoji: setEmojiHandler,
        setUserFiles,
        resetPreviousSkin,
        resetPreviousCustomSkin,
        setLeftMenuTab,
        setCurrentRoom,
        setCurrentRegion,
        currentRoomDolbySpatialAudioScale,
        cleanStates,
        connectedPod,
        coreweaveLoading,
        dataChannelOpened,
        handleInitializeButton,
        isPlayButtonPressed,
        pixelStreaming: coreweavePixelStreaming,
        playStream,
        rootElement,
        setRootElement,
        setWebSocketAddress,
        togglePlayStream,
        webSocketAddress,
        setTeleportingToRoom,
        teleportToRoom,
        teleportToParticipant,
        isTeleportingToRoomByUnreal,
        setIsTeleportingToRoomByUnreal,
        setParticipantState,
        updateParticipant,
        setSpeakingParticipants,

        setRoomDolbySpatialAudioScale,
        updateRoomScale,

        trackAnalytics,
        pixelStreamingClient,
        pendingFollowersData,
        setPendingFollowersData,
        acceptedFollowersData,
        setAcceptedFollowersData,
        enableSoulmachinesAssistant,
        setEnableSoulmachinesAssistant,
        ue5CoreWeaveDisabled,
        setUe5CoreWeaveDisabled,
        isGamecastCrash,
        setIsGamecastCrash,
        events,
        gamecastStats,
        setGamecastStats,
        showGamecastStats,
        setShowGamecastStats,
        showExitButton,
        setShowExitButton,
        isHideWebUI,
        setIsHideWebUI,

        gameCastRef,
      }}
    />
  );
};
