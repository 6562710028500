import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AppHeader from '../../../components/AppHeader';
import { DeviceOrientationIcon } from './DeviceOrientationIcon';
import { useMytaverseEvent } from '../../../../../modules/dashboard/providers';

import ROUTES from '../../../../../constants/routes';
import { useStyles } from './styles';
import { IOrientationOverlay } from './interfaces';

const OrientationOverlay = ({
  isButton,
  onClick,
  t: translate,
}: IOrientationOverlay) => {
  const classes = useStyles();
  const { currentEventId } = useMytaverseEvent();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(ROUTES.DEVICES_TEST(currentEventId));
  };

  return (
    <Box sx={classes.root(isButton)}>
      <AppHeader isAuthorized onBackClick={handleClick} isHideLogo={isButton} />
      <Box sx={classes.container}>
        <Box sx={classes.content}>
          <DeviceOrientationIcon sx={classes.icon} />
          <Typography sx={classes.title}>
            {translate('ek.orientationScreen.title')}
          </Typography>
          <Typography sx={classes.description}>
            {translate('ek.orientationScreen.text')}
          </Typography>
        </Box>
        <Button onClick={onClick} sx={classes.button(isButton)}>
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default withTranslation('common')(OrientationOverlay);
