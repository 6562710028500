import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

import ModalBox from '../../ModalBox';

import { useStyles } from './styles';
import { usePocAi } from '../../providers/PocAiProvider';
import { POC_SCREENS } from '../../constants';
import { QUESTIONS } from './constants';
import QuestionBox from '../../QuestionBox';
import PocForm from '../../PocForm';
import PocTag from '../../PocTag';
import { withTranslation, WithTranslation } from 'react-i18next';

const HowToScreen = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();

  const { setOpen, navigate, setNavigationHistory } = usePocAi();

  const renderTitle = () => {
    return (
      <Box sx={classes.titleBox}>
        <Typography sx={classes.title}>Noa</Typography>
        <PocTag />
      </Box>
    );
  };

  return (
    <ModalBox
      title={renderTitle()}
      onClose={() => {
        setOpen(false);
        setNavigationHistory([]);
        navigate(POC_SCREENS.DEFAULT);
      }}
      onBack={() => {
        navigate(POC_SCREENS.DEFAULT, true);
      }}
    >
      <Typography sx={classes.text}>{translate('poc.howTo.text1')}:</Typography>

      <Box sx={classes.questions}>
        {QUESTIONS.map(({ title, icon }, index) => (
          <QuestionBox key={index} title={translate(title)} icon={icon} />
        ))}
      </Box>

      <Divider sx={classes.divider} />

      <Typography sx={classes.text}>{translate('poc.howTo.text2')}:</Typography>

      <PocForm />
    </ModalBox>
  );
};

export default withTranslation('common')(HowToScreen);
