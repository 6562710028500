import React from 'react';

import AssistantImage from '../../../../../public/images/poc/assistant.jpg';
import { useStyles } from './styles';

export const VirtualAssistantIcon = () => {
  const classes = useStyles();

  return (
    <div style={classes.iconContainer} className="virtual-assistant-icon">
      <img style={classes.icon} src={AssistantImage} />
    </div>
  );
};
