import { colors } from '../../../../../constants/colors';

import { IStyles } from '../../../../../interfaces/styles';

export const classes: IStyles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  container: {
    alignSelf: 'center',
    height: 'fit-content',
    background: colors.white,
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: 1,
    padding: '32px 24px',
    width: '100%',
    maxWidth: 480,
    [`@media screen and (orientation:landscape)
        and (max-device-height: 820px)
        and (max-device-width: 1180px)`]: {
      maxWidth: '70%',
    },
    [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
      padding: '16px 12px',
    },
    [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 1180px)`]: {
      maxWidth: '50%',
    },
    [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 980px)`]: {
      maxWidth: '60%',
    },
  },
};
