import { colors } from '../../../../constants/colors';

export const classes = {
  logo: ({
    maxWidth = 184,
    maxHeight = 80,
  }: {
    maxWidth?: number;
    maxHeight?: number;
  } = {}) => ({
    height: 75,
    color: colors.oxford,
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      color: 'inherit',
      width: '100%',
      height: 'auto',
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      objectFit: 'contain',
    },
    '& img': {
      width: 'auto',
      height: 'auto',
      maxWidth: maxWidth,
      maxHeight: maxHeight,
    },

    '@media (max-width: 375px)': {
      height: 50,
    },

    [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
      height: 50,
    },
    [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
      height: 50,
    },
    [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
      height: 50,
    },

    [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
      {
        height: 50,
      },
  }),
};
