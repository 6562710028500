import NearMeIcon from '@mui/icons-material/NearMe';
import ChairIcon from '@mui/icons-material/Chair';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';

/*export const QUESTIONS = [
  { title: 'poc.questions.question1', icon: NearMeIcon },
  { title: 'poc.questions.question2', icon: ChairIcon },
  { title: 'poc.questions.question3', icon: SentimentSatisfiedAltIcon },
  { title: 'poc.questions.question4', icon: MultipleStopIcon },
];*/

export const QUESTIONS = [
  { title: 'How to deploy Lambda function.', icon: NearMeIcon },
  { title: 'How to setup S3 bucket', icon: ChairIcon },
  { title: 'How to create ECS.', icon: SentimentSatisfiedAltIcon },
  { title: 'How to create IAM role', icon: MultipleStopIcon },
];
