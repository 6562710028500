import { useMemo } from 'react';
import { COLORS, colors } from '../../../../../../constants/colors';
import { IStyles } from '../../../../../../interfaces/styles';

export const useStyles = (): IStyles =>
  useMemo(
    () => ({
      root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignContent: 'space-between',
      },

      form: {
        m: 0,
        width: '100%',
      },

      formIcon: {
        color: COLORS.MEDIUM_GREY,
      },

      input: {
        backgroundColor: colors.gray,
        borderRadius: 1,
        height: 50,
        color: colors.white_50,
        width: '100%',
        mt: 2,
        fontSize: 18,
        fontWeight: 500,
      },

      icon: {
        mr: 3,
      },

      groupLine: {
        width: '40%',
        height: '1px',
        borderRadius: 1,
        backgroundColor: COLORS.MEDIUM_GREY,
      },
    }),
    [],
  );
