import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../../interfaces';

import AvatarBg from '../../../../../public/images/ek/avatars/avatar-bg.png';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      position: 'relative',
      display: 'flex',
      height: '100%',
      alignItems: 'center',

      '@media (max-width: 767px)': {
        height: '100%',
      },
    },
    wrapper: {
      width: '100%',
      position: 'relative',
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 0) 45%, #DEDEDE 100%)',
      paddingTop: '52px',
      paddingBottom: '52px',
      display: 'flex',
      justifyContent: 'end',
      transform: 'translateY(-40px)',

      '@media (max-width: 767px)': {
        height: '100%',
        background: 'none',
        paddingTop: 0,
        paddingBottom: 0,
        transform: 'none',
      },

      /*'& img': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 'calc(50% - 80px)',
        transform: 'translateY(-50%) translateX(-90%)',
        maxWidth: '603px',
        maxHeight: 'calc(100vh - 200px)',
        objectFit: 'contain',
      },*/
    },
    /*content: {
      width: '50%',
      paddingX: '40px',
      [breakpoints.down(ScreenSizes.PcLarge)]: {
        paddingX: '25px',
        width: '60%',
      },

      '@media (max-width: 767px)': {
        width: '100%',
        height: '100%',
        paddingX: '30px',
      },
    },*/

    floor: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: `url(${AvatarBg})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      zIndex: -1,

      '@media (max-width: 767px)': {
        background:
          'linear-gradient(to top, #F6F6F5 15%, #E1E1E0 20%, #EFEFEE 22%, #f8f8f8 28%, #FCFCFC 38%, #FBFBFB, #FCFCFC, #FBFBFB)',
        //backgroundImage: `url(${AvatarMobileBg})`,
      },
    },
  };
};
