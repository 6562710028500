import React, {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import {
  useChannelStateContext,
  useMessageInputContext,
} from 'stream-chat-react';

import ChatInputView from './ChatInputView';

import { usePreventGameKeydownListening } from '../../../../../hooks';
import { useMytaverseEvent } from '../../../providers';

import { DefaultStreamChatGenerics as StreamChatGenerics } from 'stream-chat-react/dist/types/types';

const ChatInput = () => {
  const {
    textareaRef,
    text,
    handleChange,
    handleSubmit,
    emojiPickerIsOpen,
    openEmojiPicker,
    closeEmojiPicker,
    uploadNewFiles,
  } = useMessageInputContext();
  const { quotedMessage } = useChannelStateContext<StreamChatGenerics>();
  const { currentParticipant } = useMytaverseEvent();
  const eventListenerRef = useRef(false);

  const preventKeydownCallback = (event: Event) => {
    if ((event as KeyboardEvent).key !== 'Enter') {
      event.stopPropagation();
    }
  };

  usePreventGameKeydownListening<HTMLTextAreaElement>(
    textareaRef,
    preventKeydownCallback,
  );

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (!textareaRef.current) {
        return;
      }

      eventListenerRef.current = true;
      const isInputClicked =
        textareaRef.current.contains(event.target as Node) ||
        textareaRef.current === event.target;

      if (!isInputClicked) {
        textareaRef.current.blur();
      }
    },
    [textareaRef],
  );

  useEffect(() => {
    if (!textareaRef.current || eventListenerRef.current) {
      return;
    }

    document.addEventListener('click', handleClick);

    return () => {
      if (eventListenerRef.current) {
        document.removeEventListener('click', handleClick);
        eventListenerRef.current = false;
      }
    };
  }, [textareaRef]);

  const handleSendMessage = (event: BaseSyntheticEvent) => {
    handleSubmit(event, { userImage: currentParticipant?.avatarImage || '' });
  };

  return (
    <ChatInputView
      hasText={!!text}
      quotedMessage={quotedMessage}
      onClickEmoji={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
      onChange={handleChange}
      onSubmit={handleSendMessage}
      onSelectFiles={uploadNewFiles}
    />
  );
};

export default ChatInput;
