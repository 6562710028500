import { useTheme } from '@mui/material';

import { isTablet } from 'react-device-detect';

import OverlayBg from '../../../../../public/images/ek/overlay-bg.png';
import OverlayLandscapeBg from '../../../../../public/images/ek/overlay-landscape-bg.png';
import { colors, colorsEK } from '../../../../../constants/colors';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';

export const useStyles = () => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();

  const {
    submitButton: { backgroundColor, backgroundColorHover },
  } = appTheme.avatarPage.assets;

  return {
    root: (isLogoHidden: boolean) => ({
      position: 'fixed',
      top: 0,
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      backgroundColor: colors.white,
      width: '100%',
      height: '100%',
      background: `url(${OverlayBg})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',

      '& a': {
        display: isLogoHidden ? 'none' : 'flex',
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          background: `url(${OverlayLandscapeBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',

          '& a': {
            display: 'none',
          },
        },
    }),
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      flexDirection: 'column',
      gap: 2,
      overflow: 'auto',

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        gap: 1,
        justifyContent: 'start',
        marginTop: '-60px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        gap: 1,
        justifyContent: 'start',
        marginTop: '-60px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        gap: 1,
        justifyContent: 'start',
        marginTop: '-60px',
      },
      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          gap: 1,
          justifyContent: 'start',
          marginTop: '-10px',
        },

      [`@media only screen and (max-height: 340px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          marginTop: '-60px',
        },
    },
    content: {
      width: '84%',
      textAlign: 'center',
    },
    icon: {
      width: 134,
      height: 160,
      color: colorsEK.white,
      marginBottom: isTablet ? '53px' : '33px',
      '@keyframes rotate': {
        '0%': {
          transform: 'rotate(0deg)',
        },
        '45%': {
          transform: 'rotate(90deg)',
        },
        '100%': {
          transform: 'rotate(0deg)',
        },
      },

      '& path': {
        '@keyframes coloring': {
          '0%': {
            fill: 'black',
          },
          '50%': {
            fill: appTheme.mainColor,
          },
          '100%': {
            fill: appTheme.mainColor,
          },
        },
        animation: 'coloring 3s infinite',
      },

      animation: 'rotate 3s infinite',

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        width: 115,
        height: 96,
        animation: 'none',
        transform: 'rotate(90deg)',

        '& path': {
          animation: 'none',
          fill: appTheme.mainColor,
        },
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        width: 115,
        height: 96,
        animation: 'none',
        transform: 'rotate(90deg)',

        '& path': {
          animation: 'none',
          fill: appTheme.mainColor,
        },
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        width: 115,
        height: 96,
        animation: 'none',
        transform: 'rotate(90deg)',

        '& path': {
          animation: 'none',
          fill: appTheme.mainColor,
        },
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          width: 115,
          height: 96,
          animation: 'none',
          transform: 'rotate(90deg)',

          '& path': {
            animation: 'none',
            fill: appTheme.mainColor,
          },
        },
    },
    title: {
      fontFamily: 'Emirates-Bold !important',
      fontWeight: 700,
      fontSize: 32,
      lineHeight: '44px',
      color: colorsEK.black,
      marginBottom: '12px',
    },
    description: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '22px',
      color: '#707070',
    },
    boldText: {
      inherit: 'all',
      fontWeight: 600,
    },
    button: (isVisible: boolean) => ({
      width: '50%',
      maxWidth: '365px',
      color: colors.white,
      fontWeight: 500,
      background: backgroundColor,
      fontSize: 20,
      lineHeight: '24px',
      paddingY: '8px',
      textTransform: 'none',
      display: isVisible ? 'block' : 'none',
      mb: '10px',

      '&:hover': {
        background: backgroundColorHover,
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          display: 'block',
        },
    }),
  };
};
