import { colors } from '../../constants/colors';

import { IStyles } from '../../interfaces/styles';

const hoverStyles = {
  '&:hover': {
    backgroundColor: 'transparent',
    '& p': {
      color: colors.oxford,
      opacity: 0.8,
    },
  },
};

export const useStyles = (): IStyles => {
  return {
    dropdown: {
      m: 0,
      minWidth: 80,
      border: 'none',
      '& fieldset': {
        border: 'none',
      },
    },
    select: {
      opacity: 0.5,
      '& fieldset': {
        border: 'none',
      },
      '& .MuiSelect-select': {
        p: 0,
        '& p': {
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          color: colors.oxford,
        },
      },
      '& svg': {
        color: colors.oxford_60,
      },
    },
    menuItem: {
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        '& p': {
          color: colors.oxford,
          opacity: 0.8,
        },
        ...hoverStyles,
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      ...hoverStyles,
    },
    selectItemText: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1,
      color: colors.oxford_60,
      width: '100%',
    },
    noDevices: {
      marginLeft: 4,
    },
  };
};
