import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useAvaturn } from '../../../../../modules/auth/pages/Avaturn/hooks';
import AppHeader from '../../../components/AppHeader';
import { useIdleTimerLogout } from '../../../../../hooks/idleTimer';

const { REACT_APP_IDLE_TIMEOUT_AVATURN_MINS } = process.env;

import { useStyles } from './styles';
import LoadingProgress from '../../../../../components/LoadingProgress';
import ConfirmModal from '../../../components/ConfirmModal';

const Avaturn = () => {
  const classes = useStyles();
  const { isLoading, isExporting } = useAvaturn();
  const { t: translate } = useTranslation('common');

  useIdleTimerLogout(Number(REACT_APP_IDLE_TIMEOUT_AVATURN_MINS));

  return (
    <>
      <AppHeader isAuthorized />
      <Box
        component="div"
        sx={classes.iframeWrapper}
        id="avaturn-sdk-container"
      >
        {isLoading ? <LoadingProgress /> : null}
      </Box>
      <ConfirmModal
        icon={<AccountCircleIcon />}
        title={translate('ek.avatarExport.title')}
        message={translate('ek.avatarExport.description')}
        open={isExporting}
      />
    </>
  );
};

export default Avaturn;
