import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useMytaverseEvent } from '../../../providers';
import { StreamingProviders } from '../../../providers/MytaverseEventProvider/interfaces';
import React, { useRef } from 'react';
import { useStyles } from './styles';
import useAsyncEffect from 'use-async-effect';
import { useZoom } from '../../DashboardContent/Zoom/ZoomProvider';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { colors } from '../../../../../constants/colors';

const ConfigTab = () => {
  const { initMessageHandler, streamingProvider, currentRoom, currentRegion } =
    useMytaverseEvent();
  const { connectedPod } = useMytaverseEvent();
  const classes = useStyles();
  const {
    zoomConferenceName,
    isLimitedScreenResolution,
    setIsLimitedScreenResolution,
  } = useZoom();
  const currentInitMessage = useRef('');
  const { isDebugMode, setIsDebugMode } = useMytaverse();

  useAsyncEffect(async () => {
    if (!initMessageHandler || currentInitMessage.current) {
      return;
    }
    const message = await initMessageHandler();

    if (!message) {
      return;
    }

    currentInitMessage.current = JSON.stringify(message);
  }, [initMessageHandler]);

  const handleCopy = () => {
    navigator.clipboard.writeText(currentInitMessage.current);
  };

  return (
    <Box sx={classes.root} className="no-drag">
      <FormControlLabel
        sx={{ color: colors.white }}
        control={
          <Checkbox
            checked={isDebugMode}
            onChange={() => {
              localStorage.setItem('isDebugMode', String(!isDebugMode));
              setIsDebugMode(!isDebugMode);
            }}
            sx={{ color: colors.white }}
          />
        }
        label="Editor Debug Mode"
      />
      <FormControlLabel
        sx={{ color: colors.white }}
        control={
          <Checkbox
            checked={isLimitedScreenResolution}
            onChange={() => {
              setIsLimitedScreenResolution(!isLimitedScreenResolution);
            }}
            sx={{ color: colors.white }}
          />
        }
        label="Zoom Limited Resolution"
      />
      <Typography>Streaming Provider: {streamingProvider}</Typography>
      <Typography>Current Room: {currentRoom?.roomId || '---'}</Typography>
      <Typography>Current Region: {currentRegion?.region || '---'} </Typography>
      <Typography>Zoom Conference: {zoomConferenceName || '---'}</Typography>
      {streamingProvider === StreamingProviders.CoreWeave && (
        <Typography>Connected Pod: {connectedPod || '---'}</Typography>
      )}
      <Typography>
        Init Message
        <IconButton
          sx={{ cursor: 'pointer', color: 'white' }}
          onClick={handleCopy}
        >
          <ContentCopyIcon />
        </IconButton>
        : {currentInitMessage.current || '---'}
      </Typography>
    </Box>
  );
};

export default ConfigTab;
