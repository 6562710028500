import { useTheme } from '@mui/material';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    modal: {
      position: 'fixed',
      top: '50%',
      right: '40px',
      left: 'auto',
      bottom: 'auto',
      transform: 'translate(0, -50%)',
      background: 'transparent',
      outline: 'none',
    },
    box: (flexDirectionRow = false) => ({
      display: 'flex',
      flexDirection: flexDirectionRow ? 'row' : 'column',
      justifyContent: 'center',
      alignItems: flexDirectionRow ? 'start' : 'center',
      gap: 2,
      outline: 'none',
    }),
  };
};
