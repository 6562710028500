import React from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LanguageIcon from '@mui/icons-material/Language';

import { ILanguageButtonView } from './interfaces';
import { LANGUAGES } from '../../constants/language';

import { useStyles } from './styles';

const LanguageButtonView = ({
  anchorEl,
  onClick,
  onClose,
  selectedLanguage,
  onItemClick,
}: ILanguageButtonView) => {
  const classes = useStyles();

  const open = !!anchorEl;

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : ''}
        aria-haspopup="true"
        aria-expanded={open}
        disableRipple
        onClick={onClick}
        sx={classes.button}
      >
        <LanguageIcon sx={classes.languageIcon} />
        <Typography sx={classes.selectedLanguage}>
          {selectedLanguage}
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {LANGUAGES.map(({ key, label }) => (
          <MenuItem key={key} onClick={() => onItemClick(key)} lang={key}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageButtonView;
