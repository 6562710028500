import React, { useCallback } from 'react';

import SearchChatView from './SearchChatView';

import { useSearchField } from '../../../SearchField';
import { useRedirectToPrivateChat } from '../../../../../../hooks/channel';
import { useGetSearchChatList } from './hooks';

import { sendCustomSentryBreadcrumb } from '../../../../../../helpers/sentry';

import { ISearchChatItem } from './interfaces';

const SearchChat = () => {
  const redirectToPrivateChat = useRedirectToPrivateChat();
  const searchChatList = useGetSearchChatList();
  // const { inputValue, filteredData, handleInputChange, handleInputClear } =
  //   useSearchField<ISearchChatItem>(searchChatList, 'participant.fullName');

  const { inputValue, filteredData, handleInputChange, handleInputClear } =
    useSearchField<ISearchChatItem>(searchChatList, 'participant.name');

  const handleItemClick = useCallback((participantUserId: string) => {
    const chatMemberId = participantUserId.replace('|', '_');
    sendCustomSentryBreadcrumb({
      message: `open private chat with userId=${chatMemberId}`,
    });
    redirectToPrivateChat(chatMemberId);
  }, [redirectToPrivateChat]);

  return (
    <SearchChatView
      results={filteredData}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onInputClear={handleInputClear}
      onItemClick={handleItemClick}
    />
  );
};

export default SearchChat;
