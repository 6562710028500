export const GAME_CAST_REQUEST_TIMEOUT = 0.2 * 60 * 1000;

export const disallowKeyCode = [
  'Backquote',
  ...Array.from({ length: 12 }, (_, i) => `F${i + 1}`),
  'Quote', // debug overlay, GameCast lib doesn't filter this key despite returning false in relevant piece of code
           // as outlined in their docs
  'KeyM', // keyboard bindings menu
  'KeyP', // laser pointer
  'BracketLeft' // switch day/time in IoW
];