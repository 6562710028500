import { styled } from '@mui/material/styles';
import { colorsEK } from '../../../../../../../../constants/colors';

import { SkinToneType } from '../../../interfaces';

const getSkinColor = (tone: SkinToneType) => {
  switch (tone) {
    case SkinToneType.LIGHT:
      return colorsEK.skin_light;
    case SkinToneType.MEDIUM_LIGHT:
      return colorsEK.skin_medium_light;
    case SkinToneType.MEDIUM:
      return colorsEK.skin_medium;
    case SkinToneType.MEDIUM_DARK:
      return colorsEK.skin_medium_dark;
    case SkinToneType.DARK:
      return colorsEK.skin_dark;
    case SkinToneType.VERY_DARK:
      return colorsEK.skin_very_dark;
  }
};

export const Icon = styled('span')(({ variant }: { variant: SkinToneType }) => {
  const color = getSkinColor(variant);

  return {
    borderRadius: '50%',
    width: 60,
    height: 60,
    boxShadow: 'none',
    background: 'transparent',
    border: `2px solid transparent`,

    '&::before': {
      borderRadius: '50%',
      display: 'block',
      width: 48,
      height: 48,
      background: color,
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  };
});

export const CheckedIcon = styled(Icon)({
  borderColor: '#979797',

  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
});

export const useStyles = () => {
  return {
    genderRadio: {
      padding: 0,
    },
  };
};
