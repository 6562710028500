import { useCallback } from 'react';

export const useEvenHostname = () => {
  const getEventHostname = useCallback(
    () => window.location.hostname.split('.')[0],
    [],
  );

  const isAwsEvent = useCallback(() => getEventHostname() === 'aws', []);

  return { isAwsEvent, getEventHostname };
};
