import { ReactComponent as Chrome } from '../../../../../../public/images/ChromeLogo.svg';
import { ReactComponent as Safari } from '../../../../../../public/images/SafariLogo.svg';

import chromeStep1 from '../../../../../../public/images/fullscreen/chrome/step1.png';
import chromeStep2 from '../../../../../../public/images/fullscreen/chrome/step2.png';
import chromeStep3 from '../../../../../../public/images/fullscreen/chrome/step3.png';

import safariStep1 from '../../../../../../public/images/fullscreen/ios/step1.png';
import safariStep2 from '../../../../../../public/images/fullscreen/ios/step2.png';
import safariStep3 from '../../../../../../public/images/fullscreen/ios/step3.png';

export const CHROME = {
  title: 'Chrome',
  Icon: Chrome,
  device: 'Android Devices',
  steps: [
    {
      text: 'fullscreenTutorial.step1',
      image: chromeStep1,
    },
    {
      text: 'fullscreenTutorial.step2',
      image: chromeStep2,
    },
    {
      text: 'fullscreenTutorial.step3',
      image: chromeStep3,
    },
  ],
};

export const SAFARI = {
  title: 'Safari',
  Icon: Safari,
  device: 'Safari Browser',
  steps: [
    {
      text: 'fullscreenTutorial.step1',
      image: safariStep1,
    },
    {
      text: 'fullscreenTutorial.step2',
      image: safariStep2,
    },
    {
      text: 'fullscreenTutorial.step3',
      image: safariStep3,
    },
  ],
};

export const IOS_CHROME = {
  ...SAFARI,
  title: 'Chrome',
  Icon: Chrome,
  device: 'iPhones / iPads',
};
