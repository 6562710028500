import React from 'react';
import { Button } from '@mui/material';

import { EscKey } from '../constants';
import { useStyles } from './styles';
import { IKeyButton } from './interfaces';

const KeyButton = ({ name, isActive, onClick = () => {} }: IKeyButton) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      sx={{
        ...classes.button(isActive),
        ...(EscKey === name ? classes.escape : {}),
      }}
    >
      {name}
    </Button>
  );
};

export default KeyButton;
