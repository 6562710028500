import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../../../interfaces';
import { colorsEK } from '../../../../../../constants/colors';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      '& .MuiBackdrop-root': {
        backgroundColor: '#0C0B0B99',
        backdropFilter: 'blur(12px)',
      },
    },
    message: {
      fontFamily: '"Noto Sans", sans-serif',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.4,
      textAlign: 'center',
      width: '100%',
      letterSpacing: '-0.04em',
      color: colorsEK.white,
    },
    container: {
      padding: 4,
      width: 454,
      background: '#0C0B0B99',
      borderRadius: 6,
      gap: 3,
      border: `1px solid ${colorsEK.grey_400}`,

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: 4,
      },

      '@media (max-width: 767px)': {
        padding: 4,
      },
    },
    button: {
      fontFamily: '"Noto Sans", sans-serif',
      paddingY: 1,
      paddingX: 6,
      fontSize: 14,
      fontWeight: 500,
      borderRadius: '99px',
      width: 'content-width',
      maxWidth: '50%',
      borderWidth: '1px',
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      gap: 2,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cancelButton: {
      color: colorsEK.grey_300,
      borderColor: colorsEK.grey_300,
      background: 'transparent',
    },
    close: {
      position: 'absolute',
      top: 18,
      right: 18,
      fontSize: 10,
      color: colorsEK.grey_200,

      cursor: 'pointer',
    },
  };
};
