import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import { ScreenShare } from '@mui/icons-material';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';

import HomeBarButton from '../../HomeBarButton';
import WarningButtonBadge from '../../WarningButtonBadge';
import WebcamSharingButton from './WebcamSharing';
import SettingsButton from '../SettingsButton';

import { DolbyService, useConference } from '../../DashboardContent/Dolby';
import { useMytaverseEvent } from '../../../providers';
import { useIsSpeakerMutedNotificationShown } from '../../DashboardContent/Dolby/hooks';
import { useModal } from '../../../../../hooks/modal';
import { useShowInitialMuteModal } from './hooks';
import { useNotification } from '../../../../../components/Notification';

import { sendCustomSentryBreadcrumb } from '../../../../../helpers/sentry';
import { getCatchErrorMessage } from '../../../../../helpers/error';

import { useStyles } from './styles';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { IXRHrPersonaTypes } from '../../../../../interfaces/profile';
import OfficeHoursButton from '../../../../../ek/modules/dashboard/components/HomeBottomBar/OfficeHoursButton';
import { useZoom } from '../../DashboardContent/Zoom/ZoomProvider';

const { REACT_APP_SINGLE_PLAYER_MAP } = process.env;

const HomeBottomButtonOptions = () => {
  const classes = useStyles();

  const {
    microphones,
    isConferenceInitialized,
    isFirstPersonView,
    setIsFirstPersonView,
    disabledPersonView,
    isConferenceListener,
    screenMediaStreams,
    ueScreenScreenName,
    ueScreenMediaStreams,
  } = useConference();
  const {
    muted,
    setMuted,
    currentEvent,
    currentRoom,
    currentParticipant,
    canSpeak,
  } = useMytaverseEvent();

  const {
    startScreenSharing,
    stopScreenSharing,
    isSharingScreenToUE,
    isJoiningConference,
  } = useZoom();

  const { sendMessageToUnreal } = useMytaverse();
  const { speakerMutedNotification, resetSpeakerMutedNotification, showPulse } =
    useIsSpeakerMutedNotificationShown(isConferenceInitialized, muted);
  const { t: translate } = useTranslation('common');

  const initialMuteModalData = useModal();

  const currentParticipantId = currentParticipant?.participantId || '';
  const microDeviceId = microphones.length ? microphones[0].deviceId : '';

  // const showDolbyShareScreen = !!currentParticipant?.isSpeaker;

  const currentScreenSharing = !!ueScreenMediaStreams.find(
    (s) => s.participantId === currentParticipantId,
  );

  useShowInitialMuteModal({
    eventId: currentEvent?.eventId || '',
    initialMuteModalData,
  });

  const handleMicroClick = () => {
    sendCustomSentryBreadcrumb({
      message: `${!muted ? 'mute' : 'unmute'} micro`,
    });
    setMuted();
    resetSpeakerMutedNotification(speakerMutedNotification);
  };

  const handleDolbyShareScreenClick = async () => {
    try {
      if (isSharingScreenToUE) {
        sendCustomSentryBreadcrumb({ message: 'Stop zoom share screen' });
        console.log('Stop zoom share screen');
        await stopScreenSharing();
      } else {
        sendCustomSentryBreadcrumb({ message: 'Start zoom share screen' });
        console.log('Start zoom share screen');
        await startScreenSharing();
      }
    } catch (error: unknown) {
      if (canSpeak) {
        //showNotification(getDolbyNotification());
      }
      throw Error(getCatchErrorMessage(error));
    }
  };

  const handleSetPersonView = useCallback(() => {
    const newStateIsFirstPersonView = !isFirstPersonView.flag;

    sendMessageToUnreal({
      action: 'TOGGLE_VIEW',
      view: newStateIsFirstPersonView ? 'FIRST_PERSON' : 'THIRD_PERSON',
    });

    // setIsFirstPersonView((prevState) => ({
    //   ...prevState,
    //   flag: newStateIsFirstPersonView,
    // }));
  }, [sendMessageToUnreal, setIsFirstPersonView, isFirstPersonView]);

  return (
    <>
      {canSpeak ? (
        <>
          <WebcamSharingButton />

          <HomeBarButton
            label={translate('tooltips.toggleMic')}
            handleClick={handleMicroClick}
            showPulseAnimation={!!showPulse}
            isRed={muted && !!microDeviceId}
            disabled={isJoiningConference || isConferenceListener}
            icon={
              <>
                {muted && !!microDeviceId ? (
                  <WarningButtonBadge
                    classes={{
                      root: classes.badge(microDeviceId),
                      tooltip: classes.badgeMic(microDeviceId),
                    }}
                  >
                    <MicOffIcon />
                  </WarningButtonBadge>
                ) : (
                  <MicIcon />
                )}
              </>
            }
          />

          {canSpeak && !isMobile && ueScreenScreenName && (
            <HomeBarButton
              label="Share Screen"
              icon={
                <ScreenShare
                  sx={classes.stop(isSharingScreenToUE && currentScreenSharing)}
                />
              }
              disabled={
                isJoiningConference ||
                (!!ueScreenMediaStreams.length && !currentScreenSharing)
              }
              handleClick={handleDolbyShareScreenClick}
            />
          )}
        </>
      ) : (
        ''
      )}

      <HomeBarButton
        label="Toggle Person View"
        icon={isFirstPersonView.flag ? <PersonIcon /> : <VisibilityIcon />}
        disabled={disabledPersonView}
        handleClick={handleSetPersonView}
      />
      <SettingsButton />
      {currentRoom?.map?.ueIdentifier === REACT_APP_SINGLE_PLAYER_MAP ? (
        <OfficeHoursButton />
      ) : (
        ''
      )}
    </>
  );
};

export default HomeBottomButtonOptions;
