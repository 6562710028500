import React, { MutableRefObject, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

import { useConference } from '../../../DashboardContent/Dolby';
import { useMytaverseEvent } from '../../../../providers';
import { sendCustomSentryBreadcrumb } from '../../../../../../helpers/sentry';

import ParticipantAvatar from '../../../DashboardContent/ParticipantAvatar';
import WarningButtonBadge from '../../../WarningButtonBadge';
import HomeBarButton from '../../../HomeBarButton';
import DolbyShareWebcamButton from './DolbyShareWebcamButton';

import { useStyles } from '../styles';
import { useZoom } from '../../../DashboardContent/Zoom/ZoomProvider';
import { isMobile } from 'react-device-detect';
import useAsyncEffect from 'use-async-effect';
import { useConferenceState } from '../../../../../../hooks/conferenceContext';

const WebcamSharing = () => {
  const classes = useStyles();

  const { ueWebcamScreenName, isConferenceListener, videoStarted } =
    useConference();

  const {
    mediaStream,
    isJoiningConference,
    cameraList,
    isCameraOn,
    handleToggleVideoSharing,
  } = useZoom();

  const { activeCameraDeviceId } = useConferenceState();

  const videoRef = React.useRef<HTMLVideoElement | null>(null);

  const { currentParticipant } = useMytaverseEvent();

  const { t: translate } = useTranslation('common');

  const showDolbyShareWebcam = !!ueWebcamScreenName;

  const cameraDeviceId =
    mediaStream?.getActiveCamera() || activeCameraDeviceId || cameraList.length
      ? cameraList[0]?.deviceId
      : '';

  const currentTracks = React.useRef<MediaStreamTrack[] | null>(null);

  const handleVideoClick = async () => {
    sendCustomSentryBreadcrumb({
      message: `${!videoStarted ? 'start' : 'stop'} video`,
    });
    await handleToggleVideoSharing(
      videoRef as MutableRefObject<HTMLVideoElement>,
    );
  };

  const showWarningButtonBadge = useCallback(
    (icon: ReactNode) => {
      return (
        <WarningButtonBadge classes={{ root: classes.badge(!!cameraDeviceId) }}>
          {icon}
        </WarningButtonBadge>
      );
    },
    [cameraDeviceId, isMobile],
  );

  useAsyncEffect(async () => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: { deviceId: { exact: activeCameraDeviceId } },
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          currentTracks.current = stream.getTracks();
        }
      } catch (err) {
        console.error('Error accessing camera:', err);
      }
    };

    const stopCamera = () => {
      if (currentTracks.current) {
        currentTracks.current.forEach((track) => {
          track.stop();
        });
        currentTracks.current = null;
      }
    };

    if (isCameraOn) {
      await startCamera();
    } else {
      stopCamera();
    }

    return () => {
      stopCamera();
    };
  }, [isCameraOn, videoRef, activeCameraDeviceId]);

  if (isCameraOn && !!cameraDeviceId) {
    return (
      <ParticipantAvatar participant={currentParticipant} videoRef={videoRef} />
    );
  }

  if (!isJoiningConference && showDolbyShareWebcam) {
    return (
      <DolbyShareWebcamButton
        cameraDeviceId={activeCameraDeviceId as string}
        onVideoClick={handleVideoClick}
        iconColorToggle={classes.stop}
        showWarningBadge={showWarningButtonBadge}
      />
    );
  }

  return (
    <HomeBarButton
      label={translate('tooltips.toggleCam')}
      handleClick={handleVideoClick}
      disabled={isConferenceListener || isJoiningConference}
      isRed
      icon={
        <WarningButtonBadge classes={{ root: classes.badge(!!cameraDeviceId) }}>
          <VideocamOffIcon />
        </WarningButtonBadge>
      }
    />
  );
};

export default WebcamSharing;
