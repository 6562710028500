import React from 'react';
import { SwipeableDrawer } from '@mui/material';
import { isIOS } from 'react-device-detect';

import DrawerHeader from './DrawerHeader';

import { IDrawerView } from './interfaces';
import { classes } from './styles';

const DrawerView = ({
  isOpen,
  onClick,
  children,
  title,
  icon,
  disableHeader,
}: IDrawerView) => {
  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      onClose={onClick}
      onOpen={onClick}
      disableBackdropTransition={!isIOS}
      disableDiscovery={isIOS}
      sx={classes.root}
    >
      {!disableHeader && (
        <DrawerHeader onClick={onClick} title={title} icon={icon} />
      )}
      {children}
    </SwipeableDrawer>
  );
};

export default DrawerView;
