import { Box, IconButton, Typography } from '@mui/material';
import React, { CSSProperties, useEffect } from 'react';
import ZoomSharingComponents from './ZoomSharingComponents';
import CloseIcon from '@mui/icons-material/Close';
import { useZoom } from '../ZoomProvider';
import { useStyles } from './styles';
import { useConference } from '../../Dolby';
import useAsyncEffect from 'use-async-effect';

const ZoomScreenPreview = () => {
  const { ueScreenMediaStreams } = useConference();
  const {
    expandedScreenSharing,
    setExpandedScreenSharing,
    mediaStream,
    zoomUser,
  } = useZoom();
  const classes = useStyles();

  useAsyncEffect(async () => {
    if (!mediaStream || !ueScreenMediaStreams || !expandedScreenSharing) {
      return;
    }

    if (
      ueScreenMediaStreams[0] &&
      (ueScreenMediaStreams[0] as any).zoomUserId &&
      zoomUser?.userId !== (ueScreenMediaStreams[0] as any).zoomUserId
    ) {
      const element = document.getElementById(
        'share-view-canvas',
      ) as HTMLCanvasElement;

      await mediaStream.startShareView(
        element,
        (ueScreenMediaStreams[0] as any).zoomUserId,
      );
    }
  }, [mediaStream, ueScreenMediaStreams, expandedScreenSharing, zoomUser]);

  const handleClose = () => {
    setExpandedScreenSharing(false);
  };

  return (
    <Box sx={expandedScreenSharing ? classes.root : classes.notExpandedRoot}>
      {expandedScreenSharing && ueScreenMediaStreams ? (
        <Typography className="user-name" sx={classes.userName}>
          {ueScreenMediaStreams[0]?.owner.name}
        </Typography>
      ) : null}
      {expandedScreenSharing ? (
        <IconButton
          className="close-button"
          sx={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon sx={classes.closeIcon} />
        </IconButton>
      ) : null}
      <ZoomSharingComponents
        style={
          expandedScreenSharing
            ? (classes.zoomPreview as CSSProperties)
            : undefined
        }
      />
    </Box>
  );
};

export default ZoomScreenPreview;
