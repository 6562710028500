import { useTheme } from '@mui/material';
import { COLORS } from '../../../../constants/colors';
import { ScreenSizes } from '../../../../interfaces';

export const useStyles = (open: boolean) => {
  const { breakpoints } = useTheme();

  return {
    button: {
      backgroundColor: open ? COLORS.BACKGROUND_COLOR : COLORS.DARK_GREY,
    },
    popover: {
      top: '-50px',
      '& .MuiPopover-paper': {
        backdropFilter: 'blur(12px)',
        backgroundColor: COLORS.NEW_BLACK,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        maxHeight: 'calc(100vh - 40px)',
        top: '-40px',
      },
    },
  };
};
