import React, { useState } from 'react';

/*import AppLoader from '../../../AppLoader';
import LoadingProgressTutorial from '../../../LoadingProgressTutorial';*/
import AppLoader from '../../../../../../ek/modules/dashboard/components/AppLoader';
import LoadingProgressTutorial from '../../../../../../ek/modules/dashboard/components/LoadingProgressTutorial';

import { useCoreWeaveProgressBar } from './hooks';
import NavigationScreen from '../../../../../../ek/modules/dashboard/components/NavigationScreen/NavigationScreen';
import { isDesktop } from 'react-device-detect';
import { useEventContext } from '../../../../../../ek/providers/EventProvider';
import { useLandscapeDetection } from '../../../../../../hooks/orientation';
import { useMytaverseEvent } from '../../../../providers';

const CoreWeaveLoader = () => {
  const [showNavigation, setShowNavigation] = useState(false);
  const { isEventRun } = useEventContext();
  const isLandscape = useLandscapeDetection();
  const { currentRoom } = useMytaverseEvent();

  const {
    secondStepLoading,
    secondStepCompleted,
    thirdStepLoading,
    thirdStepCompleted,
    forthStepLoading,
    forthStepCompleted,
  } = useCoreWeaveProgressBar({ setShowNavigation });

  if (!isEventRun && showNavigation) {
    return <NavigationScreen isLandscape={isLandscape && !isDesktop} />;
  }

  return (
    <AppLoader>
      <LoadingProgressTutorial
        secondStepLoading={secondStepLoading}
        secondStepCompleted={secondStepCompleted}
        thirdStepLoading={thirdStepLoading}
        thirdStepCompleted={thirdStepCompleted}
        forthStepLoading={forthStepLoading}
        forthStepCompleted={forthStepCompleted}
      />
    </AppLoader>
  );
};

export default CoreWeaveLoader;
