import React, { MouseEvent } from 'react';

import { Button, Typography } from '@mui/material';

import { IDashboardBarButton } from './interfaces';

import { useStyles } from './styles';

const DashboardBarButton = ({
  children,
  buttonIcon,
  onClick,
  sx = {},
}: IDashboardBarButton) => {
  const classes = useStyles(!!children);
  const rootSx = { ...classes.root, ...sx };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.blur();
    onClick();
  };

  return (
    <Button sx={rootSx} onClick={handleClick}>
      {buttonIcon && buttonIcon}
      <Typography sx={classes.typography}>{children}</Typography>
    </Button>
  );
};

export default DashboardBarButton;
