export const classes = {
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    justifyItems: 'center',
    padding: '17px 23px',
    height: 'calc(100% - 60px)',
    alignItems: 'center',
  },
  button: {
    p: 0,
  },
};
