import { SxProps, useTheme } from '@mui/material';

import { useMytaverse } from '../../../../providers/MytaverseProvider';
import { colors, colorsEK } from '../../../../constants/colors';
import { ScreenSizes } from '../../../../interfaces';

export const useModalStyles = () => {
  const { appTheme } = useMytaverse();
  const { breakpoints } = useTheme();

  const {
    submitButton: { backgroundColorHover },
  } = appTheme.avatarPage.assets;

  return {
    root: {
      '& .MuiBackdrop-root': {
        backgroundColor: '#00000080',
      },
    },
    container: {
      display: 'flex',
      gap: 1,
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'start',
      padding: '21px 61px 62px 71px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 953,

      background: colorsEK.white,

      borderRadius: 1.5,
      maxHeight: '90vh',
      maxWidth: '90vw',
      overflow: 'auto',

      '&:focus-visible': {
        outline: 'none',
      },

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: '21px 56px 56px 56px',
      },

      '@media (max-width: 767px)': {
        padding: '20px 32px 32px 32px',
        width: '100%',
        height: '100%',
        maxHeight: '100dvh',
        maxWidth: '100dvw',
        borderRadius: 0,
      },
    } as SxProps,
    closeIcon: {
      position: 'absolute',
      top: 33,
      right: 30,
      color: '#939393',
      fontSize: 16,
      cursor: 'pointer',

      '@media (max-width: 767px)': {
        top: 32,
        right: 32,
      },
    },
    iconContainer: {
      display: 'flex',
      background: appTheme.mainColor,
      borderRadius: 9,
      padding: 1.25,
      '& svg': {
        color: colorsEK.white,
        width: 20,
        height: 20,
      },
    },
    title: {
      fontFamily: 'Emirates-Bold !important',
      fontWeight: 700,
      fontSize: 40,
      lineHeight: 1.4,
      color: colorsEK.black,

      '@media (max-width: 767px)': {
        fontSize: 28,
        lineHeight: 1.4,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
      mt: 1.5,
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      gap: 4,
      mt: 9,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',

      '@media (max-width: 767px)': {
        flexDirection: 'column',
        gap: 2,
        mt: 'auto',
      },
    },
    button: {
      padding: '12px',
      maxWidth: 365,
      width: '50%',
      fontWeight: 500,
      borderRadius: '6px',
      fontSize: 24,
      lineHeight: 1.125,
      textTransform: 'none',
      border: `2px solid ${appTheme.mainColor}`,
      marginTop: 'auto',

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        borderWidth: '1px',
      },

      '@media (max-width: 767px)': {
        width: '100%',
        maxWidth: 'unset',
      },

      '@media (max-width: 375px)': {
        fontSize: 16,
        lineHeight: '22px',
        paddingY: '8px',
      },
    },
    buttonPrimary: {
      color: colorsEK.white,
      backgroundColor: appTheme.mainColor,

      '&:hover': {
        backgroundColor: backgroundColorHover,
      },

      '&:disabled': {
        color: colorsEK.white,
        background: `${appTheme.mainColor}4D`,
        cursor: 'no-drop',
      },
    },
    buttonSecondary: {
      backgroundColor: colors.white,
      color: appTheme.mainColor,

      '&:hover': {
        color: colors.white,
        background: backgroundColorHover,
      },

      '&:disabled': {
        color: colors.white,
        borderColor: 'transparent',
        background: `${appTheme.mainColor}4D`,
      },
    },
  };
};
