import React from 'react';

import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { IToolboxHeader } from './interfaces';

import { useStyles } from './styles';

const ToolboxHeader = ({ icon: Icon, text, onClose }: IToolboxHeader) => {
  const classes = useStyles();

  return (
    <Box sx={classes.header}>
      <Box sx={classes.headerText}>
        <Icon />
        <Typography sx={classes.text}>{text}</Typography>
      </Box>
      <CloseIcon sx={classes.closeIcon} onClick={onClose} />
    </Box>
  );
};

export default ToolboxHeader;
