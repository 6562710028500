import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import AppModal from '../../../../../../components/AppModal';

import { OfficeHoursModalViewProps } from './interfaces';

import { useStyles } from './styles';
import { useOfficeHours } from './provider';

const OfficeHoursModalView = ({
  open,
  handleClose,
  t: translate,
  children,
}: OfficeHoursModalViewProps) => {
  const classes = useStyles();

  const { resetState, loadSlots } = useOfficeHours();

  useEffect(() => {
    if (open) {
      loadSlots();
    } else {
      resetState();
    }
  }, [open, resetState, loadSlots]);

  return (
    <AppModal
      onClick={handleClose}
      isOpen={open}
      sx={classes.modal}
      // disableMobileDrawer
    >
      <Box sx={classes.title} component="div">
        <Box component="div" sx={classes.titleWrapper}>
          <Typography sx={classes.titleName}>
            {translate('ek.officeHours.title')}
          </Typography>
        </Box>
        <IconButton onClick={handleClose} sx={classes.titleButton}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={classes.content}>{children}</Box>
    </AppModal>
  );
};

export default withTranslation('common')(OfficeHoursModalView);
