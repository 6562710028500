import { useTheme } from '@mui/material';
import { COLORS } from '../../../../../constants/colors';

import { IStyles } from '../../../../../interfaces/styles';
import { ScreenSizes } from '../../../../../interfaces';
import { IDashboardTopBarContentStyles } from './interfaces';
import { useMytaverseEvent } from '../../../providers';
import { DEVICES_LANDSCAPE_SIZES } from '../../../../../constants/devicesSizes';

export const useStyles = ({
  isOpenChat,
  hasMediaStream,
}: IDashboardTopBarContentStyles): IStyles => {
  const { breakpoints } = useTheme();
  const { isMinimizedScreenSharing } = useMytaverseEvent();

  return {
    root: {
      display: ['none', 'flex'],
      mt: 1,
      columnGap: 2,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        mt: 0,
        columnGap: 1,
      },
    },
    rootLoading: {
      display: 'flex',
      mt: 1,
    },
    iconButton: {
      display: ['block', 'none'],
      padding: 0,
    },
    stack: {
      alignItems: 'center',
      columnGap: 2,
      padding: '8px 24px 0',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        columnGap: 1,
        px: 2,
        pt: 1,
      },
    },
    inviteUserBtn: {
      width: 40,
      height: 40,
      minWidth: 40,
    },
    addPersonIcon: {
      color: COLORS.GREY,
    },
    logoutIcon: { color: 'red' },
    fullscreen: {
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: '6px 10px',
        minWidth: 'unset',
        fontSize: 14,
        '& svg': {
          mr: 0,
        },
      },
    },
    leftBar: {
      width: 'auto',
      left: 0,
      right: 'unset',
    },
    rightBar: {
      width: 'auto',
      left: 'unset',
      right:
        isOpenChat || (hasMediaStream && !isMinimizedScreenSharing) ? 400 : 0,

      [breakpoints.down(DEVICES_LANDSCAPE_SIZES.MOBILE)]: {
        right:
          isOpenChat || (hasMediaStream && !isMinimizedScreenSharing)
            ? '50%'
            : 0,
      },
    },
  };
};
