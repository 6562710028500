import { useTheme } from '@mui/material';

import { COLORS, colors } from '../../../constants/colors';
import { ScreenSizes } from '../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      height: '100%',
      display: 'grid',
      gridTemplateRows: '1fr auto',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 3,
      maxWidth: '100%',
      px: 3,
      py: 2,
      overflow: 'hidden',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        py: 1.5,
      },
    },
    userData: {
      width: '100%',
      overflow: 'hidden',
      '& .MuiBox-root:last-child': {
        mb: 0,
      },
    },
    name: {
      fontSize: [28, 22],
      color: colors.white,
      borderBottom: '1px solid transparent',
      lineHeight: '26px',
      fontWeight: 500,
      mb: 0.3,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        mb: 0.2,
      },
    },
    company: {
      color: COLORS.PROFILE_GREY,
      fontSize: 16,
      letterSpacing: 0.1,
      lineHeight: '24px',
      mb: 2.5,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        mb: 2,
      },
    },
    text: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 0.1,
      color: COLORS.WHITE,
    },
    handledText: (hasCursor = false) => ({
      cursor: hasCursor ? 'pointer' : 'auto',
    }),
    button: {
      backgroundColor: colors.gray,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
      px: 2.5,
      py: 2,
      gap: 3,
    },
    actionBtn: {
      p: 1,
      width: 'auto',
      display: 'flex',
      background: colors.white_30,
      whiteSpace: 'nowrap',
      gap: 1,
      color: COLORS.BG,
      border: `1px solid transparent`,
      '&:hover': {
        borderColor: colors.white_30,
      },
    },
    actionIcon: {
      color: 'inherit',
      height: 16,
      width: 16,
    },
    actionText: {
      textTransform: 'capitalize',
      color: colors.white,
      fontSize: 14,
      fontWeight: 600,
    },
    followMeBtn: {
      backgroundColor: COLORS.WHITE,
      color: colors.black,
      '&:hover': {
        borderColor: COLORS.WHITE,
      },
    },
    followMeText: {
      color: colors.black,
    },
  };
};
