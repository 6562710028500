import { useMytaverse } from '../../../../../../../providers/MytaverseProvider';
import { DEVICES_LANDSCAPE_SIZES } from '../../../../../../../constants/devicesSizes';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const { appTheme } = useMytaverse();
  const { breakpoints } = useTheme();

  return {
    root: {
      background: 'rgba(255, 255, 255, 0.9)',
      width: '344px',
      padding: '16px 8px',
      display: 'flex',
      flexDirection: 'column',
      gap: 2,

      '@media (max-width: 1400px)': {
        width: '300px',
        gap: 1,
      },

      [breakpoints.down(DEVICES_LANDSCAPE_SIZES.TABLET)]: {
        width: '240px',
      },
    },
    text: {
      fontSize: 24,
      fontWeight: 500,
      color: appTheme.mainColor,
      textAlign: 'center',

      '@media (max-width: 1400px)': {
        fontSize: 18,
      },
    },
    helper: {
      display: 'flex',
      gap: 2,
      alignItems: 'center',

      '@media (max-width: 1400px)': {
        gap: 1,
      },
    },
    helperText: {
      fontSize: 24,
      fontWeight: 500,

      '@media (max-width: 1400px)': {
        fontSize: 18,
      },
    },
  };
};
