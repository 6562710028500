import { useTheme } from '@mui/material';
import { colors } from '../../../../../../constants/colors';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    box: {
      width: 207,
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      background: '#0C0B0B99',
      padding: 2,
      borderRadius: 6,
      border: `1px solid ${colors.white_90}`,
      backdropFilter: 'blur(60px)',
      // border-image-source: linear-gradient(167.37deg, rgba(255, 255, 255, 0.54) 6.62%, rgba(255, 255, 255, 0.12) 105.27%);
    },
    button: {
      borderRadius: 99,
      border: '1px solid white',
      padding: 1.5,
      fontSize: 14,
      lineHeight: 1,
      textTransform: 'none',
      color: colors.white,
    },
    blueButton: {
      background: colors.myta,
    },
    hideButton: {
      marginTop: 1,
      padding: 0,
      textTransform: 'none',
      fontSize: 14,
      lineHeight: 1.5,
      color: colors.white,
    },
  };
};
