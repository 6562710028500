import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';

import {
  NOTIFICATION_TYPES,
  useNotification,
} from '../components/Notification';

import { getNotification } from '../components/Notification/helpers';
import { defaultIdleTimeout } from '../constants/idleTimeout';

import { useNavigate } from 'react-router-dom';
import ROUTES from '../constants/routes';

// this method doesn't apply to iframe content such as Avaturn
// so logout will happen in 20 min regardless of user's actions

export const useIdleTimerLogout = (timeout: number) => {
  const { showNotification } = useNotification();
  const { t: translate } = useTranslation('common');
  const navigate = useNavigate();

  useIdleTimer({
    timeout: +(timeout || defaultIdleTimeout) * 60000,
    onIdle: () => {
      navigate(ROUTES.SELECT_EVENT),
      showNotification(
        getNotification({
          message: translate('notifications.idleTimeout'),
          type: NOTIFICATION_TYPES.WARNING,
        }),
      );
    },
  });
};
