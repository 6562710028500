import { COLORS } from '../../../../../../constants/colors';

export const useStyles = () => ({
  root: {
    p: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },

  permissionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& img': {
      width: '100%',
      height: 'auto',
      maxWidth: 325,
    },
  },

  bottomSettings: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  fontColor: {
    color: COLORS.LIGHT_GREY,
    fontSize: 14,
    lineHeight: 2.5,
    mr: 1,
  },

  box: {
    mt: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    background: COLORS.GREY,

    '&:hover': {
      backgroundColor: COLORS.MYTAVERSE_BLUE,
    },
  },
});
