import React from 'react';
import { Typography } from '@mui/material';
import { WithTranslation, withTranslation } from 'react-i18next';

import { useStyles } from './styles';

const PocTag = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();

  return <Typography sx={classes.tag}>{translate('poc.assistant')}</Typography>;
};

export default withTranslation('common')(PocTag);
