import React, { useState, KeyboardEvent, useRef, useCallback, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Box, IconButton, TextField } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import CheckIcon from '@mui/icons-material/Check';
import StopCircle from '@mui/icons-material/StopCircle';

import { useStyles } from './styles';
import { usePocAi } from '../providers/PocAiProvider';
import { POC_SCREENS } from '../constants';
import { usePreventGameKeydownListening } from '../../../../../hooks';

const PocForm = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();

  const { activeScreen, navigate, handleSendRequest, startRecording, stopRecording, stopPlayback } = usePocAi();

  const [inputValue, setInputValue] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  
  const preventKeydownCallback = (event: Event) => {
    if ((event as any).key !== 'Enter') {
      event.stopPropagation();
    }
  };
  const elementRef = useRef<any>();
  usePreventGameKeydownListening(elementRef, preventKeydownCallback);

  const handleSendMessage = () => {
    if (!inputValue) {
      return;
    }

    handleSendRequest(inputValue);
    setInputValue('');

    if (activeScreen === POC_SCREENS.HOW_TO) {
      navigate(POC_SCREENS.CHAT);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleMicToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
    setIsRecording(!isRecording);
    console.warn('isRecording', isRecording);
  };

  const handleStop = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.warn('stop audio button clicked');
    stopPlayback();
  };

  return (
    <Box sx={classes.root}>
      <TextField
        ref={elementRef}
        sx={classes.box}
        inputProps={{
          style: { ...classes.input },
        }}
        placeholder={translate('poc.form.placeholder')}
        variant="standard"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <Box sx={classes.controls}>
        <IconButton onClick={handleSendMessage} sx={classes.button}>
          <CheckIcon sx={classes.icon} />
        </IconButton>
        <IconButton
          onClick={handleStop}
          sx={classes.button}
        >
          <StopCircle sx={classes.icon} />
        </IconButton>
        <IconButton
          onClick={handleMicToggle}
          sx={classes.button}
        >
          <MicIcon sx={isRecording ? classes.micOn : classes.icon} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default withTranslation('common')(PocForm);
