import { useTheme } from '@mui/material';
import { DEVICES_LANDSCAPE_SIZES } from '../../../../../constants/devicesSizes';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    tabs: {
      minHeight: 0,
      '& .MuiTabs-scroller': {
        display: 'flex',
      },
      '& .MuiTabs-flexContainer': {
        display: 'inline-flex',
        justifyContent: 'center',
        columnGap: 4,
        padding: '8px 0',
        width: '100%',
        [breakpoints.down(DEVICES_LANDSCAPE_SIZES.MOBILE)]: {
          padding: 0,
        },
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
    tab: {
      '&.MuiTab-root': {
        minHeight: 0,
      },
      '&.Mui-selected': {
        '& svg': {
          opacity: 1,
        },
        '& .MuiTypography-root': {
          opacity: 1,
        },
      },
      [breakpoints.down(DEVICES_LANDSCAPE_SIZES.MOBILE)]: {
        '&.MuiTab-root': {
          p: 1,
        },
      },
    },
    unreadChannelWrapper: {
      '& .MuiBadge-badge': {
        top: 0,
        right: -6,
      },
    },
  };
};
