import React, { useState, useEffect, SetStateAction, Dispatch } from 'react';

export interface IMytaverseFetchResponse<DataType, CallLoadDataParams> {
  data: DataType;
  setData: Dispatch<SetStateAction<DataType>>;
  loading: boolean;
  callLoadData: (params: CallLoadDataParams) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const useMytaverseFetch = (loadData: any) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error] = useState(null);

  const axiosAbortControllerRef = React.useRef<any>();
  const loadDataPromiseRef = React.useRef<any>();

  const callLoadData = React.useCallback(
    (params: any = {}) => {
      if (loadDataPromiseRef.current && axiosAbortControllerRef.current) {
        axiosAbortControllerRef.current.abort();
      }

      setLoading(true);

      axiosAbortControllerRef.current = new AbortController();

      loadDataPromiseRef.current = loadData(params, {
        signal: axiosAbortControllerRef.current
          ? axiosAbortControllerRef.current.signal
          : null,
      })
        .then((response: any) => {
          const { data } = response;

          loadDataPromiseRef.current = null;
          setData(data !== undefined ? data : []);

          setLoading(false);
        })
        .catch(() => {});
    },
    [loadData]
  );

  useEffect(() => {
    return () => {
      if (!axiosAbortControllerRef || !axiosAbortControllerRef.current) {
        return;
      }
      axiosAbortControllerRef.current.abort();
    };
  }, [callLoadData]);

  return { data, setData, loading, error, callLoadData, setLoading };
};

export default useMytaverseFetch;
