import React from 'react';
import { Box, Typography } from '@mui/material';

import Avatar from '../../../../../../public/images/ek/avatars/avatar.png';

import { useStyles } from './styles';
import { IScreen } from './interfaces';

const ScreenWrapper = ({
  title,
  subtitle,
  secondarySubtitle,
  actions,
  children,
}: IScreen) => {
  const classes = useStyles();

  return (
    <>
      <Box sx={classes.avatarDesktop}>
        <img src={Avatar} />
      </Box>
      <Box sx={classes.root}>
        <Box sx={classes.content}>
          <Box>
            <Typography sx={classes.title}>{title}</Typography>
            <Typography sx={classes.subtitle}>{subtitle}</Typography>
            {secondarySubtitle ? (
              <Typography sx={{ ...classes.subtitle, ...classes.secondary }}>
                {secondarySubtitle}
              </Typography>
            ) : null}
            {children}
          </Box>

          <Box sx={classes.avatar}>
            <img src={Avatar} />
          </Box>
          {actions ? <Box sx={classes.actions}>{actions}</Box> : null}
        </Box>
      </Box>
    </>
  );
};

export default ScreenWrapper;
