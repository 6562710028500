import { useTheme } from '@mui/material';

import { ScreenSizes } from '../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    formContainer: {
      maxWidth: 720,
      padding: '20px 24px 32px',
      boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.1)',
      borderRadius: 1,
      mx: 4,
      my: 2,

      '& .MuiLinearProgress-root': {
        borderRadius: 1,
        backgroundColor: '#B5B5B5 !important',
      },

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        mx: 0,
        my: 0,
        padding: '0 24px',
        height: '100%',
      },

      /*[`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        padding: '20px 24px 32px !important',
      },

      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 980px)`]: {
        padding: '20px 24px 32px !important',
      },*/

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          padding: 0,
        },
    },
  };
};
