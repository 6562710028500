import { useTheme } from '@mui/material';

import { COLORS } from '../../../../../../constants/colors';
import { IStyles } from '../../../../../../interfaces/styles';
import { ScreenSizes } from '../../../../../../interfaces';

export const useStyles = (hasChildren: boolean): IStyles => {
  const { breakpoints } = useTheme();
  return {
    root: {
      backgroundColor: COLORS.MYTAVERSE_BLACK,
      color: COLORS.WHITE,
      borderRadius: 72,
      border: `1px solid ${COLORS.NEW_LOGIN_GREY}`,
      backdropFilter: 'blur(12px)',
      textTransform: 'none',
      padding: '8px 20px 8px 16px',
      fontSize: 14,
      '& svg': {
        color: COLORS.WHITE,
        opacity: 0.5,
        mr: hasChildren ? 1 : 0,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: '6px 10px',
        minWidth: 'unset',
        //fontSize: 14,
      },
    },

    typography: {
      fontSize: 'inherit',
      fontWeight: 500,
      letterSpacing: '0.1px',
      lineHeight: 1.43,
      textTransform: 'capitalize',
    },
  };
};
