import { useTheme } from '@mui/material';

import { COLORS, colorsEK } from '../../../../constants/colors';
import { ScreenSizes } from '../../../../interfaces';
import { useMytaverse } from '../../../../providers/MytaverseProvider';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  const { appTheme } = useMytaverse();

  const {
    submitButton: { backgroundColorHover },
  } = appTheme.avatarPage.assets;

  return {
    root: {
      '& .MuiBackdrop-root': {
        backgroundColor: 'transparent',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      padding: '24px 36px',
      height: 'auto',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 430,
      background: colorsEK.white,

      borderRadius: 1,
      maxHeight: '90vh',
      maxWidth: '90vw',
      overflow: 'auto',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: '20px 50px',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        maxHeight: 'unset',
        maxWidth: 'unset',
      },
    },
    closeIcon: {
      position: 'absolute',
      top: 13,
      right: 13,
      color: '#939393',
      fontSize: 14,
      cursor: 'pointer',
    },
    iconContainer: {
      display: 'flex',
      background: appTheme.mainColor,
      borderRadius: 9,
      padding: 1,
      '& svg': {
        color: colorsEK.white,
        width: 20,
        height: 20,
      },
    },
    title: {
      fontFamily: 'Emirates-Medium !important',
      fontWeight: 400,
      fontSize: 32,
      lineHeight: 1.5,
      color: colorsEK.black,
      marginTop: '30px',
    },
    description: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.5,
      color: '#707070',
      marginTop: '9px',
      letterSpacing: 'normal',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 16,
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      columnGap: '22px',
      marginTop: '30px',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        marginTop: '24px',
      },
    },
    button: {
      padding: '7px 24px',
      width: 77,
      borderRadius: '8px',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1,
      color: COLORS.WHITE,
      textTransform: 'none',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 16,
      },
    },
    stayButton: {
      color: COLORS.WHITE,
      border: `1px solid ${appTheme.mainColor}`,
      backgroundColor: appTheme.mainColor,
      '&:hover': {
        backgroundColor: backgroundColorHover,
        color: colorsEK.white,
        border: `1px solid ${backgroundColorHover}`,
      },
    },
    exitButton: {
      color: appTheme.mainColor,
      border: `1px solid ${appTheme.mainColor}`,
      '&:hover': {
        backgroundColor: backgroundColorHover,
        color: colorsEK.white,
        border: `1px solid ${backgroundColorHover}`,
      },
    },
  };
};
