import { COLORS } from '../../../../../constants/colors';
import { ScreenSizes } from '../../../../../interfaces';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      mt: 1.5,
      mb: 5,
      width: '100%',
      [breakpoints.down(ScreenSizes.Pc)]: {
        mb: 0,
      },
    },

    icon: {
      color: COLORS.GREY,
    },

    linearProgress: {
      width: '100%',
      maxWidth: 512,
      pt: 1,
      borderRadius: 2,
      background: COLORS.GREY,

      '& span': {
        background: COLORS.BLUE,
      },
    },
  };
};
