import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import DevicesTestWrapper from '../DevicesTestWrapper';
import ProgressBar from '../ProgressBar';
import {
  useCheckServiceLatency,
  useFastCheckInternetConnectionSpeed,
} from '../../../../../modules/auth/components/ConnectionTest/hooks';

import { useStyles } from './styles';

const ConnectionTest = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation('common');

  const { isCompleted: isSpeedTestCompleted, speed } =
    useFastCheckInternetConnectionSpeed();
  const { isCompleted: isServiceLatencyTestCompleted, serviceLatency } =
    useCheckServiceLatency(true);

  const title = translate('ek.devicesTest.connectionTest.title');
  const description = translate('ek.devicesTest.connectionTest.description');
  const progressBarSpeed = speed > 100 ? 100 : speed;
  const progressBarLatency = serviceLatency > 100 ? 100 : serviceLatency;

  return (
    <DevicesTestWrapper
      title={title}
      description={description}
    >
      <Box sx={classes.root}>
        <Box sx={classes.section}>
          <ProgressBar
            value={progressBarSpeed}
            isCompleted={isSpeedTestCompleted}
          />
          <Box sx={classes.textContent}>
            <Typography sx={classes.text}>
              {translate('devicesTest.connectionTest.connectionSpeed')}
            </Typography>
            <Typography sx={classes.speed}>{speed} Mb/s</Typography>
          </Box>
        </Box>
        <Box sx={classes.section}>
          <ProgressBar
            value={progressBarLatency}
            isCompleted={isServiceLatencyTestCompleted}
          />
          <Box sx={classes.textContent}>
            <Typography sx={classes.text}>
              {translate('devicesTest.connectionTest.serverLatency')}
            </Typography>
            <Typography sx={classes.speed}>{serviceLatency} ms</Typography>
          </Box>
        </Box>
      </Box>
    </DevicesTestWrapper>
  );
};

export default ConnectionTest;
