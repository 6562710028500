import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';

import HomeBarButton from '../../HomeBarButton';
import DevicesSettingsModal from '../DevicesSettingsModal';

import { useOpenDevicesSettingsModal } from '../HomeBottomButtonOptions/hooks';

const SettingsButton = ({ t: translate }: WithTranslation) => {
  const devicesSettingsModalData = useOpenDevicesSettingsModal();

  return (
    <>
      <HomeBarButton
        label={translate('tooltips.settings')}
        icon={<SettingsIcon />}
        handleClick={devicesSettingsModalData.switchOpenModal}
      />

      <DevicesSettingsModal
        open={devicesSettingsModalData.open}
        handleClose={devicesSettingsModalData.closeModal}
      />
    </>
  );
};

export default withTranslation('common')(SettingsButton);
