import { useNavigate } from 'react-router-dom';

import SelectAvatarPageView from './SelectAvatarPageView';

import ROUTES from '../../../../../constants/routes';

import { useIdleTimerLogout } from '../../../../../hooks/idleTimer';

const { REACT_APP_IDLE_TIMEOUT_MINS } = process.env;

const SelectAvatarPage = () => {
  const navigate = useNavigate();
  
  useIdleTimerLogout(Number(REACT_APP_IDLE_TIMEOUT_MINS));

  const onBackButtonClick = () => navigate(ROUTES.SELECT_EVENT);

  return <SelectAvatarPageView onBackButtonClick={onBackButtonClick} />;
};

export default SelectAvatarPage;
