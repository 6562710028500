/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { IParticipant } from '../../../../../interfaces/participants';
import { useStyles } from './styles';

const SharingWindowVideo = ({
  participant,
  mediaStream,
  muted,
}: {
  participant: IParticipant;
  mediaStream: MediaStream;
  muted: boolean;
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const classes = useStyles();
  useEffect(() => {
    //@ts-ignore
    navigator.attachMediaStream(videoRef.current, mediaStream);
  }, [participant]);

  return (
    <>
      <Typography sx={classes.name}>{participant.name}</Typography>
      <video ref={videoRef} autoPlay playsInline muted={muted} />
    </>
  );
};

export default SharingWindowVideo;
