import React from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

import CloseDrawerButton from '../CloseDrawerButton';

import { useStyles } from './styles';

const ChatHeader = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root} component="div">
      <Box sx={classes.titleContainer} component="div">
        <ChatBubbleIcon sx={classes.chatIcon} />
        <Typography sx={classes.title}>
          {translate('chatDrawer.title')}
        </Typography>
      </Box>
      <CloseDrawerButton />
    </Box>
  );
};

export default withTranslation('common')(ChatHeader);
