import React from 'react';
import { useNavigate } from 'react-router-dom';

import ReadyPlayerMeView from './ReadyPlayerMeView';

import { useSubscribeToReadyPlayerMeEvents } from './hooks';

import ROUTES from '../../../../constants/routes';
import { useMytaverseEvent } from '../../../dashboard/providers';

import { useIdleTimerLogout } from '../../../../hooks/idleTimer';

const { REACT_APP_IDLE_TIMEOUT_MINS } = process.env;

const ReadyPlayerMe = () => {
  const navigate = useNavigate();
  const frameRef = useSubscribeToReadyPlayerMeEvents();
  const { currentEventId } = useMytaverseEvent();
  useIdleTimerLogout(Number(REACT_APP_IDLE_TIMEOUT_MINS));

  const handleBackBtnClick = () => {
    navigate(ROUTES.AVATAR_CHOOSING(currentEventId));
  };

  return (
    <ReadyPlayerMeView
      frameRef={frameRef}
      onBackBtnClick={handleBackBtnClick}
    />
  );
};

export default ReadyPlayerMe;
