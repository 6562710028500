import { ScreenSizes } from '../../../../../../interfaces';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      width: 400,

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        height: '100%',
        width: '100%',
      },
    },
  };
};
