import React, { useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import ChatAttachments from '../ChatAttachments';

import { IChatQuotedMessage } from './interfaces';
import { MessageResponseBase } from 'stream-chat/dist/types/types';
import { DefaultStreamChatGenerics as StreamChatGenerics } from 'stream-chat-react/dist/types/types';

import { classes } from './styles';

const ChatQuotedMessage = ({ message }: IChatQuotedMessage) => {
  const { quoted_message, text } = message;
  const quotedMessage =
    quoted_message as MessageResponseBase<StreamChatGenerics>;

  const attachments = useMemo(
    () => quotedMessage.attachments ?? [],
    [quotedMessage.attachments],
  );
  const hasAttachments = !!attachments.length;

  return (
    <Box sx={classes.root}>
      <Box sx={classes.quotedMessage}>
        {hasAttachments && <ChatAttachments attachments={attachments} />}
        <Typography sx={classes.quotedText}>{quotedMessage.text}</Typography>
      </Box>
      <Typography sx={classes.messageText}>{text}</Typography>
    </Box>
  );
};
export default ChatQuotedMessage;
