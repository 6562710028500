import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Box } from '@mui/material';

import ReactionItem from './ReactionItem';

import { useReactions } from './ReactionItem/hooks';
import { useMytaverseEvent } from '../../../../../providers';

import { useStyles } from './styles';

const Reactions = ({ onClose }: { onClose: () => void }) => {
  const classes = useStyles();

  const reactions = useReactions();
  const { setReaction } = useMytaverseEvent();

  const handleClick = (reaction: string) => {
    setReaction(reaction);

    if (isMobileOnly) {
      onClose();
    }
  };

  return (
    <Box sx={classes.root}>
      <Box sx={classes.content}>
        {reactions.map((reaction) => (
          <ReactionItem
            key={reaction.id}
            reaction={reaction}
            onClick={() => handleClick(reaction.id)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Reactions;
