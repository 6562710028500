import React, { useCallback, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import SearchFieldView from './SearchFieldView';

import { usePreventGameKeydownListening } from '../../../../hooks';

import { ISearchField } from './interfaces';

const SearchField = ({
  sx = {},
  value,
  onChange,
  onClear,
  placeholder = '',
}: ISearchField) => {
  const inputRef = usePreventGameKeydownListening<HTMLInputElement>();
  const { t: translate } = useTranslation('common');
  const eventListenerRef = useRef(false);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (!inputRef.current) {
        return;
      }
      const inputElement = inputRef.current.querySelector('input');
      eventListenerRef.current = true;

      if (!inputElement) {
        return;
      }
      const isInputClicked =
        inputElement.contains(event.target as Node) ||
        inputElement === event.target;

      if (!isInputClicked) {
        inputElement.blur();
      }
    },
    [inputRef],
  );

  useEffect(() => {
    if (!inputRef.current || eventListenerRef.current) {
      return;
    }
    document.addEventListener('click', handleClick);

    return () => {
      if (eventListenerRef.current) {
        document.removeEventListener('click', handleClick);
        eventListenerRef.current = false;
      }
    };
  }, [inputRef]);

  return (
    <SearchFieldView
      ref={inputRef}
      sx={sx}
      value={value}
      onChange={onChange}
      onClear={onClear}
      placeholder={placeholder || translate('inputPlaceholders.sidebarSearch')}
    />
  );
};

export default SearchField;
