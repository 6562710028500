import React, { useState, MouseEvent } from 'react';

import LanguageButtonView from './LanguangeButtonView';

import { useMytaverse } from '../../providers/MytaverseProvider';
import { LanguageCodes } from '../../constants/language';

const LanguageButton = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { selectedLanguage, selectLanguage } = useMytaverse();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCLose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (lang: LanguageCodes) => {
    selectLanguage(lang);
    handleCLose();
  };

  return (
    <LanguageButtonView
      anchorEl={anchorEl}
      selectedLanguage={selectedLanguage}
      onClick={handleClick}
      onClose={handleCLose}
      onItemClick={handleItemClick}
    />
  );
};

export default LanguageButton;
