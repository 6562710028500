import { Box, Typography } from '@mui/material';

import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../../providers';
import useGoldenTicket from './hooks';
import { colors } from '../../../../../constants/colors';

const GoldenTicket = () => {
  const { user } = useMytaverse();
  const { goldenTicketRef } = useMytaverseEvent();

  const { getTicketDate } = useGoldenTicket();

  const [ticket, mainTextPosition, secondaryTextPosition] = getTicketDate(
    user?.hrData?.brand?.toUpperCase(),
  );

  return (
    <Box sx={{ position: 'absolute', zIndex: -100 }}>
      <Box
        ref={goldenTicketRef}
        sx={{ position: 'absolute', scale: '0', zIndex: -100 }}
      >
        <img src={ticket} style={{ display: 'block' }} />
        <Typography
          sx={{
            fontSize: 52,
            position: 'absolute',
            zIndex: 1,
            top: 178,
            left: 100,
            fontWeight: 700,
            textTransform: 'uppercase',
            fontFamily: 'YaHei-Bold !important',
            color: colors.gray,
            ...mainTextPosition,
          }}
        >
          {user.hrData.name}
        </Typography>
        <Typography
          sx={{
            fontSize: 38,
            position: 'absolute',
            zIndex: 1,
            top: 180,
            left: 1325,
            fontWeight: 700,
            maxWidth: 300,
            lineHeight: '40px',
            textTransform: 'uppercase',
            fontFamily: 'YaHei-Bold !important',
            color: colors.gray,
            ...secondaryTextPosition,
          }}
        >
          {user.hrData.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default GoldenTicket;
