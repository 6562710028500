export enum LanguageCodes {
  En = 'EN',
  Es = 'ES',
  Pt = 'PT',
  Fr = 'FR',
}

export const LANGUAGES = [
  {
    key: LanguageCodes.En,
    label: 'English',
  },
  {
    key: LanguageCodes.Es,
    label: 'Español',
  },
  {
    key: LanguageCodes.Pt,
    label: 'Português',
  },
  {
    key: LanguageCodes.Fr,
    label: 'Française',
  },
];
