import React from 'react';

import ConfirmModalView from './ConfirmModalView';

import { IConfirmModal } from './interfaces';

const ConfirmModal = ({
  open,
  onCancel,
  icon,
  title,
  message,
  onConfirm,
  confirmButtonName,
  cancelButtonName,
}: IConfirmModal) => {
  return (
    <ConfirmModalView
      icon={icon}
      title={title}
      message={message}
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmButtonName={confirmButtonName}
      cancelButtonName={cancelButtonName}
    />
  );
};

export default ConfirmModal;
