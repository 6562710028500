import { useTheme } from '@mui/material';
import { colors, colorsEK } from '../../../../../constants/colors';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    box: {
      width: 360,
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      background: '#0C0B0B99',
      paddingTop: 2,
      paddingBottom: 3,
      paddingX: 2.5,
      borderRadius: 6,
      border: `1px solid ${colors.white_90}`,
      backdropFilter: 'blur(60px)',
      outline: 'none',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      padding: 0,
      color: colorsEK.grey_200,
      fontSize: 10,
      '& svg': {
        fontSize: 10,
        width: 18,
        height: 18,
      },
    },
  };
};
