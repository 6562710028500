import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import CssBaseline from '@mui/material/CssBaseline';

import NotificationProvider from './providers/NotificationProvider';
import { MytaverseProvider } from './providers/MytaverseProvider';
import { Amplify } from 'aws-amplify';

import Routes from './routes/Routes';

import i18next from './locales/i18n';

import './styles/App.scss';
import './ek/styles/App.scss';
import ParticipantScopes from './constants/scopes';

import PolicyModal from './ek/modules/auth/components/PolicyModal';
import LogoutNotificationModal from './ek/modules/components/LogoutNotificationModal';

const {
  // @ts-ignore
  REACT_APP_COGNITO_CLIENT_ID,
  // @ts-ignore
  REACT_APP_COGNITO_USERPOOL_ID,
  // @ts-ignore
  REACT_APP_COGNITO_DOMAIN,
} = {
  ...process.env,
};

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: REACT_APP_COGNITO_CLIENT_ID,
      userPoolId: REACT_APP_COGNITO_USERPOOL_ID,

      loginWith: {
        oauth: {
          domain: REACT_APP_COGNITO_DOMAIN,
          scopes: ['openid'],
          // scopes: Object.values(ParticipantScopes),
          redirectSignIn: [window.location.origin],
          redirectSignOut: [window.location.origin],
          responseType: 'code',
        },
      },
    },
  },
});

declare module 'axios' {
  export interface AxiosRequestConfig {
    sendAuth0Headers?: boolean;
  }
}

const App = () => {
  const scopes = Object.values(ParticipantScopes).join(' ');

  return (
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <CssBaseline />
        <NotificationProvider>
          <MytaverseProvider>
            <Routes />
            <PolicyModal />
            <LogoutNotificationModal />
          </MytaverseProvider>
        </NotificationProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
};

export default App;
