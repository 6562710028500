import React from 'react';
import Popover from '@mui/material/Popover';

import HomeBarButton from '../HomeBarButton';
import Drawer from '../../../../components/Drawer';
import { useToggleIconPopoverDrawer } from './hooks';

import { IIconPopoverView } from './interfaces';

import { useStyles } from './styles';

const IconPopover = ({
  children,
  icon,
  label,
  isDrawer = false,
  buttonSx = {},
  popoverSx = {},
}: IIconPopoverView) => {
  const {
    open: isOpened,
    anchorEl,
    handleClick,
    handleClose,
  } = useToggleIconPopoverDrawer(isDrawer);

  const classes = useStyles(isOpened);
  const buttonClass = { ...classes.button, ...buttonSx };
  const popoverClass = { ...classes.popover, ...popoverSx };

  const homeButton = (
    <HomeBarButton
      label={label}
      icon={icon}
      handleClick={handleClick}
      buttonSx={buttonClass}
    />
  );

  if (isDrawer) {
    return (
      <>
        {homeButton}
        <Drawer isOpen={isOpened} onClick={handleClose} disableHeader>
          {children(handleClose)}
        </Drawer>
      </>
    );
  }

  return (
    <>
      {homeButton}
      <Popover
        open={isOpened}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={popoverClass}
      >
        {children(handleClose)}
      </Popover>
    </>
  );
};

export default IconPopover;
