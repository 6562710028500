import React from 'react';

import ConfirmLogoutModalView from './ConfirmLogoutModalView';

import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../../providers';
import { useChatState } from '../../../../../hooks/context';

import { sendCustomSentryBreadcrumb } from '../../../../../helpers/sentry';

import { IConfirmLogoutModal } from './interfaces';

const ConfirmLogoutModal = ({ open, onClose }: IConfirmLogoutModal) => {
  const { logoutUser } = useMytaverse();
  const { cleanStates } = useMytaverseEvent();
  const { resetState } = useChatState();

  const handleExitButtonClick = () => {
    sendCustomSentryBreadcrumb({ message: 'log out from event' });
    logoutUser(false);
    cleanStates();
    resetState();
    onClose();
  };

  return (
    <ConfirmLogoutModalView
      open={open}
      onClose={onClose}
      onExitButtonClick={handleExitButtonClick}
    />
  );
};

export default ConfirmLogoutModal;
