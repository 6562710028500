import { CSSProperties } from 'react';
import { useTheme } from '@mui/material';

import { COLORS, colors } from '../../../constants/colors';
import { ScreenSizes } from '../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    form: {
      height: '100%',
    },
    root: {
      width: '100%',
      height: '100%',
      display: 'grid',
      gridTemplateRows: '1fr auto',
    } as CSSProperties,
    content: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 3,
      px: 3,
      py: 2,
      maxWidth: '100%',
      overflow: 'hidden',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        py: 1.5,
      },
    },
    userData: {
      width: '100%',
      overflow: 'hidden',
      '& .MuiBox-root:last-child': {
        mb: 0,
      },
    },
    rootInput: {
      width: '100%',
    },
    input: {
      color: COLORS.LIGHT_GREY,
      fontSize: [18, 22],
      '&::before': {
        borderBottom: `1px solid ${COLORS.BG}`,
      },
      '&::after': {
        borderBottom: `1px solid ${colors.white}`,
      },
      '&.MuiInput-root:hover': {
        '&::before': {
          borderBottom: `1px solid ${colors.white}`,
        },
        '&::after': {
          borderBottom: `1px solid ${colors.white}`,
        },
      },
      '& input': {
        p: 0,
      },
    },
    inputCompany: {
      fontSize: 16,
      mb: 2.5,
    },
    inputItems: {
      fontSize: 14,
    },
    text: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 0.1,
      color: COLORS.PROFILE_GREY,
    },
    buttons: {
      backgroundColor: colors.gray,
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'flex-end',
      justifyContent: 'flex-end',
      width: '100%',
      px: 2.5,
      py: 2,
      gap: 3,
    },
    button: {
      ml: 0,
      display: 'flex',
      py: 1,
      px: 2,
      whiteSpace: 'nowrap',
      gap: 1,
      '& p': {
        textTransform: 'capitalize',
        color: colors.white,
        fontSize: 14,
        fontWeight: 600,
      },
      '& svg': {
        color: COLORS.BG,
        height: 16,
        width: 16,
      },
    },
    saveBtn: {
      background: COLORS.GREEN,
      border: `1px solid ${COLORS.GREEN}`,
    },
    iconButton: {
      border: `1px solid ${colors.red}`,
    },
    closeIcon: {
      color: COLORS.RED,
    },
    checkIcon: {
      color: COLORS.GREEN,
    },
  };
};
