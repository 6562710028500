import React, { useState, useCallback } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Box, useMediaQuery } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { useMytaverse } from '../../../../../../../providers/MytaverseProvider';
import ScreenWrapper from '../ScreenWrapper';
import CustomUIForm from '../../NonConsentFlow/CustomUIForm';
import useCustomAvaturn from './useCustomAvaturn';
import ModalForm from './ModalForm';
import UpdateConsentButton from '../../UpdateConsentButton';

import { useStyles } from '../styles';

interface ICustomUIScreen extends WithTranslation {
  onClick: () => void;
}

const CustomUIScreen = ({ onClick, t: translate }: ICustomUIScreen) => {
  const classes = useStyles();

  const isMobile = useMediaQuery('(max-width: 767px)');

  const [open, setOpen] = useState(true);
  const { user } = useMytaverse();

  const {
    isProcessing,
    data: avatarSettings,
    setData: setAvatarSettings,
    createAvatar,
  } = useCustomAvaturn();

  const name = user?.hrData?.name || user?.firstName;
  const subtitle = translate('ek.selectAvatar.customUIScreen.description1', {
    name: name ? ` ${name}` : '',
  });

  const handleClick = useCallback(async () => {
    await createAvatar();
    onClick();
  }, [createAvatar]);

  return (
    <ScreenWrapper
      title={translate('ek.selectAvatar.title')}
      subtitle={subtitle}
      actions={
        <Box sx={classes.boxConsent}>
          <LoadingButton
            onClick={handleClick}
            sx={classes.button}
            disabled={isProcessing}
            loading={isProcessing}
          >
            {translate('ek.selectAvatar.beginButton')}
          </LoadingButton>
          <UpdateConsentButton />
        </Box>
      }
    >
      {isMobile ? (
        <ModalForm open={open} onClose={() => setOpen(false)}>
          <CustomUIForm data={avatarSettings} onChange={setAvatarSettings} />
        </ModalForm>
      ) : (
        <CustomUIForm data={avatarSettings} onChange={setAvatarSettings} />
      )}
    </ScreenWrapper>
  );
};

export default withTranslation('common')(CustomUIScreen);
