import React from 'react';
import { withTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import { SwiperSlide } from 'swiper/react';

import SelectWrapper from '../../components/SelectWrapper';
import SwiperWrapper from '../../components/SwiperWrapper';
import SelectAvatarSkinItem from '../../components/SelectAvatarSkinItem';

import {
  AVATAR_SKIN_BREAKPOINTS,
  AVATAR_SKIN_SLIDER_WIDTH_BREAKPOINTS,
} from '../../constants';

import { ISelectAvatarSkinPageView } from './interfaces';

import { classes } from './styles';

const SelectAvatarSkinPageView = ({
  avatarSkins,
  loop,
  onBackButtonClick,
  onAvatarSkinClick,
  t: translate,
}: ISelectAvatarSkinPageView) => {
  const hasAvatarSkins = !!avatarSkins.length;

  return (
    <SelectWrapper
      title={translate('selectSkin.title')}
      description={translate('selectSkin.description')}
      showEventsDropdown
      onBackClick={onBackButtonClick}
    >
      {hasAvatarSkins ? (
        <SwiperWrapper
          breakpoints={AVATAR_SKIN_BREAKPOINTS}
          loop={loop}
          sliderWidth={AVATAR_SKIN_SLIDER_WIDTH_BREAKPOINTS}
        >
          {avatarSkins.map((avatarSkin) => (
            <SwiperSlide key={avatarSkin.avatarSkinId}>
              <SelectAvatarSkinItem
                src={avatarSkin.previewImage.url}
                alt="skin preview"
                onClick={() => onAvatarSkinClick(avatarSkin)}
              />
            </SwiperSlide>
          ))}
        </SwiperWrapper>
      ) : (
        <Typography sx={classes.emptyPlaceholder}>
          {translate('selectSkin.noSkins')}
        </Typography>
      )}
    </SelectWrapper>
  );
};

export default withTranslation('common')(SelectAvatarSkinPageView);
