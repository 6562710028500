import React, { useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import SpeakerIcon from '@mui/icons-material/Speaker';
import MicIcon from '@mui/icons-material/Mic';

import { TabPanel } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';

import HomeBottomButtonOptionsMic from '../../HomeBottomButtonOptionsMic';
import HomeBottomButtonOptionsSettings from '../../components/HomeBottomButtonOptionsSettings/HomeBottomButtonOptionsSettings';
import LoadingProgress from '../../../../../../components/LoadingProgress';
import PermissionsGuide from '../../../../../../public/images/ChromeOperation.jpg';
import { DevicesSettingsSoundViewProps } from './interfaces';
import { useStyles } from './styles';
import { NavigatorPermissionsService } from '../../../../../../helpers/permissionsService';
import { useMytaverseEvent } from '../../../../providers';

const DevicesSettingsSoundView = ({
  microphones,
  speakers,
  selectedMicrophone,
  selectMicrophone,
  selectedSpeaker,
  selectSpeaker,
  changingDevice,
  translate,
  play,
  stop,
  isTestingSound,
}: DevicesSettingsSoundViewProps) => {
  const classes = useStyles();
  const [showGuide, setShowGuide] = useState(false);

  const { canSpeak } = useMytaverseEvent();

  useAsyncEffect(async () => {
    const grantPermissions = (state: string) => {
      setShowGuide(state !== 'granted');
    };

    const permissionStatus =
      await NavigatorPermissionsService.checkAndEnsurePermission('microphone');

    grantPermissions(permissionStatus.state);

    if ('onchange' in permissionStatus) {
      permissionStatus.onchange = () => {
        grantPermissions(permissionStatus.state);
      };
    }
  }, []);

  const renderMicrophone = () => {
    if (!canSpeak || !microphones.length) {
      return null;
    }

    return (
      <>
        <HomeBottomButtonOptionsSettings
          devices={microphones}
          handleSelect={async (e) => await selectMicrophone(e.target.value)}
          label={
            <Box sx={classes.bottomSettings} component="div">
              {translate('settingsModal.mic')}
            </Box>
          }
          selectedValue={selectedMicrophone}
          SelectIcon={MicIcon}
        />
        {changingDevice ? (
          <LoadingProgress />
        ) : (
          <HomeBottomButtonOptionsMic selectedMicrophone={selectedMicrophone} />
        )}
      </>
    );
  };

  return (
    <TabPanel value="sound" sx={classes.root}>
      {showGuide ? (
        <Box sx={classes.permissionsWrapper}>
          <img src={PermissionsGuide} alt="" />
        </Box>
      ) : (
        <>
          {renderMicrophone()}
          <HomeBottomButtonOptionsSettings
            devices={speakers}
            handleSelect={(e) => selectSpeaker(e.target.value)}
            label={translate('settingsModal.speakers')}
            selectedValue={selectedSpeaker}
            SelectIcon={SpeakerIcon}
            disabled={isTestingSound}
          />
          <Button sx={classes.box} onClick={isTestingSound ? stop : play}>
            {isTestingSound ? (
              <VolumeOffIcon />
            ) : (
              <VolumeUpIcon sx={classes.fontColor} />
            )}
            <Typography sx={classes.fontColor}>
              {translate('settingsModal.checkSound')}
            </Typography>
          </Button>
        </>
      )}
    </TabPanel>
  );
};

export default DevicesSettingsSoundView;
