import { useEffect, useState } from 'react';

const ScreenOrientationLandscape = ['landscape-primary', 'landscape-secondary'];

const isLandscapeOrientation = () => {
  if (screen.orientation) {
    return ScreenOrientationLandscape.includes(screen.orientation.type);
  }
  return window.innerWidth > window.innerHeight;
};

export const useLandscapeDetection = () => {
  const [isLandscape, setIsLandscape] = useState(isLandscapeOrientation);

  useEffect(() => {
    const updateLandscapeState = () => {
      setIsLandscape(isLandscapeOrientation());
    };

    if (screen.orientation) {
      screen.orientation.addEventListener('change', updateLandscapeState);
    } else {
      window.addEventListener('resize', updateLandscapeState);
    }

    return () => {
      if (screen.orientation) {
        screen.orientation.removeEventListener('change', updateLandscapeState);
      } else {
        window.removeEventListener('resize', updateLandscapeState);
      }
    };
  }, []);

  return isLandscape;
};
