/* eslint-disable no-useless-escape */
import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { usePreventGameKeydownListening } from '../../../../../hooks';
import { Typography } from '@mui/material';
import { COLORS } from '../../../../../constants/colors';

interface BrowserUrlDialogProps {
  onClose: () => void;
  onSubmit: (url: string) => void;
}

const BrowserUrlDialog = ({ onClose, onSubmit }: BrowserUrlDialogProps) => {
  const { t: translate } = useTranslation('common');

  const urlRef = usePreventGameKeydownListening();
  const [error, setError] = useState<string | null>(null);
  const [browserUrl, setBrowserUrl] = useState<string>('https://');

  const isValid = React.useMemo(() => {
    const reg =
      /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

    if (browserUrl.match(reg)) {
      setError(null);
      return true;
    }

    if (browserUrl.length > 3) {
      setError('Invalid URL input');
    }

    if (browserUrl.length === 0) {
      setError(null);
    }
    return false;
  }, [browserUrl]);

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <DialogTitle>{translate('browserUrlDialog.title')}</DialogTitle>
      <DialogContent>
        <TextField
          inputRef={urlRef}
          autoFocus
          placeholder="https://www.mytaverse.com/"
          value={browserUrl}
          fullWidth
          type="url"
          onChange={(e) => setBrowserUrl(e.target.value)}
          variant="standard"
        />
        {error ? (
          <Typography sx={{ color: COLORS.RED, fontSize: 12 }}>
            {error}
          </Typography>
        ) : (
          ''
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translate('browserUrlDialog.close')}</Button>
        <Button disabled={!isValid} onClick={() => onSubmit(browserUrl)}>
          {translate('browserUrlDialog.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BrowserUrlDialog;
