import { useTheme } from '@mui/material';

import { colors } from '../../../../../../constants/colors';
import { ScreenSizes } from '../../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 1,
    },
    iconWrapper: {
      display: 'flex',
      position: 'absolute',
      left: '18px',
      top: '50%',
      transform: 'translateY(-50%)',

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        '& svg': {
          width: '14px !important',
          height: '14px !important',
        },
      },
    },
    icon: {
      color: colors.white,
    },
    select: {
      color: colors.white,
      width: '100%',
      overflow: 'hidden',
      '& .MuiInputBase-root': {
        opacity: 1,
        width: '100%',
      },
      '& .MuiSelect-select': {
        display: 'flex',
        alignItems: 'center',
        '& p': {
          paddingLeft: '36px',
          color: colors.white,
        },
      },
      '& svg': {
        color: colors.white,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
          width: '20px !important',
          height: '20px !important',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
        },
        '& input': {
          padding: 0,
          border: 'none',
        },
        '& .MuiSelect-select': {
          '& p': {
            paddingLeft: '24px',
          },
        },
      },
    },
  };
};
