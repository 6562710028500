import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';

import { Box } from '@mui/material';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

import IconPopover from '../../IconPopover';
import ActionsContent from './ActionsContent';

import { useChatState } from '../../../../../hooks/context';
import { useToolsState } from './hooks';

import { ActionsTabs } from './interfaces';

import { useStyles } from './styles';

const Actions = () => {
  const [activeTab, setActiveTab] = useState<ActionsTabs>(ActionsTabs.Tools);

  const { open: isOpenChat } = useChatState();
  const { resetState } = useToolsState();
  const { t: translate } = useTranslation('common');

  const classes = useStyles(isOpenChat);

  useEffect(() => resetState, []);

  const handleChangeTab = (_: SyntheticEvent, value: ActionsTabs) => {
    setActiveTab(value);
  };

  return (
    <Box sx={classes.root}>
      <IconPopover
        icon={<SentimentSatisfiedAltIcon />}
        label={translate('actions.actions')}
        isDrawer={isMobileOnly}
      >
        {(handleClose) => (
          <ActionsContent
            activeTab={activeTab}
            onChangeTab={handleChangeTab}
            onClose={handleClose}
          />
        )}
      </IconPopover>
    </Box>
  );
};

export default Actions;
