import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { useOfficeHours } from '../provider';
import { getTimeFormat, convertToGST } from './helpers';

import ConfirmModal from '../../ConfirmModal';
import SlotView from './SlotView';
import { reloadPageTimeout } from '../../../../../../../helpers';
import { ISlotProps, SlotActions } from './interfaces';

import ROUTES from '../../../../../../../constants/routes';

const Slot = ({ slot, t: translate }: ISlotProps) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState<SlotActions | null>(null);

  const { bookSlot, cancelSlot } = useOfficeHours();

  const handleBook = useCallback(async () => {
    setAction(null);
    try {
      setIsLoading(true);
      await bookSlot(slot.eventId);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, [slot.eventId, bookSlot]);

  const handleJoin = useCallback(() => {
    //TODO: Implement Join handler
    navigate(ROUTES.EVENT_PAGE(slot.eventId));
    reloadPageTimeout(0, `Join Event ${slot.eventId}`);
  }, [slot.eventId]);

  const handleCancel = useCallback(async () => {
    setAction(null);
    try {
      setIsLoading(true);
      await cancelSlot(slot.eventId);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, [slot.eventId, cancelSlot]);

  const renderConfirmationModal = () => {
    if (!action) {
      return null;
    }

    const { startTime, endTime } = slot;

    const startDate = convertToGST(startTime);
    const endDate = convertToGST(endTime);

    const slotTime = format(startDate, 'dd MMM yyyy (EEEE)');

    const getMessage = (message: string) => {
      const messageTime = `${slotTime}, ${getTimeFormat(
        startDate,
      )}–${getTimeFormat(endDate)} GST`;

      return (
        <>
          {message}
          <br /> {messageTime}?
        </>
      );
    };

    switch (action) {
      case SlotActions.BOOK:
        return (
          <ConfirmModal
            open
            message={getMessage('Do you want to book the session on')}
            onConfirm={handleBook}
            onCancel={() => {
              setAction(null);
            }}
          />
        );
      case SlotActions.CANCEL:
        return (
          <ConfirmModal
            open
            message={getMessage('Do you want to cancel the session')}
            onConfirm={handleCancel}
            onCancel={() => {
              setAction(null);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <SlotView
        slot={slot}
        isLoading={isLoading}
        onBook={() => setAction(SlotActions.BOOK)}
        onCancel={() => setAction(SlotActions.CANCEL)}
        onJoin={handleJoin}
      />
      {renderConfirmationModal()}
    </>
  );
};

export default withTranslation('common')(Slot);
