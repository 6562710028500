import React from 'react';
import { Backdrop, Box, Modal, Button, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';

import { CloseIcon } from '../../../../../../../../icons';

import { IModalForm } from './interfaces';
import { useModalStyles } from '../../../../../hooks/useModalStyles';
import { useStyles } from './styles';

const ModalForm = ({ open, children, onClose, t: translate }: IModalForm) => {
  const modalClasses = useModalStyles();
  const classes = useStyles();

  return (
    <Modal sx={modalClasses.root} open={open} components={{ Backdrop }}>
      <Box component="div" sx={modalClasses.container}>
        <CloseIcon sx={modalClasses.closeIcon} onClick={onClose} />
        <Typography sx={{ ...modalClasses.title, ...classes.title }}>
          {translate('ek.selectAvatar.customUIScreen.modal.title')}
        </Typography>
        <Box sx={modalClasses.content}>
          <Typography sx={classes.text}>
            {translate('ek.selectAvatar.customUIScreen.modal.description1')}
          </Typography>
          {children}
        </Box>

        <Button
          onClick={onClose}
          sx={{ ...modalClasses.button, ...modalClasses.buttonPrimary }}
        >
          {translate('ek.selectAvatar.continueButton')}
        </Button>
      </Box>
    </Modal>
  );
};

export default withTranslation('common')(ModalForm);
