import { Box } from '@mui/material';

import { useStyles } from './styles';

interface ILoadingStepDelimiter {
  isCompleted?: boolean;
}

const LoadingStepDelimiter = ({
  isCompleted = false,
}: ILoadingStepDelimiter) => {
  const classes = useStyles(isCompleted);

  return <Box sx={classes.root} />;
};

export default LoadingStepDelimiter;
