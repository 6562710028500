import { colorsEK } from '../../../../../../../constants/colors';

export const useStyles = () => {
  return {
    root: {
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      width: '100%',
    },
    tabList: {
      borderBottom: `1px solid ${colorsEK.white}`,
      '& .MuiTabs-indicator': {
        backgroundColor: colorsEK.white,
        height: '3px',
      },
    },
    tab: {
      fontFamily: '"Noto Sans", sans-serif',
      color: colorsEK.grey_300,
      textTransform: 'none',

      '&.Mui-selected': {
        color: colorsEK.white,
        fontWeight: 600,
      },
    },
    tabPanel: {
      padding: 0,
      height: '100%',
      overflow: 'hidden',
    },
  };
};
