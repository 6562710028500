import React from 'react';

import DashboardTopBarContentView from './DashboardTopBarContentView';

import { useMytaverseEvent } from '../../../providers';
import { useChatState } from '../../../../../hooks/context';

import { checkIsAdminRole } from '../../../../../helpers/role';

import { EventDrawerTabs } from '../../../constants';
import { IDashboardTopBarContent } from './interfaces';
import { useConference } from '../../DashboardContent/Dolby';

const DashboardTopBarContent = ({
  onOpenConfirmLogoutModal,
  onOpenInviteUserModal,
}: IDashboardTopBarContent) => {
  const {
    openLeftMenu,
    participants,
    currentRoom,
    currentEvent,
    currentParticipant,
  } = useMytaverseEvent();
  const { screenMediaStreams } = useConference();
  const { open: isOpenChat } = useChatState();


  const showInviteUserBtn = currentParticipant && currentParticipant.eventRole
    ? checkIsAdminRole(currentParticipant.eventRole)
    : false;
  const participantTotal = participants.filter(({ roomId }) => roomId).length;
  const hasMediaStream = !!screenMediaStreams?.length;

  const handleOpenEventDrawer = (tab: EventDrawerTabs) => {
    openLeftMenu(tab);
  };

  return (
    <DashboardTopBarContentView
      currentRoom={currentRoom}
      isOpenChat={isOpenChat}
      hasMediaStream={hasMediaStream}
      showInviteUserBtn={showInviteUserBtn}
      participantTotal={participantTotal}
      onOpenEventDrawer={handleOpenEventDrawer}
      onOpenConfirmLogoutModal={onOpenConfirmLogoutModal}
      onOpenInviteUserModal={onOpenInviteUserModal}
    />
  );
};

export default DashboardTopBarContent;
