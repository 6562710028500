import { useTheme } from '@mui/material';

import { colors } from '../../../../../../constants/colors';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    box: (isAssistant = true) => ({
      display: 'flex',
      justifyContent: isAssistant ? 'start' : 'end',
    }),
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      background: '#1B1B1B',
      padding: 2,
      borderRadius: 2,
      color: colors.white,
      width: '80%',
    },
    name: {
      color: '#AFAFAF',
      fontSize: 12,
      lineHeight: 1.25,
      fontWeight: 600,
    },
    headerName: {
      display: 'flex',
      gap: 1.5,
    },
    time: {
      color: '#AFAFAF',
      fontSize: 12,
      lineHeight: 1.25,
      fontWeight: 400,
      letterSpacing: '-0.04em',
    },
    text: {
      fontSize: 14,
      letterSpacing: '-0.04em',
      lineHeight: 1.3,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    }
  };
};
