import { colors, colorsEK } from '../../../../../constants/colors';

import { IStyles } from './interfaces';
import { useTheme } from '@mui/material';
import { ScreenSizes } from '../../../../../interfaces';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';

export const useStyles = ({ isCompleted }: IStyles) => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();

  return {
    root: {
      width: '100%',
      height: 56,
      borderRadius: '500px',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        height: 30,
        borderRadius: 0.5,
      },

      '&.MuiLinearProgress-root': {
        backgroundColor: colors.oxford_30,
      },
      '& .MuiLinearProgress-bar': {
        backgroundColor: isCompleted ? colorsEK.green : appTheme.mainColor,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        height: 40,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        height: 30,
      },
    },
  };
};
