import { useMytaverse } from '../../../../../providers/MytaverseProvider';

export const useStyles = () => {
  const { appTheme } = useMytaverse();

  return {
    root: {
      position: 'relative',
      width: '62px',
      height: '62px',

      '& circle': {
        strokeWidth: '2.5px',
      },
    },
    base: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: '100% !important',
      height: '100% !important',
      color: 'rgba(228, 228, 228, 1)',
    },
    progress: {
      width: '100% !important',
      height: '100% !important',
      color: appTheme.mainColor,
      transform: 'rotate(90deg) !important',
    },
    step: {
      position: 'absolute',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '19px',
      color: 'rgba(181, 181, 181, 1)',
      letterSpacing: 4,
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%) translateX(-50%)',
    },
  };
};
