import React, { CSSProperties } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { createGlobalStyle } from 'styled-components';
import { Box } from '@mui/material';

import AppHeader from '../../../components/AppHeader';
import PageTitleContent from '../PageTitleContent';
import Floor from '../../../../../modules/auth/components/Floor';

import { ISelectWrapper } from './interfaces';

import { useStyles } from './styles';

const GlobalStyle = createGlobalStyle`
    @media (max-width: 460px) {
        #root > div > div {
            height: 100dvh !important;
        }
    }
`;

const SelectWrapper = ({
  children,
  title = '',
  description = '',
  onBackClick,
  hideFloor = false,
  sx = {},
}: ISelectWrapper) => {
  const classes = useStyles();

  const { container: containerSx = {}, content: contentSx = {} } = sx;

  const isTitle = title || description;

  return (
    <>
      <GlobalStyle />
      <Scrollbars
        style={classes.root as CSSProperties}
        autoHide
        autoHideTimeout={1500}
        autoHideDuration={300}
        thumbSize={100}
      >
        <Box sx={{ ...classes.container(!!isTitle), ...containerSx }}>
          <AppHeader isAuthorized={true} onBackClick={onBackClick} />
          {isTitle ? (
            <Box sx={classes.header}>
              <PageTitleContent title={title} subtitle={description} />
            </Box>
          ) : null}
          <Box sx={{ ...classes.content, ...contentSx }}>{children}</Box>
          {!hideFloor ? <Floor /> : null}
        </Box>
      </Scrollbars>
    </>
  );
};

export default SelectWrapper;
