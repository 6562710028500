import { IStyles } from '../../../../interfaces/styles';

export const classes: IStyles = {
  root: {
    cursor: 'pointer',
    ml: '0 !important',
    backgroundColor: 'rgba(184, 184, 184, 0.1)',
    '& svg': {
      color: 'rgba(151, 151, 151, 1)',
    },
  },
};
