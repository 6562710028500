import { useTheme } from '@mui/material';

import { AVATAR_SKIN_SLIDER_WIDTH_BREAKPOINTS } from '../../constants';

import { IStyles } from '../../../../interfaces/styles';
import { SwiperScreenSizes } from '../../../../interfaces';

export const useStyles = (): IStyles => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: AVATAR_SKIN_SLIDER_WIDTH_BREAKPOINTS[SwiperScreenSizes.Pc].width,
      [breakpoints.down(SwiperScreenSizes.Pc)]: {
        width:
          AVATAR_SKIN_SLIDER_WIDTH_BREAKPOINTS[SwiperScreenSizes.Laptop].width,
      },
      [breakpoints.down(SwiperScreenSizes.Laptop)]: {
        width:
          AVATAR_SKIN_SLIDER_WIDTH_BREAKPOINTS[SwiperScreenSizes.Tablet].width,
      },
      [breakpoints.down(SwiperScreenSizes.Tablet)]: {
        width:
          AVATAR_SKIN_SLIDER_WIDTH_BREAKPOINTS[SwiperScreenSizes.Mobile].width,
      },
      cursor: 'pointer',
      '& img': {
        objectFit: 'contain',
        maxWidth: '100%',
        height: 'auto',
        '&:hover': {
          transform: 'scale(1.01)',
        },
      },
    },
  };
};
