import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import Notification from './Notification';
import ChatNotification from './ChatNotification';

import {
  checkIsDismissNotification,
  getMessageData,
  getNotification,
} from './helpers';

import { AUTO_HIDE_DURATION_TIME, NOTIFICATION_TYPES } from './constants';
import ROUTES from '../../constants/routes';

const { REACT_APP_EK_ENV } = process.env;

import {
  GetDolbyNotificationType,
  GetSpecificNotificationType,
  ShowChatNotificationType,
  ShowNotificationType,
  UseNotificationType,
} from './interfaces';
import { useNotificationContext } from '../../providers/NotificationProvider';

export const useNotification: UseNotificationType = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t: translate } = useTranslation('common');

  const showNotification: ShowNotificationType = useCallback(
    ({
      title = '',
      message,
      logMessage = '',
      type,
      closeManually = false,
      withReloadButton = false,
      withDismissButton = true,
      ...options
    }) => {
      const isDismissNotification = checkIsDismissNotification(message);

      if (isDismissNotification) {
        console.log(logMessage || message);
        return '';
      }

      const notificationId = enqueueSnackbar(message, {
        autoHideDuration: closeManually ? null : AUTO_HIDE_DURATION_TIME,
        preventDuplicate: true,
        content(key) {
          return Notification({
            id: key,
            title,
            message,
            type,
            withReloadButton,
            withDismissButton,
          });
        },
        ...options,
      });

      return notificationId;
    },
    [enqueueSnackbar],
  );

  const showChatNotification: ShowChatNotificationType = useCallback(
    ({ message, onReply, ...options }) => {
      const notificationId = enqueueSnackbar('', {
        autoHideDuration: AUTO_HIDE_DURATION_TIME,
        preventDuplicate: false,
        content(key) {
          return ChatNotification({
            id: key,
            message,
            onReply,
          });
        },
        ...options,
      });

      return notificationId;
    },
    [enqueueSnackbar],
  );

  const getSuccessNotification: GetSpecificNotificationType = ({
    message,
    ...props
  }) => {
    return getNotification({
      title: translate('notifications.success'),
      message,
      type: NOTIFICATION_TYPES.SUCCESS,
      ...props,
    });
  };

  const getBadRequestNotification: GetSpecificNotificationType = ({
    message,
    ...props
  }) => {
    return getNotification({
      title: translate('notifications.badRequest'),
      message,
      type: NOTIFICATION_TYPES.ERROR,
      ...props,
    });
  };

  const getStreamingNotification: GetSpecificNotificationType = ({
    message = translate('notifications.pleaseReloadPage'),
    ...props
  }) => {
    return getNotification({
      title: translate('notifications.streamingError'),
      message,
      type: NOTIFICATION_TYPES.WARNING,
      ...props,
    });
  };

  const getDolbyNotification: GetDolbyNotificationType = ({
    message = '',
    ...props
  } = {}) => {
    return getNotification({
      title: translate('notifications.dolbyError'),
      message: message || translate('notifications.dolbyErrorText'),
      type: NOTIFICATION_TYPES.ERROR,
      ...props,
    });
  };

  const getMillicastNotification: GetDolbyNotificationType = ({
    message = '',
    ...props
  } = {}) => {
    return getNotification({
      title: translate('notifications.millicast.millicastError'),
      message:
        message || translate('notifications.millicast.millicastErrorText'),
      type: NOTIFICATION_TYPES.ERROR,
      ...props,
    });
  };

  const getWebsocketNotification: GetSpecificNotificationType = ({
    message,
    ...props
  }) => {
    return getNotification({
      title: translate('notifications.websocketError'),
      message,
      type: NOTIFICATION_TYPES.ERROR,
      ...props,
    });
  };

  return {
    showNotification,
    showChatNotification,
    hideNotification: closeSnackbar,
    getSuccessNotification,
    getBadRequestNotification,
    getStreamingNotification,
    getDolbyNotification,
    getMillicastNotification,
    getWebsocketNotification,
  };
};

export const useMessageData = (type: NOTIFICATION_TYPES) => {
  const { notificationTheme, isEventRun } = useNotificationContext();

  const { icon, color, background } = getMessageData(type);

  let themeData = {};

  if (!!REACT_APP_EK_ENV) {
    themeData = {
      icon: null,
      color: notificationTheme.mainColor,
      isTheme: true,
      background: '#03030399',
    };

    if (
      location.pathname === ROUTES.SELECT_EVENT ||
      location.pathname === ROUTES.LOGIN ||
      location.pathname.includes('/avatars') ||
      location.pathname.includes('/avaturn') ||
      location.pathname.includes('/devices') ||
      !isEventRun
    ) {
      themeData = { ...themeData, background: '#FFFFFF99' };
    }
  }

  return { icon, color, background, isTheme: false, ...themeData };
};
