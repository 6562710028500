export const COLORS = {
  WHITE: '#FFFFFF',
  WHITE_1: '#FFFFFF1A',
  WHITE_6: '#FFFFFF99',
  BLACK: '#3D5066',
  GREEN: '#1DAB56',
  BLUE_GRADIENT: 'linear-gradient(180deg, #547ECD 0%, #713FDC 100%)',
  GREY: '#4A4B66',
  MEDIUM_GREY: '#989898',
  LIGHT_GREY: '#CDCECF',
  BLUE: '#1A63F1',
  CTA: '#6666FF',
  RED: '#F05125',
  BG: '#E1E2E4',
  GOOGLE_BG: '#DE4931',
  MAIL_BG: '#D6DBE2',
  FACEBOOK_BG: '#3975EA',
  LINKEDIN_BG: '#2D64BC',
  DARK_GREY: '#03030399',
  ST_PATRICKS: '#222C72',
  OXFORD: '#0D0F33',
  MYTAVERSE_BLUE: '#4554E5',
  SUBTITLE: '#868799',
  NEW_BLACK: '#0A0A0A',
  WARNING: '#ED6C02',
  SUCCESS: '#2E7D32',
  MYTAVERSE_BLACK: '#03030399',
  LOGIN_GREY: '#F5F6F7',
  LOGIN_SUBTITLE: '#3C3D41',
  NEW_LOGIN_GREY: '#646464',
  LOGIN_LINK: '#1864D7',
  LOGIN_LIGHT_GREY: '#C4C4C4',
  AVATAR_BLACK: '#3C3D41',
  AZURE: '#F0F7F7',
  PROFILE_GREY: '#BABABA',
  PROFILE_BACKGROUND_GREY: 'rgba(255, 255, 255, 0.2)',
  TOOLS_LIGHT_BLUE: '#EDFCFC',
  ERROR: '#FF7699',
  DASSAULT_GREEN: '#95C11F',
  BACKGROUND_COLOR: '#030303',
  ESCALA_TURQUOISE: '#17BAC6',
  PEPSICO_BLUE: '#004196',
  TIPS_GREY: '#47474799',
  BUBBLES_GREEN: '#95F89E',
  BUBBLES_RED: '#FF7699',
  LIGHT_GRAY: '#DBDBDB',
};

// new color palette
export const colors = {
  black: '#000000',
  black_60: '#00000099',
  black_25: '#00000040',
  oxford: '#0D0F33',
  oxford_80: '#0D0F33CC',
  oxford_60: '#0D0F3399',
  oxford_40: '#0D0F3366',
  oxford_30: '#0D0F334D',
  oxford_20: '#0D0F3333',
  oxford_10: '#0D0F331A',
  oxford_05: '#0D0F330D',
  patrick: '#222C72',
  myta: '#4554E5',
  myta_10: '#4554E51A',
  mytaLight: '#5865E8',
  mytaDark: '#3A46C1',
  cyan: '#2B88F5',
  azure: '#31D0AA',
  red: '#EB3B67',
  red_80: '#EB3B67CC',
  white: '#FFFFFF',
  white_90: '#FFFFFFE6',
  white_80: '#FFFFFFCC',
  white_70: '#FFFFFFB3',
  white_60: '#FFFFFF99',
  white_50: '#FFFFFF80',
  white_40: '#FFFFFF66',
  white_30: '#FFFFFF4D',
  white_20: '#FFFFFF33',
  white_10: '#FFFFFF1A',
  gray: '#1A1A1A',
  gray_light: '#C8C8C8',
  green: '#1DAB56',
};

export const colorsEK = {
  black: '#000000',
  white: '#FFFFFF',
  grey_500: '#666666',
  grey_400: '#7E7E7E',
  grey_300: '#979797',
  grey_200: '#AFAFAF',
  grey_100: '#C8C8C8',
  grey_50: '#D4D4D4',
  grey: '#D8D8D8',
  blue: '#0088CE',
  green: '#69BE28',

  skin_light: '#FFDFC4',
  skin_medium_light: '#F0D5BE',
  skin_medium: '#E3B084',
  skin_medium_dark: '#C68642',
  skin_dark: '#8D5524',
  skin_very_dark: '#4A3426',
};
