import log from 'loglevel';
import dayjs from 'dayjs';

const originalFactory = log.methodFactory;
log.noConflict();
log.setLevel(log.levels.TRACE);

log.methodFactory = (methodName, logLevel, loggerName) => {
  return (message) => {
    const name = loggerName ? String(loggerName) : 'default';
    const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

    const finalMessage = [`[${date}]`, `[${name}]`, message].join(' ');

    originalFactory(methodName, logLevel, loggerName)(finalMessage);
  };
};

const MytaverseLogger = log.getLogger('mytaverse');

export default log;
export { MytaverseLogger };
