import { useCallback, useEffect, useRef, useState } from 'react';
import { UseSoundType, IAudioElement } from './interfaces';
import { useConferenceState } from './conferenceContext';

export const useSound: UseSoundType = (src, { volume = 1 } = {}) => {
  const audio = useRef<IAudioElement | null>(null);
  const [duration, setDuration] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const { activeSpeakerDeviceId } = useConferenceState();

  useEffect(() => {
    const handleInit = async () => {
      setIsPlaying(false);

      audio.current = new Audio(src);
      if (volume) audio.current.volume = volume;

      try {
        await audio.current.setSinkId?.(activeSpeakerDeviceId);
      } catch (error: unknown | Error) {
        console.error('Failed to set Sink ID', activeSpeakerDeviceId, error);
      }
    };

    handleInit();
  }, [activeSpeakerDeviceId]);

  useEffect(() => {
    if (!volume || audio.current === null) return;
    audio.current.volume = volume;
  }, [volume, audio]);

  useEffect(() => {
    if (audio.current === null) return;

    const handleLoadedMetadata = () => {
      if (audio.current === null) return;
      setDuration(audio.current.duration);
    };

    const handleEnded = () => {
      if (audio.current === null) return;
      setIsPlaying(false);
    };

    audio.current.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.current.addEventListener('ended', handleEnded);

    return () => {
      audio.current?.removeEventListener(
        'loadedmetadata',
        handleLoadedMetadata,
      );
      audio.current?.removeEventListener('ended', handleEnded);
    };
  }, [audio.current, setDuration, setIsPlaying]);

  useEffect(() => {
    return () => {
      if (audio.current) {
        audio.current.pause();
      }
    };
  }, []);

  const handlePlay = useCallback(() => {
    if (audio.current === null) return;

    setIsPlaying(true);
    audio.current.play();
  }, [audio.current]);

  const handlePause = useCallback(() => {
    if (audio.current === null) return;

    setIsPlaying(false);
    audio.current.pause();
  }, [audio.current]);

  const handleStop = useCallback(() => {
    if (audio.current === null) return;

    setIsPlaying(false);
    audio.current.pause();
    audio.current.currentTime = 0;
  }, [audio.current]);

  const handleSeek = useCallback(() => {
    if (audio.current === null) return 0;
    return audio.current.currentTime;
  }, [audio.current]);

  return [
    handlePlay,
    {
      pause: handlePause,
      stop: handleStop,
      seek: handleSeek,
      duration,
      isPlaying,
    },
  ];
};
