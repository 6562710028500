import { useTheme } from '@mui/material';
import { COLORS } from '../../../../../constants/colors';

import { ScreenSizes } from '../../../../../interfaces';
import { IStyles } from '../../../../../interfaces/styles';

export const useStyles = (
  showOverlay: boolean,
  isEventRun: boolean,
): IStyles => {
  const { breakpoints } = useTheme();

  return {
    root: {
      position: 'absolute',
      top: 0,
      height: '100vh',
      width: isEventRun ? 'auto' : '100vw',
      overflow: isEventRun ? 'hidden' : 'auto',
      backgroundColor: '#cccccc',
      // zIndex: isEventRun ? 'auto' : -1,
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        maxHeight: '100%',
      },

      '& #poi_view': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        transform: 'translate(-50%, -50%)',
        '& iframe': {
          pointerEvents: 'all',
          width: '60vw',
          height: '80vh',
          border: 'none',
        },
        '& button': {
          '& svg': {
            color: COLORS.RED,
            fontSize: 'bold',
          },
        },
      },
    },
    homePage: {
      display: showOverlay ? 'none' : 'block',
    },
  };
};
