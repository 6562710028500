import React from 'react';
import { withTranslation } from 'react-i18next';

import { Typography, Button, Box } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import UserAvatar from '../UserAvatar';
import GameInput from '../../GameInput';
import ProfileIInfoItem from '../ProfileIInfoItem';
import OverflowTitle from '../../OverflowTitle';

import { IUserProfileFormView } from './interfaces';

import { useStyles } from './styles';

const UserProfileFormView = ({
  avatarSrc,
  email,
  values,
  errors,
  touched,
  onChange,
  onCloseButton,
  onSubmit,
  onOpenUploadModal,
  t: translate,
}: IUserProfileFormView) => {
  const classes = useStyles();

  return (
    <form onSubmit={onSubmit} style={classes.form}>
      <Box sx={classes.root}>
        <Box sx={classes.content}>
          <UserAvatar src={avatarSrc} onOpenUploadModal={onOpenUploadModal} />
          <Box sx={classes.userData}>
            <GameInput
              name="username"
              placeholder="Firstname Lastname"
              value={values.username}
              errorMessage={errors.username}
              showError={touched.username && !!errors.username}
              onChange={onChange}
              rootSx={classes.rootInput}
              sx={classes.input}
            />
            <GameInput
              name="company"
              placeholder="Company"
              value={values.company}
              onChange={onChange}
              rootSx={classes.rootInput}
              sx={{
                ...classes.input,
                ...classes.inputCompany,
              }}
            />
            <ProfileIInfoItem icon={MailIcon}>
              <OverflowTitle title={email} sx={classes.text} />
            </ProfileIInfoItem>
            <ProfileIInfoItem icon={LocalPhoneIcon}>
              <GameInput
                name="phoneNumber"
                placeholder="Phone number"
                value={values.phoneNumber}
                onChange={onChange}
                rootSx={classes.rootInput}
                sx={{
                  ...classes.input,
                  ...classes.inputItems,
                }}
              />
            </ProfileIInfoItem>
            <ProfileIInfoItem icon={LinkedInIcon}>
              <GameInput
                name="linkedIn"
                placeholder="LinkedIn"
                value={values.linkedIn}
                errorMessage={errors.linkedIn}
                showError={touched.linkedIn && !!errors.linkedIn}
                onChange={onChange}
                rootSx={classes.rootInput}
                sx={{
                  ...classes.input,
                  ...classes.inputItems,
                }}
              />
            </ProfileIInfoItem>
          </Box>
        </Box>
        <Box sx={classes.buttons}>
          <Button
            sx={{ ...classes.button, ...classes.iconButton }}
            onClick={onCloseButton}
          >
            <CloseIcon sx={classes.closeIcon} />
            <Typography>{translate('userProfile.cancel')}</Typography>
          </Button>
          <Button sx={{ ...classes.button, ...classes.saveBtn }} type="submit">
            <SaveIcon sx={classes.checkIcon} />
            <Typography>{translate('userProfile.save')}</Typography>
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default withTranslation('common')(UserProfileFormView);
