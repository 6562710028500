import * as Yup from 'yup';

import { EMAIL_SPECIAL_SYMBOLS } from '../../../../constants/regex';

export const TryDemoPageSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .matches(EMAIL_SPECIAL_SYMBOLS, 'validations.email.symbols')
      .email('validations.email.match')
      .required('validations.email.required')
      .max(254, 'validations.email.maxLength'),
    name: Yup.string()
      .min(3, 'tryDemo.messageErrorName')
      .required('tryDemo.messageNameIsRequired'),
  });
