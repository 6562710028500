import React from 'react';
import { Box } from '@mui/material';

import ChatDrawer from '../../../../../modules/dashboard/components/ChatDrawer';
import DashboardContent from '../../../../../modules/dashboard/components/DashboardContent';
import DashboardTopBar from '../../../../../modules/dashboard/components/DashboardTopBar';
import EventDrawer from '../../../../../modules/dashboard/components/EventDrawer';
import HomeBottomBar from '../../../../../modules/dashboard/components/HomeBottomBar';
import PoiPreview from '../../../../../modules/dashboard/components/PoiPreview';
import OrientationOverlay from '../../components/OrientationOverlay';
import LeaveRegionDialog from '../../../../../modules/dashboard/components/LeaveRegionDialog';
import TeleportToRoomDialog from '../../../../../modules/dashboard/components/TeleportToRoomDialog';
import AdminSettings from '../../../../../modules/dashboard/components/AdminSettings';
import DolbyCameraDialog from '../../../../../modules/dashboard/components/DolbyCameraDialog';

import { IEventPageView } from './interfaces';
import { StreamingProviders } from '../../../../../modules/dashboard/providers/MytaverseEventProvider/interfaces';

import { useStyles } from './styles';
import { useShowOrientationOverlay } from '../../components/OrientationOverlay/hooks';
import { useEventContext } from '../../../../providers/EventProvider';
import ZoomScreenPreview from '../../../../../modules/dashboard/components/DashboardContent/Zoom/ZoomScreenPreview';

const EventPageView = ({
  showControls,
  openAdminSettingsModal,
  gameReadyToPlay,
  streamingProvider,
}: IEventPageView) => {
  const { showOverlay, isLandscape, handleHideOverlay } =
    useShowOrientationOverlay();
  const { isEventRun } = useEventContext();

  const classes = useStyles(showOverlay, isEventRun);

  const visibleControls = isEventRun && showControls;

  return (
    <>
      <ZoomScreenPreview />

      <Box id="home" sx={classes.homePage}>
        {streamingProvider === StreamingProviders.CoreWeave &&
        gameReadyToPlay &&
        visibleControls ? (
          <DashboardTopBar />
        ) : null}
        {streamingProvider !== StreamingProviders.CoreWeave &&
        visibleControls ? (
          <DashboardTopBar />
        ) : null}
        <EventDrawer />
        <ChatDrawer />
        <Box sx={classes.root}>
          <DashboardContent />
          <PoiPreview />
          <LeaveRegionDialog />
          <TeleportToRoomDialog />
          <DolbyCameraDialog />
          {openAdminSettingsModal && <AdminSettings />}
        </Box>
        {streamingProvider === StreamingProviders.CoreWeave &&
        gameReadyToPlay &&
        visibleControls ? (
          <HomeBottomBar />
        ) : null}

        {streamingProvider !== StreamingProviders.CoreWeave &&
        visibleControls ? (
          <HomeBottomBar />
        ) : null}
      </Box>
      {showOverlay && (
        <OrientationOverlay
          isButton={isLandscape}
          onClick={handleHideOverlay}
        />
      )}
    </>
  );
};

export default EventPageView;
