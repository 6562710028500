import { useTheme } from '@mui/material';

import { getScrollbarStyles } from '../../../helpers';
import { colors, COLORS } from '../../../../../constants/colors';
import { DEVICES_LANDSCAPE_SIZES } from '../../../../../constants/devicesSizes';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      width: '100%',
      '& .str-chat': {
        height: '100%',
      },
      '& .str-chat__container': {
        flexDirection: 'column',
        backgroundColor: COLORS.BACKGROUND_COLOR,
        '& .str-chat__virtual-list': {
          backgroundColor: COLORS.BACKGROUND_COLOR,
          backdropFilter: 'blur(6px)',
          ...getScrollbarStyles({ width: 24, borderWidth: 8 }),
          '& .str-chat__date-separator': {
            padding: '24px 0 0',
            '& .str-chat__date-separator-line': {
              backgroundColor: '#FFFFFF',
              opacity: 0.6,
            },
            '& .str-chat__date-separator-date': {
              color: '#FFFFFF',
              opacity: 0.6,
              fontWeight: 400,
            },
          },
          '& .str-chat__virtual-list-message-wrapper': {
            padding: '0 32px',
            '&:last-of-type': {
              paddingBottom: 2,
            },
            '& .str-chat__message-simple-text-inner a': {
              fontWeight: 400,
            },
            [breakpoints.down(DEVICES_LANDSCAPE_SIZES.MOBILE)]: {
              padding: '0 16px',
            },
          },
        },
        '& .rfu-dropzone': {
          width: '100%',
        },
      },
    },
    clearHistoryBtn: {
      backgroundColor: colors.red,
      padding: '10px 0px',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: '0.25px',
      textTransform: 'capitalize',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: colors.red,
      },
    },
  };
};
