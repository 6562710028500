import React from 'react';
import { withTranslation } from 'react-i18next';

import { IconButton, Stack, Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import { IUserProfileModalHeader } from './interfaces';

import { classes } from './styles';

const UserProfileModalHeader = ({
  onClose,
  t: translate,
}: IUserProfileModalHeader) => {
  return (
    <Box>
      <Box sx={classes.header}>
        <Stack flexDirection="row">
          <PersonIcon />
          <Typography>{translate('userProfile.title')}</Typography>
        </Stack>
        <IconButton onClick={onClose} sx={classes.iconButton}>
          <CloseIcon sx={classes.closeIcon} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default withTranslation('common')(UserProfileModalHeader);
