import { useEffect, useState } from 'react';

import { IUseMicrophoneTestProps, RenderFrameType } from './interfaces';

export const useMicrophoneTest = ({
  activeDeviceId,
  permissionState,
}: IUseMicrophoneTestProps) => {
  const [volume, setVolume] = useState(0);

  useEffect(() => {
    const timeoutIDs: NodeJS.Timer[] = [];
    let unmuted = false;

    const setMicrophoneValue: RenderFrameType = ({
      analyser,
      bufferLength,
    }) => {
      const dataArray = new Uint8Array(bufferLength);

      requestAnimationFrame(() => {
        if (unmuted) {
          return;
        }

        setMicrophoneValue({ analyser, bufferLength });
      });
      analyser.getByteFrequencyData(dataArray);

      for (let i = 0; i < bufferLength; i++) {
        const timeoutID = setTimeout(() => {
          if (unmuted) {
            return;
          }

          setVolume(dataArray[i]);
        }, 12);

        timeoutIDs.push(timeoutID);
      }
    };

    const startCheckMicro = async () => {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: activeDeviceId }, noiseSuppression: true },
        video: false,
      });
      const context = new AudioContext();
      const src = context.createMediaStreamSource(mediaStream);
      const analyser = context.createAnalyser();

      src.connect(analyser);
      analyser.fftSize = 64;

      const bufferLength = analyser.frequencyBinCount;

      setMicrophoneValue({ analyser, bufferLength });
    };

    if (permissionState === 'granted') {
      startCheckMicro();
    }

    return () => {
      unmuted = true;

      setTimeout(() => {
        timeoutIDs.map((timeoutID) => clearTimeout(timeoutID));
      }, 0);
    };
  }, [activeDeviceId, permissionState]);

  return { volume };
};
