import React from 'react';

import { TabPanel } from '@mui/lab';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Slider,
  Typography,
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import HighQualityIcon from '@mui/icons-material/HighQuality';

import HomeBottomButtonOptionsSettings from '../../components/HomeBottomButtonOptionsSettings/HomeBottomButtonOptionsSettings';
import { LANGUAGES } from '../../../../../../constants/language';

import { DevicesSettingsGeneralViewProps } from './interfaces';

import { useStyles } from './styles';

const DevicesSettingsGeneralView = ({
  selectLanguage,
  selectedLanguage,
  streamQuality,
  setStreamQuality,
  translate,
  gameSound,
  handleChange,
  participantsSound,
  handleLoudness,
  devices,
}: DevicesSettingsGeneralViewProps) => {
  const classes = useStyles();

  const languages = LANGUAGES.map(({ label, key }) => ({
    label,
    deviceId: key,
  }));

  return (
    <TabPanel value="general" sx={classes.root}>
      <HomeBottomButtonOptionsSettings
        devices={languages}
        handleSelect={(e) => selectLanguage(e.target.value)}
        label={translate('settingsModal.language')}
        selectedValue={selectedLanguage}
        SelectIcon={LanguageIcon}
      />

      <HomeBottomButtonOptionsSettings
        devices={devices}
        handleSelect={(e) => setStreamQuality(e.target.value)}
        label={translate('settingsModal.quality')}
        selectedValue={streamQuality}
        SelectIcon={HighQualityIcon}
      />
      <Box sx={classes.slider}>
        <Typography>{translate('settingsModal.worldSound')}</Typography>
        <Slider
          aria-label="Volume"
          value={gameSound * 100}
          onChange={handleChange}
        />
      </Box>
      <Box sx={classes.slider}>
        <Typography>{translate('settingsModal.loudness')}</Typography>
        <Slider
          aria-label="Volume"
          value={participantsSound * 100}
          onChange={handleLoudness}
        />
      </Box>
    </TabPanel>
  );
};

export default DevicesSettingsGeneralView;
