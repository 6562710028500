import { SxProps, useTheme } from '@mui/material';

import { COLORS, colorsEK } from '../../../../../../constants/colors';
import { ScreenSizes } from '../../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    modal: {
      width: 360,
      height: 602,
      paddingY: 2,
      paddingX: 2.5,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#0C0B0B99',
      borderRadius: 6,
      border: `1px solid ${colorsEK.grey_400}`,

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        borderRadius: 0,
        maxHeight: '100%',
        maxWidth: '100%',
        width: '100%',
        height: '100%',
      },
    } as SxProps,

    title: {
      width: '100%',
      pb: 2,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [breakpoints.down(ScreenSizes.Pc)]: {
        pb: 1,
      },
    },
    content: {
      height: '100%',
      overflow: 'hidden',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
      },
    },

    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 1,
      '& svg': {
        color: COLORS.LIGHT_GREY,
        height: '22px',
      },
    },

    titleName: {
      textAlign: 'left',
      color: COLORS.WHITE,
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1,
      letterSpacing: '-0.96px',

      [breakpoints.down(ScreenSizes.Pc)]: {
        fontSize: 16,
        lineHeight: 1.5,
      },
    },

    titleButton: {
      padding: 0,
      '& svg': {
        color: COLORS.WHITE,
      },
    },
  };
};
