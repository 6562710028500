import { useTheme } from '@mui/material';

import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { colors, colorsEK } from '../../../../../constants/colors';
import { ScreenSizes } from '../../../../../interfaces';
import { IStylesProps } from '../../../../../modules/auth/components/SpeakerTest/interfaces';

export const useStyles = ({ isPlaying }: IStylesProps) => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();

  return {
    handleSoundBtn: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '22px',
      color: isPlaying ? colors.white : appTheme.loadingPage.mainColor,
      backgroundColor: isPlaying
        ? appTheme.loadingPage.mainColor
        : colors.white,
      border: `1px solid ${
        isPlaying ? appTheme.loadingPage.mainColor : colorsEK.grey_300
      }`,
      textTransform: 'capitalize',
      transition: 'none',
      boxShadow: 'none',
      marginTop: '25px',
      padding: '8px 16px',
      display: 'flex',
      gap: 1,
      '&:hover': {
        transition: 'none',
        boxShadow: 'none',
        color: colors.white,
        backgroundColor: appTheme.loadingPage.mainColor,
        borderColor: appTheme.loadingPage.mainColor,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        fontSize: 16,
        lineHeight: '22px',
        marginTop: '10px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        fontSize: 14,
        lineHeight: '20px',
        marginTop: '10px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        fontSize: 14,
        lineHeight: '20px',
        marginTop: '10px',
      },
    },
    selectionContainer: {
      width: '100%',
      position: 'relative',
      marginTop: '25px',
      marginBottom: '25px',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        marginTop: '14px',
        marginBottom: '14px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        marginTop: '20px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        marginTop: '8px',
        marginBottom: '8px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        marginTop: '8px',
        marginBottom: '8px',
      },
    },
    selection: {
      position: 'absolute',
      content: '""',
      inset: 0,
      padding: '14px 18px',
      '& .MuiInputBase-root': {
        opacity: 1,
        width: '100%',
      },
      '& .MuiSelect-select': {
        '& p': {
          color: `${colors.white} !important`,
          fontSize: 18,
          lineHeight: 1.6,
          fontWeight: 500,
        },
      },
      '& svg': {
        color: `${colors.white} !important`,
      },

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: '8px 14px',
        '& .MuiSelect-select p': {
          fontSize: '12px !important',
          lineHeight: '14px',
        },

        '& svg.MuiSvgIcon-root': {
          width: '20px !important',
          height: '20px !important',
        },
      },
    },
    helper: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '22px',
      color: '#707070',
      marginBottom: '25px',
      textAlign: 'center',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        marginBottom: 4.5,
      },

      '@media (max-width: 340px)': {
        fontSize: 14,
        lineHeight: '18px',
      },

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        marginTop: 1,
        marginBottom: 4,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        fontSize: 14,
        lineHeight: '14px',
        marginBottom: 2,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        fontSize: 10,
        lineHeight: '14px',
        marginBottom: 1,
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          fontSize: 14,
          lineHeight: '14px',
          marginBottom: 2,
        },
    },
  };
};
