/* eslint-disable react/jsx-key */
import { BeginScreen, CustomiseScreen, FinalScreen } from '../Screens';
import React, { useEffect } from 'react';
import ROUTES from '../../../../../../constants/routes';
import {
  AVATAR_SCREENS,
  useEventContext,
} from '../../../../../providers/EventProvider';
import { useNavigate } from 'react-router-dom';
import { useMytaverseEvent } from '../../../../../../modules/dashboard/providers';
import { useMytaverse } from '../../../../../../providers/MytaverseProvider';

const ConsentAvatarFlow = ({
  isAvaturnValid,
  isProcessing,
  currentCustomAvatarUrl,
}: {
  isAvaturnValid: boolean;
  isProcessing: boolean;
  currentCustomAvatarUrl: string | null;
}) => {
  const navigate = useNavigate();
  const { currentEventId } = useMytaverseEvent();
  const { user, customAvatarUrl } = useMytaverse();

  const { avatarScreen: activeScreen, setAvatarScreen } = useEventContext();

  const handleAvaturnClick = () => navigate(ROUTES.AVATURN(currentEventId));
  const handleDeviceClick = () => navigate(ROUTES.DEVICES_TEST(currentEventId));
  const handleBeginClick = () => navigate(ROUTES.AVATURN(currentEventId));

  // useEffect does not get hit when pressing the browser back button
  let useEffectHit = false;

  useEffect(() => {
    if (user.isAvatarTermsAccepted !== false) {
      return;
    }

    useEffectHit = true;

    setAvatarScreen(
      isAvaturnValid || customAvatarUrl || currentCustomAvatarUrl
        ? AVATAR_SCREENS.CUSTOMISE
        : AVATAR_SCREENS.CUSTOM_UI,
    );
  }, [
    user.isAvatarTermsAccepted,
    isAvaturnValid,
    customAvatarUrl,
    currentCustomAvatarUrl,
  ]);

  const avatarScreens = [
    <BeginScreen onClick={handleBeginClick} />,
    <CustomiseScreen
      onClick={handleAvaturnClick}
      onSkipClick={handleDeviceClick}
      isAvaturnValid={isAvaturnValid}
      isProcessing={isProcessing}
    />,
    <FinalScreen
      onClick={handleAvaturnClick}
      onSkipClick={handleDeviceClick}
    />,
  ];

  if (
    !useEffectHit &&
    (isAvaturnValid || customAvatarUrl || currentCustomAvatarUrl)
  ) {
    return avatarScreens[AVATAR_SCREENS.CUSTOMISE];
  }

  return avatarScreens[activeScreen];
};

export default ConsentAvatarFlow;
