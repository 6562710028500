import { IInitMessage } from '../modules/dashboard/components/DashboardContent/Pureweb/interfaces';
import { ICreateGameSessionResponse } from '../modules/dashboard/components/DashboardContent/GameCast/interfaces';
import axios from 'axios';
import {
  IGamecastConfig,
  IGamecastStreamGroup,
  IGetStreamGroupParams,
} from '../interfaces/gamecast';

interface StreamSessionData {
  gameName: string;
  description: string;
  participantId: string;
  websocketSessionId: string;
  signalRequest: string;
  timeout: number;
  streamId: string;
  initMessage: IInitMessage;
  region: string;
}

interface StreamGroupIdResponse {
  eventId: string;
  streamGroupId: string;
  region: string;
}

export default class GameCastService {
  static startGameSession({
    gameName,
    description,
    participantId,
    websocketSessionId,
    signalRequest,
    timeout,
    streamId,
    initMessage,
    region,
  }: StreamSessionData): Promise<ICreateGameSessionResponse> {
    return axios
      .post(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/gamecast/stream-session`,
        {
          gameName,
          description,
          participantId: participantId
            ? participantId.replace('ActiveDirectory_', '').replaceAll('_', '')
            : '',
          websocketSessionId,
          signalRequest,
          timeout,
          streamGroupId: streamId,
          initMessage,
          region,
          additionalLaunchArgs: [
            '-PixelStreamingDecoupleFramerate=true',
            '-PixelStreamingCaptureUseFence=true',
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        },
      )
      .then((res) => res.data);
  }

  static getGameSessionId(eventId: string): Promise<StreamGroupIdResponse> {
    return axios
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/gamecast/${eventId}`,
      )
      .then((res) => res.data);
  }

  static async getStreamGroup(
    eventId: string,
    config = {},
  ): Promise<IGamecastStreamGroup> {
    return await axios
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/gamecast/${eventId}`,
        {
          ...config,
        },
      )
      .then((res) => res.data);
  }

  static async getStreamGroupConfig(
    { eventId, region, streamGroupId }: IGetStreamGroupParams,
    config = {},
  ): Promise<IGamecastConfig> {
    return await axios
      .get(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/gamecast/${eventId}/config`,
        {
          ...config,
          params: {
            region,
            streamGroupId,
          },
        },
      )
      .then((res) => res.data);
  }
}
