import { useEffect, useRef, useState } from 'react';

import { useSound } from '../../../../hooks/media';
import { useConferenceState } from '../../../../hooks/conferenceContext';
import { useDeviceChange } from '../../../dashboard/components/HomeBottomBar/DevicesSettingsModal/hooks';

import { getCatchErrorMessage } from '../../../../helpers/error';

import { DEFAULT_DEVICE } from './constants';

import Sound from '../../../../public/sounds/test.mp3';

import { UseInitHeadphonesDevicesType } from './interfaces';

export const useInitHeadphonesDevices: UseInitHeadphonesDevicesType = () => {
  const [initializing, setInitializing] = useState(true);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  const { activeSpeakerDeviceId, setActiveSpeakerDeviceId } =
    useConferenceState();

  useDeviceChange(setInitializing);

  useEffect(() => {
    if (!initializing) return;

    const init = async () => {
      if (!navigator.mediaDevices?.enumerateDevices) {
        throw new Error('enumerateDevices() not supported.');
      }

      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = (devices || []).filter(
          (device) => device.kind === 'audiooutput',
        );

        localStorage.setItem('audioDevices', JSON.stringify(audioDevices));

        const noDevices = !audioDevices.length || !audioDevices[0].deviceId;
        const nextDevices = noDevices ? [DEFAULT_DEVICE] : audioDevices;
        const nextActiveDeviceId =
          audioDevices.find(
            ({ deviceId }) => deviceId === activeSpeakerDeviceId,
          )?.deviceId || DEFAULT_DEVICE.deviceId;

        setDevices(nextDevices);
        setActiveSpeakerDeviceId(nextActiveDeviceId);
        setInitializing(false);
      } catch (error) {
        throw Error(getCatchErrorMessage(error));
      }
    };

    init();
  }, [activeSpeakerDeviceId, initializing, setActiveSpeakerDeviceId]);

  return {
    initializing,
    devices,
    activeDeviceId: activeSpeakerDeviceId,
    setActiveDeviceId: setActiveSpeakerDeviceId,
  };
};

export const useSpeakerTest = () => {
  const [seconds, setSeconds] = useState(0);
  const [isPlaying, setPlaying] = useState(false);
  const intervalIdRef = useRef<NodeJS.Timer | null>(null);

  const [roundedDuration, setRoundedDuration] = useState(0);

  const [play, { pause, duration, seek, stop }] = useSound(Sound);

  useEffect(() => {
    if (!duration) return;

    setRoundedDuration(Math.round(duration));
  }, [duration]);

  useEffect(() => {
    if (isPlaying) {
      setSeconds(Math.round(seek()));
      intervalIdRef.current = setInterval(() => {
        const currentPosition = Math.round(seek());
        const nextSeconds = currentPosition || roundedDuration;

        setSeconds(nextSeconds);
      }, 1000);
    } else {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    }
  }, [roundedDuration, isPlaying, seek]);

  useEffect(() => {
    if (isPlaying && seconds === roundedDuration) {
      // need timeout to display last set seconds changes
      setTimeout(() => {
        setPlaying(false);
        setSeconds(0);
      }, 1000);
    }
  }, [roundedDuration, isPlaying, seconds]);

  useEffect(() => {
    return () => {
      if (intervalIdRef.current) {
        stop();
        clearInterval(intervalIdRef.current);
      }
    };
  }, [stop]);

  const handleSound = () => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }

    setPlaying((prev) => !prev);
  };

  return {
    loading: !roundedDuration,
    seconds,
    duration: roundedDuration,
    isPlaying,
    handleSound,
  };
};
