import { useTheme } from '@mui/material';
import { colors } from '../../../../../../constants/colors';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    titleBox: {
      display: 'flex',
      gap: 1,
      alignItems: 'center',
    },
    title: {
      color: colors.white,
      fontSize: 24,
      lineHeight: 1,
      fontWeight: 500,
    },
    tag: {
      color: colors.white,
      background: colors.myta,
      fontSize: 8,
      lineHeight: 1,
      borderRadius: '3px',
      padding: 0.5,
      height: 'fit-content',
    },
    text: {
      color: colors.white,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '-0.04em',
      textAlign: 'center',
    },
    questions: {
      gap: 2,
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      textAlign: 'center',
      marginTop: 1,
    },
    divider: {
      background: colors.white_30,
      marginY: 1,
    },
  };
};
