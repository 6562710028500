import { useTheme } from '@mui/material';

import { useMytaverse } from '../../../../providers/MytaverseProvider';

import { colors } from '../../../../constants/colors';
import { ScreenSizes } from '../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();

  const { appTheme } = useMytaverse();

  return {
    root: {
      width: '100vw',
      height: '100vh',
      position: 'relative',
      backgroundImage: `url(${appTheme.loadingBackground})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    content: {
      padding: 2,
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gap: 2,
      borderRadius: 6,
      border: '1px solid rgba(255, 255, 255, 0.15)',
      background: 'rgba(255, 255, 255, 0.1)',
      backdropFilter: 'blur(20px)',
      maxWidth: '100%',
      maxHeight: '90%',
      overflow: 'auto',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: 1,
        gap: 1,
        borderRadius: 3,
        maxWidth: '90%',
      },
    },
    left: {
      color: colors.oxford,
      background: colors.white,
      borderRadius: 4,
      border: '1px solid rgba(13, 15, 51, 0.20)',
      maxWidth: '354px',
      px: 2.5,
      py: 3,
      display: 'flex',
      flexDirection: 'column',
      gap: 0.5,
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '100%',

      [breakpoints.up(ScreenSizes.Pc)]: {
        py: 6,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        maxWidth: '220px',
        borderRadius: 2,
        padding: 1,
      },
    },
    right: {
      background: colors.gray_light,
      borderRadius: 4,
      overflow: 'hidden',
      width: '55vw',
      maxWidth: '872px',
      position: 'relative',
      backdropFilter: 'blur(0px)',

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        borderRadius: 2,
        gap: 2,
      },
    },

    text: {
      fontSize: 18,
      fontWeight: 500,
      textAlign: 'center',
      [breakpoints.down(ScreenSizes.PcLarge)]: {
        fontSize: 16,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 14,
      },
    },
    title: {
      fontWeight: 600,
      fontSize: 32,
      lineHeight: 1.42,
      textAlign: 'center',
      [breakpoints.down(ScreenSizes.PcLarge)]: {
        fontSize: 24,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 18,
      },
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      padding: '28px 48px',
      [breakpoints.down(ScreenSizes.PcLarge)]: {
        padding: '28px 32px',
      },
      [breakpoints.down(ScreenSizes.PcLarge)]: {
        padding: '8px 16px',
        '& svg': {
          width: 95,
        },
      },
    },
  };
};
