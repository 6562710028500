import { useTheme } from '@mui/material';

import { colors } from '../../../../../constants/colors';
import { ScreenSizes } from '../../../../../interfaces';

const TICK =
  "data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.4336 0.947266L5.07895 12.3802L0.949012 8.19523' stroke='white' stroke-width='1.42105'/%3E%3C/svg%3E%0A";

export const useStyles = () => {
  const { breakpoints } = useTheme();

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 1,
      width: '100%',
      maxWidth: 475,
      paddingBottom: '56.25%',
      overflow: 'hidden',
      position: 'relative',
      marginTop: '32px',
      marginBottom: '32px',
      '& video': {
        left: 0,
        top: 0,
        width: '100%',
        position: 'absolute',
        pointerEvents: 'auto',
        border: 0,
        [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
          height: '100%',
          objectFit: 'cover',
        },
        [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        marginTop: '20px',
        marginBottom: 1,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        marginTop: 0,
        paddingBottom: '46%',
      },
    },
    selection: {
      position: 'absolute',
      content: '""',
      bottom: 0,
      width: '100%',
      backgroundColor: '#B5B5B5',
      padding: '16px',
      '& .MuiSelect-select p': {
        color: colors.white,
        fontSize: 18,
        lineHeight: 1.6,
        fontWeight: 500,
      },
      '& svg': {
        right: 0,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        padding: '4px 16px',
        '& p.MuiTypography-root': {
          fontSize: 10,
          lineHeight: '14px',
        },

        '& .MuiMenuItem-root': {
          padding: '8px 14px',
          minHeight: '30px',
        },

        '& svg': {
          width: 14,
          height: 14,
        },
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        padding: '5px 16px',
      },
    },
    paper: {
      backgroundColor: '#B5B5B5',
      borderRadius: '0',
      marginTop: '20px',
      width: '475px',
      boxShadow: 'none',
      '& p.MuiTypography-root': {
        color: colors.white,
        fontSize: 18,
        lineHeight: 1.6,
        fontWeight: 500,
      },
      '& .Mui-selected p.MuiTypography-root': {
        color: colors.white,
        opacity: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 4,
      },
      '& .Mui-selected p:after': {
        content: '""',
        backgroundImage: `url("${TICK}")`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: 16,
        height: 16,
        display: 'block',
        color: colors.white,
      },
      '& .MuiMenuItem-root': {
        padding: '15px 25px',
      },
      '& .MuiList-root': {
        padding: 0,
      },
      '& svg': {
        width: 20,
        height: 20,
      },
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        marginTop: '10px',
        width: 'calc(100% - 80px)',
        maxWidth: '475px',
        '& p.MuiTypography-root': {
          fontSize: 10,
          lineHeight: '14px',
        },

        '& .MuiMenuItem-root': {
          padding: '8px 14px',
          minHeight: '30px',
        },
        '& .Mui-selected p:after': {
          width: 12,
          height: 12,
        },
      },
      // [`@media screen and (orientation:landscape)
      //   and (max-height: 760px)
      //   and (max-width: 1180px)`]: {
      //   maxWidth: '342px',
      // },
      // [`@media screen and (orientation:landscape)
      //   and (max-device-height: 650px)
      //   and (max-device-width: 1180px)`]: {
      //   maxWidth: '260px',
      // },
      // [`@media screen and (orientation:landscape)
      //   and (max-device-height: 570px)
      //   and (max-device-width: 900px)`]: {
      //   maxWidth: '368px',
      // },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 820px)
        and (max-device-width: 1180px)`]: {
        maxWidth: 'calc(70% - 48px - 32px)',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 1180px)`]: {
        maxWidth: 'calc(50% - 48px - 32px)',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 980px)`]: {
        maxWidth: 'calc(50% - 48px - 32px)',
      },
    },
    helper: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      color: '#707070',
      marginBottom: '32px',
      textAlign: 'center',
      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 16,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        marginTop: 1,
        marginBottom: 4,
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        display: 'none',
      },
    },
  };
};
