import { checkIsLocalhost } from '../../helpers/path';

import { customAppThemes } from '../../constants/eventStyles';

import WorldsService from '../../services/WorldsService';

export const getAppTheme = async () => {
  const hostname = window.location.hostname;
  const isLocalhost = checkIsLocalhost();

  if (isLocalhost) {
    return customAppThemes.default();
  }

  const eventConfig = await WorldsService.getCustomStyles(hostname);

  if (eventConfig?.customStyles) {
    const colorTheme: string = eventConfig.customStyles?.colorTheme ?? 'light';
    //@ts-ignore
    return customAppThemes[colorTheme.toLowerCase()](eventConfig.customStyles);
  }

  return customAppThemes.default();
};
