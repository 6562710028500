import { useCallback, useEffect, useRef, useState } from 'react';

import { SnackbarKey } from 'notistack';

import { useNotification } from '../../../../../components/Notification';

import { useTranslation } from 'react-i18next';

export const useIsSpeakingMuted = (muted: boolean) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const mediaStreamRef = useRef<MediaStream | null>(null);

  useEffect(() => {
    if (muted) {
      navigator.mediaDevices
        .getUserMedia({ audio: { noiseSuppression: true }, video: false })
        .then((stream) => {
          mediaStreamRef.current = stream;

          const audioContext = new AudioContext();

          const analyser = audioContext.createAnalyser();
          analyser.fftSize = 64;

          const sourceNode = audioContext.createMediaStreamSource(stream);
          sourceNode.connect(analyser);

          setInterval(() => {
            const frequencyRangeData = new Uint8Array(
              analyser.frequencyBinCount,
            );

            analyser.getByteFrequencyData(frequencyRangeData);

            const maxValue = Math.max(...frequencyRangeData);
            const isCurrentSpeaking = maxValue > 180;

            setIsSpeaking(isCurrentSpeaking);
          }, 100);
        })
        .catch((e) => {
          console.error(e);
        });
    }

    if (!muted && mediaStreamRef.current) {
      mediaStreamRef.current.getAudioTracks().forEach((track) => {
        track.stop();
        setIsSpeaking(false);
      });
    }
  }, [muted]);

  return isSpeaking;
};

export const useIsSpeakerMutedNotificationShown = (
  isConferenceInitialized: boolean,
  muted: boolean,
) => {
  const [notification, setNotification] = useState<SnackbarKey>('');
  const [isNotificationShownPerMute, setIsNotificationShownPerMute] =
    useState(false);

  const [showPulse, setShowPulse] = useState(false);

  // const isSpeakingMuted = useIsSpeakingMuted(muted);

  const { showNotification, hideNotification } = useNotification();
  const { t: translate } = useTranslation('common');

  const resetNotification = useCallback(
    (notificationId: SnackbarKey) => {
      hideNotification(notificationId);
      setNotification('');
      setShowPulse(false);
    },
    [hideNotification],
  );

  useEffect(() => {
    if (!isConferenceInitialized) return;

    if (!muted) {
      setIsNotificationShownPerMute(false);

      if (notification) {
        resetNotification(notification);
      }
      return;
    }

    // if (isSpeakingMuted && !notification && !isNotificationShownPerMute) {
    //   setShowPulse(true);
    //   setIsNotificationShownPerMute(true);
    // }
  }, [
    isConferenceInitialized,
    // isSpeakingMuted,
    muted,
    resetNotification,
    showNotification,
    notification,
    isNotificationShownPerMute,
    translate,
  ]);

  return {
    speakerMutedNotification: notification,
    resetSpeakerMutedNotification: resetNotification,
    showPulse,
  };
};
