import React, { createContext, useContext, useState } from 'react';

import { Box } from '@mui/material';

import { SnackbarProvider } from 'notistack';

import { useChatState } from '../../hooks/context';

import { INotificationContext, INotificationProvider } from './interfaces';

import { useStyles } from './styles';

const NotificationContext = createContext<INotificationContext | null>(null);

export const useNotificationContext = () =>
  useContext(NotificationContext) as INotificationContext;

const NotificationProvider = ({ children }: INotificationProvider) => {
  const [hasSharedScreen, setHasSharedScreen] = useState(false);
  const [notificationTheme, setNotificationTheme] = useState<any | null>(null);
  const [isEventRun, setIsEventRun] = useState(false);

  const { open: isOpenChat } = useChatState();

  const classes = useStyles({ hasSharedScreen, isOpenChat });

  return (
    <NotificationContext.Provider
      value={{
        setHasSharedScreen,
        notificationTheme,
        setNotificationTheme,
        isEventRun,
        setIsEventRun,
      }}
    >
      <Box sx={classes.root}>
        <SnackbarProvider
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          maxSnack={3}
          disableWindowBlurListener
        >
          {children}
        </SnackbarProvider>
      </Box>
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
