import React, { useCallback, useEffect, useState } from 'react';

import { useMytaverse } from '../../../../../../providers/MytaverseProvider';
import ParticipantsService from '../../../../../../services/ParticipantsService';
import AvaturnModalView from './AvaturnModalView';
import { IUser } from '../../../../../../interfaces/user';
import {
  AVATAR_SCREENS,
  useEventContext,
} from '../../../../../providers/EventProvider';
import AvaturnService from '../../../../../../services/AvaturnService';

const AvaturnModal = () => {
  const { user, setUser, setCurrentAvatarId, setCustomAvatarUrl } =
    useMytaverse();

  const [isProcessing, setIsProcessing] = useState(false);

  const {
    avatarConsentOpen: open,
    setAvatarConsentOpen: setOpen,
    setAvatarScreen,
  } = useEventContext();

  useEffect(() => {
    if (user && user.isAvatarTermsAccepted === null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [user]);

  const handleClose = useCallback(() => {
    if (
      user?.isAvatarTermsAccepted === true ||
      user?.isAvatarTermsAccepted === false
    ) {
      setOpen(false);
    }
  }, [user?.isAvatarTermsAccepted]);

  const handleAccept = useCallback(
    async (consent: boolean) => {
      if (!user) {
        setOpen(false);
        return;
      }

      if (user.isAvatarTermsAccepted === consent) {
        setOpen(false);
        return;
      }

      try {
        setIsProcessing(true);

        if (user.avaturnId) {
          await AvaturnService.cleanAvaturnResources(user.avaturnId);
        }

        // need to delete Avaturn if user changes opinion
        const userUpdate = {
          isAvatarTermsAccepted: consent,
          avaturnId: '',
          customAvatarUrl: '',
        };
        await ParticipantsService.updateCurrentParticipantAvatar(userUpdate);

        setCurrentAvatarId('');
        setCustomAvatarUrl('');
        setUser((prevState: IUser) => ({
          ...prevState,
          ...userUpdate,
        }));

        setAvatarScreen(
          consent ? AVATAR_SCREENS.BEGIN : AVATAR_SCREENS.CUSTOM_UI,
        );
        setOpen(false);
      } catch (e) {
        console.error(e);
      } finally {
        setIsProcessing(false);
      }
    },
    [user],
  );

  if (!user?.id) {
    return null;
  }

  return (
    <AvaturnModalView
      open={open}
      disabled={isProcessing}
      onClose={handleClose}
      onClick={handleAccept}
    />
  );
};

export default AvaturnModal;
