import React, { useState } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { WithTranslation, withTranslation } from 'react-i18next';

import TabLabel from './TabLabel';
import TabContent from './TabContent';
import { useOfficeHours } from '../provider';

import { useStyles } from './styles';
import { OfficeHoursTabs } from './interfaces';

const Tabs = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();

  const [tab, setTab] = useState<OfficeHoursTabs>(OfficeHoursTabs.UPCOMING);

  const {
    upcomingSlots,
    isSlotsLoading,
    scheduledSlots,
    isScheduledSlotsLoading,
  } = useOfficeHours();

  const handleChange = (_: React.SyntheticEvent, newValue: OfficeHoursTabs) => {
    setTab(newValue);
  };

  return (
    <Box sx={classes.root}>
      <TabContext value={tab}>
        <Box sx={classes.tabList}>
          <TabList onChange={handleChange}>
            <Tab
              label={
                <TabLabel
                  label={translate('ek.officeHours.scheduled')}
                  count={scheduledSlots.length}
                />
              }
              value={OfficeHoursTabs.SCHEDULED}
              sx={classes.tab}
            />
            <Tab
              label={
                <TabLabel
                  label={translate('ek.officeHours.upcoming')}
                  count={upcomingSlots.length}
                />
              }
              value={OfficeHoursTabs.UPCOMING}
              sx={classes.tab}
            />
          </TabList>
        </Box>
        <TabPanel value={OfficeHoursTabs.SCHEDULED} sx={classes.tabPanel}>
          <TabContent
            slots={scheduledSlots}
            isLoading={isScheduledSlotsLoading}
            // onClick={() => {}}
            noSlotsText={translate('ek.officeHours.noScheduledSlots')}
          />
        </TabPanel>
        <TabPanel value={OfficeHoursTabs.UPCOMING} sx={classes.tabPanel}>
          <TabContent
            slots={upcomingSlots}
            //onClick={() => {}}
            isLoading={isSlotsLoading}
            noSlotsText={translate('ek.officeHours.noUpcomingSlots')}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default withTranslation('common')(Tabs);
