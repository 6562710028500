import { SwiperScreenSizes } from '../../../interfaces';

export const SwiperSettings = {
  [SwiperScreenSizes.Pc]: 4,
  [SwiperScreenSizes.Laptop]: 3,
  [SwiperScreenSizes.Tablet]: 3,
  [SwiperScreenSizes.Mobile]: 1,
};

export const SwiperEventSettings = {
  [SwiperScreenSizes.Pc]: 4,
  [SwiperScreenSizes.Laptop]: 3,
  [SwiperScreenSizes.Tablet]: 2,
  [SwiperScreenSizes.Mobile]: 1,
};

export const AVATAR_SKIN_SLIDER_WIDTH = 210;
export const AVATAR_SKIN_SLIDER_MOBILE_WIDTH = 70;

export const AVATAR_SKIN_BREAKPOINTS = {
  [SwiperScreenSizes.Mobile]: {
    slidesPerView: SwiperSettings[SwiperScreenSizes.Mobile],
    slidesPerGroup: SwiperSettings[SwiperScreenSizes.Mobile],
    spaceBetween: 0,
  },
  [SwiperScreenSizes.Tablet]: {
    slidesPerView: SwiperSettings[SwiperScreenSizes.Tablet],
    slidesPerGroup: 1,
    spaceBetween: 90,
  },
  [SwiperScreenSizes.Laptop]: {
    slidesPerView: SwiperSettings[SwiperScreenSizes.Laptop],
    slidesPerGroup: 1,
    spaceBetween: 70,
  },
  [SwiperScreenSizes.Pc]: {
    slidesPerView: SwiperSettings[SwiperScreenSizes.Pc],
    slidesPerGroup: SwiperSettings[SwiperScreenSizes.Pc],
    spaceBetween: 90,
  },
};

export const AVATAR_SKIN_SLIDER_WIDTH_BREAKPOINTS = {
  [SwiperScreenSizes.Mobile]: {
    width: AVATAR_SKIN_SLIDER_WIDTH,
  },
  [SwiperScreenSizes.Tablet]: {
    width: AVATAR_SKIN_SLIDER_MOBILE_WIDTH,
  },
  [SwiperScreenSizes.Laptop]: {
    width: AVATAR_SKIN_SLIDER_MOBILE_WIDTH,
  },
  [SwiperScreenSizes.Pc]: {
    width: AVATAR_SKIN_SLIDER_WIDTH,
  },
};
