import React, { useEffect, useRef } from 'react';
import useAsyncEffect from 'use-async-effect';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useGameInitialization } from './hooks';
import { IUseGamecast, IUseGamecastProps } from './interfaces';
import { getUserBrowser } from './helpers';
import { WebsocketAction } from '../../../../../interfaces/webSocketConnectionInfo';
import { useConference } from '../Dolby';
import { MytaverseLogger } from '../../../../../helpers/logger';
import { useLocation } from 'react-router-dom';
import ROUTES from '../../../../../constants/routes';
import { useEventContext } from '../../../../../ek/providers/EventProvider';
import {
  EventParticipantStateType,
  IEventParticipant,
} from '../../../../../interfaces/eventParticipant';
import {
  IXR_HR_BRAND_UNIFORMS,
  IXRHrCredStatusTypes,
} from '../../../../../interfaces/profile';
import { useConferenceState } from '../../../../../hooks/conferenceContext';

export const useGamecast = ({
  currentEventId,
  currentParticipant,
  websocketSessionId,
  currentRoom,
  gameSound,
  setGameCastStreamId,
  setGameCastStreamRegion,
  trackAnalytics,
  initMessageHandler,
  gameCastStreamId,
  gameCastStreamRegion,
  gameCastStreamRequestSent,
  setGameCastStreamRequestSent,
  setInitMessageSent,
  setStreamingProvider,
  setIsGamecastCrash,
  setCurrentRoom,
  isGamecastCrash,
  setGamecastStats,
  currentEventParticipant,
}: IUseGamecastProps): IUseGamecast => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const connectionTimer = useRef<number | null>(0);
  const browserType = getUserBrowser();
  const [isStreamLoading, setIsStreamLoading] = React.useState(false);
  const [isStreamLoaded, setIsStreamLoaded] = React.useState(false);
  const { conference } = useConference();
  const {
    sendJSONMessageToWebSocket,
    navigateToEventsPage,
    user,
    isDebugMode,
  } = useMytaverse();
  const { pathname } = useLocation();
  const { isEventRun } = useEventContext();

  const { activeSpeakerDeviceId } = useConferenceState();

  const sendMytaverseActions = React.useCallback(
    (currentEventParticipant: IEventParticipant) => {
      const eventParticipantState: EventParticipantStateType =
        currentEventParticipant?.state
          ? JSON.parse(currentEventParticipant?.state)
          : '';
      if (
        user.hrData &&
        user.hrData.ixrCredentialStatus === IXRHrCredStatusTypes.FT &&
        IXR_HR_BRAND_UNIFORMS.includes(user.hrData?.brand?.toUpperCase()) &&
        currentEventParticipant?.isHubMultiplayer &&
        !eventParticipantState?.bIsCelebrationMoment
      ) {
        console.log('Celebratory Moment Active');
        sendJSONMessageToWebSocket({
          action: WebsocketAction.MytaverseAction,
          tag: 'MytaEvent.Action.Tutorial.Start',
          data: 'Tutorial.iXR.CelebratoryMoment',
        });
      }
    },
    [sendJSONMessageToWebSocket],
  );

  const { initializeStream, handleReconnectGamecast, gameCastRef } = useGameInitialization({
    browserType,
    description: 'Test connection with gamecast',
    gameName: gameCastStreamId,
    streamId: gameCastStreamId,
    currentParticipant,
    setStreamingProvider,
    setIsGamecastCrash,
    setGamecastStats,
    currentRoom,
  });

  useAsyncEffect(async () => {
    if (
      isStreamLoading ||
      !currentParticipant ||
      gameCastStreamRequestSent ||
      !gameCastStreamId ||
      !gameCastStreamRegion
    ) {
      return;
    }

    if (isDebugMode) {
      const initMessage = await initMessageHandler();
      console.log({ initMessage });
      setInitMessageSent(true)
      setIsStreamLoaded(true);
      return;
    }

    try {
      setIsStreamLoading(true);
      if (!initMessageHandler) {
        return;
      }

      connectionTimer.current = new Date().getTime();
      const initMessage = await initMessageHandler();
      if (!initMessage) {
        return;
      }

      setGameCastStreamRequestSent(true);
      setInitMessageSent(true);

      const isStarted = await initializeStream(
        document.getElementById('gamecastVideoTag') as any,
        currentParticipant.participantId,
        websocketSessionId,
        initMessage,
        gameCastStreamRegion,
      );

      if (isStarted) {
        videoRef.current = document.getElementById(
          'gamecastVideoTag',
        ) as HTMLVideoElement;
        setIsStreamLoading(false);
        setIsStreamLoaded(true);

        if (currentEventParticipant) {
          sendMytaverseActions(currentEventParticipant);
        }
      } else {
        navigateToEventsPage('notifications.noAvailableStreamingProviders');
      }
    } catch (error) {
      setIsStreamLoading(false);
    }
  }, [
    currentParticipant,
    initMessageHandler,
    gameCastStreamRequestSent,
    gameCastStreamRegion,
    isStreamLoading,
    isDebugMode,
  ]);

  useEffect(() => {
    return () => {
      setGameCastStreamId('');
      setGameCastStreamRegion('');
    };
  }, []);

  useEffect(() => {
    if (
      !currentRoom ||
      !isStreamLoaded ||
      !videoRef.current ||
      !currentEventId ||
      !isEventRun ||
      pathname !== ROUTES.EVENT_PAGE(currentEventId)
    ) {
      return;
    }

    videoRef.current.style.position = 'absolute';
    videoRef.current.style.left = '0';
    videoRef.current.style.top = '0';
    videoRef.current.style.zIndex = '-1';
    videoRef.current.style.visibility = 'visible';
    videoRef.current.style.width = '100%';
    videoRef.current.style.height = '100vh';
    videoRef.current.style.maxHeight = '100%';
  }, [
    currentRoom,
    isStreamLoaded,
    videoRef,
    currentEventId,
    pathname,
    isEventRun,
  ]);

  useAsyncEffect(async () => {
    if (videoRef.current && currentRoom && isEventRun) {
      const spatialAudioStyle =
        conference && conference.params.spatialAudioStyle
          ? conference.params.spatialAudioStyle
          : 'disabled';

      try {
        //@ts-ignore
        await videoRef.current.setSinkId(activeSpeakerDeviceId);
      } catch (e) {
        console.error('Error setting audio output:', e);
      }

      videoRef.current.muted = false;
      videoRef.current.volume = gameSound;
    }
  }, [
    gameSound,
    videoRef,
    conference,
    currentRoom,
    isEventRun,
    activeSpeakerDeviceId,
  ]);

  useAsyncEffect(async () => {
    //if (!currentRoom || !streamSession) {
    if (!currentRoom) {
      return;
    }

    if (connectionTimer.current) {
      const connectionTime = new Date().getTime() - connectionTimer.current;
      // await trackAnalytics('GAMECAST_CONNECTION_FINISH', {
      //   // TODO update bigquery
      //   // gamecastClientOptions: {
      //   //   streamGroupId: streamId,
      //   //   participantId: currentParticipant.participantId,
      //   // },
      //   duration: connectionTime,
      // });

      MytaverseLogger.log(
        `GAME CAST CONNECTION TIME ${connectionTime / 1000}s`,
      );
      connectionTimer.current = null;
    }
  }, [currentRoom]);

  useAsyncEffect(() => {
    const browserType = getUserBrowser();

    MytaverseLogger.log(`ENTERING BY ${browserType} BROWSER`);
  }, []);

  useAsyncEffect(async () => {
    if (
      !isGamecastCrash ||
      !gameCastStreamRegion ||
      !currentParticipant ||
      !initMessageHandler
    ) {
      return;
    }
    setCurrentRoom(null);

    const message = await initMessageHandler();

    if (!message) {
      return;
    }

    await handleReconnectGamecast({
      region: gameCastStreamRegion,
      videoElement: document.getElementById('gamecastVideoTag') as any,
      participantId: currentParticipant.participantId,
      websocketSessionId,
      initMessage: message,
    });

    setTimeout(() => {
      setIsGamecastCrash(false);
    }, 20000);
  }, [isGamecastCrash, initMessageHandler]);

  return {
    isStreamLoading,
    isStreamLoaded,
    setIsStreamLoaded,
    setIsStreamLoading,
    videoRef,
    gameCastRef
  };
};
