import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Typography,
  Box,
  useMediaQuery,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import OverflowTitle from '../../../../../../components/OverflowTitle';
import { EventStatus } from '../../../../../../interfaces/event';

import { ISelectEventItemView } from './interfaces';

import { useStyles } from './styles';

const SelectEventItemView = ({
  event,
  eventsCount,
  onClick,
}: ISelectEventItemView) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:900px)');

  return (
    <Box sx={classes.container}>
      {isMobile && eventsCount > 1 && (
        <Box sx={classes.mobileContainer}>
          <ArrowBackIcon sx={classes.icon} />
          <Typography variant="body2" sx={classes.swipeText}>
            Swipe to see more events
          </Typography>
          <ArrowForwardIcon sx={classes.icon} />
        </Box>
      )}

      <Card sx={classes.root} onClick={onClick}>
        {event.streamGroupConfig ? (
          <Chip
            label={`Available: ${event.streamGroupConfig.IdleCapacity}`}
            size="small"
            sx={classes.chipCapacity}
          />
        ) : null}
        {event.status === EventStatus.TESTING ? (
          <Chip
            label="Testing"
            color="success"
            size="small"
            sx={classes.chip}
          />
        ) : null}
        {event.clientChangeset ? (
          <Chip
            label={`Version: ${event.clientChangeset}`}
            size="small"
            sx={classes.version}
          />
        ) : null}
        <CardMedia
          component="img"
          image={event.logoImage ? event.logoImage.url : ''}
          alt=""
          sx={classes.image}
        />
        <CardContent sx={classes.content}>
          <OverflowTitle
            title={event.name}
            component="div"
            sx={classes.eventName}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default SelectEventItemView;
