import $api from '../http/axios';

import {
  ICheckUserExistsInAppResponse,
  IInviteUserToEventData,
} from './interfaces';
import { IUser } from '../interfaces/user';
import axios, { AxiosResponse } from 'axios';

export default class UserService {
  static changeUser(userId: string, credo: any) {
    return axios
      .patch(
        `${process.env.REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/participants/${userId}`,
        credo,
      )
      .then((res) => res.data);
  }

  static getUserByEmail(email: string): Promise<AxiosResponse<IUser>> {
    return $api
      .get(`/auth/users/get/${email}`)
      .then((res) => res)
      .catch((error) => error);
  }

  static updatePassword(password: string, token: string): Promise<boolean> {
    return $api
      .post('/auth/passwordChange', {
        password,
        token,
      })
      .then((res) => !!res.data.result);
  }

  static subscribeUserToEvent(
    data: IInviteUserToEventData,
  ): Promise<{ message?: string }> {
    return $api
      .post<{ message?: string }>('/cms/events/subscribe', data)
      .then((res) => res.data);
  }

  static inviteUserToEvent(
    data: IInviteUserToEventData,
  ): Promise<{ message?: string }> {
    return $api
      .post<{ message?: string }>('/cms/events/invite', data)
      .then((res) => res.data);
  }

  static publicInviteUserToEvent(
    data: IInviteUserToEventData,
  ): Promise<{ message?: string }> {
    return $api
      .post<{ message?: string }>('/public/emails/tryDemo', data)
      .then((res) => res.data);
  }

  static async checkUserExistsInApp(
    token: string,
  ): Promise<ICheckUserExistsInAppResponse> {
    try {
      const res = await $api.post('auth/invitationCheck', { token });
      return res.data;
    } catch (error) {
      return { error: `${error}` };
    }
  }
}
