import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Button, Backdrop, Modal, Typography } from '@mui/material';

import { CloseIcon } from '../../../../../icons';
import { ReactComponent as PrivacyIcon } from '../../../../../public/images/ek/privacy.svg';
import PdfViewer from './PdfViewer';

import { ModalView } from './interfaces';
import { useStyles } from './styles';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';

const PolicyModalView = ({
  file,
  open,
  disabled,
  isRead,
  handleScroll,
  onClose,
  onClick,
  t: translate,
}: ModalView) => {
  const classes = useStyles();

  const { user } = useMytaverse();

  const handleClose = useCallback(() => {
    if (user?.isTermsAccepted === true) {
      onClose();
    }
  }, [onClose, user]);

  return (
    <Modal sx={classes.root} open={open} components={{ Backdrop }}>
      <Box component="div" sx={classes.container}>
        <CloseIcon sx={classes.closeIcon} onClick={handleClose} />
        <Box component="div" sx={classes.iconContainer}>
          <PrivacyIcon />
        </Box>
        <Typography sx={classes.title}>
          {translate('ek.agreement.title')}
        </Typography>
        <Box sx={classes.pdf} onScroll={handleScroll}>
          <PdfViewer file={file} />
          {isRead ? null : <Box sx={classes.overlay} />}
        </Box>

        <Button sx={classes.button} onClick={onClick} disabled={disabled}>
          {translate('ek.agreement.accept')}
        </Button>
      </Box>
    </Modal>
  );
};

export default withTranslation('common')(PolicyModalView);
