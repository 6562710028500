import React from 'react';
import { Box, Typography } from '@mui/material';

import { IStep } from './interfaces';

import { classes } from '../styles';

const Step = ({ title, text, image }: IStep) => {
  return (
    <Box component="div" sx={classes.step}>
      <Typography sx={classes.stepTitle}>{title}</Typography>
      <Typography sx={classes.text}>{text}</Typography>
      <Box sx={classes.image}>
        <img src={image} alt={title} />
      </Box>
    </Box>
  );
};

export default Step;
