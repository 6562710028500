import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import { io } from 'socket.io-client';

import { IPocAiContext, PocAiProviderProps } from './interfaces';
import { POC_SCREENS } from '../constants';
import { IPocMessage, PocMessageType } from '../interfaces';
import { visualizeAudio } from './avatarVisualizer';
import { assistantSize, greetingsText } from '../constants';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../../../dashboard/providers/MytaverseEventProvider';

import {
  getLocalStorageValue,
  setLocalStorageValue,
} from '../../../../../helpers/localStorage';
import { StorageValues } from '../../../../../interfaces/storage';

const { REACT_APP_AI_API_URL } = process.env;

export const PocAiContext = createContext<IPocAiContext>({} as IPocAiContext);

export const usePocAi = () => useContext(PocAiContext);

const socket = io(REACT_APP_AI_API_URL);
let audioContext: any;
let audioInput: any;
let processor: any;
let visualizerInstance: any;

export const PocAiProvider: FC<PocAiProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [activeScreen, setActiveScreen] = useState<POC_SCREENS>(
    POC_SCREENS.DEFAULT,
  );
  const [navigationHistory, setNavigationHistory] = useState<POC_SCREENS[]>([POC_SCREENS.DEFAULT]);
  let userName = '';

  const [chatHistory, setChatHistory] = useState<IPocMessage[]>([]);

  const [isConnected, setIsConnected] = useState(socket.connected);

  const { user } = useMytaverse();
  const mytaverseEventProvider = useMytaverseEvent();

  if (user.firstName) {
    userName = user.firstName;
  } else if (user.name) {
    userName = user.name;
  }

  const navigate = (screen: POC_SCREENS, navigateBack?: boolean) => {
    if (navigateBack) {
      setActiveScreen(navigationHistory[navigationHistory.length - 2]);
    } else {
      setActiveScreen(screen);
    }

    setNavigationHistory((prevState) => {
      if (navigateBack) {
        prevState.pop();
        return [...prevState];
      }

      return [...prevState, screen];
    });
  }

  const addMessage = useCallback(
    ({ text, type }: { text: string; type: PocMessageType }) => {
      setChatHistory((prevState) => {
        return [...prevState, { text, type, timestamp: Date.now() }];
      });
    },
    [],
  );

  const handleSendRequest = useCallback(
    async (text: string) => {
      addMessage({ text, type: PocMessageType.USER });
      setAwaitingResponse(true);

      socket.emit('queryBedrock', { text });
    },
    [addMessage],
  );

  const queryBedrockTest = () => {
    console.warn('queryBedrockTest clicked');
    socket.emit('queryBedrock', { text: 'what is aws?' });
  }

  const generateAudioPlaybackTest = () => {
    console.warn('generateAudioPlaybackTest clicked');
    socket.emit('generateAudio', { text: 'what is aws?' });
  }

  const generateGreetingsAudio = () => {
    console.warn('generateGreetingsAudio clicked');
    socket.emit('generateAudio', { text: greetingsText.replace('@userName@', userName ? ', ' + userName : '') });
  }

  const playBackOnFirstTime = () => {
    const assistantStorage =
    getLocalStorageValue<any>(StorageValues.Assistant, 'session') || {
      isFirstTime: true,
    };

    if (assistantStorage.isFirstTime) {
      setLocalStorageValue(StorageValues.Assistant, {
        isFirstTime: false,
      }, 'session');
      generateGreetingsAudio();
    }
  }

  const startRecording = async() => {
    console.log('start recording audio');

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('microphone access granted');
      audioContext = new AudioContext();
      audioInput = audioContext.createMediaStreamSource(stream);
      processor = audioContext.createScriptProcessor(1024, 1, 1);
      audioInput.connect(processor);
      processor.connect(audioContext.destination);

      processor.onaudioprocess = (e: any) => {
        const float32Array = e.inputBuffer.getChannelData(0);
        const int16Array = new Int16Array(float32Array.length);
        for (let i = 0; i < float32Array.length; i++) {
          int16Array[i] = Math.max(-32768, Math.min(32767, Math.floor(float32Array[i] * 32768)));
        }
        console.log('sending audio chunk to server, size', int16Array.buffer.byteLength);
        socket.emit('transcribeAudioData', int16Array.buffer);
      };

      socket.emit('startTranscription');
      console.log('startTranscription event emitted');
    } catch (error) {
      console.error('error accessing microphone', error);
    }
  }

  function stopRecording() {
    console.log('stop recording audio');

    if (audioContext && audioContext.state !== 'closed') {
      try {
        audioInput.disconnect();
        processor.disconnect();
        audioContext.close();
        socket.emit('stopTranscription');
      } catch(error) {
        console.error('error closing audio', error);
      }
    }
  }
  
  function stopPlayback() {
    visualizerInstance.stopAudio();
  }

  function isAIEnabledForEvent() {
    if (mytaverseEventProvider &&
        mytaverseEventProvider.currentEventId &&
        mytaverseEventProvider.events.find(x => x.eventId === mytaverseEventProvider.currentEventId)?.enableAssistant) {

      return true;
    }

    return false;
  }

  useEffect(() => {
    function onConnect() {
      console.warn('connected to AI POC');
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onQueryBedrock(data: any) {
      console.warn('onQueryBedrock', data);
      setAwaitingResponse(false);
      socket.emit('generateAudio', { text: data });
      addMessage({ text: data, type: PocMessageType.ASSISTANT });
    }

    function onGenerateAudio(data: any) {
      console.warn('onGenerateAudio', data);
      if (visualizerInstance) {
        visualizerInstance.stopAudio();
      }
      visualizerInstance = visualizeAudio(assistantSize.width, assistantSize.height);
      visualizerInstance.initialize(data);
    }

    function onTranscription(data: any) {
      console.log('onTranscription', data);
  
      if (data.isFinal) {
        addMessage({ text: data.text, type: PocMessageType.USER });
      }
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('queryBedrock', onQueryBedrock);
    socket.on('generateAudio', onGenerateAudio);
    socket.on('transcription', onTranscription);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('queryBedrock', onQueryBedrock);
      socket.off('generateAudio', onGenerateAudio);
      socket.off('transcription', onTranscription);
    };
  }, []);

  return (
    <PocAiContext.Provider
      value={{
        activeScreen,
        setActiveScreen,
        open,
        setOpen,
        handleSendRequest,
        chatHistory,
        queryBedrockTest,
        generateAudioPlaybackTest,
        generateGreetingsAudio,
        playBackOnFirstTime,
        startRecording,
        stopRecording,
        navigate,
        setNavigationHistory,
        awaitingResponse,
        stopPlayback,
        isAIEnabledForEvent
      }}
    >
      {children}
    </PocAiContext.Provider>
  );
};
