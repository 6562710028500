import React from 'react';
import { withTranslation } from 'react-i18next';
import { signInWithRedirect } from 'aws-amplify/auth';

import { Box, Typography } from '@mui/material';

import {
  FormButton,
  FormLayout,
} from '../../../../../modules/auth/components/Form';
import LegalNotes from '../../../../../modules/auth/components/LegalNotes';
import PageTitleContent from '../../components/PageTitleContent';

import { ILoginPageView } from './interfaces';

import { useStyles } from './styles';

const LoginPageView = ({
  title,
  welcomeBackFlag,
  t: translate,
}: ILoginPageView) => {
  const classes = useStyles();

  return (
    <Box sx={classes.root}>
      <PageTitleContent title={title} subtitle={translate('login.subtitle')} />
      <LegalNotes>
        <FormLayout classes={{ container: classes.layoutContainer }}>
          <Typography sx={classes.formTitle}>
            {welcomeBackFlag
              ? translate('login.welcomeBack')
              : translate('login.title')}
          </Typography>
          <FormButton onClick={() => signInWithRedirect()} sx={classes.button}>
            {translate('login.signIn')}
          </FormButton>
        </FormLayout>
      </LegalNotes>
    </Box>
  );
};

export default withTranslation('common')(LoginPageView);
