import React from 'react';
import { Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import DashboardBarButton from '../DashboardBarButton';
import { IParticipantsButton } from './interfaces';

import { useStyles } from './styles';

const ParticipantsButton = ({ onClick, total }: IParticipantsButton) => {
  const classes = useStyles();

  return (
    <DashboardBarButton
      onClick={onClick}
      buttonIcon={<PeopleAltIcon />}
      sx={classes.button}
    >
      <Typography component="span" sx={classes.content}>
        <Typography component="span">{total}</Typography>
        <Typography component="span" sx={classes.info}>
          Online User{total > 1 && 's'} <CircleIcon sx={classes.indicator} />
        </Typography>
      </Typography>
    </DashboardBarButton>
  );
};

export default ParticipantsButton;
