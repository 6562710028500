import { IStyles } from '../../../../interfaces/styles';

export const useStyles = (iconColor: string): IStyles => ({
  root: {
    margin: 0,
    padding: 0,
    width: 'auto',
    height: 'auto',
    '& svg': {
      color: iconColor,
      fontSize: 16,
    },
  },
});
