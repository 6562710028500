import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import UserProfileModalHeader from './UserProfileModalHeader';
import UserProfileForm from './UserProfileForm';
import UserProfilePreview from './UserProfilePreview';
import UploadImageModal from './UploadImageModal';
import LoadingProgress from '../LoadingProgress';

import { useMytaverse } from '../../providers/MytaverseProvider';

import AppModal from '../AppModal';

import { IUserProfileModal } from './interfaces';

import { useStyles } from './styles';
import { IMytaverseFile } from '../../interfaces/file';

const UserProfileModal = ({
  onClose,
  isOpen,
  enableEditing = false,
  previewingParticipant = null,
}: IUserProfileModal) => {
  const classes = useStyles();

  const [editing, setEditing] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [updImage, setUpdImage] = useState<string | File | IMytaverseFile>('');

  const { changingUserData, user } = useMytaverse();

  useEffect(() => {
    if (!editing) {
      setUpdImage('');
    }
  }, [editing]);

  return (
    <AppModal isOpen={isOpen} onClick={onClose} sx={classes.container}>
      <Box sx={classes.content}>
        <UserProfileModalHeader onClose={onClose} />
        <Box sx={classes.contentWrapper}>
          {changingUserData ? (
            <LoadingProgress height="100%" />
          ) : editing ? (
            <UserProfileForm
              openUpload={openUpload}
              setOpenUpload={setOpenUpload}
              updImage={updImage}
              onClose={() => setEditing(false)}
            />
          ) : (
            <UserProfilePreview
              user={user}
              enableEditing={enableEditing}
              onEditClick={() => setEditing(!editing)}
            />
          )}
        </Box>
      </Box>
      <UploadImageModal
        open={openUpload}
        setUpdImage={setUpdImage}
        setOpen={setOpenUpload}
      />
    </AppModal>
  );
};

export default UserProfileModal;
