import { useTheme } from '@mui/material';

import { IStyles } from '../../../../../../interfaces/styles';
import { ScreenSizes } from '../../../../../../interfaces';

export const useStyles = (): IStyles => {
  const { breakpoints } = useTheme();

  return {
    button: {
      [breakpoints.down(ScreenSizes.PcLarge)]: {
        padding: '6px 10px',
        minWidth: 'unset',
        fontSize: 14,
        '& svg': {
          mr: 0,
        },
      },
    },
  };
};
