import { Box, styled } from '@mui/material';

export const VideoResponsive = styled(Box)({
  overflow: 'hidden',
  paddingBottom: '56.25%',
  position: 'relative',
  height: 0,
  width: '100%',
});

export const Iframe = styled('iframe')({
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
  position: 'absolute',
  pointerEvents: 'auto',
  border: 0,
});
