import { colors } from '../../../../../../constants/colors';

export const useStyles = () => {
  return {
    controls: {
      background: colors.oxford_40,
      backdropFilter: 'blur(18px)',
      paddingX: 2,
      paddingY: 1,
      display: 'flex',
      justifyContent: 'end',
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      '& svg': {
        height: 40,
        width: 40,
      },
    },
    button: {
      color: colors.white,
      minWidth: 'unset',
      padding: 0,
    },
  };
};
