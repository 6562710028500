import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSound } from '../../../../../../hooks/media';
import { useConferenceState } from '../../../../../../hooks/conferenceContext';
import { useZoom } from '../../../DashboardContent/Zoom/ZoomProvider';

import Sound from '../../../../../../public/sounds/test.mp3';
import DevicesSettingsSoundView from './DevicesSettingsSoundView';
import { useInitSpeakerDevices } from '../hooks';

const DevicesSettingsSound = () => {
  const { speakerList, micList } = useZoom();

  const {
    activeSpeakerDeviceId,
    activeMicroDeviceId,
    setActiveSpeakerDeviceId,
    setActiveMicroDeviceId,
  } = useConferenceState();

  const { devices, initializing } = useInitSpeakerDevices();

  const { t: translate } = useTranslation('common');

  const [play, { stop, isPlaying }] = useSound(Sound);

  const speakers = speakerList.length ? speakerList : devices;

  return (
    <DevicesSettingsSoundView
      translate={translate}
      microphones={micList}
      speakers={speakers}
      selectedMicrophone={activeMicroDeviceId}
      selectMicrophone={setActiveMicroDeviceId}
      selectedSpeaker={activeSpeakerDeviceId || 'default'}
      selectSpeaker={setActiveSpeakerDeviceId}
      changingDevice={speakerList.length ? false : initializing}
      play={play}
      stop={stop}
      isTestingSound={isPlaying}
    />
  );
};

export default DevicesSettingsSound;
