import { WithTranslation } from 'react-i18next';
import { ISlot as ISlotEvent } from '../../../../../../../interfaces/officeHours';

export interface ISlotProps extends WithTranslation {
  slot: ISlotEvent;
}

export interface ISlotViewProps extends WithTranslation {
  slot: ISlotEvent;
  isLoading: boolean;
  onBook: () => void;
  onCancel: () => void;
  onJoin: () => void;
}

export enum SlotActions {
  BOOK = 'BOOK',
  CANCEL = 'CANCEL',
}
