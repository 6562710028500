import { IStyles } from '../../../../../interfaces/styles';
import { COLORS } from '../../../../../constants/colors';

export const useStyles = (): IStyles => {
  return {
    root: {
      borderBottom: 1,
      borderColor: 'divider',
      width: '100%',
      mt: [1, 1, 2, 2],
    },
    totalText: {
      fontSize: 16,
      fontWeight: 500,
      color: COLORS.LIGHT_GRAY,
      py: 1,
      position: 'relative',
    },
    icon: { mr: [1, 2] },
    emptyText: {
      fontSize: [20, 24],
      textAlign: 'left',
      color: COLORS.WHITE,
      mt: 3,
    },
    // buttonLink: {
    //   width: ['100vw', '41vw', '41vw', '29vw'],
    //   height: 64,
    //   textTransform: 'none',
    //   fontSize: 24,
    //   position: 'absolute',
    //   bottom: 0,
    //   left: 0,
    //   color: COLORS.WHITE,
    //   bgcolor: COLORS.BLUE,
    // } as IStyles,
    // iconLink: {
    //   mr: 3,
    // },
  };
};
