import * as React from 'react';
import Radio, { RadioProps } from '@mui/material/Radio';

import { SkinToneType } from '../../../interfaces';
import { CheckedIcon, Icon, useStyles } from './styles';

const SkinToneRadio = (props: RadioProps) => {
  const classes = useStyles();

  return (
    <Radio
      disableRipple
      checkedIcon={<CheckedIcon variant={props.value as SkinToneType} />}
      icon={<Icon variant={props.value as SkinToneType} />}
      sx={classes.genderRadio}
      {...props}
    />
  );
};

export default SkinToneRadio;
