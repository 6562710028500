import axios, { AxiosError } from 'axios';
import {
  IAvatarAssets,
  IAvaturnData,
  IExportAvatar,
  INewAvaturnData,
} from '../interfaces/avatar';
import {
  ITokens,
  IXRHrBrandTypes,
  IXRHrCredStatusTypes,
} from '../interfaces/profile';
import {
  GenderType,
  SkinToneType,
} from '../ek/modules/auth/components/ChooseAvatar/interfaces';

const {
  REACT_APP_AVATURN_USERS_API,
  REACT_APP_AVATURN_SESSIONS_API,
  REACT_APP_SPATIAL_MANAGER_API_URL,
  REACT_APP_EK_ENV
} = process.env;

const customAxios = axios.create();

export default class AvaturnService {
  static createUser(
    tokens: ITokens | null,
    brand: string,
    ixrCredentialStatus: string,
  ) {
    if (!brand || !ixrCredentialStatus) {
      console.log('Avaturn: createUser Missing required parameters');
      return null;
    }

    if (REACT_APP_EK_ENV === 'true' && ixrCredentialStatus.toUpperCase() === IXRHrCredStatusTypes.Pre) {
      return customAxios
        .post(
          REACT_APP_AVATURN_USERS_API || '',
          {},
          {
            headers: {
              Authorization: `Bearer ${tokens?.avaturn?.ek}`,
            },
          },
        )
        .then((res) => res.data);
    } else {
      switch (brand.toUpperCase()) {
        case IXRHrBrandTypes.Mytaverse:
          return customAxios
            .post(
              REACT_APP_AVATURN_USERS_API || '',
              {},
              {
                headers: {
                  Authorization: `Bearer ${tokens?.avaturn?.mytaverse}`,
                },
              },
            )
            .then((res) => res.data);
        case IXRHrBrandTypes.Dnata:
          return customAxios
            .post(
              REACT_APP_AVATURN_USERS_API || '',
              {},
              {
                headers: {
                  Authorization: `Bearer ${tokens?.avaturn?.dnata}`,
                },
              },
            )
            .then((res) => res.data);
        case IXRHrBrandTypes.Marhaba:
          return customAxios
            .post(
              REACT_APP_AVATURN_USERS_API || '',
              {},
              {
                headers: {
                  Authorization: `Bearer ${tokens?.avaturn?.marhaba}`,
                },
              },
            )
            .then((res) => res.data);

        case IXRHrBrandTypes.Ekas:
          return customAxios
            .post(
              REACT_APP_AVATURN_USERS_API || '',
              {},
              {
                headers: {
                  Authorization: `Bearer ${tokens?.avaturn?.ekas}`,
                },
              },
            )
            .then((res) => res.data);
      }
    }

    return customAxios
      .post(
        REACT_APP_AVATURN_USERS_API || '',
        {},
        {
          headers: {
            Authorization: `Bearer ${tokens?.avaturn?.ek}`,
          },
        },
      )
      .then((res) => res.data);
  }

  static createSession(
    tokens: ITokens | null,
    avaturnId: string,
    avatarId: string,
    sessionType: string,
    brand: string,
    ixrCredentialStatus: string,
  ) {
    if (!avaturnId || !brand || !ixrCredentialStatus) {
      console.log('Avaturn: createSession Missing required parameters');
      return null;
    }

    let config = { type: sessionType } as any;
    if (sessionType === 'edit_existing') {
      config = { ...config, avatar_id: avatarId };
    }

    if (
      REACT_APP_EK_ENV === 'true' &&
      ixrCredentialStatus.toUpperCase() === IXRHrCredStatusTypes.Pre
    ) {
      return customAxios
        .post(
          REACT_APP_AVATURN_SESSIONS_API || '',
          {
            user_id: avaturnId,
            config,
          },
          {
            headers: {
              Authorization: `Bearer ${tokens?.avaturn?.ek}`,
            },
          },
        )
        .then((res) => res.data)
        .catch((error) => {
          console.log(error);
          if (error.response?.status === 404) {
            return null;
          }
        });
    } else {
      switch (brand.toUpperCase()) {
        case IXRHrBrandTypes.Mytaverse:
          return customAxios
            .post(
              REACT_APP_AVATURN_SESSIONS_API || '',
              {
                user_id: avaturnId,
                config,
              },
              {
                headers: {
                  Authorization: `Bearer ${tokens?.avaturn?.mytaverse}`,
                },
              },
            )
            .then((res) => res.data)
            .catch((error) => {
              console.log(error);
              if (error.response?.status === 404) {
                return null;
              }
            });
        case IXRHrBrandTypes.Dnata:
          return customAxios
            .post(
              REACT_APP_AVATURN_SESSIONS_API || '',
              {
                user_id: avaturnId,
                config,
              },
              {
                headers: {
                  Authorization: `Bearer ${tokens?.avaturn?.dnata}`,
                },
              },
            )
            .then((res) => res.data)
            .catch((error) => {
              console.log(error);
              if (error.response?.status === 404) {
                return null;
              }
            });
        case IXRHrBrandTypes.Marhaba:
          return customAxios
            .post(
              REACT_APP_AVATURN_SESSIONS_API || '',
              {
                user_id: avaturnId,
                config,
              },
              {
                headers: {
                  Authorization: `Bearer ${tokens?.avaturn?.marhaba}`,
                },
              },
            )
            .then((res) => res.data)
            .catch((error) => {
              console.log(error);
              if (error.response?.status === 404) {
                return null;
              }
            });

        case IXRHrBrandTypes.Ekas:
          return customAxios
            .post(
              REACT_APP_AVATURN_SESSIONS_API || '',
              {
                user_id: avaturnId,
                config,
              },
              {
                headers: {
                  Authorization: `Bearer ${tokens?.avaturn?.ekas}`,
                },
              },
            )
            .then((res) => res.data)
            .catch((error) => {
              console.log(error);
              if (error.response?.status === 404) {
                return null;
              }
            });
      }
    }

    return customAxios
      .post(
        REACT_APP_AVATURN_SESSIONS_API || '',
        {
          user_id: avaturnId,
          config,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens?.avaturn?.mytaverse}`,
          },
        },
      )
      .then((res) => res.data)
      .catch((error) => {
        console.log(error);
        if (error.response?.status === 404) {
          return null;
        }
      });
  }

  static async validateUrl(avatarUrl: string) {
    try {
      const result = await customAxios.get(avatarUrl, {
        timeout: 500,
      });

      if (result.status === 200) {
        return true;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 404) {
          return false;
        } else if (
          error.code === AxiosError.ECONNABORTED ||
          error.message.includes('maxContentLength size of')
        ) {
          return true;
        }
      }
    }

    return false;
  }

  static async getAssets(
    ixrCredentialStatus: string,
    gender: string,
    brand: string,
  ): Promise<IAvatarAssets> {
    const url = `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/avaturn/assets/gender/${gender}/ixrCredentialStatus/${ixrCredentialStatus}/brand/${brand}`;

    return axios
      .get(url)
      .then((res) => res.data)
      .catch(() => {
        // Return an empty object
        return {
          assetId: '',
          uniforms: [],
          glasses: [],
          shoes: [],
          hair: [],
          default: [],
        } as IAvatarAssets;
      });
  }

  static async exportAvaturnDataToS3(
    ixrCredentialStatus: string,
    brand: string,
    data: Omit<IExportAvatar, 'brand'>,
  ): Promise<IAvaturnData> {
    let brandWithCreds = brand;
    if (brand !== IXRHrBrandTypes.Mytaverse && ixrCredentialStatus?.toUpperCase() === IXRHrCredStatusTypes.Pre) {
      brandWithCreds = 'no-brand';
    }
    const url = `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/avaturn/exportAvaturnDataToS3`;

    return axios
      .post(url, { ...data, brand: brandWithCreds })
      .then((res) => res.data)
      .catch((error) => {
        console.error('Error in exportAvaturnDataToS3:', error);
        throw error; // Re-throw the error for handling upstream
      });
  }

  static async createNewAvaturn(
    ixrCredentialStatus: string,
    brand: string,
    data: { avaturnId?: string; gender?: GenderType; skinTone?: SkinToneType },
  ): Promise<INewAvaturnData> {
    let brandWithCreds = brand;
    if (brand !== IXRHrBrandTypes.Mytaverse && ixrCredentialStatus.toUpperCase() === IXRHrCredStatusTypes.Pre) {
      brandWithCreds = 'no-brand';
    }

    const url = `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/avaturn/createNewAvaturn`;

    return axios
      .post(url, {
        avaturnId: data.avaturnId,
        brand: brandWithCreds,
        //handle it for clients
        gender: data.gender || 'male',
        skinTone: data.skinTone,
      })
      .then((res) => res.data)
      .catch((error) => {
        console.error('Error in createNewAvaturn:', error);
        throw error;
      });
  }

  static async deleteAvaturn(avaturnId: string): Promise<void> {
    const url = `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/avaturn/deleteAvaturn`;

    return axios
      .post(url, { avaturnId })
      .then((res) => res.data)
      .catch((error) => {
        console.error('Error in deleteAvaturn:', error);
        throw error;
      });
  }

  static async deleteAvaturnByParticipant(): Promise<void> {
    const url = `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/avaturn/deleteAvaturnByParticipant`;

    return axios
      .post(url)
      .then((res) => res.data)
      .catch((error) => {
        console.error('Error in deleteAvaturn:', error);
        throw error;
      });
  }

  static async cleanAvaturnResources(avaturnId: string): Promise<void> {
    const url = `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/avaturn/cleanAvaturnResources`;

    return axios
      .post(url, { avaturnId })
      .then((res) => res.data)
      .catch((error) => {
        console.error('Error in cleanAvaturnResources:', error);
        throw error;
      });
  }
}
