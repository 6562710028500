import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box } from '@mui/material';

import { useChatState } from '../../../../../../hooks/context';
import ScrollableContainer from '../../../../../../components/ScrollableContainer';
import { checkIsStreamChannelUnread } from '../../../../helpers';
import ParticipantStatusIcon from '../../../../../../components/ParticipantStatusIcon';
import LoadingProgress from '../../../../../../components/LoadingProgress';
import ArrowButton from '../../ArrowButton';

import ParticipantSearchItem from '../ParticipantSearchItem';
import { ISearchChatParticipants } from './interfaces';
import { useStyles } from './styles';

const COUNT = 5;
const ITEM_HEIGHT = 48;

const SearchChatParticipants = ({
  results,
  onItemClick,
}: ISearchChatParticipants) => {
  const classes = useStyles();
  const { loadingPrivateChannel } = useChatState();

  const listRef = useRef<HTMLDivElement | null>(null);

  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(COUNT);

  useEffect(() => {
    if (!listRef.current?.offsetHeight) {
      return;
    }

    const count = Math.ceil(listRef.current?.offsetHeight / ITEM_HEIGHT);
    setItemsPerPage((prevState) => (count > prevState ? count : prevState));
  }, [listRef.current?.offsetHeight]);

  const onScroll = useCallback(() => {
    if (!listRef.current || !results.length) {
      return;
    }

    const maxPages = Math.ceil(results.length / itemsPerPage);
    const { scrollTop, scrollHeight, clientHeight } = listRef.current;

    if (scrollTop + clientHeight === scrollHeight && maxPages > page) {
      setPage((prevState) => ++prevState);
    }
  }, [results.length, page, itemsPerPage]);

  const itemsToDisplay =
    itemsPerPage * page > results.length ? results.length : itemsPerPage * page;

  return (
    <Box ref={listRef}>
      <ScrollableContainer
        sxProps={{
          scrollContent: classes.scrollContent,
          content: classes.content,
          root: classes.scrollRoot,
        }}
        onScroll={onScroll}
      >
        {results
          .slice(0, itemsToDisplay)
          .map(({ participant, privateChannel }) => {
            const lastMessage =
              privateChannel?.channel?.lastMessage()?.text || '';
            const isUnreadChannel = checkIsStreamChannelUnread(
              privateChannel?.channel || null,
            );

            return (
              <ParticipantSearchItem
                key={participant.participantId}
                item={participant}
                lastMessage={lastMessage}
                isUnreadChannel={isUnreadChannel}
                onClick={() => onItemClick(participant.participantId)}
                actions={
                  <>
                    <ParticipantStatusIcon
                      isOnline={!!participant.roomId}
                      className="participantStatusIcon"
                      sx={classes.statusIcon}
                    />
                    <ArrowButton sx={classes.resultItemAction} forwardIcon />
                    {participant.participantId.replace('|', '_') ===
                    loadingPrivateChannel ? (
                      <LoadingProgress
                        size={26}
                        height="auto"
                        classes={{ root: classes.loading }}
                      />
                    ) : null}
                  </>
                }
                classes={{
                  root: classes.resultItem(lastMessage),
                  name: classes.resultItemName(isUnreadChannel),
                }}
              />
            );
          })}
      </ScrollableContainer>
    </Box>
  );
};

export default SearchChatParticipants;
