import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box } from '@mui/material';
import { ReactComponent as ArrowRightIcon } from '../../../../../../public/images/ek/joystick/arrow-right.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../../../../public/images/ek/joystick/arrow-left.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../../../public/images/ek/joystick/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../../../public/images/ek/joystick/arrow-down.svg';

import { useStyles } from './styles';

const COORDINATES = {
  top: { x: 28, y: 5 },
  right: { x: 50, y: 28 },
  bottom: { x: 28, y: 50 },
  left: { x: 5, y: 28 },
  center: { x: 28, y: 28 },
};

type PositionType = 'top' | 'right' | 'bottom' | 'left' | 'center';

const Joystick = () => {
  const classes = useStyles();

  const containerRef = useRef<HTMLElement>();
  const joystickRef = useRef<HTMLElement>();

  const [position, setPosition] = useState<PositionType>('center');

  const handleTouchMove = useCallback((event: TouchEvent) => {
    event.preventDefault();

    if (!containerRef.current) {
      setPosition('center');
      return;
    }
    const bounding = containerRef.current.getBoundingClientRect();
    const touch = event.touches[0];

    const touchX = touch.clientX - bounding.left;
    const touchY = touch.clientY - bounding.top;

    const containerWidth = bounding.width;
    const containerHeight = bounding.height;

    const topThreshold = 0.1;
    const bottomThreshold = containerHeight * (1 - topThreshold);
    const leftThreshold = 0.1;
    const rightThreshold = containerWidth * (1 - leftThreshold);

    if (touchY < topThreshold * containerHeight) {
      setPosition('top');
    } else if (touchY > bottomThreshold) {
      setPosition('bottom');
    } else if (touchX < leftThreshold * containerWidth) {
      setPosition('left');
    } else if (touchX > rightThreshold) {
      setPosition('right');
    } else {
      setPosition('center');
    }
  }, []);

  const handleTouchEnd = useCallback(() => {
    setPosition('center');
  }, []);

  useEffect(() => {
    joystickRef.current?.addEventListener('touchmove', handleTouchMove, {
      passive: false,
    });
    joystickRef.current?.addEventListener('touchend', handleTouchEnd);

    return () => {
      joystickRef.current?.removeEventListener('touchmove', handleTouchMove);
      joystickRef.current?.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  const { x, y } = COORDINATES[position];

  return (
    <Box sx={classes.root}>
      <Box sx={classes.arrow(position === 'top')}>
        <ArrowUpIcon />
      </Box>
      <Box sx={classes.middleBox}>
        <Box sx={classes.arrow(position === 'left')}>
          <ArrowLeftIcon />
        </Box>
        <Box sx={classes.boundary} ref={containerRef}>
          <Box
            ref={joystickRef}
            sx={{
              ...classes.joystick,
              transform: `translate(${x}%, ${y}%)`,
            }}
          />
        </Box>
        <Box sx={classes.arrow(position === 'right')}>
          <ArrowRightIcon />
        </Box>
      </Box>
      <Box sx={classes.arrow(position === 'bottom')}>
        <ArrowDownIcon />
      </Box>
    </Box>
  );
};

export default Joystick;
