import { SxProps, useTheme } from '@mui/material';

import { useMytaverse } from '../../../../../providers/MytaverseProvider';

import LoadingBg from '../../../../../public/images/ek/loading-bg.png';

import { DEVICES_LANDSCAPE_SIZES } from '../../../../../constants/devicesSizes';
import { colors } from '../../../../../constants/colors';
import { ScreenSizes } from '../../../../../interfaces';

export const useStyles = () => {
  const { breakpoints } = useTheme();
  const { appTheme } = useMytaverse();
  const {
    submitButton: { backgroundColorHover },
  } = appTheme.avatarPage.assets;

  return {
    root: {
      width: '100vw',
      minHeight: '100vh',
      position: 'relative',
      backgroundImage: `url(${LoadingBg})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: 'auto',
      overflow: 'auto',

      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        minHeight: '100dvh',
        height: 'auto',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        minHeight: '100dvh',
        height: 'auto',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        minHeight: '100dvh',
        height: 'auto',
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          minHeight: '100dvh',
          height: 'auto',
        },
    },
    wrapper: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      minHeight: '100vh',

      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        minHeight: '100dvh',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        minHeight: '100dvh',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        minHeight: '100dvh',
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          minHeight: '100dvh',
        },
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 2,
      padding: '0px 28px',

      [breakpoints.down(DEVICES_LANDSCAPE_SIZES.DESKTOP)]: {
        justifyContent: 'start',
      },

      '@media (max-width: 1400px)': {
        gap: 1,
      },

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        marginTop: '-45px',
        justifyContent: 'space-between',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        marginTop: '-45px',
        justifyContent: 'space-between',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        marginTop: '-45px',
        justifyContent: 'space-between',
      },

      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          marginTop: '-45px',
          justifyContent: 'space-between',
        },

      [`@media only screen and (max-height: 340px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          marginTop: '-45px',
          justifyContent: 'space-between',
        },
    },
    title: {
      fontFamily: 'Emirates-Medium !important',
      fontSize: 40,
      lineHeight: '56px',
      textAlign: 'center',

      '& span': {
        fontFamily: 'Emirates-Bold !important',
        fontSize: 40,
        lineHeight: '56px',
        textAlign: 'center',

        // '@media (max-width: 1400px)': {
        //   fontSize: 32,
        //   lineHeight: '42px',
        // },

        [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
          fontSize: 24,
          lineHeight: '32px',
        },

        [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
          fontSize: 22,
          lineHeight: '28px',
        },
        [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
          fontSize: 22,
          lineHeight: '28px',
        },
        [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
          fontSize: 22,
          lineHeight: '28px',
        },

        [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
          {
            fontSize: 22,
            lineHeight: '28px',
          },
      },

      // '@media (max-width: 1400px)': {
      //   fontSize: 32,
      //   lineHeight: '42px',
      // },

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 24,
        lineHeight: '32px',
      },

      [`@media screen and (orientation:landscape)
        and (max-device-height: 760px)
        and (max-device-width: 1180px)`]: {
        fontSize: 22,
        lineHeight: '28px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        fontSize: 22,
        lineHeight: '28px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        fontSize: 22,
        lineHeight: '28px',
      },
      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          fontSize: 22,
          lineHeight: '28px',
        },
    },
    text: {
      fontSize: 24,
      lineHeight: '38px',
      margin: '0 auto',
      textAlign: 'center',

      // '@media (max-width: 1400px)': {
      //   fontSize: 18,
      //   lineHeight: '24px',
      // },

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 16,
        lineHeight: '22px',
        color: '#707070',
      },

      [`@media screen and (orientation:landscape)
        and (max-height: 760px)
        and (max-width: 1180px)`]: {
        fontSize: 14,
        lineHeight: '16px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 650px)
        and (max-device-width: 1180px)`]: {
        fontSize: 14,
        lineHeight: '16px',
      },
      [`@media screen and (orientation:landscape)
        and (max-device-height: 570px)
        and (max-device-width: 900px)`]: {
        fontSize: 14,
        lineHeight: '16px',
      },
      [`@media only screen and (max-height: 575.98px) and (max-device-width: 750px) and (orientation: landscape)`]:
        {
          fontSize: 14,
          lineHeight: '16px',
        },
    },
    button: {
      width: '50%',
      maxWidth: '365px',
      color: colors.white,
      fontWeight: 500,
      background: appTheme.mainColor,
      fontSize: 24,
      lineHeight: '28px',
      paddingY: '14px',
      textTransform: 'none',
      marginBottom: 2,

      '&:hover': {
        background: backgroundColorHover,
      },

      '&:disabled': {
        color: colors.white,
        background: `${appTheme.mainColor}4D`,
        cursor: 'no-drop',
        pointerEvents: 'auto',
      },

      '@media (max-width: 1400px)': {
        fontSize: 20,
        lineHeight: '24px',
        maxWidth: '300px',
      },

      [breakpoints.down(ScreenSizes.TabletHorizontal)]: {
        fontSize: 18,
        lineHeight: '22px',
        paddingY: '8px',
      },

      [breakpoints.down(DEVICES_LANDSCAPE_SIZES.TABLET)]: {
        width: '100%',
        maxWidth: '265px',
      },

      '@media (max-width: 375px)': {
        fontSize: 16,
        lineHeight: '22px',
        paddingY: '8px',
      },
    } as SxProps,
  };
};
