import { useEffect, useState } from 'react';

import GameCastService from '../../../../../services/GameCastService';

import { UseInitGameCastStreamType } from './interfaces';
import { StreamingProviders } from '../interfaces';

const DEFAULT_GAME_CAST_REGION = 'us-east-2';

export const useInitGameCastStream: UseInitGameCastStreamType = (
  currentEvent,
  streamingProvider,
  pixelStreamingClient,
) => {
  const [streamId, setStreamId] = useState<string>('');
  const [streamRegion, setStreamRegion] = useState<string>('');

  useEffect(() => {
    if (
      !currentEvent ||
      !streamingProvider ||
      streamingProvider !== StreamingProviders.GameCast ||
      !pixelStreamingClient ||
      !pixelStreamingClient.gamecastClientData
    ) {
      return;
    }

    const { region, streamGroupId } = pixelStreamingClient.gamecastClientData;

    setStreamRegion(region || DEFAULT_GAME_CAST_REGION);

    if (streamGroupId) {
      setStreamId(streamGroupId);
    }
  }, [currentEvent, setStreamRegion, streamingProvider, pixelStreamingClient]);

  return {
    streamId,
    setStreamId,
    streamRegion,
    setStreamRegion,
  };
};
