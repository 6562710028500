export const classes = {
  root: {
    '& .str-chat__message-attachment': {
      margin: '6px 0 0',
      '& .str-chat__message-attachment--img': {
        width: 200,
        maxWidth: 200,
        height: 'auto',
        maxHeight: 300,
        objectFit: 'contain',
      },
    },
    '& .str-chat__modal__inner .image-gallery-content.fullscreen': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .image-gallery-image': {
        width: '100%',
        height: 'auto',
      },
    },
  },
};
