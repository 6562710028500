import React, { SyntheticEvent, useCallback, useMemo } from 'react';

import ChatTabsView from './ChatTabsView';

import { useChatState } from '../../../../../hooks/context';

import { IChatTabs } from './interfaces';
import { ChatTabs as ChatTabsEnum } from '../../../../../interfaces/channel';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { IXRHrPersonaTypes } from '../../../../../interfaces/profile';
import { useMytaverseEvent } from '../../../providers';

const { REACT_APP_EK_ENV } = process.env;

const ChatTabs = ({ sx = {} }: IChatTabs) => {
  const {
    activeTab,
    setActiveTab,
    isUnreadEventChannel,
    isUnreadPrivateChannel,
  } = useChatState();
  const { currentEvent } = useMytaverseEvent();
  const { user } = useMytaverse();

  const handleChangeTab = useCallback(
    (_: SyntheticEvent, value: ChatTabsEnum) => {
      setActiveTab(value);
    },
    [],
  );

  const isPrivateChatEnabled = useMemo(() => {
    if (!REACT_APP_EK_ENV) {
      return true;
    }

    const isOfficeHours =
      currentEvent?.eventTags &&
      currentEvent?.eventTags.indexOf('OfficeHours') > -1;
    const isHRFacilitator =
      user?.hrData?.persona?.toUpperCase() === IXRHrPersonaTypes.HR_Facilitator;

    return !isOfficeHours || (isOfficeHours && isHRFacilitator);
  }, [user, currentEvent]);

  return (
    <ChatTabsView
      sx={sx}
      activeTab={activeTab}
      onChangeTab={handleChangeTab}
      isUnreadEventChannel={isUnreadEventChannel}
      isUnreadPrivateChannel={isUnreadPrivateChannel}
      isPrivateChatEnabled={isPrivateChatEnabled}
    />
  );
};

export default ChatTabs;
